(function () {
    'use strict';
}());

app.service('PartService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    function ($http, $q, UserService, API_URL) {
        var self = this;
        self.searchProducts = function (term, exactMatch) {
            var defer = $q.defer();
            var url = API_URL + "/parts/search";

            var data = {
                term: term,
                return_exact_match: exactMatch
            };
            var user = UserService.getPersistedUser();
            if (user) { data.user_id = user.id; }

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data)
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });
            return defer.promise;
        };

        self.searchCustomerProducts = function (custNum, part, warehouseCustNos) {
            var defer = $q.defer();
            var url = API_URL + "/parts/search/customer/" + custNum.trim();
            url += '?' + 'partno=' + part.trim();
            if (warehouseCustNos) {
                url += '&warehouse_customers=' + warehouseCustNos.trim();
            }

            $http({
                "method": "GET",
                "url": url
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });
            return defer.promise;
        };

        self.getAllUoms = function () {
            var defer = $q.defer();
            var url = API_URL + "/uoms/get-all-uoms";

            $http({
                "method": "GET",
                "url": url,
                "cache": true,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then( function (response) {
                defer.resolve(response.data.units_of_measure);
            }, function () {
                defer.resolve(false);
            });
            return defer.promise;
        };

        self.refinedUoms = [
            { abbr: 'BG', desc: 'Bag' },
            { abbr: 'BO', desc: 'Bottle' },
            { abbr: 'BX', desc: 'Box' },
            { abbr: 'CN', desc: 'Can' },
            { abbr: 'CQ', desc: 'Cartridge' },
            { abbr: 'CA', desc: 'Case' },
            { abbr: 'DZ', desc: 'Dozen' },
            { abbr: 'DR', desc: 'Drum' },
            { abbr: 'EA', desc: 'Each' },
            { abbr: 'FT', desc: 'Foot' },
            { abbr: 'GL', desc: 'Gallon' },
            { abbr: 'IN', desc: 'Inch' },
            { abbr: 'OZ', desc: 'Ounce' },
            { abbr: 'PK', desc: 'Pack' },
            { abbr: 'PA', desc: 'Pail' },
            { abbr: 'PR', desc: 'Pair' },
            { abbr: 'PC', desc: 'Piece' },
            { abbr: 'LB', desc: 'Pound' },
            { abbr: 'QT', desc: 'Quart' },
            { abbr: 'RL', desc: 'Roll' },
            { abbr: 'ST', desc: 'Set' },
            { abbr: 'SH', desc: 'Sheet' },
            { abbr: 'SO', desc: 'Spool' },
            { abbr: 'YD', desc: 'Yard' }
        ];
    }
]);
