(function () {
    'use strict';
}());

app.directive('itemQuantity', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'directives/itemQuantity.html',
            replace: true,
            scope: {
                item: '=',
                decrement: '&',
                manual: '&',
                increment: '&'
            },
            require: 'ngModel'
        }
    }
]);
