(function () {
    'use strict';
}());

app.service('UserService', [
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    '$window',
    'jwtHelper',
    'API_URL',
    'DEFAULT_CATALOGS',
    'OPEN_BROWSE_COMPANY_NUMBER',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'OPEN_BROWSE_WAREHOUSE',
    'PASSWORD_REGEX',
    'CatalogService',
    '$cookies',
    'DOMAIN',
    function ($rootScope, $http, $location, $q, $state, $window, jwtHelper, API_URL, DEFAULT_CATALOGS, OPEN_BROWSE_COMPANY_NUMBER, OPEN_BROWSE_CUSTOMER_NUMBER, OPEN_BROWSE_WAREHOUSE, PASSWORD_REGEX, CatalogService, $cookies, DOMAIN) {
        var baseUrl = API_URL;
        var self = this;

        var setCatalogInfo = function (catalogs) {
            var queryString = "";
            angular.forEach(catalogs, function (catalog) {
               queryString += catalog + ",";
            });

            if (self.getIsAuthenticated() && self.getPersistedUser()) {
                $window.sessionStorage.setItem('userCatalogs', JSON.stringify(catalogs));
            }
        };

        this.getCompanyNumber = function () {
            var companyNumber = OPEN_BROWSE_COMPANY_NUMBER;
            var user = self.getPersistedUser();
            if (self.getIsAuthenticated() && user) {
                if (undefined !== user.company_number && null !== user.company_number) {
                    return user.company_number;
                }
            }

            return companyNumber;
        };

        this.getCustomerNumber = function () {
            var customerNumber = OPEN_BROWSE_CUSTOMER_NUMBER;
            var user = self.getPersistedUser();
            if (self.getIsAuthenticated() && user) {
                if (undefined !== user.customer_number && null !== user.customer_number) {
                    return user.customer_number;
                }
            }

            return customerNumber;
        };

        this.getCustomerWarehouse = function () {
            var warehouse = OPEN_BROWSE_WAREHOUSE;
            var user = self.getPersistedUser();
            if (self.getIsAuthenticated() && user) {
                if (undefined !== user.warehouse && null !== user.warehouse) {
                    return user.warehouse;
                }
            }

            return warehouse;
        };

        function generateSessionId() {
            var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var sessionId = '';
            for (var i = 32; i > 0; --i) sessionId += chars[Math.floor(Math.random() * chars.length)];
            return sessionId;
        }

        this.getSessionId = function () {
            var deferral = $q.defer();
            var sessionId = $window.localStorageOverride["session_id"];
            if (self.getIsAuthenticated() &&
                $cookies.get('sessionID') &&
                $cookies.get('isPunchOut')
            ) {
                sessionId = $cookies.get('sessionID');
            }
            if (undefined !== sessionId && sessionId) {
                try {
                    JSON.parse(sessionId);
                    deferral.resolve(JSON.parse(sessionId));
                } catch (e) {
                    deferral.resolve(sessionId);
                }

                return deferral.promise;
            }

            sessionId = generateSessionId();

            $window.localStorageOverride["session_id"] = sessionId ? JSON.stringify(sessionId) : '';
            deferral.resolve(sessionId);
            return deferral.promise;
        };

        this.getDefaultShipTo = function () {
            var defaultShipTo = null;
            var user = this.getPersistedUser();
            if (self.getIsAuthenticated() && user) {
                if (user.shipto_id && !user.can_multiview) {
                    defaultShipTo = user.shipto_id;
                }
            }

            return defaultShipTo;
        };

        this.getUserCatalogs = function () {
            var catalog = DEFAULT_CATALOGS;
            var user = self.getPersistedUser();
            if (self.getIsAuthenticated() && user && user.catalog && user.catalog.length) {
                catalog = user.catalog;
            }
            setCatalogInfo(catalog);

            return catalog;
        };

        this.isNullCatalog = function () {
            var catalogs = this.getUserCatalogs();
            return catalogs && catalogs.length === 1 && catalogs[0] === 'Custom_Null';
        };

        this.getUserCatalogsQuery = function() {
            var catalogs = this.getUserCatalogs();

            var query = '';
            for (var i=0; i<catalogs.length; i++) {
                query += '&catalogs[]=' + catalogs[i];
            }

            return query;
        };

        this.login = function (username, password, skipNotification) {
            var deferral = $q.defer();
            var url = baseUrl + "/login_check";
            var data = {
                _username: username,
                _password: password
            };

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data)
            }).then( function (response) {
                var data = response.data;
                $cookies.put('token', JSON.stringify(data.token));
                var user = jwtHelper.decodeToken(data.token);
                self.getUser(user.user_id).then(function () {
                    $rootScope.$broadcast('login');
                    if (!skipNotification) {
                        $rootScope.$broadcast('notify', {message: "Log In successful"});
                    }
                    deferral.resolve(true);
                }).catch(function (err) {
                    console.error(err);
                });
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.formatLimitReset = function (limit_reset) {
            return !limit_reset  || undefined === limit_reset ? null : moment(limit_reset).format('YYYY-MM-DD');
        };

        this.updateUser = function () {
            return self.getUser(this.getUserId()).then(function (data) {
                if ($cookies.get("isPunchOut")) {
                    data.session_id = $cookies.get('sessionID');
                    data.punchout = true;
                }
                $window.localStorageOverride["user"] = JSON.stringify(data);
            }).catch( function(err) {
                console.error(err);
            });
        };

        this.updateUserAdmin = function (user) {
            var deferral = $q.defer(),
                data = angular.copy(user);
            delete data.id;
            delete data.customer_type;
            delete data.created;
            delete data.session_id;
            delete data.session_expiry;
            delete data.welcome_sent;
            delete data.registration_notification_sent;
            delete data.shipping_addresses;
            delete data.billing_addresses;
            delete data.payment_methods;
            delete data.company_info;
            data.limit_type = (data.limit_type === "") ? null : data.limit_type;
            data.spend_limit = (data.spend_limit === "") ? null : data.spend_limit;
            if (data.unlimited_spend) {
                data.spend_limit = -1;
                delete data.unlimited_spend;
            }
            data.max_limit = (data.max_limit === "") ? null : data.max_limit;
            if (data.unlimited_max_spend) {
                data.max_limit = -1;
                delete data.unlimited_max_spend;
            }

            if ('units' !== data.limit_type) {
                data.limit_years = null;
                data.limit_reset = null;
            } else {
                data.limit_years = !data.limit_years ? null : data.limit_years;
                data.limit_reset = self.formatLimitReset(data.limit_reset);
            }

            $http({
                method: "PUT",
                url: baseUrl + "/user/" + user.id,
                data: JSON.stringify(data),
                headers: { "Authorization" : "Bearer {" +this.getToken()+ "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.logout = function (notify) {
            $cookies.put('token', null);
            $cookies.remove('token');

            $window.sessionStorage.clear();
            if (typeof $window.localStorageOverride.clear === "function") {
                $window.localStorageOverride.clear();
            } else {
                $window.localStorage.removeItem("user");
                $window.localStorage.removeItem("session_id");
            }
            $rootScope.$broadcast('logout');
            $rootScope.$broadcast('cart_update');
            if (notify) {
                $rootScope.$broadcast('notify', { message: "Logged Out" });
            }

            return false;
        };

        this.getIsAuthenticated = function () {
            return !!$cookies.get('token');
        };

        this.getToken = function () {
            return !!$cookies.get('token') ? $cookies.get('token') : false;
        };

        this.getIsPunchOut = function () {
            return !!$cookies.get('isPunchOut');
        };

        this.getPersistedUser = function () {
            try {
                var persistedUser = $window.localStorageOverride["user"];
                return persistedUser ? JSON.parse(persistedUser) : null;
            } catch (e) {
                console.error("Unable to parse persisted user:", e);
                return null;
            }

            return null;
        };

        this.getUserId = function() {
            return (self.getPersistedUser()) ? self.getPersistedUser().id : null;
        };

        this.getUser = function (id, promptLogin) {
            var deferral = $q.defer();

            // var id = null;
            var token = self.getToken();

            if(token || !$window.localStorageOverride["guest"]) {

                try {
                    var decodedToken = jwtHelper.decodeToken(token);
                    id = decodedToken.user_id;
                    if (Date.now() / 1000 >= decodedToken.exp) {
                        self.logout();
                        deferral.reject('Token has expired.');
                        return deferral.promise;
                    }
                } catch (e) {
                    self.logout();
                    deferral.reject('Failed to parse token.');
                }

            }

            if(!id) {
                $window.localStorageOverride["guest"] = true;
                deferral.reject('Unable to retrieve user profile information.');
            } else {
                var url = baseUrl + '/user/' + id;
                $http({
                    method: "GET",
                    url: url,
                    headers: { "Authorization": "Bearer {" + token + "}" }
                }).then( function (response) {
                    var data = response.data;

                    data.availableCatalogs = [];
                    if (data.catalog && data.catalog.length >= 1) {
                        angular.forEach(data.catalog, function (x) {
                            data.availableCatalogs.push({
                                name: x,
                                status: false
                            });
                        });
                    }
                    if (data.availableCatalogs.length) {
                        angular.forEach(data.availableCatalogs, function (catalogFacet) {
                            if (data.catalog_meta_data && data.catalog_meta_data.length >= 1) {
                                angular.forEach(data.catalog_meta_data, function(metaData){
                                    if (catalogFacet.name == metaData.catalog) {
                                        catalogFacet.label = metaData.name.charAt(0).toUpperCase() + metaData.name.slice(1);
                                        catalogFacet.imageUrl = metaData.imageUrl
                                    }
                                });
                            }
                            if (!catalogFacet.product_count) {catalogFacet.product_count = 0;}
                            if (!catalogFacet.label) {catalogFacet.label = catalogFacet.name;}
                            if (!catalogFacet.imageUrl) {catalogFacet.imageUrl = null;}
                        });
                    }
                    if (data.site_csr && data.site_csr.email && !data.site_csr.name) {
                        data.site_csr.name = data.site_csr.email;
                    }

                    if ($cookies.get("isPunchOut")) {
                        data.session_id = $cookies.get('sessionID');
                        data.punchout = true;
                    }
                    $window.localStorageOverride["user"] = JSON.stringify(data);
                    $window.localStorageOverride["session_id"] = data.session_id ? JSON.stringify(data.session_id) : '';
                    $window.localStorageOverride["guest"] = false;
                    deferral.resolve(data);
                }, function () {
                    $window.localStorageOverride["guest"] = true;
                    deferral.reject('Unable to retrieve user profile information.');
                });
            }

            return deferral.promise;
        };

        this.getAssociateStatus = function(userName){
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: baseUrl + '/associate/status/' + userName
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Associate status check failed')
            });
            return deferral.promise;
        };

        this.getUserStatus = function (userName) {
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: baseUrl + '/user/status/' + userName
            }).then( function (response) {
                if (response.data.id && !response.data.active) {
                    response.data.errorMessage = "This user account already exists, but it is currently disabled. Please contact ";
                    var emailDomain = response.data.username.substring(response.data.username.lastIndexOf("@") +1).toLowerCase();
                    if (emailDomain === 'vallen.com') {
                        response.data.errorMessage += 'GoDigital@vallen.com for support.';
                    } else {
                        response.data.errorMessage += 'support@vallen.com for support.';
                    }
                } else {
                    response.data.errorMessage = "Invalid credentials";
                }
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('User status check failed')
            });
            return deferral.promise;
        };

        this.getUserSettings = function (custNo, userName) {
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: baseUrl + '/user/settings/' + custNo + '/' + userName
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Unable to retrieve user settings.');
            });
            return deferral.promise;
        };

        this.getUserSettingFields = function () {
            return [
                'account_overview',
                'can_edit_bill_to',
                'can_edit_ship_to',
                'can_multiview',
                'can_share_cart',
                'can_submit_non_stock',
                'can_submit_orders',
                'can_submit_quotes',
                'catalog',
                'disable_cc',
                'email_invoice',
                'email_scantoweb',
                'email_tracking',
                'express_checkout',
                'manage_departments',
                'manage_users',
                'open_invoices',
                'order_history',
                'paid_invoices',
                'summary_invoices',
                'require_cc',
                'site_chat',
                'site_tour',
                'shipto_id',
                'user_setup'
            ];
        };

        this.getUserById = function (id, custNo) {
            var url = baseUrl + '/user/' + id;
            if (custNo) {
                url += '?customer_number=' + custNo;
            }
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Unable to locate the user you\'re looking for')
            });

            return deferral.promise;
        };

        this.getIsVallenXpress = function() {
            if (self.getPersistedUser()) {
                return self.getPersistedUser().vallen_xpress;
            } else {
                return false;
            }
        };

        this.getUserApprovalWorkflow = function (id, custNo) {
            var url = baseUrl + '/user-approval-workflow/' + id;
            if (custNo) {
                url += '/' + custNo;
            }
            var deferral = $q.defer();

            if (!id) {
                deferral.resolve(false);
                return deferral.promise;
            }
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.putUser = function (user, id, bypassWorkflow) {
            var deferral = $q.defer();
            var url = baseUrl + "/user/" + id;

            var data = angular.copy(user);
            delete data.id;
            delete data.created;
            delete data.session_id;
            delete data.session_expiry;
            delete data.welcome_sent;
            delete data.registration_notification_sent;
            delete data.shipping_addresses;
            delete data.billing_addresses;
            delete data.payment_methods;
            delete data.allowedCompanies;
            delete data.cart_requests;
            delete data.shared_carts;
            delete data.scan_to_web_entries;
            data.limit_reset = self.formatLimitReset(data.limit_reset);

            if (bypassWorkflow) {
                delete data.approval_workflow;
                delete data.spend_limit;
                delete data.max_limit;
                delete data.spent_amount;
                delete data.is_supervisor;
                delete data.supervisors;
                delete data.limit_reset;
            }

            var response = {};
            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                $window.localStorageOverride["user"] = JSON.stringify(response.data);
                deferral.resolve(response);
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };


        this.deleteUser = function (user_id) {
            var deferral = $q.defer();

            $http({
                method: "DELETE",
                url: baseUrl + "/user/" + user_id,
                headers: { "Authorization": "Bearer {" + self.getToken() +"}" }
            }).then( function () {
                deferral.resolve(true);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getCustomerSummary = function () {
            var persistedUser = self.getPersistedUser();
            if (!persistedUser) {
                return false;
            }

            var url = baseUrl + '/user/customerSummary/' + persistedUser.id,
                deferral = $q.defer();
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then(function (response) {
                deferral.resolve(response.data);
            },function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getCustomerMaster = function () {
            var persistedUser = self.getPersistedUser();
            if (!persistedUser) {
                return false;
            }

            var url = baseUrl + '/user/customerMaster/' + persistedUser.id,
                deferral = $q.defer();
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then(function (response) {
                deferral.resolve(response.data);
            },function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getUserShipTos = function () {
            var deferral = $q.defer();
            var persistedUser = self.getPersistedUser();
            if (!persistedUser) {
                deferral.resolve(false);
            } else {
                $http({
                    method: "GET",
                    url: baseUrl + '/user/ship-tos/' + persistedUser.id,
                    headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
                }).then(function (response) {
                    deferral.resolve(response.data);
                },function () {
                    deferral.resolve(false);
                });
            }

            return deferral.promise;
        };

        this.postUser = function (user) {
            var deferral = $q.defer();
            var url = baseUrl + '/user';
            var data = {
                username: user.username,
                password: user.password,
                name: user.name,
                phone: user.phone,
                phone_extension: user.phone_extension,
                department: user.department,
                domain: DOMAIN,
                allow_all_departments: user.allow_all_departments === undefined ? 0 : user.allow_all_departments,
                customer_number: user.customer_number,
                catalog: user.catalog === undefined ? [] : user.catalog,
                supervisors: user.supervisors === undefined ? [] : user.supervisors,
                customer_type: 'sxe',
                cdf: user.cdf,
                user_type: user.user_type
            };

            var optionalFields = [
                'token',
                'roles',
                'approver',
                'approval_workflow',
                'departments',
                'spend_limit',
                'max_limit',
                'limit_type',
                'limit_years'
            ];
            optionalFields = optionalFields.concat(self.getUserSettingFields());

            angular.forEach(optionalFields, function (prop) {
                if (user.hasOwnProperty(prop)) {
                    data[prop] = user[prop];
                }
            });

            if ('units' !== user.limit_type) {
                data.limit_years = null;
                data.limit_reset = null;
            } else {
                data.limit_reset = self.formatLimitReset(user.limit_reset);
            }
            if (user.unlimited_spend) {
                data.spend_limit = -1;
            }
            if (user.unlimited_max_spend) {
                data.max_limit = -1;
            }

            var response = {};

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve(response);
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };

        this.getUsers = function (params) {
            var deferral = $q.defer(),
                url = baseUrl + '/users?_format=json';
            if (params == undefined) {
                params = {
                    order_direction: 'ASC',
                    limit: '25',
                    offset: '1',
                    active: 'active'
                };
            }
            if (params.customer_numbers !== undefined && params.customer_numbers !== "") url += '&customer_numbers=' + params.customer_numbers;
            if (params.domain !== undefined && params.domain !== "") url += '&domain=' + params.domain;
            if (params.filter !== undefined && params.filter !== "") url += '&filter=' + params.filter;
            if (params.order_by !== undefined && params.order_by !== "") url += '&order_by=' + params.order_by;
            if (params.has_role !== undefined && params.has_role !== "") url += '&has_role=' + params.has_role;
            if (params.order_direction !== undefined && params.order_direction !== "") url += '&order_direction=' + params.order_direction;
            if (params.limit !== undefined && params.limit !== "") url += '&limit=' + params.limit;
            if (params.offset !== undefined && params.offset !== "") url += '&offset=' + params.offset;
            if (params.active !== undefined && params.active !== "") url += '&active=' + params.active;

            $http({
                method: 'GET',
                url: url,
                headers: { "Authorization": "Bearer {"+ self.getToken() +"}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getUsersByCustomerNumber = function (customer_number, fields) {
            var deferral = $q.defer(),
            url = baseUrl + '/users/' + customer_number;
            if (fields) {
                url += '?fields=' + fields;
            }

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Unable to locate the users you\'re looking for');
            });

            return deferral.promise;
        };

        this.getSupervisors = function (params) {
            var deferral = $q.defer();

            if (undefined === params.id || undefined === params.type) {
                deferral.reject('Missing required data');
                return deferral.promise;
            }

            var url = baseUrl + '/' + params.type + '/' + params.id + '/supervisors?';

            if (undefined !== params.filter) {
                url += '&filter=' + params.filter;
            }
            if (undefined !== params.unit_type) {
                url += '&unit_type=' + params.unit_type;
            }
            if (undefined !== params.customer_number) {
                url += '&customer_number=' + params.customer_number;
            }

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Unable to locate the users you\'re looking for');
            });

            return deferral.promise;
        };

        this.recoverPassword = function (username) {
            var url = baseUrl + '/user/' + username + '/hash',
                response_link = $location.protocol() + '://' + $location.host(),
                deferral = $q.defer();

            var data = { callback: response_link + "/reset"};
            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(data)
            }).then( function () {
                deferral.resolve(true);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.resetPasswordUnauthenticated = function (data) {
            var url = baseUrl + '/user/' + data.username + '/hash/' + data.hash,
                deferral = $q.defer();
            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve({ status: response.status, message: response.data.message });
            }, function (err) {
                deferral.resolve({ status: err.status, message: err.data.message || 'Request failed' });
            });

            return deferral.promise;
        };

        this.resetPasswordAuthenticated = function (user, id) {
            var deferral = $q.defer();
            var url = baseUrl + "/user/" + id;
            var data = angular.copy(user);
            delete data.id;
            delete data.customer_type;
            delete data.created;
            delete data.session_id;
            delete data.session_expiry;
            delete data.welcome_sent;
            delete data.registration_notification_sent;
            delete data.shipping_addresses;
            delete data.billing_addresses;
            delete data.payment_methods;
            data.updated_password = user.update_password;

            var response = {};

            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response);
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };

        this.isAdmin = function (user) {
            return user.roles.indexOf("ROLE_ADMIN") !== -1 || user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        };

        this.isSuperAdmin = function (user) {
            return user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        };

        this.isOpenBrowse = function (user) {
            var storeFrontAccounts = ['87023', '99762', '122185'];
            return user ? storeFrontAccounts.indexOf(user.customer_number) > -1 : true;
        };

        this.canViewAccountOverview = function (user) {
            if (!user) { return false; }
            return (user.account_overview && !this.isOpenBrowse(user));
        };

        this.canViewBillingInfo = function (user) {
            return (!user || (user.can_edit_bill_to && !this.getIsPunchOut()));
        };

        this.canViewManageUsers = function (user) {
            return (user && user.manage_users);
        };

        this.canViewManageDepartments = function (user) {
            return (user && user.manage_departments);
        };

        this.canViewMyOrders = function (user) {
            return (user && !this.getIsPunchOut());
        };

        this.canViewQuoteRequest = function (user) {
            return (user && user.can_submit_quotes);
        };

        this.canViewOrderNonStock = function (user) {
            return (user && user.can_submit_orders && user.can_submit_non_stock && !this.getIsPunchOut());
        };

        this.canViewOrderHistory = function (user) {
            if (!user) { return false; }
            return (user.order_history || user.is_supervisor || (DOMAIN !== 'vallen' && DOMAIN === user.domain));
        };

        this.canViewReviewQuotes = function (user) {
            return (user && user.review_quotes) || $rootScope.currentCustomer.quote_report_active;
        };

        this.canViewOpenInvoices = function (user) {
            if (!user) { return false; }
            return ((user.open_invoices || (DOMAIN !== 'vallen' && DOMAIN === user.domain)) && !this.isOpenBrowse(user) && !this.getIsPunchOut());
        };

        this.canViewPaidInvoices = function (user) {
            if (!user) { return false; }
            return ((user.paid_invoices || (DOMAIN !== 'vallen' && DOMAIN === user.domain)) && !this.isOpenBrowse(user) && !this.getIsPunchOut());
        };

        this.canViewSummaryInvoices = function (user) {
            if (!user) { return false; }
            return (user.summary_invoices
                && $rootScope.currentCustomer.summary_invoices
                && this.getIsPunchOut()
            );
        };

        this.postCustomerApplication = function (data) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer-application';
            var response = {};

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve(response);
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };

        this.getCompanyApplications = function (applicationFilter) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer-applications?_format=json';
            if (applicationFilter == undefined) {
                applicationFilter = {
                    order_direction: 'DESC',
                    limit: '25',
                    offset: '1'
                };
            }
            if (applicationFilter !== undefined) {
                if (applicationFilter.type !== undefined && applicationFilter.type !== "") {
                    url += "&customer_type=" + applicationFilter.type;
                }
                if (applicationFilter.status !== undefined && applicationFilter.status !== "") {
                    url += "&status=" + applicationFilter.status;
                }
                if (applicationFilter.offset !== undefined && applicationFilter.offset !== "") {
                    url += '&offset=' + applicationFilter.offset;
                }
                if (applicationFilter.limit !== undefined && applicationFilter.limit !== "") {
                    url += '&limit=' + applicationFilter.limit;
                }
                if (applicationFilter.order_direction !== undefined && applicationFilter.order_direction !== "") {
                    url += '&order_direction=' + applicationFilter.order_direction;
                }
                if (applicationFilter.bdm_prospect !== undefined && applicationFilter.bdm_prospect !== false) {
                    url += '&bdm_prospect=' + applicationFilter.bdm_prospect;
                }
                if (applicationFilter.filter !== undefined && applicationFilter.filter !== "") {
                    url += "&filter=" + applicationFilter.filter;
                }
            }

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function(response){
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });
            return deferral.promise;
        };

        this.getCompanyApplication = function (id) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer-application/' + id;
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then( function(response){
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });
            return deferral.promise;
        };

        this.putCompanyApplication = function (application) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer-application/' + application.id;
            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(application),
                headers: { "Authorization" : "Bearer {" +this.getToken()+ "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });
            return deferral.promise;
        };

        this.canViewUserSetup = function (user) {
            return (user && user.user_setup);
        };

        this.canViewUniversalCrib = function (user) {
            return ((user && user.universal_crib) && ($rootScope.currentCustomer && $rootScope.currentCustomer.universal_crib));
        };

        this.postUserRequestSetup = function (data) {
            var deferral = $q.defer();
            var url = baseUrl + '/user-setup';

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Bearer {" + self.getToken() + "}" }
            }).then(function (response) {
                deferral.resolve(response.status);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };
    }
]);
