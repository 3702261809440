(function () {
    'use strict';
}());

app.controller('BrandsController', [
    '$scope',
    '$filter',
    'AlgoliaService',
    'FilterService',
    'CatalogService',
    'UserService',
    function ($scope, $filter, AlgoliaService, FilterService, CatalogService, UserService) {
        FilterService.deleteFilters();

        $scope.loading = true;
        $scope.brands = [
            {letter: "#", brands: []},
            {letter: "A", brands: []},
            {letter: "B", brands: []},
            {letter: "C", brands: []},
            {letter: "D", brands: []},
            {letter: "E", brands: []},
            {letter: "F", brands: []},
            {letter: "G", brands: []},
            {letter: "H", brands: []},
            {letter: "I", brands: []},
            {letter: "J", brands: []},
            {letter: "K", brands: []},
            {letter: "L", brands: []},
            {letter: "M", brands: []},
            {letter: "N", brands: []},
            {letter: "O", brands: []},
            {letter: "P", brands: []},
            {letter: "Q", brands: []},
            {letter: "R", brands: []},
            {letter: "S", brands: []},
            {letter: "T", brands: []},
            {letter: "U", brands: []},
            {letter: "V", brands: []},
            {letter: "W", brands: []},
            {letter: "X", brands: []},
            {letter: "Y", brands: []},
            {letter: "Z", brands: []}
        ];

        AlgoliaService.browseTaxonomy({
            facetFilters: [['type:vendor']]
        }).then(function(vendors) {
            if (vendors) {
                angular.forEach(vendors, function (v) {
                    if (vendors.product_count <= 0) {
                        return true;
                    }

                    angular.forEach($scope.brands, function (b) {
                        if (b.letter === "#") {
                            if (!isNaN(parseFloat(v.name.slice(0, 1)))) {
                                b.brands.push(v);
                                return false;
                            }
                        } else {
                            if (v.name.slice(0, 1).toUpperCase() == b.letter.toUpperCase()) {
                                b.brands.push(v);
                                return false;
                            }
                        }
                    });
                });

                for (var i = $scope.brands.length - 1; i >= 0; i--) {
                    if (!$scope.brands[i].brands.length) {
                        $scope.brands.splice(i, 1);
                    } else {
                        var unique = {}, distinct = [], array = $scope.brands[i].brands;
                        for (var o in array) {
                            if (typeof(unique[array[o].slug]) == "undefined") {
                                distinct.push(array[o]);
                            }
                            unique[array[o].slug] = 0;
                        }

                        $scope.brands[i].brands = $filter('orderBy')(distinct, 'name');
                    }
                }

                $scope.brands = chunk($scope.brands, ($scope.isSmall) ? 2 : 3);
            } else {
                $scope.error = true;
            }
        }).catch(function(err) {
            console.error(err);
            $scope.error = true;
        }).finally(function() {
            $scope.loading = false;
        });

        function chunk(arr, size) {
            if (arr.length == 0) {
                return [];
            }

            var retArr = [];
            for (var i = 0; i < arr.length; i += size) {
                retArr.push(arr.slice(i, i + size));
            }

            return retArr;
        }

        $scope.isNullCatalog = UserService.isNullCatalog();
        $scope.errorMessage = function() {
            if (!$scope.loading) {
                if ($scope.isNullCatalog) {
                    return CatalogService.nullCatalogMsg();
                } else if ($scope.error) {
                    return 'Uh Oh! An unknown error occurred while retrieving data. Please refresh the page to try again.';
                } else if (!$scope.brands.length) {
                    return 'Uh Oh! There are no products found in this catalog. Please contact support.';
                }
            }
            return null;
        };
    }
]);
