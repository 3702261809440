(function () {
    'use strict';
}());

var states = {
    'cart.summary': 1,
    'cart.login': 2,
    'cart.order': 3,
    'cart.payment': 4,
    'cart.review': 5,
    'cart.confirm': 6,
    'cart.approval': 7,
    'cart.error': 8
};

app.controller('CartChildController', [
    '$scope',
    '$state',
    '$window',
    'UserService',
    'AnalyticsService',
    function($scope, $state, $window, UserService, AnalyticsService) {
        var curState = states[$state.$current.self.name];
        if (curState <= $scope.$parent.state || curState <= 3 || $window.sessionStorage['s2wOverride']) {
            $scope.$parent.state = curState;
        } else {
            var beginning = 'cart.summary';
            if ($window.sessionStorage["cart_state"] >= 3) {
                beginning = 'cart.order';
            }
            $state.go(beginning);
            $scope.$parent.state = states[beginning];
        }

        if (!UserService.getIsAuthenticated() && $state.$current.self.name === 'cart.payment') {

            var thisUser = {
                name: $scope.data.name,
                phone: $scope.data.phone,
                email: $scope.data.email,
                company: $scope.data.shippingCompanyName
            };

            AnalyticsService.identifyUser(thisUser);

        }

        if ($window.sessionStorage['s2wOrderConfirmation']) {
            $scope.orders = JSON.parse($window.sessionStorage['s2wOrderConfirmation']);
        }
    }
]);

app.controller('CartController', [
    '$rootScope',
    '$scope',
    '$sce',
    '$filter',
    '$location',
    '$window',
    '$uibModal',
    '$timeout',
    'UIService',
    'BillingInfoService',
    'AlgoliaService',
    'CartService',
    'UserService',
    'PricingService',
    'OrderService',
    'CompanyService',
    '$q',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    '$state',
    'AnalyticsService',
    'PromoCodeService',
    'moment',
    '$cookies',
    'PHONE',
    'CENPOS_MERCHANT_ID',
    'DepartmentService',
    'StatesService',
    'ShippingAddressService',
    'ShoppingListService',
    'cartSessionId',
    'UDFService',
    'PunchoutService',
    function ($rootScope, $scope, $sce, $filter, $location, $window, $uibModal, $timeout, UIService, BillingInfoService, AlgoliaService, CartService, UserService, PricingService, OrderService, CompanyService, $q, OPEN_BROWSE_CUSTOMER_NUMBER, $state, AnalyticsService, PromoCodeService, moment, $cookies, PHONE, CENPOS_MERCHANT_ID, DepartmentService, StatesService, ShippingAddressService, ShoppingListService, cartSessionId, UDFService, PunchoutService) {
        $scope.udfDisabled = false; // set to true will disable checkout for accounts with UDFs
        $scope.loading = true;
        $scope.isAuthenticated = UserService.getIsAuthenticated();
        $scope.state = 1;
        $scope.zipPattern = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/i;
        $scope.pricingLoading = false;
        $scope.invalidItems = false;
        $scope.shipping = 'TBD';
        $scope.tax = 'TBD';
        $scope.includeBlackbook = false;
        $scope.cvcPopover = $sce.trustAsHtml('<div class="card back"><span></span></div>');
        $scope.use_cc = true;
        $scope.cartSessionId = cartSessionId ? cartSessionId : null;
        $scope.cartType = CartService.getCartType(cartSessionId);
        $scope.orderType = $scope.cartType === 'quote' ? 'q' : 'o';
        $scope.estimated_price = false;
        $scope.maxShipTo = 50;
        $scope.showPromoCode = false;
        $scope.submitApprovalError = false;
        $scope.submitOrderError = false;

        PromoCodeService.getLiveCount().then(function (data) {
            $scope.showPromoCode = data > 0;
        });

        var lineBatchSize = 20;
        $scope.itemLimit = {
            summary: angular.copy(lineBatchSize),
            review: angular.copy(lineBatchSize)
        };
        $scope.loadMoreLines = function (limit) {
            $scope.itemLimit[limit] += lineBatchSize;
        };

        function setBillingZip(z) {
            $window.sessionStorage['BillingZip'] = z;
        }

        function getBillingZip() {
            return $window.sessionStorage['BillingZip'];
        }

        $scope.ccYears = [];
        var curYear = new Date().getFullYear();
        for (var i = 0; i < 15; i++){
            $scope.ccYears.push(curYear + i);
        }

        $scope.loadingUnspsc = true;
        $scope.punchoutHook = $sce.trustAsResourceUrl($cookies.get('punchoutHook'));
        $scope.processPunchout = function() {
            PunchoutService.logPunchout($scope.cart).then(function() {
                // logPunchout success
            }).catch(function(err) {
                console.error('Unable to log punchout submission', err);
            }).finally(function() {
                CartService.deleteCart();
            });
        };

        $scope.punchoutForm = function() {
            return $scope.punchoutHook && $scope.cartType !== 'quote';
        };

        $scope.express = {
            saveForExpress: false,
            hasExpressCheckout: false,
            data: null
        };

        $scope.loginData = {};

        $scope.data = {
            shippingState: null,
            billingState: null,
            cardType: 'Visa',
            cardExpMonth: new Date().getMonth() + 1,
            cardExpYear: new Date().getFullYear(),
            map: true,
            promo_codes: {
                "discount": false,
                "free_freight": false
            }
        };

        $scope.shippingAddresses = [];
        $scope.canEditShipTo = true;

        $scope.billingAddresses = [];
        $scope.canEditBillTo = true;

        if ($scope.user == undefined) {
            try {
                $scope.user = JSON.parse($window.localStorageOverride["user"]);
            } catch(e){}
        }

        $scope.orderLimit = -1;
        if ($scope.user) {
            if ($scope.user.customer_order_limit >= 0) {
                $scope.orderLimit = $scope.user.customer_order_limit;
            }
            UserService.getUserApprovalWorkflow($scope.user.id, $scope.user.customer_number).then(function (data) {
                if (data) {
                    angular.forEach(data, function (v) {
                        // if (v.max_limit >= 0 && v.max_limit > $scope.orderLimit) {
                        if (v.max_limit >= 0) { // user max_limit >= 0 supersedes customer order_limit
                            $scope.orderLimit = v.max_limit;
                        }
                    });
                }
            }).catch(function() {
                // ignore error
            }).finally( function () {
                $scope.calculate();
            });

            CompanyService.getCompanySummary($scope.user.customer_number).then(function (data) {
                $scope.customerData = data;
            });

            OrderService.getExpressCheckout().then(function (data) {
                if (data) {
                    $scope.express.hasExpressCheckout = true;
                    $scope.express.data = data;
                }
            }, function () {
                $scope.express.hasExpressCheckout = false;
                //Ignore this error.
            });
        } else {
            $scope.orderLimit = 0;  // default during COVID-19
            CompanyService.getCompanySettings(OPEN_BROWSE_CUSTOMER_NUMBER).then(function (settings) {
                $scope.orderLimit = undefined !== settings.order_limit ? settings.order_limit : -1;
            }).catch(function() {
                // ignore error
            }).finally( function () {
                $scope.calculate();
            });
        }

        UserService.getSessionId().then(function (data) {
            $scope.session = data;
        });

        CartService.initSelectItems($scope);

        $scope.removeItems = function(deselectItem) {
            var selected = $filter('filter')($scope.cart.cart_lines, {selected: true});

            if (selected.length) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'deleteCartItemsModal.html',
                    controller: 'deleteCartItemsModalInstanceCtrl',
                    windowClass: '',
                    resolve: {
                        cart: function(){
                            return $scope.cart
                        },
                        items: function(){
                            return selected;
                        }
                    }
                });

                modalInstance.result.then(function (data) {
                    $scope.cart = data;
                    $rootScope.$broadcast('cart_update');
                    initUdfData();
                    CartService.updateCartCache($scope.cart);
                    $scope.$broadcast('refreshCart');
                    $scope.calculate();
                }, function() {
                    if (deselectItem) {
                        var i = $filter('filter')($scope.cart.cart_lines, {id: deselectItem.id});
                        if (i) { i[0].selected =false; }
                    }
                });
            }
        };

        $scope.addToShoppingList = function () {
            //check if user is logged in
            var user = UserService.getPersistedUser();
            if (null == user) {
                var errorMsg = "You must be logged in to use this feature.";
                $rootScope.$broadcast('notify', {message: errorMsg});
                return;
            }

            var selectedItems = $filter('filter')($scope.cart.cart_lines, {selected: true});

            $rootScope.$broadcast('open-loading-modal');

            // check if Shopping lists already exist, specify that only lists owned by this user are to be returned
            ShoppingListService.getLists({ owner: true }).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data) {
                    ShoppingListService.shoppingListModal(data, selectedItems);
                } else {
                    var errorMsg = "An error occurred while checking for Shopping List. Please try again.";
                    $rootScope.$broadcast('notify', {message: errorMsg});
                }
            });
        };

        $rootScope.$on('login', function() {
            UserService.getSessionId().then(function (data) {
                $scope.session = data;
            });
        });

        $rootScope.$on('logout', function() {
            $scope.session = null;
        });

        $scope.$on('refreshCart', function () {
            $scope.cart = CartService.getCartCache($scope.cartSessionId);
        });

        $scope.setState = function(state) {
            $scope.state = states[state];
            if (!$scope.state) {
                $scope.state = 1;
            }

            $window.sessionStorage["cart_state"] = $scope.state;
            if($scope.state!==6) {
                AnalyticsService.cartFunnel($scope.cart, $scope.state);
            }

            $state.go(state);
        };

        $scope.loadPaymentForm = function () {
            // get verifying post token and load payment form
            $scope.paymentOptions.selected_payment = false;
            getPaymentOptions();
            if (($scope.user && $scope.user.disable_cc) ||
                ($rootScope.currentCustomer && $rootScope.currentCustomer.disable_cc)
            ) { return; }

            var vpParams = $scope.user ? $scope.user : { username: $scope.data.email };
            vpParams.merchantid = CENPOS_MERCHANT_ID;
            vpParams.address = $scope.data.billingAddress1;
            vpParams.zipcode = $scope.data.billingZip;
            if (vpParams.username) {
                vpParams.email = vpParams.username;
            }
            if (vpParams.id) {
                vpParams.customerCode = vpParams.id;
            }

            BillingInfoService.getVerifyingPost(vpParams).then(function(data) {
                var params = {
                    isCvv: true,
                    verifyingpost: data.verifying_post
                };
                var queryString = Object.keys(params).map(function (key) {
                    return key + '=' + encodeURIComponent(params[key])
                }).join('&');

                $('#swpPlugin').createWebpay({
                    url: 'https://www.cenpos.net/simplewebpay/cards',
                    params: queryString,
                    width: 500,
                    height: 480,
                    success: swpSuccess,
                    cancel: swpCancel,
                    sessionToken: false
                });

            }, function() {
                $scope.errorMessage = "Unable to load payment form";
            });
        };

        $scope.setPayment = function () {
            // For some reason, this kept coming back as blank.
            if (!$scope.data.billingZip) {
                $scope.data.billingZip = getBillingZip();
            }
            if ($scope.use_cc && (!$scope.paymentOptions.selected_payment || '000NEW' === $scope.paymentOptions.selected_payment)) {
                $("#swpPlugin").submitAction();
            } else {
                $scope.setState('cart.review');
            }
        };

        function swpSuccess(response) {
            var acceptedCards = ['VISA', 'MASTERCARD', 'AMEX'];
            if (!acceptedCards.includes(response.CardType)) {
                $scope.swpError = "We only accept Visa, MasterCard, or American Express. Please select a different card.";
                return false;
            } else {
                $scope.swpError = false;
            }

            $scope.newPaymentOption = false;
            $scope.data.token = response.RecurringSaleTokenId;
            $scope.paymentOptions.selected_payment = response.RecurringSaleTokenId;
            $scope.data.cardNumber = response.ProtectedCardNumber.replace(/\*+/, "");
            $scope.data.cardName = response.NameonCard;
            $scope.data.cardType = response.CardType;
            $scope.data.cardExpMonth = parseFloat(response.CardExpirationDate.substring(0, 2));
            $scope.data.cardExpYear = parseFloat("20" + response.CardExpirationDate.substring(2, 4));

            if ($scope.user) {
                var data = {
                    token: $scope.data.token,
                    user_id: $scope.user.id,
                    name: $scope.data.cardName,
                    address: decodeURIComponent(response.CustomerAddress),
                    zip: response.CustomerZipCode,
                    card_type: $scope.data.cardType,
                    card: $scope.data.cardNumber,
                    exp_month: response.CardExpirationDate.substring(0, 2),
                    exp_year: response.CardExpirationDate.substring(2, 4),
                    card_default: 0,
                    nick_name: null
                };

                BillingInfoService.postPaymentMethod(data).then(
                    function (data) {
                        if (data.id) {
                            data.Title = data.card_type + " - ending in " + data.card;
                            $scope.paymentOptions.options.unshift(data);
                        }
                    },
                    function (data) {
                        // save to payment_method failed - not critical, payment can still be processed
                    }
                );
            }
            $scope.setState('cart.review');
        }

        function swpCancel(response) {
            $scope.errorMessage = "Error processing payment form";
        }

        $scope.closeAlert = function () {
            $scope.errorMessage = false;
        };

        $scope.getSubTotal = function() {
            var subtotal = 0, total_units = 0, estimated = 0;
            if ($scope.cart) {
                $scope.closeAlert();
                angular.forEach($scope.cart.cart_lines, function (v) {
                    if ('units' === $rootScope.workflow.currency && !v.units) {
                        $scope.errorMessage = 'Your cart contains one or more invalid items &mdash; Shown with a <i class="fa fa-exclamation-circle text-danger"></i> &nbsp;';
                        $scope.errorMessage += 'These items must be removed before you can complete your order. If the problem persists, please contact support at <a href="mailto:support@vallen.com" class="alert-danger">support@vallen.com</a>.';
                    }
                    v.display_price = v.last_paid && !v.price ? v.last_paid : v.price;
                    if (v.last_paid && !v.price) {
                        estimated += (v.quantity * v.last_paid);
                    } else {
                        subtotal += (v.quantity * v.price);
                    }
                    total_units += (v.quantity * v.units);
                });
                $scope.cart.sub_total = subtotal;
                $scope.cart.total_units = total_units;
            }
            $scope.subTotal = subtotal + estimated;
            $scope.totalUnits = total_units;
            $scope.estimated_price = estimated ? true : false;

            if ('units' === $rootScope.workflow.currency) {
                $scope.overLimit = $scope.orderLimit >= 0 && $scope.totalUnits > $scope.orderLimit;
            } else {
                $scope.overLimit = $scope.orderLimit >= 0 && $scope.subTotal > $scope.orderLimit;
            }

            if ($scope.overLimit && (!$scope.user || $scope.user.customer_number === '87023')) {
                $scope.errorMessage = "Due to COVID-19 and related product shortages, Vallen.com has restricted access to online purchasing to limited accounts.";
            } else if ($scope.overLimit) {
                $scope.errorMessage = "Your subtotal exceeds the maximum amount allowed by your company. Please adjust your cart accordingly or contact customer support for assistance at "+PHONE+".";
            }
        };

        var setQuantity = function (item) {
            item.quantity = isNaN(parseInt(item.quantity)) || parseInt(item.quantity) < 1 ? 0 : parseInt(item.quantity);
            if (item.quantity < 1) {
                item.selected = true;
                item.quantity = 1;
                $scope.selectItem();
                $scope.removeItems(item);
            } else {
                $scope.updateLine(item);
            }

            CartService.updateCartCache($scope.cart);
            $scope.$broadcast('refreshCart');
            $scope.calculate();
        };

        $scope.addQty = function (item) {
            item.quantity++;
            setQuantity(item);
            AnalyticsService.addToCart(item, 1);
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 1) {
                item.quantity--;
                setQuantity(item);
                AnalyticsService.removeFromCart(item, 1);
            }
        };

        $scope.changeItemQty = function (item) {
            setQuantity(item);
        };

        $scope.getTotal = function () {
            var total = $scope.subTotal;
            if (!isNaN($scope.shipping)) total += parseFloat($scope.shipping);
            if (!isNaN($scope.tax)) total += parseFloat($scope.tax);
            if (!isNaN($scope.discount)) total -= parseFloat($scope.discount);

            $scope.total = total;
        };

        $scope.calculate = function () {
            if ($scope.state === states['cart.approval']) {
                return;
            }
            $scope.getSubTotal();
            $scope.getTotal();
        };

        $scope.scrollTo = function (eID) {
            UIService.smoothScrollTo(eID);
        };

        $scope.disableShippingAndBillingChange = false;
        $scope.data.saveShipping = null;
        var getShippingAddresses = function () {
            $scope.shippingAddresses = [];
            if ($scope.user !== undefined) {
                $scope.shippingAddresses = angular.copy($scope.customerShipTos);
                angular.forEach($scope.shippingAddresses, function (v) {
                    var loc = [];
                    if (v.addr1) { loc.push(v.addr1); }
                    if (v.addr2) { loc.push(v.addr2); }
                    if (v.addr3) { loc.push(v.addr3); }
                    if (v.city) { loc.push(v.city); }
                    if (v.state) { loc.push(v.state); }
                    if (v.zip) { loc.push(v.zip); }
                    var location = loc.join(', ');
                    var s = [];
                    if (v.shipTo) { s.push(v.shipTo); }
                    if (v.name) { s.push(v.name); }
                    if (location) { s.push(location); }
                    v.display = s.join(' - ');
                    if (v.isDefault === true) {
                        $scope.setShipTo(v);
                    }
                });

                if ($scope.shippingAddresses.length === 1) {
                    $scope.setShipTo($scope.shippingAddresses[0]);
                } else if (!$scope.shippingAddresses || $scope.shippingAddresses.length <= 0) {
                    $scope.setDefaultShipping();
                }

                if ($window.sessionStorage["s2wCartShipTo"]) {
                    $scope.shippingAddresses.forEach(function (s) {
                        if (s.shipTo === $window.sessionStorage["s2wCartShipTo"]) {
                            $scope.setShipTo(s);
                            $scope.disableShippingAndBillingChange = true;
                        }
                    });
                } else {
                    $scope.disableShippingAndBillingChange = false;
                }
            }
        };
        $scope.x = {};
        $scope.setShipTo = function (sa) {
            if (angular.isObject(sa)) {
                $scope.x.selectedShippingAddress = sa;
                $scope.data.saveShipping = null;
                $scope.data.shippingShipTo = sa.shipTo;
                $scope.data.shippingCompanyName = sa.name;
                $scope.data.shippingAddress1 = sa.addr1;
                $scope.data.shippingAddress2 = sa.addr3 ? sa.addr3 : sa.addr2;
                $scope.data.shippingCity = sa.city;
                $scope.data.shippingState = setShipToBillToState(sa);
                $scope.data.shippingZip = sa.zip;
                $scope.data.po = sa.customerPo ? sa.customerPo : $scope.customerMaster.defaultCustomerPO;
                $scope.data.poreqfl = sa.poRequired ? sa.poRequired :  $scope.customerMaster.poRequired;
            } else {
                $scope.x.selectedShippingAddress = null;
                $scope.data.shippingShipTo = null;
            }

            //Set default address from customer summary if one is not saved.
            $scope.setDefaultShipping();
            $scope.mapShippingAndBilling();
        };

        $scope.searchAddShipTo = function() {
            document.getElementById('shipToAutoComplete').focus();
            $scope.setShipTo('');
        }

        $scope.setDefaultShipping = function() {
            if ($scope.customerMaster && !$scope.data.shippingCity && !$scope.data.shippingAddress1 && !$scope.user.can_edit_ship_to) {
                $scope.data.shippingCompanyName = $scope.customerMaster.customerName;
                $scope.data.shippingAddress1 = $scope.customerMaster.customerAddress1;
                $scope.data.shippingAddress2 = $scope.customerMaster.customerAddress2;
                $scope.data.shippingCity =  $scope.customerMaster.customerCity;
                $scope.data.shippingState = setShipToBillToState({state: $scope.customerMaster.customerState});
                $scope.data.shippingZip = $scope.customerMaster.customerZipCode;
            }
        };

        $scope.setDefaultBilling = function() {
            if ($scope.customerMaster && !$scope.data.billingCity && !$scope.data.billingAddress1 && !$scope.user.can_edit_bill_to) {
                $scope.data.billingCompanyName = $scope.customerMaster.customerName;
                $scope.data.billingAddress1 = $scope.customerMaster.customerAddress1;
                $scope.data.billingAddress2 = $scope.customerMaster.customerAddress2;
                $scope.data.billingCity =  $scope.customerMaster.customerCity;
                $scope.data.billingState = setShipToBillToState({state: $scope.customerMaster.customerState});
                $scope.data.billingZip = $scope.customerMaster.customerZipCode;
                setBillingZip($scope.data.billingZip);
            }
        };

        var setShipToBillToState = function (obj) {
            var state = { name: '', abbr: '' };
            angular.forEach($scope.states, function (v) {
                if (v.abbr == obj.state) {
                    state = v;
                    return false;
                }
            });
            if (!state.abbr && obj.state) {
                state.name = obj.state;
                state.abbr = obj.state;
                $scope.states.push(state);
            }
            return state;
        };

        $scope.disableEditShipTo = function () {
            if (!$scope.user) { return false; }
            return !($scope.user.can_edit_ship_to && !$scope.x.selectedShippingAddress);
        };

        $scope.showShipToSelect = function () {
            return $scope.shippingAddresses.length > 1 || $scope.canEditShipTo;
        };

        var getBillingAddresses = function () {
            if ($scope.user !== undefined) {
                angular.forEach($scope.user.billing_addresses, function (v) {
                    if (v.active) {
                        var loc = [];
                        if (v.street_address_1) { loc.push(v.street_address_1); }
                        if (v.street_address_2) { loc.push(v.street_address_2); }
                        if (v.city) { loc.push(v.city); }
                        if (v.state) { loc.push(v.state); }
                        if (v.zip) { loc.push(v.zip); }
                        v.display = loc.join(', ');
                        $scope.billingAddresses.push(v);
                    }
                });

                if (
                    $scope.billingAddresses.length === 1
                ) {
                    $scope.setBillingAddress($scope.billingAddresses[0]);
                } else if (!$scope.billingAddresses || $scope.billingAddresses.length <= 0) {
                    $scope.setDefaultBilling();
                }
            }
        };

        $scope.setBillingAddress = function (selectedBillingAddress) {
            $scope.selectedBillingAddress = selectedBillingAddress;

            $scope.data.billingAddress1 = selectedBillingAddress.street_address_1;
            $scope.data.billingAddress2 = selectedBillingAddress.street_address_2;
            $scope.data.billingCity = selectedBillingAddress.city;
            $scope.data.billingState = setShipToBillToState(selectedBillingAddress);
            $scope.data.billingZip = selectedBillingAddress.zip;
            setBillingZip(selectedBillingAddress.zip);
        };


        /** State 1 - Cart Summary **/
        $scope.setIncludeBlackbook = function (val) {
            $scope.includeBlackbook = val;
        };

        $scope.remove = function (id) {
            CartService.removeItem($scope.session, $scope.cart, id).then(function() {

            }, function() {
                $scope.errorMessage = "We were unable to remove this item from your cart. Please try again. If the problem persists, please contact support for help.";
            });
        };

        $scope.updateLine = function (item) {
            CartService.updateCartLine($scope.cart.session_id, item).then(function() {
            }).catch(function() {
                $scope.errorMessage = "We were unable to update the quantity of this item. Please try again. If the problem persists, please contact support for help.";
            });
        };

        $rootScope.$on('cart_update', function() {
            $scope.calculate();
        });

        var verifyUnits = function() {
            if ('units' === $rootScope.workflow.currency) {
                var updateItems = 0;
                AlgoliaService.getItemUnits($scope.cart).then(function(units) {
                    var newUnits;
                    angular.forEach($scope.cart.cart_lines, function(item) {
                        newUnits = undefined === units[item.erp] ? null : units[item.erp];
                        if (newUnits !== item.units) {
                            item.units = newUnits;
                            $scope.updateLine(item);
                            updateItems++;
                        }
                        if (!newUnits) {
                            $scope.invalidItems = true;
                        }
                    });

                    if (updateItems) {
                        CartService.updateCartCache($scope.cart);
                        $scope.calculate();
                    }
                });
            }
        };

        var getCart = function () {
            $scope.cart = CartService.getCartCache($scope.cartSessionId);
            $scope.loading = false;
            $scope.invalidItems = false;
            if (!$scope.cart || !$scope.cart.cart_lines || !$scope.cart.cart_lines.length) {
                $window.sessionStorage.removeItem('cart_state');
                AlgoliaService.searchTaxonomy({
                    hitsPerPage: 1000,
                    facetFilters: ['type:department'],
                    distinct: true,
                }).then(function(data) {
                    $scope.departments = data.hits;
                });
            } else {
                $scope.cartSessionId = $scope.cart.session_id;
                verifyUnits();
                $scope.subTotal = $scope.cart.sub_total;
                $scope.calculate();
            }

            if ($scope.item_qty_available && $scope.cart && $scope.cart.cart_lines) {
                angular.forEach($scope.cart.cart_lines, function (line) {
                    if ($scope.item_qty_available[line.erp]) {
                        line.quantity_available = $scope.item_qty_available[line.erp];
                    }
                });
            }

            if ($scope.cart && $scope.cart.customer_number !== UserService.getCustomerNumber()) {
                $scope.cart.customer_number = UserService.getCustomerNumber();
                CartService.initializeUdf($scope.cart);
                CartService.updateCartCache($scope.cart);
            }

            $scope.shipToAlert = null;
            if ($scope.cart && $scope.cart.customer_number) {
                if ($scope.cart.customer_number === '206106') {
                    $scope.shipToAlert = 'If you are using a residential address, Vallen trucks cannot deliver ' +
                        'pallet quantities. If you are placing a large sample order, please ship directly to ' +
                        'customer or another Vallen warehouse.';
                } else if ($scope.cart.customer_number === '15345') {
                    $scope.shipToAlert = 'This account is set to ship items from DBEL distribution center to ' +
                        'the ship to of your choosing. If you do not have access to edit your ship to in the ' +
                        'checkout process please contact GoDigital.';
                }
            }

            if ($scope.punchoutForm() && $scope.cart && $scope.cart.cart_lines && $scope.cart.cart_lines.length) {
                OrderService.getUnspscCodes($scope.cart.cart_lines).then(function(data) {
                    angular.forEach($scope.cart.cart_lines, function (line) {
                        if (!line.unspsc) {
                            angular.forEach(data, function (unspsc) {
                                if (unspsc.part === line.erp) {
                                    line.unspsc = unspsc.unspsc;
                                    return false;
                                }
                            });
                        }
                    });
                }).catch(function() {
                    console.error('Error fetching UNSPSC codes.');
                }).finally( function () {
                    $scope.loadingUnspsc = false;
                });
            }
        };

        var initUdfData = function() {
            var udfCustno = ($scope.cart && $scope.cart.customer_number) ? $scope.cart.customer_number : UserService.getCustomerNumber();
            var udfLines = ($scope.cart && $scope.cart.cart_lines) ? $scope.cart.cart_lines : [];
            UDFService.initUdfData($scope, udfCustno, $scope.cart, udfLines, 'cart');
        }

        getCart();
        initUdfData();

        $scope.editLineProperties = function (line) {
            $scope.modalInstance =  $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: "modalEditLineProperties.html",
                controller: "EditLinePropertiesController",
                windowClass: '',
                size: 'lg',
                resolve: {
                    cart: function () {
                        return $scope.cart;
                    },
                    line: function () {
                        return line;
                    },
                    lineProperties: function () {
                        return $scope.udfProperties.line;
                    }
                }
            });
            $scope.modalInstance.result.then(function(s){
                // modal closed
            }, function(s) {
                // modal dismissed
            });
        };

        $rootScope.$on('cart_update', function () {
            getCart();
        });

        if ($scope.state===1) {
            AnalyticsService.cartFunnel($scope.cart,1);
        }

        var getUser = function () {
            UserService.getUser().then(function (data) {
                if (data) {
                    if ($rootScope.currentCustomer.disable_cc) { data.disable_cc = true; }
                    $scope.user = data;
                    $scope.canEditShipTo = $scope.user.can_edit_ship_to;
                    $scope.canEditBillTo = $scope.user.can_edit_bill_to;
                    $scope.canShareCart = $scope.user.can_share_cart;
                    $scope.isOpenBrowse = ($scope.user.customer_number == OPEN_BROWSE_CUSTOMER_NUMBER);

                    if ($scope.user.phone === '000-000-0000') {
                        $scope.user.phone = null;
                    }

                    $scope.use_cc = data.require_cc && !data.disable_cc;

                    if (!$scope.canEditBillTo) $scope.data.map = false;

                    // Set the name, e-mail and phone number if not already set
                    if (null == $scope.data.name) $scope.data.name = $scope.user.name;
                    if (null == $scope.data.email) $scope.data.email = $scope.user.username;
                    if (null == $scope.data.phone) $scope.data.phone = $scope.user.phone;
                    if (null == $scope.data.phone_extension) $scope.data.phone_extension = $scope.user.phone_extension;

                    // require user to enter their name and email address for punchout quotes
                    if ($scope.punchoutHook && $scope.cartType === 'quote') {
                        $scope.data.name = $scope.data.email = '';
                    }

                    UserService.getUserShipTos().then(function (shipTos) {
                        $scope.customerShipTos = shipTos;
                        UserService.getCustomerMaster().then(function(data) {
                            $scope.customerMaster = data ? data.sx_customer : false;
                            getShippingAddresses();
                            getBillingAddresses();
                            getPaymentOptions();
                        }).catch(angular.noop);
                    }).catch(angular.noop);
                }
            }).catch(function(err) {
                console.error(err);
            });
        };

        $scope.openModal = function (template) {
            $scope.modalInstance = $uibModal.open({
                scope: $scope,
                templateUrl: template,
                size: 'lg'
            });
            $scope.modalInstance.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        $scope.closeModal = function () {
            $scope.modalInstance.dismiss();
        };

        $rootScope.$on('cart-share-update', function () {
            $scope.cartIsShared = true;
        });

        // Get any shared cart and bring up a dropdown
        $scope.sharedCarts = [];
        CartService.getSharedCarts($scope.user).then(function (shared_carts) {
            $scope.sharedCarts = shared_carts;
        });

        $scope.goToSharedCart = function (cart) {
            if (cart) {
                $state.go('cart_shared', {cart: cart.id});
            }
        };

        $scope.shareCart = function () {
            if ($scope.user === undefined) {
                $scope.user = JSON.parse($window.localStorageOverride["user"]);
            }
            $scope.modalInstance =  $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: "modalShareCart.html",
                controller: "ShareCartModalInstanceController",
                windowClass: '',
                size: 'lg',
                resolve: {
                    self: function () {
                        return $scope.user;
                    },
                    cartSessionId: function () {
                        return $scope.cartSessionId;
                    }
                }
            });
            $scope.modalInstance.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
            $scope.modalInstance.closed.then( function () {
                $window.sessionStorage.removeItem("shareCartToggle");
            });
        };

        $scope.disableCheckout = function () {
            return (
                ($scope.udfDisabled && $scope.foundUdf()) ||
                $scope.submitApprovalError ||
                $scope.submitOrderError ||
                $scope.loadingUdfLines ||
                $scope.overLimit ||
                $scope.invalidItems ||
                $rootScope.invalidLineProps ||
                ($scope.user && $scope.user.can_submit_orders !== null && $scope.user.can_submit_orders === false)
            );
        };

        $scope.disableQuote = function () {
            var allowStockQuotes = $rootScope.currentCustomer && $rootScope.currentCustomer.allow_stock_quotes ? 1 : 0;
            return (
                $scope.loadingUdfLines ||
                $scope.submitOrderError ||
                $scope.overLimit ||
                $scope.invalidItems ||
                $rootScope.invalidLineProps ||
                ($scope.user && $scope.user.can_submit_quotes !== null && $scope.user.can_submit_quotes === false) ||
                ($scope.orderType !== 'q' && !allowStockQuotes)
            );
        };

        $scope.showQuoteBtn = function () {
            return (
                (($rootScope.currentCustomer && $rootScope.currentCustomer.allow_stock_quotes && $scope.user.can_submit_quotes) || $scope.cartType === 'quote') &&
                $scope.cartType !== 'nonstock'
            );
        };

        $scope.hasUdf = function () {
            return $scope.loadingUdfHeader || $scope.loadingUdfLines || Object.keys($scope.udfProperties).length > 0;
        }

        $scope.foundUdf = function () {
            return !$scope.loadingUdfHeader && !$scope.loadingUdfLines && Object.keys($scope.udfProperties).length > 0;
        }

        $scope.showExpressCheckout = function () {
            return $scope.user && $scope.user.express_checkout;
        };

        $scope.enableExpressCheckout = function () {
            return $scope.express.hasExpressCheckout && !$scope.disableCheckout() && !$scope.hasUdf() && $scope.orderType !== 'q';
        };

        $scope.disableShareCart = function () {
            return $scope.invalidItems;
        };

        /** State 2 - Checkout As **/
        $scope.login = function () {
            $scope.closeAlert();
            $rootScope.$broadcast('open-loading-modal');

            UserService.login($scope.loginData.username, $scope.loginData.password).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (!data) {
                    $scope.errorMessage = "Invalid Credentials"
                } else {
                    $rootScope.$broadcast('notify', { message: "Your cart has been updated. Please review the content of your cart." });
                    $scope.isAuthenticated = true;
                    $scope.setState('cart.summary');
                    getUser();
                }
            });
        };

        /** State 3 - Order Details **/
        if ($scope.isAuthenticated) {
            getUser();
        }

        $scope.getShippingAndTax = function (theForm) {
            var goToReviewOrder = function() {
                if ($scope.cartType === 'quote' &&
                    $scope.user &&
                    (!$scope.use_cc || !$scope.user.can_submit_orders)
                ) {
                    $scope.setState('cart.review');
                } else {
                    $scope.setState('cart.payment');
                }
            }

            if ($scope.udfProperties.header) {
                UDFService.verifyUdfValues($scope.udfProperties.header, $scope.data.header_properties, $scope).then(function() {
                    $scope.cart.header_properties = $scope.data.header_properties;
                    CartService.updateCart($scope.cart).then(function() {
                        CartService.updateCartCache($scope.cart);
                        goToReviewOrder();
                    }).catch(function() {
                        $rootScope.$broadcast('notify', { message: "We were unable to update the header properties for this cart. Please try again." });
                    });
                }).catch(function(invalid) {
                    $rootScope.$broadcast('notify', { message: 'One or more of the values is invalid. Please try again.' });
                    angular.forEach(invalid, function (id) {
                        if (theForm[id]) {
                            theForm[id].$setValidity('pattern', false);
                        }
                    });
                });
            } else {
                goToReviewOrder();
            }

            // Free shipping on orders over 79
            //$scope.discount = 0;
            $scope.showDiscountAlert = false;
            $scope.tax = 0;
            $scope.shipping = 0;
            $scope.pricingLoading = true;
            var data = {
                user_id: UserService.getUserId(),
                customer_number: UserService.getCustomerNumber(),
                company_number: UserService.getCompanyNumber(),
                warehouse_key: UserService.getCustomerWarehouse(),
                geocode: $scope.data.selectedGeoCode ? $scope.data.selectedGeoCode.GeoCode : null,
                ship_to_city: $scope.data.shippingCity,
                ship_to_state: $scope.data.shippingState.abbr,
                ship_to_zip: $scope.data.shippingZip,
                ship_to_id: $scope.data.shippingShipTo ? $scope.data.shippingShipTo : null,
                total_order_value: $scope.subTotal,
                order_lines: [],
                promo_codes: []
            };

            if ($scope.data.promo_codes.free_freight) data.promo_codes.push('freefr8');
            if ($scope.data.promo_codes.discount) {
                var discount = $scope.data.promo_codes.discount;
                data.promo_codes.push(discount.promo_code);
            }
            angular.forEach($scope.cart.cart_lines, function (v) {
                var i = {
                    erp: v.erp,
                    order_quantity: v.quantity,
                    unit_of_measure: v.uom,
                    actual_sell_price: v.price
                };
                data.order_lines.push(i);
            });

            //$scope.discount = 0;
            if ('units' === $rootScope.workflow.currency) {
                $scope.pricingLoading = false;
            } else {
                PricingService.getShippingAndTax(data).then(function (data) {
                    if (!data) {
                        $scope.pricingLoading = false;
                        $scope.errorMessage = "An error occurred while calculating shipping and taxes. Please go back and check that your shipping address is correct. If the problem persists, please contact customer support for assistance at "+PHONE+".";
                    } else {
                        var extended = data.listOutextamt.Outextamt;
                        if (extended) {
                            if (extended.length) {
                                angular.forEach(extended, function (v) {
                                    if (v.type == "tax") {
                                        $scope.tax = parseFloat(v.amount);
                                        AnalyticsService.taxCost = parseFloat(v.amount);
                                    }
                                    if (v.type == "addon") {
                                        $scope.shipping = parseFloat(v.amount);
                                        AnalyticsService.shippingCost = parseFloat(v.amount);
                                    }
                                    if (v.type == "discount") {
                                        $scope.discount = parseFloat(v.amount);
                                        AnalyticsService.discountAmount = parseFloat(v.amount);
                                    }
                                });
                            } else {
                                if (extended.type == "tax") {
                                    $scope.tax = parseFloat(extended.amount);
                                    AnalyticsService.taxCost = parseFloat(extended.amount);
                                }
                                if (extended.type == "addon") {
                                    $scope.shipping = parseFloat(extended.amount);
                                    AnalyticsService.shippingCost = parseFloat(extended.amount);
                                }
                                if (extended.type == "discount") {
                                    $scope.discount = parseFloat(extended.amount);
                                    AnalyticsService.discountAmount = parseFloat(extended.amount);
                                }
                            }
                        }

                        $scope.getTotal();
                        $scope.pricingLoading = false;
                    }
                }, function() {
                    // no pricing found
                });
            }
        };

        $scope.data.departments = [];
        $scope.data.department = null;
        $scope.departmentText = null;
        $scope.loadingDepartments = false;

        if ($scope.user) {
            if ($scope.user.allow_all_departments) {
                $scope.loadingDepartments = true;
                $scope.departmentText = '(generating list...)';
                DepartmentService.getDepartments({
                    active: 'active',
                    customer_number: $scope.user.customer_number,
                    limit: 1000
                }).then(function (data) {
                    angular.forEach(data.departments, function (d) {
                        $scope.data.departments.push(
                            {
                                id: d.id,
                                department_key: d.department_key,
                                description: d.description
                            }
                        );
                    });
                    $scope.departmentText = $scope.data.departments.length ? null : '- No departments found, contact support';
                }).catch(function(err) {
                    console.error(err);
                    $scope.departmentText = 'Error retrieving departments, contact support';
                }).finally(function() {
                    $scope.loadingDepartments = false;
                });
            } else if ($scope.user.departments) {
                angular.forEach($scope.user.departments, function (d) {
                    $scope.data.departments.push(d.department);
                    if (d.is_default || !$scope.data.department) {
                        $scope.data.department = d.department;
                    }
                });
            }
            $scope.departmentText = $scope.data.departments.length ? null : '- No departments found, contact support';
        }

        /** State 4 - Payment **/
        $scope.mapShippingAndBilling = function () {
            if ($scope.data.map) {
                $scope.data.billingAddress1 = $scope.data.shippingAddress1;
                $scope.data.billingAddress2 = $scope.data.shippingAddress2;
                $scope.data.billingCity = $scope.data.shippingCity;
                $scope.data.billingState = $scope.data.shippingState;
                $scope.data.billingZip = $scope.data.shippingZip;
                if ($scope.data.billingZip) {
                    $scope.data.billingZip = $scope.data.billingZip.trim();
                }
            }

            if (!$scope.data.map && $scope.billingAddresses.length) {
                $scope.setBillingAddress($scope.billingAddresses[0]);
            }

            if (!$scope.data.billingZip) {
                $scope.data.billingZip = getBillingZip();
            }
        };

        $scope.data.selectedGeoCode = null;

        $scope.mapShippingAndBilling();

        $scope.validateCardNumber = function(paymentForm) {
            $timeout(function() {
                $scope.data.cardType = paymentForm.cardNumber.$ccType;
            }, 1);
        };

        $scope.mapPaymentOption = function (token, options) {
            angular.forEach(options, function (option) {
                $scope.newPaymentOption = false;
                if (token === option.token) {
                    $scope.data.cardNumber = option.card;
                    $scope.data.token = option.token;
                    $scope.data.cardName = option.name;
                    $scope.data.cardType = option.card_type;
                    $scope.data.cardExpMonth = parseFloat(option.exp_month);
                    $scope.data.cardExpYear = parseFloat("20" + option.exp_year);
                }
            });
        };

        $scope.toggleCC = function () {
            $scope.use_cc = (!$scope.use_cc);
        };

        $scope.item_qty_available = {};
        if ($scope.cart && $scope.cart.cart_lines && !$scope.cartType) {
            PricingService.getPricing($scope.cart.cart_lines).then(function (prices) {
                angular.forEach($scope.cart.cart_lines, function (item) {
                    if (prices) {
                        angular.forEach(prices, function (priceList) {
                            angular.forEach(priceList, function (price) {
                                if (item.erp == price.erp) {
                                    item.quantity_available = angular.copy(price.quantity_available);
                                    $scope.item_qty_available[item.erp] = angular.copy(price.quantity_available);
                                    return false;
                                }
                            });
                        });
                    }
                });
            });
        }

        $scope.paymentOptions = { selected_payment: false, options: [], show: false };
        $scope.isActiveCart = function() {
            if($scope.cart){
                return (!$scope.loading && (($scope.cart.cart_lines && $scope.cart.cart_lines.length) || $scope.state > 5 ));
            }
            return false;
        };
        $scope.isEmptyCart = function() {
            if($scope.cart){
                return (!$scope.loading && (!$scope.cart.cart_lines || !$scope.cart.cart_lines.length) && $scope.state < 6);
            }
            return true;
        };

        var getPaymentOptions = function () {
            $scope.paymentOptions = { selected_payment: false, options: [], show: true };
            $scope.newPaymentOption = true;
            if ($scope.isAuthenticated) {
                if (undefined == $scope.user) {
                    $scope.user = JSON.parse($window.localStorageOverride["user"]);
                }

                if ($scope.user.disable_cc) { return; }
                BillingInfoService.getTokens({user_id: $scope.user.id}).then(function (data) {
                    if (!data.error) {
                        if (!Array.isArray(data)) {
                            data = [data];
                        }

                        $scope.newPaymentOption = false;
                        angular.forEach(data, function (d) {
                            d.Title = d.card_type + " - ending in " + d.card;
                            $scope.paymentOptions.options.push(d);

                            if (d.card_type) {
                                $scope.use_cc = true;
                            }

                            angular.forEach($scope.user.payment_methods, function (method) {
                                if (method.token === d.token && method.card_default) {
                                    $scope.paymentOptions.selected_payment = method.token;
                                    return false;
                                }
                            });
                        });

                        $scope.paymentOptions.options.push({
                            token: "000NEW",
                            Title: "Enter new payment"
                        });

                        if (!$scope.paymentOptions.selected_payment) {
                            $scope.paymentOptions.selected_payment = $scope.paymentOptions.options[0].token;
                        }

                        if ($scope.paymentOptions.selected_payment) {
                            $scope.mapPaymentOption(
                                $scope.paymentOptions.selected_payment,
                                $scope.paymentOptions.options
                            );
                        }
                    } else {
                        $scope.data.token = "000NEW";
                    }
                });

                $scope.changePayment = function (token) {
                    if (token === "000NEW") {
                        $scope.newPaymentOption = true;
                        $scope.data.cardNumber = null;
                        $scope.data.cardName = null;
                        $scope.data.cardType = 'Visa';
                        $scope.data.cardExpMonth = new Date().getMonth() + 1;
                        $scope.data.cardExpYear = new Date().getFullYear();
                        $scope.data.map = true;
                        $scope.data.token = token;
                    } else {
                        $scope.newPaymentOption = false;
                        $scope.mapPaymentOption(token, $scope.paymentOptions.options);
                        $scope.closeAlert();
                    }
                };
            }
        };

        /**
         * Check limits for Company Users. The limit must not exceed the
         * amount total of the cart. If that succeeds, proceed as normal. If not,
         * then the limit and the approve will need to be checked.
         *
         * If the user has a limit but no approver, an exception will be thrown
         * and will halt the process, stating to contact support.
         *
         * Special case: If user has limit=0 AND the total order value=0 (non-stock)
         * then the order will be sent for approval.
         *
         * If the user has a limit and an approver, then proceed to submit the cart
         * to the approver service instead of creating an order. The cart will
         * no longer be in the initiators hands, but can view the status from
         * the account settings.
         */
        function checkUserLimit () {
            if (!$rootScope.workflow.approval || $scope.cartType === 'quote') {
                return new Promise(function (resolve, reject) {
                    resolve({ needs_approval: false });
                });
            } else {
                return OrderService.checkOrderLimits(
                    $scope.user.id,
                    UserService.getCustomerNumber(),
                    'units' === $rootScope.workflow.currency ? Number($scope.totalUnits) : Number($scope.cart.sub_total),
                    CartService.calculateItemSubTotals($scope.cart),
                    $scope.data.department
                );
            }
        }

        function redirectForApproval () {
            var object = CartService.formatApprovalCart($scope.orderType, $scope);
            CartService.sendCartForApproval($scope.cartSessionId, $scope.user.id, object).then(function (data) {
                if (data) {
                    $scope.order = data;
                    $scope.setState('cart.approval');
                    CartService.deleteCart($scope.cartSessionId).then(function () {
                        $scope.cart = angular.copy(data);
                        CartService.updateCartCache(null);
                        CartService.getCart($scope.cartSessionId);
                    });
                }
            }).catch(function() {
                $scope.submitApprovalError = true;
                $rootScope.$broadcast('notify', { message: "Unable to obtain order approval." });
            }).finally(function() {
                $rootScope.$broadcast('close-loading-modal');
            });
        }

        $scope.confirmExpressCheckout = function() {
            $scope.xcoModal = $uibModal.open({
                templateUrl: 'modalExpressCheckout.html',
                controller: 'expressCheckoutModalInstanceCtrl',
                scope: $scope,
                windowClass: '',
                resolve: {
                    express: function(){
                        return $scope.express.data;
                    }
                }
            });

            $scope.xcoModal.result.then(function(data){
                $scope.expressCheckout(data);
                // modal submitted/closed
            }, function() {
                // modal dismissed
            });
        };

        $scope.expressCheckout = function(params) {
            $rootScope.$broadcast('open-loading-modal', {order: true});
            checkUserLimit().then(function(response) {
                if (response.error) {
                    throw Error(checkUser.message);
                } else if (response.needs_approval) {
                    redirectForApproval();
                    return true;
                } else {
                    $scope.cart = CartService.getCartCache($scope.cartSessionId);

                    return OrderService.expressCheckout($scope.cart.id, params).then(function (data) {
                        if (data.error || !data) {
                            throw Error('Unable to process order.');
                        } else {
                            $scope.setState('cart.confirm');
                            $scope.orders = data;
                            return CartService.deleteCart($scope.cart.session_id).then(function () {
                                CartService.getCart($scope.cart.session_id);
                            });
                        }
                    });
                }
            }).catch(function(error) {
                $rootScope.$broadcast('notify', {message: "Unable to process order via Express Checkout at this time. Please use standard checkout form."});
            }).finally( function() {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        /** State 5 - Review **/
        $scope.submitOrder = function (orderType) {
            $scope.orderType = orderType.toLowerCase();
            $rootScope.$broadcast('open-loading-modal', {order: true});

            $rootScope.previousState = null;
            if (!$scope.use_cc) {
                $scope.data.token = null;
            }

            if ($scope.data.token !== "000NEW") {
                $scope.mapPaymentOption(
                    $scope.paymentOptions.selected_payment,
                    $scope.paymentOptions.options
                );
            }

            if ($scope.data.saveShipping) {
                var shippingData = {
                    user_id: $scope.user.id,
                    is_default: true,
                    company_name: $scope.data.shippingCompanyName,
                    street_address_1: $scope.data.shippingAddress1,
                    street_address_2: $scope.data.shippingAddress2,
                    city: $scope.data.shippingCity,
                    state: $scope.data.shippingState.abbr,
                    zip: $scope.data.shippingZip
                };

                ShippingAddressService.postAddress(shippingData).then(function (data) {
                    if (data.status !== 201) {
                        $rootScope.$broadcast('notify', { message: "Error saving shipping address" });
                    }
                });
            }

            checkUserLimit().then(function(response) {
                if (response.needs_approval) {
                    redirectForApproval();
                } else {
                    var data = {
                        order_type: $scope.orderType,
                        amount:  $filter('currency')($scope.total, "").replace(",", ""),
                        token: $scope.data.token,
                        order_session: OrderService.createOrderSession(),
                        customer_number: UserService.getCustomerNumber(),
                        company_number: UserService.getCompanyNumber(),
                        warehouse_key: UserService.getCustomerWarehouse(),
                        user_id: ($scope.user) ? $scope.user.id : undefined,
                        session_id: $scope.cart.session_id,
                        email: $scope.data.email,
                        name: $scope.data.name,
                        phone: $scope.data.phone,
                        phone_extension: $scope.data.phone_extension,
                        bill_to_address_1: $scope.data.billingAddress1,
                        bill_to_address_2: $scope.data.billingAddress2,
                        bill_to_city: $scope.data.billingCity,
                        bill_to_state: $scope.data.billingState ? $scope.data.billingState.abbr : null,
                        bill_to_zip: $scope.data.billingZip,
                        ship_to_company: $scope.data.shippingCompanyName,
                        ship_to_address_1: $scope.data.shippingAddress1,
                        ship_to_address_2: $scope.data.shippingAddress2,
                        ship_to_city: $scope.data.shippingCity,
                        ship_to_state: $scope.data.shippingState.abbr,
                        ship_to_zip: $scope.data.shippingZip,
                        customer_po_number: $scope.data.po,
                        geocode: $scope.data.selectedGeoCode ? $scope.data.selectedGeoCode.GeoCode : null,
                        instructions: $scope.data.shippingInstructions,
                        comments: $scope.data.comments,
                        order_lines: [],
                        header_properties: $scope.data.header_properties,
                        save_for_express: $scope.express.saveForExpress,
                        promo_codes: []
                    };

                    if ($scope.data.promo_codes.free_freight) data.promo_codes.push('freefr8');
                    if ($scope.data.promo_codes.discount) {
                        var discount = $scope.data.promo_codes.discount;
                        data.promo_codes.push(discount.promo_code);
                    }

                    if ($scope.x.selectedShippingAddress && $scope.x.selectedShippingAddress.shipTo) {
                        data.ship_to_id = $scope.x.selectedShippingAddress.shipTo;
                    }

                    if (null != $scope.cart.order_source_type) {
                        data.order_source_type = $scope.cart.order_source_type;
                    }

                    if ($scope.data.department) {
                        data.department = $scope.data.department;
                    }

                    angular.forEach($scope.cart.cart_lines, function (v) {
                        var i = {
                            actual_sell_price: v.price,
                            erp: v.erp,
                            iref: v.iref,
                            order_quantity: v.quantity,
                            unit_of_measure: v.uom,
                            unit_type: v.unit_type,
                            cpn: v.cpn,
                            description: v.description,
                            comment: 'Manufacturer: ' + v.manufacturer + '\nAdditional Information: ' + v.comment,
                            required_date: v.required_date,
                            site_url: v.site_url,
                            last_paid: v.last_paid,
                            files: v.files
                        };

                        if (v.hasOwnProperty('apsv') && v.apsv !== null) i.apsv = v.apsv;
                        if (v.hasOwnProperty('direct_ship') && v.direct_ship !== null) i.direct_ship = v.direct_ship;
                        if (v.hasOwnProperty('line_properties') && v.line_properties !== null) i.line_properties = v.line_properties;
                        data.order_lines.push(i);
                    });
                    var expYear = $scope.data.cardExpYear.toString();
                    // Use Submit by new card
                    if (
                        (data.token === "000NEW" || !$scope.isAuthenticated) &&
                        $scope.use_cc
                    ) {
                        data.use_cc = false;
                        if ($scope.use_cc) {
                            data.use_cc = true;
                            data.card_number = $scope.data.cardNumber;
                            data.exp_month = $scope.data.cardExpMonth;
                            data.exp_year = parseFloat(expYear.slice(2));
                            data.card_name = $scope.data.cardName;
                            data.number = $scope.data.cardNumber;
                            data.cvv = $scope.data.cardCvc;
                            data.card_type = $scope.data.cardType;
                        }

                        OrderService.submitOrderByOpenBrowse(data, $scope.cart.id).then(function (d) {
                            if (d.error || d.length < 1 || d.code >= 400) {
                                CartService.handleCartError(d, $scope);
                            } else {
                                $window.sessionStorage.removeItem('cart_state');
                                $scope.setState('cart.confirm');

                                $scope.orders = d;
                                angular.forEach($scope.orders, function (order) {
                                    AnalyticsService.purchase($scope.cart, order);
                                });

                                CartService.deleteCart($scope.cart.session_id).then(function() {
                                    CartService.getCart($scope.cart.session_id);
                                });
                            }
                            $rootScope.$broadcast('close-loading-modal');
                        }).catch(function(err) {
                            CartService.handleCartError(err, $scope);
                        });
                    } else {
                        //User Submit by token
                        if (!$scope.use_cc) {
                            data.token = null;
                            data.card_number = null;
                            data.cvv = null;
                        } else {
                            data.token = $scope.data.token;
                            data.exp_month = $scope.data.cardExpMonth;
                            data.exp_year = parseFloat(expYear.slice(2));
                            data.card_number = $scope.data.cardNumber;
                            data.cvv = $scope.data.cardCvc;
                            data.card_type = $scope.data.cardType;
                        }

                        OrderService.submitOrderByToken(data, $scope.cart.id).then(function (data) {
                            if (data.error || data.length < 1 || data.code >= 400) {
                                CartService.handleCartError(data, $scope);
                            } else {
                                $window.sessionStorage.removeItem('cart_state');
                                $scope.setState('cart.confirm');

                                $scope.orders = data;
                                angular.forEach(data, function (order) {
                                    AnalyticsService.purchase($scope.cart, order);
                                });
                                CartService.deleteCart($scope.cart.session_id).then(function() {
                                    CartService.getCart($scope.cart.session_id);
                                });
                            }
                            $rootScope.$broadcast('close-loading-modal');
                        }).catch(function(err) {
                            CartService.handleCartError(err, $scope);
                        });
                    }
                }
            }).catch(function(err) {
                console.error('checkUserLimit', err);
                CartService.handleCartError(err, $scope);
                return false;
            });
        };

        $scope.continueShopping = function($event) {
            $event.preventDefault();
            if ($rootScope.previousState && $rootScope.previousState.state && $rootScope.previousState.state.name) {
                $state.go($rootScope.previousState.state.name, $rootScope.previousState.params);
            } else {
                $state.go('categories');
            }
        };

        /** State 6 - Confirmation **/
        $scope.createAccount = function () {

        };

        /**
         * PromoCode Logic
         * Need to see if we are able to apply multiple promo codes or not,
         * and add them to the submission
         * **/
        var calculatePromoCodes = function () {
            var discountAmount = 0;
            $scope.discount = 0;
            if ($scope.data.promo_codes.discount) {
                promoCode = $scope.data.promo_codes.discount;
                if (promoCode.promo_type === "percent") {
                    discountAmount = (promoCode.promo_value / 100) * $scope.subTotal;
                } else {
                    discountAmount = parseFloat(promoCode.promo_value);
                }

                if (!isNaN(discountAmount)) {
                    $scope.discount = discountAmount;
                }
            }

            if ($scope.data.promo_codes.free_freight) {
                $scope.shipping = 0;
            }

            if ($scope.state === 1) {
                $scope.showDiscountAlert = true;
                $scope.getTotal();
            }
        };

        $scope.applyPromoCode = function (promoCode) {
            if (!promoCode) { return false; }
            promoCode = promoCode.toUpperCase().trim();
            PromoCodeService.getPromoCode(promoCode).then(function (data) {
                document.getElementById('promo').value = "";
                if (!data || (data && !data.is_live)) {
                    $rootScope.$broadcast('notify', {message: 'Invalid promo code'});
                } else if (
                    data.promo_threshold !== null &&
                    (parseFloat(data.promo_threshold) > parseFloat($scope.subTotal))
                ) {
                    $rootScope.$broadcast(
                        'notify',
                        { message: 'Promo code entered is not valid with the current cart total. Please add more items' }
                    );
                    return false;
                } else {
                    if (data.promo_value > 0) {
                        $scope.data.promo_codes.discount = data;
                    }
                    $scope.data.promo_codes.free_freight = data.apply_free_freight ? true : false;

                    $rootScope.$broadcast('notify', {message: 'Promo code applied'});
                    calculatePromoCodes();
                    if ($scope.state === 4) {
                        $scope.getShippingAndTax();
                    }
                }
            });
        };

        $scope.clearPromoCode = function (key) {
            $scope.data.promo_codes[key] = false;
            calculatePromoCodes();
            if ($scope.state === 4) {
                $scope.getShippingAndTax();
            }
        };

        $rootScope.showExpectedFormat = function (prop) {
            return prop.validationGroup && prop.mustValidate;
        };

        $rootScope.showUdfNotes = function (prop) {
            if (prop.fieldName.indexOf('Ray_ShipToDropZone') !== -1 && prop.maxLength) {
                return 'Entry limited to ' + prop.maxLength + ' characters';
            }
            return false;
        }

        var initUdfSearch = function(id) {
            if (!$rootScope.udfSearch) {
                $rootScope.udfSearch = {};
            }

            if (!$rootScope.udfSearch[id]) {
                $rootScope.udfSearch[id] = {
                    active: true,
                    searching: true,
                    status: '',
                    results: []
                };
            }
        }

        $rootScope.udfLookup = function (prop, value) {
            var id = prop.fieldName;
            initUdfSearch(id);

            if (value) {
                $rootScope.udfSearch[id].status = 'Searching...';
                UDFService.getUdfValues($scope.user.customer_number, prop.validationGroup, value).then(function (results) {
                    $rootScope.udfSearch[id].results = results;
                    $rootScope.udfSearch[id].searching = false;
                    $rootScope.udfSearch[id].status = results.length + ' results found';
                }).catch(function() {
                    initUdfSearch(id);
                    $rootScope.udfSearch[id].searching = false;
                    $rootScope.udfSearch[id].status = 'Error retrieving results';
                });
            } else {
                $rootScope.udfSearch[id].searching = false;
            }
        };

        $rootScope.setUdfValue = function (prop, val) {
            $rootScope.udfSearch = {};
            prop.value = val;
        };
    }
]);

app.controller('ShareCartModalInstanceController', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'CartService',
    'UserService',
    'self',
    'cartSessionId',
    '$state',
    function ($rootScope, $scope, $window, $uibModalInstance, CartService, UserService, self, cartSessionId, $state) {
        $scope.loading = false;
        $scope.closeModal = function () {
            $uibModalInstance.dismiss();
        };

        $scope.isAdmin = self.roles.indexOf('ROLE_ADMIN') !== -1;
        $scope.isSuperAdmin = self.roles.indexOf('ROLE_SUPERADMIN') !== -1;
        $scope.saveCart = false;

        $scope.toggled = [];

        $scope.$on('getUsers', function (e,a) {
            $scope.loading = true;
            $scope.users = [];
            if ($window.sessionStorage["shareCartToggle"]) {
                $scope.toggled = JSON.parse($window.sessionStorage["shareCartToggle"]);
            }

            UserService.getUsersByCustomerNumber(self.customer_number, 'user.id,user.username,user.name').then(function (data) {
                if (data) {
                    angular.forEach(data, function (u) {
                        u.checked = false;
                        if ($scope.toggled.indexOf(u.id) != -1) {
                            u.checked = true;
                        }

                        if (u.id !== self.id) {
                            $scope.users.push(u);
                        }
                    });
                }
                $scope.loading = false;
            }).catch(function (err) {
                console.error(err);
            });
        });

        $scope.tableLoaded = function() {
            setTimeout(function() {
                $('#userList').removeClass('hide').DataTable({
                    "aoColumns": [
                        null,
                        null,
                        { "bSortable": false }
                    ]
                })
            }, 0);
        };

        $scope.$broadcast('getUsers');

        $scope.toggleApprover = function (u) {
            u.checked = (u.checked === false);
            if (u.checked && $scope.toggled.indexOf(u.id) === -1) {
                $scope.toggled.push(u.id);
            } else if (!u.checked) {
                var index = $scope.toggled.indexOf(u.id);
                if (index >= 0) {
                    $scope.toggled.splice(index, 1);
                }
            }
            $window.sessionStorage["shareCartToggle"] = JSON.stringify($scope.toggled);
        };

        $scope.toggleSaveCart = function () {
            $scope.saveCart = !$scope.saveCart;
        };

        $scope.getUsers = function () {
            $scope.$broadcast('getUsers');
        };

        $scope.submitShareCart = function () {
            $scope.loading = true;
            var approvers = [],
                cart = CartService.getCartCache(cartSessionId),
                user = JSON.parse($window.localStorageOverride["user"]);

            angular.forEach($scope.users, function (user) {
                if (user.checked) approvers.push(user);
            });

            if (cart.user_id === null) {
                cart.user_id = user.id;
                CartService.updateCartCache(cart);
            }

            CartService.shareCart(approvers, cart, $scope.saveCart)
                .then( function (data) {
                    $rootScope.$broadcast('notify', { message: (data) ? "Cart Approvers have been emailed." : "Unable to share cart. Please contact support." });
                    localStorage.removeItem(CartService.getCartStorageKey(cartSessionId));
                    return CartService.getCart(cartSessionId);
                }).then( function () {
                    $rootScope.$broadcast("cart-share-update");
                    $uibModalInstance.dismiss();
                    if ($scope.saveCart) {
                        user.shared_carts.push(cart.id);
                        $window.localStorageOverride['user'] = JSON.stringify(user);
                        $state.go('account.shared_carts');
                    } else {
                        $state.reload();
                    }
                }).catch(function(err) {
                    console.error(err);
                });
        };

        $scope.deselectUsers = function () {
            angular.forEach($scope.users, function (user) {
                if (user.checked) user.checked = false;
            });
            $scope.saveCart = false;
            $scope.toggled = [];
        }
    }
]);

app.controller('ShareCartController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    '$window',
    'CartService',
    'UserService',
    'cart',
    function ($rootScope, $scope, $state, $uibModal, $window, CartService, UserService, cart) {
        $scope.loading = true;
        $scope.error = false;
        var getSharedCart = function () {
            if (!cart || !cart.cart_lines.length) {
                $state.go('home');
                return false;
            }

            CartService.getCartOwnerData(cart.id).then( function (owner) {
                $scope.owner = owner;
                $scope.shared_cart = cart;
                $scope.loading = false;
            }, function () {
                $scope.loading = false;
                $scope.error = true;
            });
        };

        $scope.backToAccount = ($window.sessionStorage["sharedCartBack"]);
        $scope.goBack = function () {
            $window.sessionStorage.removeItem('sharedCartBack');
            $state.go('account.shared_carts');
        };

        if (!$window.localStorageOverride["user"]) {
            $scope.loginModal = $uibModal.open({
                templateUrl: 'login.html',
                controller: 'LoginModalController',
                scope: $scope,
                windowClass: 'login',
                resolve: {
                    destination: function() {
                        return null;
                    }
                }
            });
            $scope.loginModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
            $scope.loginModal.closed.then(function () {
                if (UserService.getIsAuthenticated()) {
                    return getSharedCart();
                } else {
                    $state.go('home');
                }
            });
        } else {
            getSharedCart();
        }

        $scope.approve = function () {
            $rootScope.$broadcast('open-loading-modal');
            CartService.mergeCart(cart.id).then( function (data) {
                if (data) {
                    $state.go('cart');
                    $rootScope.$broadcast('close-loading-modal');
                }
            });
        };

        var setQuantity = function (item) {
            angular.forEach($scope.shared_cart.cartlines, function (v) {
                if (v.erp === item.erp) {
                    v.quantity = item.quantity;
                }
            });
        };


        $scope.remove = function (id) {
            var cartItems = [];
            angular.forEach($scope.shared_cart.cart_lines, function (line) {
               if (id !== line.erp) {
                   cartItems.push(line)
               }
            });

            $scope.shared_cart.cart_lines = cartItems;
            CartService.removeItem($scope.shared_cart.session_id, $scope.shared_cart, id, true).then(function() {
            }, function() {
                $scope.errorMessage = "We were unable to remove this item from the shared cart. Please try again. If the problem persists, please contact support for help.";
            });
        };

        $scope.addQty = function (item) {
            item.quantity++;
            setQuantity(item);
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 1) {
                item.quantity--;
                setQuantity(item);
            }
        };
    }
]);

app.controller('CartOverlayController', [
    '$rootScope',
    '$scope',
    '$state',
    '$transitions',
    'CartService',
    'UserService',
    function ($rootScope, $scope, $state, $transitions, CartService, UserService) {
        UserService.getSessionId().then(function (data) {
            $scope.session = data;
        });

        $scope.showOverlay = !($state.current && $state.current.name == 'cart.summary');

        $scope.cart = CartService.getCartCache();

        function enablePreview() {
            return $scope.cart && $scope.cart.cart_lines && $scope.cart.cart_lines.length <= 20;
        }
        $scope.enableCartPreview = enablePreview();

        $rootScope.$on('cart_update', function() {
            $scope.cart = CartService.getCartCache();
            $scope.enableCartPreview = enablePreview();
        });

        $rootScope.$on('login', function() {
            UserService.getSessionId().then(function (data) {
                $scope.session = data;
            });
        });

        $rootScope.$on('logout', function() {
            $scope.session = null;
        });

        $scope.toggleOverlay = function() {
            $scope.showOverlay = !$scope.showOverlay;
        };

        $scope.calculate = function() {
            var subtotal = 0;
            angular.forEach($scope.cart.cart_lines, function(line) {
                subtotal += line.quantity * line.price;
            });
            $scope.cart.sub_total = subtotal;
            CartService.updateCartCache($scope.cart);
        };

        $transitions.onSuccess({ to: 'cart.**' }, function() {
            $scope.showOverlay = false;
        });

        $scope.remove = function (id) {
            CartService.removeItem($scope.session, $scope.cart, id).then(function() {
            }, function() {
                $rootScope.$broadcast('notify', { message: "Failed to remove item from cart." });
            });
        };
    }
]);

app.controller('EditLinePropertiesController', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    '$q',
    'cart',
    'line',
    'lineProperties',
    'CartService',
    'UDFService',
    function ($rootScope, $scope, $window, $uibModalInstance, $q, cart, line, lineProperties, CartService, UDFService) {
        UDFService.initUdfLineEdit($scope, cart, cart.cart_lines, line, 'cart', lineProperties, $uibModalInstance);
    }
]);

app.controller('expressCheckoutModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'express',
    function($rootScope, $scope, $uibModalInstance, express){
        $scope.express = express;
        $scope.express.editShipTo = false;

        $scope.submit = function() {
            $uibModalInstance.close($scope.express);
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);


app.controller('deleteCartItemsModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'CartService',
    'cart',
    'items',
    function($rootScope, $scope, $uibModalInstance, CartService, cart, items){
        $scope.cart = cart;
        $scope.items = items;

        $scope.deleteItems = function(){
            $rootScope.$broadcast('open-loading-modal');
            CartService.removeItems($scope.cart.session_id, $scope.cart, $scope.items).then(function() {
                $rootScope.$broadcast('notify', { message: "Your cart has been updated." });
                $scope.errorMessage = false;
            }).catch(function() {
                $rootScope.$broadcast('notify', { message: "Error updating cart." });
                $scope.errorMessage = "We were unable to remove one or more items from your cart. Please try again. If the problem persists, please contact support for help.";
            }).finally(function() {
                $uibModalInstance.close($scope.cart);
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
]);
