(function () {
    'use strict';
}());

app.directive('fileReader', [
    function () {
        return {
            scope: {
                fileReader:"="
            },
            link: function (scope, element) {
                $(element).on('change', function (changeEvent) {
                    var files = changeEvent.target.files;
                    if (files.length) {
                        var r = new FileReader();
                        r.onload = function (e) {
                            if (e || r.content) {
                                if (!e) {
                                    scope.fileReader = r.content;
                                } else {
                                    scope.fileReader = e;
                                }
                                scope.$evalAsync();
                            }
                        };
                        r.readAsBinaryString(files[0]);
                    }

                    $(element).val(null);
                });
            }
        }
    }
]);