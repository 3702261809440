(function () {
    'use strict';
}());

app.controller('ResetPasswordController', [
    '$rootScope',
    '$scope',
    '$state',
    '$location',
    'AccountService',
    'UserService',
    function ($rootScope, $scope, $state, $location, AccountService, UserService) {
        $scope.loading = false;

        $scope.data = {
            password: null,
            confirm_password: null
        };

        var queryParams = $location.search();
        var data = {
            username: queryParams.u,
            hash: queryParams.h
        };

        AccountService.initPasswordValidator($scope);
        $scope.passwordKeyUp();

        $scope.reset = function () {
            $rootScope.$broadcast('open-loading-modal');
            $scope.loading = true;
            data.password = $scope.data.password;
            UserService.resetPasswordUnauthenticated(data).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                $scope.loading = false;
                var message = (data.message) ? data.message : "Unable to set password. Please try again.";
                $rootScope.$broadcast('notify', { message: message });
                if (data.status === 200) $state.go('account_login');
            });
        };
    }
]);

app.controller('LoginController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$stateParams',
    'UserService',
    'isAuthenticated',
    function ($rootScope, $scope, $state, $window, $stateParams,  UserService, isAuthenticated) {
        if (isAuthenticated) {
            $state.go('account.profile');
        }
        
        $scope.data = {
            username: null,
            password: null
        };
        $scope.error = false;

        $scope.login = function (data) {
            $scope.error = false;
            $rootScope.$broadcast('open-loading-modal');

            UserService.login(data.username, data.password).then(function (authenticated) {
                if (authenticated) {
                    if ($stateParams.state !== null) {
                        $rootScope.$broadcast('close-loading-modal');
                        return $state.go($stateParams.state, $stateParams.stateParams)
                    } else {
                        // Create a promise that resolves when 'user' data is available or times out
                        var userPromise = new Promise(function (resolve) {
                            $rootScope.$broadcast('close-loading-modal');
                            var timeout = setTimeout(function () {
                                resolve('account.profile'); // Fallback if data doesn't populate within a certain time
                            }, 4000);

                            // Watch for 'user' localStorage data
                            var unwatch = $rootScope.$watch(function () {
                                return $window.localStorageOverride['user'];
                            }, function (newVal) {
                                if (newVal) {
                                    clearTimeout(timeout); // Cancel the timeout
                                    unwatch(); // Stop watching
                                    var user = JSON.parse(newVal);
                                    $rootScope.$broadcast('close-loading-modal');
                                    if (user.custom_landing_page) {
                                        resolve('home');
                                    } else {
                                        resolve('account.profile');
                                    }
                                }
                            });
                        });

                        // Use the promise to navigate to the appropriate state
                        userPromise.then(function (stateName) {
                            return $state.go(stateName);
                        });
                    }
                } else {
                    UserService.getUserStatus(data.username).then(function(user) {
                        $scope.error = user.errorMessage;
                    }).finally(function(){
                        $rootScope.$broadcast('close-loading-modal');
                    })
                }
            });
        }
    }
]);

app.controller('PasswordRecoveryController', [
    '$rootScope',
    '$scope',
    '$state',
    'UserService',
    function ($rootScope, $scope, $state, UserService) {
        $scope.recover = function () {
            if ($scope.data && $scope.data.email) {
                $rootScope.$broadcast('open-loading-modal');
                UserService.getUserStatus($scope.data.email).then(function (user) {
                    if (!user.active) {
                        $rootScope.$broadcast('close-loading-modal');
                        $rootScope.$broadcast('notify', { message: user.errorMessage });
                    } else {
                        UserService.recoverPassword($scope.data.email).then(function (data) {
                            $rootScope.$broadcast('close-loading-modal');
                            var message = (!data) ?
                                "We were unable to find an account associated with the e-mail address you entered. Please try again." :
                                "Please check your e-mail for password reset instructions.";

                            $rootScope.$broadcast('notify', { message: message });
                            if (data) $state.go('account_login');
                        }, function () {
                            $rootScope.$broadcast('close-loading-modal');
                        });
                    }
                })
            } else {
                $rootScope.$broadcast('notify', { message: 'The E-mail address field is required.' });
            }
        }
    }
]);

app.controller('AccountController', [
    '$rootScope',
    '$scope',
    '$state',
    'CartService',
    'SalesRepService',
    'UserService',
    '$uibModal',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'user',
    function ($rootScope, $scope, $state, CartService, SalesRepService, UserService, $uibModal, OPEN_BROWSE_CUSTOMER_NUMBER, user) {
        $rootScope.disablePaddedContent = true;
        $scope.user = user;
        if (!$scope.user) {
            $state.go('account_login');
            return false;
        }

        $scope.hasLongDesc = function (line) {
            return (
                line.webData && line.webData.longDescription &&
                line.webData.longDescription.length > line.itemDescription1.length
            );
        };

        $scope.notOpenBrowse = false;
        if ($scope.user) {
            $scope.notOpenBrowse = $scope.user.customer_number != OPEN_BROWSE_CUSTOMER_NUMBER;
        }

        $scope.go = function (link) {
            $state.go(link);
        };

        $scope.setState = function (state) {
            $scope.state = state;
        };

        $scope.isAdmin = $scope.user.roles.indexOf("ROLE_ADMIN") !== -1 || $scope.user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        $scope.isSuperAdmin = $scope.user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        $scope.isAR = false;

        /**
         * @doc Action Bar
         * In different views for each controller, create a html string in the controller,
         * then call $rootScope.$broadcast('setAction', htmlString); to it in.
         *
         */
        $scope.action = false;
        $rootScope.$on('setAction', function (e, args) {
            if (args !== undefined) {
                $scope.action = args;
            }
        });

        /**
         * @doc Action Bar
         * In different views for each controller, create a html string in the controller,
         * then call $rootScope.$broadcast('setAction', htmlString); to it in.
         *
         */
        $scope.action = false;
        $rootScope.$on('setAction', function (e, args) {
            if (args !== undefined) {
                $scope.action = args;
            }
        });
    }
]);

app.controller('AccountProfileController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'CompanyService',
    'SalesRepService',
    'UserService',
    'UtilityService',
    'CDFService',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    function ($rootScope, $scope, $state, $window, CompanyService, SalesRepService, UserService, UtilityService, CDFService, OPEN_BROWSE_CUSTOMER_NUMBER) {
        if (!$rootScope.currentUser) {
            $state.go('account_login');
            return false;
        }

        $scope.setState($state.current.name);
        $scope.$on('getCompanySummary', function (e, a) {
            CompanyService.getCompanySummary(a).then(function (data) {
                if (data) {
                    $scope.customer = null;
                    $scope.customer = data;
                    if ($scope.user.customer_number !== a) {
                        $scope.user.customer_number = a;
                        $scope.user.customer_type = data.type;
                    }

                    if ($scope.user.sales_rep_id) {
                        $scope.user.customer_name = data.name;
                    }
                }
            });
        });

        var initialize = function () {
            $scope.user = UserService.getPersistedUser();
            if (!$scope.user) {
                UserService.logout();
            }

            CDFService.getPendingRequests($scope.user.id).then(function (data) {
                var pending = {};
                angular.forEach(data, function (v) {
                    pending[v.cdfId] = v.cdfValue;
                });
                $scope.user.cdf_pending = pending;
            }).catch(angular.noop);

            $scope.data = $scope.user;

            // Force CDF value to default, if empty
            angular.forEach($scope.data.cdf, function (v, k) {
                if (!v.value) {
                    $scope.data.cdf[k].value = $scope.data.cdf[k].defaultValue;
                }
            });

            $scope.editing = false;
            $scope.resetting = false;
            $scope.isOpenBrowse = ($scope.user.customer_number == OPEN_BROWSE_CUSTOMER_NUMBER);
            if (!$scope.isOpenBrowse) {
                $scope.$broadcast('getCompanySummary', $scope.user.customer_number);
            }
        };

        initialize();

        $scope.edit = function () {
            $scope.editing = true;
            $scope.data2 = angular.copy($scope.data);
        };

        $scope.reset = function () {
            $scope.resetting = true;
        };

        $scope.cancel = function () {
            $scope.editing = false;
            $scope.resetting = false;
            $scope.data = $scope.data2;
        };

        $scope.errors = [];

        $scope.notificationsList = function (user) {
            var list = [];
            if (user.email_tracking) { list.push('Shipping/Tracking'); }
            if (user.email_invoice) { list.push('Invoices'); }
            if (user.email_scantoweb) { list.push('Scan to Web'); }
            if (!list.length) { list.push('Disabled'); }
            return list.join(', ');
        };

        $scope.isValidEmail = function (user) {
            if (!user) { return false; }
            return UtilityService.isValidEmail(user.username);
        };

        $scope.submit = function (data, id) {
            $scope.errors = [];
            $rootScope.$broadcast('open-loading-modal');
            UserService.putUser(data, id).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data.status === 200) {
                    var message = 'Profile successfully updated.';
                    var approvalMsg = $scope.approvalMessage(data.data);
                    if (approvalMsg) {
                        message += ' ' + approvalMsg;
                    }
                    UserService.getUser(id).then(function () {
                        initialize();
                        $rootScope.$broadcast('notify',
                            { message: message });
                        if ($scope.user.sales_rep_id) {
                            $scope.user.customer_name = $scope.selectedCompany.customer_name;
                            $rootScope.$broadcast('refreshSalesRepUser', $scope.user);
                        }
                    }).catch(function(err) {
                        console.error(err);
                    });
                } else {
                    angular.forEach(data.data, function (v) {
                        if (undefined !== v.message) {
                            $scope.errors.push(v.message);
                        }
                    });
                }
            });
        };

        $scope.updatePassword = function (data, id) {
            $scope.errors = [];
            $rootScope.$broadcast('open-loading-modal');
            UserService.resetPasswordAuthenticated(data, id).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data.status === 200) {
                    initialize();
                    $rootScope.$broadcast('notify', { message: "Password successfully updated." });
                } else {
                    angular.forEach(data.data, function (v) {
                        if (undefined !== v) {
                            $scope.errors.push(v);
                        }
                    });
                }
            });
        };

        $scope.isAdmin = function() {
            return $scope.user.roles.indexOf("ROLE_ADMIN") !== -1 || $scope.user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        };

        $scope.approvalMessage = function (user) {
            var fields = [];
            angular.forEach(user.cdf_requests, function (v) {
                if (v.active && v.status === 'Pending') {
                    fields.push(v.cdfName);
                }
            });
            return fields.length ? 'The following fields require approval: ' + fields.join(', ') : '';
        };
    }
]);

app.controller('AccountSummaryController', [
    '$filter',
    '$rootScope',
    '$scope',
    '$state',
    'CompanyService',
    'UserService',
    function ($filter, $rootScope, $scope, $state, CompanyService, UserService) {
        if (!UserService.canViewAccountOverview($scope.user)) {
            $state.go('unauthorized');
            return false;
        }

        $scope.showTable = true;
        $scope.setState($state.current.name);

        $scope.summary_loading = true;
        CompanyService.getCompanySummary($scope.user.customer_number).then(function (res) {
            if (res) {
                $scope.summary = res;
                $scope.summary.period = $scope.summary.custperiodbal.split(';');

                $scope.summary.openordval = 0;
                angular.forEach($scope.summary.period, function (period) {
                    $scope.summary.openordval += parseFloat(period);
                });

                //AMOUNT DUE
                var html = '<div class="col-xs-6 col-sm-6 col-md-6"><span>Amount Due By ' + moment()
                        .format('MM/DD/YYYY') + '</span>' +
                    '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
                    '<span>' + $filter('currency')($scope.summary.ordbal) + '</span></div>' +
                    '<div class="col-xs-6 col-sm-6 col-md-2"><a href="#" class="pull-right btn btn-primary btn-large">Pay Bill</a></div>';
                $rootScope.$broadcast('setAction', html);
                //PIE CHART
                $scope.labels = ["Available", "Total"];
                $scope.data = [5000.50, 10000.90];
                $scope.options = {
                    tooltipEvents: [],
                    showTooltips: true,
                    tooltipCaretSize: 0,
                    tooltipTemplate: '<%=label%>: $<%=value%>',
                    onAnimationComplete: function () {
                        this.showTooltip(this.segments, true);
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                var currentValue = dataset.data[tooltipItem.index];
                                return data.labels[tooltipItem.index] + ": " + $filter('currency')(
                                    currentValue);
                            }
                        }
                    }
                };
                $scope.override = {
                    borderColor: ['#ED8C01', '#0061A0'],
                    backgroundColor: ['#ED8C01', '#0061A0'],
                    hoverBackgroundColor: ['#ED8C01', '#0061A0']
                };
            }

            $scope.summary_loading = false;
        }, function () {
            $scope.summary_loading = false;
        });
    }
]);

app.controller('AccountReviewOrdersController', [
    '$scope',
    '$location',
    '$state',
    '$filter',
    '$sce',
    'NgTableParams',
    'AccountService',
    'InvoiceService',
    'OrderService',
    'UserService',
    'PunchoutService',
    '$rootScope',
    '$uibModal',
    function ($scope, $location, $state, $filter, $sce, NgTableParams, AccountService, InvoiceService, OrderService, UserService, PunchoutService, $rootScope, $uibModal) {
        if (
            ('account.orders' === $state.current.name && !UserService.canViewMyOrders($scope.user)) ||
            ('account.revieworders' === $state.current.name && !UserService.canViewOrderHistory($scope.user)) ||
            ('account.reviewquotes' === $state.current.name && !UserService.canViewReviewQuotes($scope.user)) ||
            ('account.open_invoices' === $state.current.name && !UserService.canViewOpenInvoices($scope.user)) ||
            ('account.paid_invoices' === $state.current.name && !UserService.canViewPaidInvoices($scope.user))
        ) {
            $state.go('unauthorized');
            return false;
        }

        $scope.setState($state.current.name);
        $scope.orderType = $state.current.data.orderType ? $state.current.data.orderType : '';
        $scope.customerNumber = UserService.getCustomerNumber();
        $scope.limits = [10,25,50,100];
        $scope.loading = false;
        $scope.pageTitle = $state.current.data.title;
        var stageCd = $state.current.data.stageCd;
        $scope.invoicePage = !!stageCd;
        AccountService.initOrderFilter($scope);

        $scope.isPunchout = false;
        $scope.processed = [];
        if (UserService.getIsPunchOut()) {
            $scope.isPunchout = true;
            PunchoutService.getPunchoutLog($scope.customerNumber).then(function(orders) {
                if (orders) {
                    $scope.processed = orders;
                }
            });
        }

        $scope.processedIcon = function (oe) {
            return oe && $scope.processed.indexOf(oe) !== -1 ? $sce.trustAsHtml('<i class="fa fa-check-circle text-success"></i>') : '';
        };

        $scope.metaData = {
            customerNumber: $scope.customerNumber,
            fromDate: '',
            toDate: '',
            shipTo: '',
            stageCd: stageCd,
            transType: '',
            filter: '',
            limit: $scope.limits[1],
            page: 1,
            current_items: 0,
            total_items: 0,
            userId: UserService.getPersistedUser().id,
            viewType: ''
        };

        $scope.getOrders = function (newSearch) {
            $rootScope.$broadcast('open-loading-modal');
            $scope.data = [];

            $scope.loading = true;
            $scope.metaData.fromDate = $scope.orderFilter.fromDate;
            $scope.metaData.toDate = $scope.orderFilter.toDate;
            $scope.metaData.shipTo = $scope.orderFilter.shipTo;
            $scope.metaData.transType = $scope.orderFilter.transType;
            $scope.metaData.filter = $scope.orderFilter.filter;
            $scope.metaData.viewType = $scope.orderFilter.viewType;
            $scope.metaData.omitCancelled = $scope.orderFilter.omitCancelled;

            if (newSearch) {
                $scope.isFiltered = $scope.hasFilters();
                $scope.metaData.page = 1;
            }
            OrderService
                .getOrderHistory($scope.metaData).then(function (data) {
                    if (data) {
                        $scope.metaData.total_items = parseInt(data.meta.total_items);
                        $scope.metaData.current_items = parseInt(data.meta.current_items);
                        _showOrders(data);
                    } else {
                        $scope.showTable = false;
                    }
                }).catch(function () {
                    $scope.showTable = false;
                    $scope.metaData.total_items = 0;
                    $scope.metaData.current_items = 0;
                }).finally(function () {
                    $scope.loading = false;
                    $rootScope.$broadcast('close-loading-modal');
                });
        };

        function _showOrders(data) {
            $scope.showTable = true;
            $scope.data = (data.orders) ? data.orders : [];
            $scope.showTable = ($scope.data.length > 0);
            $scope.data.forEach(function (order){
                order.params = {
                    order_number: order.orderno + '-' + order.ordersuf
                };
                if (+order.custno !== +UserService.getCustomerNumber()) {
                    order.params.c = order.custno;
                }
                order.formattedInvAmt = AccountService.formatInvAmt(order);
            });

            $scope.tableParams = new NgTableParams({
                page: 1,
                count: $scope.metaData.limit
            }, {
                dataset: $scope.data,
                counts: []
            });
        }

        $scope.openEmailInvoiceModal = function(order) {
            $scope.emailInvoiceNumber = order.orderno + '-' + $filter('zeroPad')(order.ordersuf, 2);
            $scope.toEmail = $scope.user.username;

            $scope.emailInvoicePdfModal = $uibModal.open({
                templateUrl: 'emailInvoicePdfModal.html',
                scope: $scope
            });

            $scope.emailInvoicePdfModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });

            $scope.closeModal = function () {
                $scope.emailInvoicePdfModal.dismiss();
            };
        };

        $scope.emailInvoice = function(emailInvoiceNumber, toEmail) {
            $rootScope.$broadcast('open-loading-modal');
            InvoiceService.sendEmailInvoice(emailInvoiceNumber, $scope.user.customer_number, toEmail).then(function (data) {
                if (data.data.error === 0) {
                    $rootScope.$broadcast('notify', { message: "Email successfully sent!" });
                } else {
                    $rootScope.$broadcast('notify', { message: "Email failed to be sent." });
                }
            }).catch(function(err) {
                $rootScope.$broadcast('notify', { message: err });
            }).finally(function() {
                $scope.emailInvoicePdfModal.dismiss();
                $rootScope.$broadcast('close-loading-modal');
            })
        };

        $scope.getOrders(false);
    }
]);

app.controller('AccountSummaryInvoicesController', [
    '$rootScope',
    '$scope',
    '$state',
    'InvoiceService',
    'UserService',
    'NgTableParams',
    function ($rootScope, $scope, $state, InvoiceService, UserService, NgTableParams) {
        $scope.setState($state.current.name);

        if (!UserService.canViewSummaryInvoices($scope.user)) {
            $state.go('unauthorized');
            return false;
        }

        var custNo = $scope.user.customer_number;
        var fetchLimit = 25;
        $scope.showTable = false;
        $scope.loading = false;
        InvoiceService.initSummaryInvoiceFilter($scope);

        $scope.$on('refreshList', function (e, status) {
            $scope.tableParams = new NgTableParams({
                page: 1,
                count: fetchLimit
            }, {
                filterDelay: 500,
                getData: function (params) {
                    $rootScope.$broadcast('open-loading-modal');
                    var sorting = params.sorting();
                    var order_by = Object.keys(sorting)[0];
                    $scope.invoiceFilter.orderBy = order_by ? order_by + ' ' + sorting[order_by] : null;
                    $scope.loading = !$scope.data;

                    return InvoiceService.getSummaryInvoices(custNo, params.count(), params.page(), $scope.invoiceFilter).then(function (response) {
                        $scope.data = response && response.invoices ? response.invoices : [];
                        params.total(response.meta.total_items);
                        return $scope.data;
                    }).catch(function (err) {
                        $scope.data = [];
                    }).finally(function() {
                        $scope.isFiltered = $scope.hasFilters();
                        $scope.loading = false;
                        $rootScope.$broadcast('close-loading-modal');
                        $scope.showTable = $scope.data && $scope.data.length > 0;
                    });
                }
            });
        });
        $scope.getSummaryInvoices = function() {
            $scope.$broadcast('refreshList');
        }

        $scope.getSummaryInvoices();

        $scope.downloadCsv = function() {
            InvoiceService.downloadSummaryInvoices(custNo, $scope.invoiceFilter);
        };
    }
]);

app.controller('AccountSummaryInvoiceDetailsController', [
    '$rootScope',
    '$cookies',
    '$sce',
    '$scope',
    '$state',
    'InvoiceService',
    'UserService',
    'NgTableParams',
    '$stateParams',
    'Blob',
    function ($rootScope, $cookies, $sce, $scope, $state, InvoiceService, UserService, NgTableParams, $stateParams, Blob) {
        $scope.setState($state.current.name);

        if (!UserService.canViewSummaryInvoices($scope.user)) {
            $state.go('unauthorized');
            return false;
        }

        var billno = $stateParams.summary_billing_number;
        var groupno = $stateParams.group_number;
        var cost_center = $stateParams.cost_center !== undefined ? $stateParams.cost_center : null;
        var fetchLimit = 5000;  // # records not expected to exceed 500, ok to use client side NgTable features
        $scope.showTable = false;
        $scope.loading = false;
        $scope.punchoutHook = $sce.trustAsResourceUrl($cookies.get('punchoutHook'));

        $scope.getSummaryInvoiceDetails = function() {
            $scope.loading = true;
            $rootScope.$broadcast('open-loading-modal');

            InvoiceService.getSummaryInvoiceDetails(billno, groupno, cost_center, fetchLimit, 1).then(function (data) {
                $scope.invoice = {
                    header: data.invoice_lines.length ? data.invoice_lines[0] : null,
                    meta: data.meta,
                    lines: data.invoice_lines,
                    totalUnitPrice: data.total_unit_price
                };
                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 150
                }, {
                    counts: [],
                    dataset: $scope.invoice.lines
                });
            }).catch(function(err) {
                if (!err) {
                    err = 'Error fetching data';
                }
                $rootScope.$broadcast('notify', { message: err });
            }).finally(function() {
                $scope.showTable = $scope.invoice && $scope.invoice.lines.length > 0;
                $scope.loading = false;
                $rootScope.$broadcast('close-loading-modal');
            })
        }
        $scope.getSummaryInvoiceDetails();

        $scope.punchoutForm = function() {
            // this line is just for testing
            // return $scope.invoice && !$scope.invoice.header.submit_date;
            // the following line should be used in production
            return $scope.punchoutHook && $scope.invoice && !$scope.invoice.header.submit_date;
        };

        try {
            $scope.isFileSaverSupported = !!new Blob;
        } catch (e) {}

        $scope.downloadCsv = function() {
            InvoiceService.downloadSummaryInvoiceDetails($scope.invoice.lines);
        };

        $scope.submitInvoice = function(invoiceHeader) {
            $rootScope.$broadcast('open-loading-modal');
            InvoiceService.postSubmitSummary(
                invoiceHeader.summary_billing_number,
                invoiceHeader.group_number,
                invoiceHeader.cost_center
            ).then(function () {
                var now = new Date();
                $scope.invoice.header.submit_date = moment(now).format("YYYY-MM-DD");
                angular.element(document.querySelector('#invoiceLinesForm')).submit();
            }).catch(function(err) {
                $rootScope.$broadcast('notify', { message: err });
            }).finally(function() {
                $rootScope.$broadcast('close-loading-modal');
            })
        }
    }
]);

app.controller('AccountOrderDetailsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'AccountService',
    'CatalogService',
    'OrderService',
    'CartService',
    'UserService',
    'PartService',
    '$uibModal',
    function ($rootScope, $scope, $state, $stateParams, AccountService, CatalogService, OrderService, CartService, UserService, PartService, $uibModal) {
        $scope.loading = true;
        $rootScope.$broadcast('open-loading-modal');
        $scope.orderType = $state.current.data.orderType ? $state.current.data.orderType : '';

        if ('account.order_details' === $state.current.name) {
            $scope.backLabel = 'My Orders';
            $scope.backRoute = 'account.orders';
        } else {
            $scope.backLabel = 'QU' === $scope.orderType ? 'Review Quotes' : 'Order History';
            $scope.backRoute = 'QU' === $scope.orderType ? 'account.reviewquotes' : 'account.revieworders';
        }
        $scope.setState($scope.backRoute);
        $scope.containsSiteUrl = false;
        $scope.containsRequiredDate = false;
        $scope.containsLastPaid = false;
        $scope.containsFile = false;
        $scope.enableAddToCart = false;
        $scope.enableConvertQuote = false;
        $scope.convertingQuote = false;

        var orderId = $stateParams.order_number;
        $scope.order = {};

        UserService.getCustomerMaster().then(function(data) {
            $scope.customerMaster = data ? data.sx_customer : false;
        });

        AccountService.initItemDetailModal($scope, $uibModal);

        var orderCustno = +UserService.getCustomerNumber();
        if ($stateParams.c !== undefined && orderCustno === 223046) {
            orderCustno = $stateParams.c;
        }

        OrderService.getOrder(orderId, orderCustno).then(function (order) {
            $scope.order = order;
            if (order &&
                order.header.orderType.toLowerCase() === 'q' &&
                !UserService.getIsPunchOut() &&
                $scope.user.can_submit_orders
            ) {
                $scope.enableConvertQuote = true;
                if (order.lines && !order.header.estimatedPricing) {
                    // if any quote lines have price = 0, treat as estimated - prevent from converting
                    var noPrice = order.lines.filter(function (x) { return !x.actualSellPrice; });
                    order.header.estimatedPricing = noPrice.length;
                    $scope.originalLines = angular.copy(order.lines);
                }
            }

            if (order &&
                order.header.orderType.toLowerCase() === 'q' &&
                !$scope.enableConvertQuote &&
                order.lines &&
                $scope.user.can_submit_orders &&
                !order.header.estimatedPricing
            ) {
                $scope.enableAddToCart = true;
            }

        }).finally(function() {
            $rootScope.$broadcast('close-loading-modal');
            $scope.loading = false;
        });

        $scope.disableQuoteBtn = function() {
            return $scope.order.lines.length < 1 ||
                $scope.order.header.estimatedPricing ||
                ($scope.order.header.cancelDate && new Date($scope.order.header.cancelDate) <= new Date()) ||
                $scope.convertingQuote ||
                ($scope.order.header.webData && $scope.user.id !== $scope.order.header.webData.userId && !$scope.user.is_vallen) ||
                ($scope.order.header.webData && $scope.order.header.webData.approvalStatus)
        }

        $scope.editQuantity = function() {
            return $scope.enableConvertQuote && !$scope.disableQuoteBtn();
        }

        $scope.qtyError = function (el) {
            if (!el.$error || !(el.$invalid && el.$dirty)) { return; }
            if (el.$error.required) { return 'Required'; }
            if (el.$error.min) { return 'Must be >= 0'; }
            if (el.$error.max) { return 'Must be <= ' + el.$$attr.max; }
            if (el.$error.step || el.$error.number) { return 'Must be whole #'; }
            return 'Invalid';
        }

        $scope.recalculate = function (i) {
            $scope.changedQty = 0;
            var itemTotal = 0;
            $scope.order.lines[i].displayLineTotal = +$scope.order.lines[i].quantityOrdered * +$scope.order.lines[i].displayPrice;
            angular.forEach($scope.order.lines, function (line, i) {
                itemTotal += Number(line.displayLineTotal);
                if (+line.quantityOrdered !== +$scope.originalLines[i].quantityOrdered) {
                    $scope.changedQty++;
                }
            });
            var grandTotal = angular.copy(itemTotal);
            if ($scope.order.header.totalSpecialCharges) {
                grandTotal += +$scope.order.header.totalSpecialCharges;
            }
            if ($scope.order.header.salesTaxAmount) {
                grandTotal += +$scope.order.header.salesTaxAmount;
            }
            if ($scope.order.header.discountAmountTrading) {
                grandTotal -= +$scope.order.header.discountAmountTrading;
            }
            $scope.order.header.displayTotalOrderValue = grandTotal;
            $scope.order.header.displayItemSalesAmount = itemTotal;
        }

        $scope.clickConvertBtn = function() {
            if ($scope.changedQty) {
                var initialValue = 0
                var totalQty = $scope.order.lines.reduce(function (accumulator, line) {
                    return accumulator + +line.quantityOrdered;
                }, initialValue);
                if (!totalQty) {
                    $rootScope.$broadcast('notify', {message: "You may not place an order with 0 items"});
                    return;
                }

                OrderService.postQuoteQuantities($scope.order).then(function() {
                    $scope.convertToOrder();
                }).catch(function () {
                    $rootScope.$broadcast('notify', {message: "ERROR: Unable to update item quantities"});
                });
            } else {
                $scope.convertToOrder();
            }
        };

        $scope.sendAllToCart = function() {
            $rootScope.$broadcast('open-loading-modal');

            CartService.convertOrderLinesToCartLines($scope.order).then(function (cartLines) {
                UserService.getSessionId().then(function (session) {
                    CartService.postCart(session, cartLines).then(function (data) {
                        $rootScope.$broadcast('close-loading-modal');
                        if (data) {
                            $state.go('cart');
                        } else {
                            $rootScope.$broadcast('notify', {message: "An error occurred. Items could not be added to the cart."});
                        }
                    });
                });
            });
        };

        $scope.convertToOrder = function () {
            $scope.quotePO = $scope.order.header.customerPurchaseOrderNumber ? angular.copy($scope.order.header.customerPurchaseOrderNumber) : '';
            $scope.quoteConfirmationModalInstance = $uibModal.open({
                size: 'md',
                templateUrl: 'views/account/_partials/quoteConfirmationModal.html',
                scope: $scope
            });

            $scope.quoteConfirmationModalInstance.result.then(function(poNumber){
                $scope.quotePO = poNumber;
                $scope.approveQuote();
            }, function() {
                // modal dismissed
            });

            $scope.submitQuote = function (poNumber) {
                $scope.quoteConfirmationModalInstance.close(poNumber)
            };

            $scope.cancelQuote = function () {
                $scope.quoteConfirmationModalInstance.dismiss()
            };
        };

        $scope.approveQuote = function () {
            $rootScope.$broadcast('open-loading-modal');
            $scope.convertingQuote = true;

            var cartTotal = 'units' === $scope.order.header.currencyDisplay ? Number($scope.order.header.webData.totalUnits) : Number($scope.order.header.totalOrderValue);
            OrderService.checkOrderLimits(
                $scope.user.id,
                $scope.order.header.customerNumber,
                cartTotal,
                CartService.calculateItemSubTotals($scope.order),
                false
            ).then(function (checkUser) {
                if (checkUser.error) {
                    throw Error(checkUser.message);
                } else if (checkUser.needs_approval) {
                    return CartService.sendQuoteForApproval(
                        $scope.user.id,
                        $scope.order.header.orderNumber,
                        {"misc_info": { "Customer PO": $scope.quotePO }}
                        )
                        .then(function (data) {
                            if (data) {
                                return $scope.quoteApprovalModal();
                            } else {
                                throw Error('Error initiating quote approval.');
                            }
                        }).catch(function(err) {
                            throw Error(err);
                        });
                } else {
                    return OrderService.postConvertToOrder(
                        $scope.order.header.companyNumber,
                        $scope.order.header.orderNumber,
                        $scope.order.header.orderSuffix,
                        $scope.quotePO
                    ).then(function (data) {
                        var ignoreCache = true;
                        if (data && data.successType == "y") {
                            return OrderService.getOrder(data.newOrderNumber + '-' + data.newOrderSuffix, $scope.order.header.customerNumber, ignoreCache)
                                .then(function (order) {
                                    $scope.order = order;
                                    if ($scope.order) {
                                        $scope.originalLines = angular.copy(order.lines);
                                        $scope.orders = [$scope.order];
                                        $scope.quoteConvertedModal();
                                    } else {
                                        throw Error('Unable to locate order details.');
                                    }
                                });
                        } else {
                            var message = data.errorMessage ? "Error: " + data.errorMessage : "Error: Unable to order quote.";
                            throw Error(message);
                        }
                    }).catch(function(err) {
                        throw Error(err);
                    });
                }
            }).catch(function (error) {
                $scope.convertingQuote = false;
                $rootScope.$broadcast('notify', error);
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.quoteConvertedModal = function () {
            $scope.quoteConvertedModalInstance = $uibModal.open({
                size: 'lg',
                templateUrl: 'views/_partials/sxeOrderConfirmationModal.html',
                scope: $scope
            });
            $scope.quoteConvertedModalInstance.result.then(function(){
                $state.go('account.orders');
                // modal closed
            }, function() {
                $state.go('account.orders');
                // modal dismissed
            });
        };

        $scope.quoteApprovalModal = function () {
            $scope.quoteApprovalModalInstance = $uibModal.open({
                size: 'md',
                templateUrl: 'views/account/_partials/quoteApprovalModal.html',
                scope: $scope
            });
            $scope.quoteApprovalModalInstance.result.then(function(){
                $state.go('account.reviewquotes');
                // modal closed
            }, function() {
                $state.go('account.reviewquotes');
                // modal dismissed
            });

            $scope.closeQuoteApprovalModal = function () {
                $scope.quoteApprovalModalInstance.dismiss();
            };
        };

        $scope.close = function () {
            $scope.quoteConvertedModalInstance.close();
        };

        $scope.orderHelpModal = function () {
            $scope.openContactModal('orderSupport',
                {
                    mail_subject: "Support requested for Order #" + $scope.order.header.orderNumber,
                    mail_message: "Re: Order #" + $scope.order.header.orderNumber
                }
            );
        };
    }
]);

app.controller('AccountSettingsController', [
    '$scope',
    '$state',
    function ($scope, $state) {
        $scope.setState($state.current.name);
    }
]);

app.controller('AccountShippingController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'ShippingAddressService',
    'UserService',
    function ($rootScope, $scope, $state, $uibModal, ShippingAddressService, UserService) {
        $scope.setState($state.current.name);

        $scope.clear = function () {
            $scope.data = {};
            $scope.showForm = '';
            $scope.errors = [];
        };

        var initialize = function () {
            $scope.clear();
            $scope.loading = true;
            $scope.shippingAddresses = [];
            UserService.getUserShipTos().then(function (shiptos) {
                $scope.shippingAddresses = shiptos;
            }).finally(function () {
                if (!$scope.shippingAddresses.length && $scope.user.can_edit_ship_to) {
                    $scope.showForm = 'Add';
                }
                $scope.loading = false;
            });
        };
        initialize();

        $scope.add = function () {
            $scope.showForm = 'Add';
        };

        $scope.edit = function (addr) {
            $scope.showForm = 'Edit';
            $scope.data = angular.copy(addr);
        };

        $scope.submit = function () {
            $scope.errors = [];
            $rootScope.$broadcast('open-loading-modal');

            var formData = {
                user_id: $scope.user.id,
                company_name: $scope.data.name,
                street_address_1: $scope.data.addr1,
                street_address_2: $scope.data.addr2,
                city: $scope.data.city,
                state: $scope.data.state,
                zip: $scope.data.zip,
                is_default: !!$scope.data.isDefault,
                active: $scope.data.id ? true : null
            };

            var updateFunction = $scope.data.id ? ShippingAddressService.putAddress(formData, $scope.data.id) : ShippingAddressService.postAddress(formData);

            updateFunction.then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data.status === 200 || data.status === 201) {
                    $rootScope.$broadcast('notify', { message: "Shipping address successfully saved." });
                    initialize();
                } else {
                    angular.forEach(data.data, function (v) {
                        if (undefined !== v.message) {
                            $scope.errors.push(v.message);
                        }
                    });
                }
            }).catch(function () {
                $rootScope.$broadcast('notify',
                    { message: "An error occurred while attempting to save. Please try again later." });
            });
        };

        $scope.delete = function (addr) {
            $scope.errors = [];
            var promptModal = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                templateUrl: 'shipping_confirm_delete.html',
                controller: 'ShippingDeleteModalInstanceController',
                windowClass: '',
            });

            promptModal.result.then(function (data) {
                if (data === 'confirm') {
                    $rootScope.$broadcast('open-loading-modal');
                    ShippingAddressService.deleteAddress(addr.id).then(function (data) {
                        $rootScope.$broadcast('close-loading-modal');
                        if (data.status === 204) {
                            initialize();
                            $rootScope.$broadcast('notify',
                                { message: "Shipping address successfully deleted" });
                        } else {
                            $rootScope.$broadcast('notify',
                                { message: "An error occurred while attempting to delete. Please try again later." });
                        }
                    });
                }
            }, function () {
                // modal dismissed
            });
        }
    }
]);

app.controller('ShippingDeleteModalInstanceController', [
    '$scope',
    '$state',
    '$uibModalInstance',
    function ($scope, $state, $uibModalInstance) {
        $scope.delete = function () {
            $uibModalInstance.close('confirm');
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }
]);

app.controller('AccountBillingController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'BillingInfoService',
    'UserService',
    '$uibModal',
    '$timeout',
    function ($rootScope, $scope, $state, $window, BillingInfoService, UserService, $uibModal, $timeout) {
        if (!UserService.canViewBillingInfo($scope.user)) {
            $state.go('unauthorized');
            return false;
        }

        $scope.setState($state.current.name);

        $scope.getTokens = function () {
            $scope.token_loading = true;

            $scope.tokens = [];
            BillingInfoService.getTokens({ user_id: $scope.user.id }).then(function (data) {
                if (!data.error) {
                    $scope.message = false;
                    if (data.length) {
                        angular.forEach(data, function (d) {
                            $scope.tokens.push(d);
                        })
                    } else {
                        if (data.length == undefined) {
                            $scope.tokens.push(data);
                        }
                    }

                    angular.forEach($scope.tokens, function (token) {
                        angular.forEach($scope.user.payment_methods, function (method) {
                            if (method.token === token.token) {
                                token.card_default = (method.card_default);
                            }
                        })
                    });
                }

                $scope.token_loading = false;
            });
        };

        var initialize = function () {
            $scope.user = JSON.parse($window.localStorageOverride["user"]);
            $scope.deleting = false;
            $scope.add_edit = false; // shows/hides the "add/edit" form
            $scope.message = false;
            $scope.form_title = "";
            $scope.btn_text = "Submit";
            $scope.loading = false;
            $scope.require_cc_option = false;
            $scope.tokens = [];

            var keep_going = true;
            angular.forEach($scope.user.roles, function (role) {
                if (keep_going) {
                    if (role === "ROLE_ADMIN") {
                        $scope.require_cc_option = true;
                        keep_going = false;
                    }
                }
            });

            $scope.getTokens();
        };

        $scope.add = function () {
            $scope.add_edit = true;
            $scope.submit_type = "add";
            $scope.form_title = "Add New Payment Method";
            $scope.data = {
                name: $scope.user.name
            };
        };

        $scope.edit = function (token) {
            $scope.add_edit = true;
            $scope.submit_type = "edit";
            $scope.form_title = "Edit Payment Method";
            $scope.data = {
                name: token.name,
                nick_name: token.nick_name,
                address: token.address,
                zip: token.zip,
                card_type: token.card_type,
                number: token.card,
                exp_month: parseInt(token.exp_month) < 10 ? '0' + parseInt(token.exp_month) : parseInt(
                    token.exp_month),
                exp_year: parseInt(20 + token.exp_year),
                card_default: token.card_default
            };

            $scope.token_id = token.token;
            $timeout(function () {
                $scope.creditCardForm.$invalid = false;
            }, 1);
        };

        $scope.submit = function (data) {
            $scope.loading = true;
            data = JSON.parse(JSON.stringify(data));
            data.exp_month = ("0" + data.exp_month).slice(-2).toString();
            data.exp_year = parseInt(data.exp_year.toString().substring(2));
            data.card_default = data.card_default ? 1 : 0;

            if ($scope.submit_type === "edit") {
                BillingInfoService.putToken($scope.token_id, data).then(function () {
                    $scope.message = false;
                    $scope.add_edit = false;

                    // set broadcast "growl" message
                    $rootScope.$broadcast('notify',
                        { message: "Payment method has been modified." });

                    $scope.data = {};
                    $scope.creditCardForm.$setPristine();
                    $scope.creditCardForm.$setUntouched();
                    UserService.getUser($scope.user.id).then(function () {
                        initialize();
                    }).catch(function(err) {
                        console.error(err);
                    });
                }).catch(function (data) {
                    $rootScope.$broadcast('notify', { message: data.error });
                }).finally(function() {
                    $scope.loading = false;
                });
            } else if ($scope.submit_type === "add") {
                data.user_id = $scope.user.id;

                BillingInfoService.postToken(data).then(function () {
                    $scope.message = false;
                    $scope.add_edit = false;

                    // set broadcast "growl" message
                    $rootScope.$broadcast('notify', { message: "New payment method added." });

                    // clear form
                    $scope.data = {};
                    $scope.creditCardForm.$setPristine();
                    $scope.creditCardForm.$setUntouched();
                    UserService.getUser($scope.user.id).then(function () {
                        initialize();
                    }).catch(function(err) {
                        console.error(err);
                    });
                }).catch(function(data) {
                    $rootScope.$broadcast('notify', { message: data.error });
                }).finally(function() {
                    $scope.loading = false;
                });
            }
        };

        $scope.delete = function (token) {
            $scope.token_to_delete = token;
            $scope.confirmDeleteModal = $uibModal.open({
                templateUrl: 'confirm_delete.html',
                scope: $scope
            });

            $scope.confirmDeleteModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        $scope.cancelDelete = function () {
            $scope.confirmDeleteModal.dismiss();
        };

        $scope.$on('refreshTokens', function () {
            $scope.tokens = [];
            // set broadcast "growl" message
            $rootScope.$broadcast('notify', { message: "Payment method has been deleted." });
            $scope.deleting = false;
            $scope.confirmDeleteModal.dismiss();
            $scope.message = false;
            $scope.getTokens();
        });

        $scope.acceptDelete = function () {
            $scope.deleting = true;
            BillingInfoService.deleteToken($scope.token_to_delete).then(function () {
                $scope.$broadcast('refreshTokens');
            }).catch(function (error) {
                $scope.message = 'Unable to complete request: ' + error.message;
                $scope.confirmDeleteModal.dismiss();
            }).finally(function() {
                $scope.deleting = false;
            });
        };

        $scope.cancel = function () {
            $scope.add_edit = false;
            $scope.message = false;
            $scope.data = {};
            $scope.creditCardForm.$setPristine();
            $scope.creditCardForm.$setUntouched();
        };

        $scope.saveOptions = function () {
            $scope.token_loading = true;
            UserService.putUser($scope.user, $scope.user.id).then(function (data) {
                $scope.token_loading = false;
                var message = (data) ? "Billing option saved." : "Unable to save billing option.";
                $rootScope.$broadcast('notify', { message: message });
            });
        };

        initialize();
    }
]);

app.controller('AccountSharedCartsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'CartService',
    'UserService',
    'NgTableParams',
    function ($rootScope, $scope, $state, $window, CartService, UserService, NgTableParams) {
        $scope.setState($state.current.name);
        var user = JSON.parse($window.localStorageOverride.user);
        var shared_carts = user.shared_carts ? user.shared_carts : [];
        if (!shared_carts) {
            $rootScope.$broadcast('notify', { message: "Unable to find shared carts." });
            $state.go('account.profile');
        }

        $scope.sharedCarts = [];
        $scope.$on('refreshSharedCarts', function (e, a) {
            $scope.sharedCarts = a;
            $scope.tableParams = new NgTableParams({}, {
                dataset: $scope.sharedCarts
            });
        });

        UserService.updateUser();
        $scope.rejectCart = function (cart) {
            CartService.rejectCart(cart.id).then(function () {
                UserService.updateUser().then(function () {
                    $state.reload();
                });
            }).catch(function() {
                $rootScope.$broadcast('notify', { message: "Failed to reject cart." });
            });
        };

        $scope.goToCart = function (cart) {
            // For back button
            $window.sessionStorage.setItem('sharedCartBack', $state.current.name);
            $state.go('cart_shared', { cart: cart.id });
        };

        $scope.refresh = function () {
            user = JSON.parse($window.localStorageOverride.user);
            CartService.getSharedCarts(user).then(function (shared_carts) {
                $scope.$broadcast('refreshSharedCarts', shared_carts);
            });
        };
        $scope.refresh();
    }
]);

app.controller('AccountCartRequestsController', [
    '$scope',
    '$rootScope',
    '$state',
    '$uibModal',
    '$window',
    'CartService',
    'UserService',
    'NgTableParams',
    function ($scope, $rootScope, $state, $uibModal, $window, CartService, UserService, NgTableParams) {
        $scope.setState('account.orders');

        $scope.$on('getRequests', function (e, requests) {
            $scope.hasEstimates = false;
            angular.forEach(requests, function (request) {
                if (request.estimated) { $scope.hasEstimates = true; }
                if (request.created_on && request.created_on.date && !(angular.isDate(request.created_on.date))) {
                    var x = request.created_on.date.split(' ');
                    var d = x[0].split('-');
                    request.created_on.date = new Date(d[0], d[1] - 1, d[2]);
                }
                request.oenum = request.order_numbers && request.order_numbers.length ? request.order_numbers.join(', ') : request.quote_id;
                request.ponum = request.misc_info && request.misc_info['Customer PO'] ? request.misc_info['Customer PO'] : '';

                switch (request.status) {
                    case "processing":
                        request.statusLine = "Pending";
                        request.resolve = false;
                        break;
                    case "reject":
                        request.statusLine = "Rejected";
                        request.resolve = true;
                        break;
                    case "approve":
                        request.statusLine = "Approved";
                        request.resolve = true;
                        break;
                    default:
                        request.statusLine = "N/A";
                        request.resolve = true;
                }
            });

            $scope.requests = requests;

            $scope.tableParams = new NgTableParams({}, {
                dataset: requests
            });
        });

        $scope.$broadcast('getRequests', $scope.user.cart_requests);

        $scope.$on('removeRequest', function (e, request) {
            $rootScope.$broadcast('open-loading-modal');
            CartService.deleteCartRequest(request.id).then(function (data) {
                if (data) {
                    UserService.getUser($scope.user.id).then(function (user) {
                        $rootScope.$broadcast('close-loading-modal');
                        $scope.$broadcast('getRequests', user.cart_requests);
                    }).catch(function(err) {
                        console.error(err);
                    });
                }
            });
        });

        $scope.$on('reclaimRequest', function (e, request) {

            var cart = {};
            if (CartService.getCartCache()) {
                cart = CartService.getCartCache();
            }
            $scope.cartMergeModalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'accountCartMergeModal.html',
                controller: 'AccountCartMergeController',
                windowClass: '',
                size: 'lg',
                resolve: {
                    request: function () {
                        return request;
                    },
                    cart: function () {
                        return cart;
                    }
                }
            });

            $scope.cartMergeModalInstance.result.then(function (request) {
                $rootScope.$broadcast('open-loading-modal');
                var lines = [];
                angular.forEach(request.cart_lines, function (line) {
                    delete line.id;
                    delete line.line_sub_total;
                    lines.push(line);
                });

                CartService.postCart(request.session_id, lines).then(function (data) {
                    if (data) {
                        CartService.getCart().then(function () {
                            CartService.deleteCartRequest(request.id).then(function () {
                                $state.go('cart');
                            })
                        });
                    } else {
                        $rootScope.$broadcast('notify', { message: 'Error merging lines' })
                    }
                    $rootScope.$broadcast('close-loading-modal');
                });
            }, function () {
                // modal dismissed
            });
        });

        $scope.remove = function (request) {
            $scope.$broadcast('removeRequest', request);
        };

        $scope.inspect = function (r) {
            CartService.getCartRequest(r.id).then(function (request) {
                var approver = {};
                var approvers = [];
                var promises = [];
                angular.forEach(request.approvers, function (id) {
                    promises.push(UserService.getUserById(id).then(function (i) {
                        approvers.push(i);
                    }));
                });

                if (request.approver_id) {
                    promises.push(UserService.getUserById(request.approver_id).then(function (i) {
                        approver = i;
                    }));
                }

                Promise.all(promises).then( function () {
                    $scope.inspectModalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'accountInspectRequestModal.html',
                        controller: 'AccountCartRequestDetailController',
                        windowClass: '',
                        size: 'lg',
                        scope: $scope,
                        resolve: {
                            request: function () {
                                return request;
                            },
                            approver: function () {
                                return approver;
                            },
                            approvers: function () {
                                return approvers;
                            },
                            status: function () {
                                return r.status;
                            }
                        }
                    });

                    $scope.inspectModalInstance.result.then(function (method) {
                        var forward = (method === 'reclaim') ? 'reclaimRequest' : 'removeRequest';
                        $scope.$broadcast(forward, request);
                    }, function() {
                        // modal dismissed
                    });
                }).catch(function(err) {
                    console.error(err);
                });
            });
        };

        $scope.approvals = function (request) {
            CartService.getCartApprovalLog(request.id).then(function (logs) {
                $scope.inspectModalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'accountCartApprovalLogModal.html',
                    controller: 'AccountCartApprovalLogController',
                    windowClass: '',
                    size: 'lg',
                    scope: $scope,
                    resolve: {
                        request: function () {
                            return request;
                        },
                        logs: function () {
                            return logs;
                        },
                        status: function () {
                            return request.status;
                        }
                    }
                });
                $scope.inspectModalInstance.result.then(function(){
                    // modal closed
                }, function() {
                    // modal dismissed
                });
            }).catch(angular.noop);
        };
    }
]);

app.controller('AccountCartRequestDetailController', [
    '$scope',
    '$uibModalInstance',
    'request',
    'approver',
    'approvers',
    'status',
    function ($scope, $uibModalInstance, request, approver, approvers, status) {
        $scope.cart = request;
        $scope.approver = approver;
        $scope.approvers = approvers;
        $scope.status = status;
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.reclaim = function () {
            $uibModalInstance.close('reclaim');
        };

        $scope.remove = function () {
            $uibModalInstance.close('remove');
        };
    }
]);

app.controller('AccountCartMergeController', [
    '$scope',
    '$uibModalInstance',
    'request',
    'cart',
    function ($scope, $uibModalInstance, request, cart) {
        $scope.request = request;
        $scope.cart = cart;
        $scope.status = status;
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.reclaim = function () {
            $uibModalInstance.close(request);
        };
    }
]);

app.controller('AccountFrcManagerController', [
    '$filter',
    '$rootScope',
    '$scope',
    '$state',
    'UserService',
    function ($filter, $rootScope, $scope, $state, UserService) {
        $scope.loading = true;
        $scope.$on("refreshUser", function (e, user) {
            $scope.loading = true;
            $scope.rows = [];
            UserService.getUserApprovalWorkflow(user.id, user.customer_number).then(function (data) {
                if (data) {
                    angular.forEach(data, function (v) {
                        $scope.rows.push({
                            type: v.unit_type ? v.unit_type : '--',
                            allowed: -1 === v.spend_limit ? 'Unlimited' : v.spend_limit.toLocaleString(),
                            ordered: v.spent_amount.toLocaleString(),
                            remaining: -1 === v.spend_limit ? 'Unlimited' : Math.max((v.spend_limit - v.spent_amount), 0).toLocaleString(),
                            reset: $filter('date')(v.limit_reset, 'MM/dd/yyyy')
                        });
                    });
                }
            }).catch(function() {
                // ignore error
            }).finally(function() {
                $scope.loading = false;
            });
        });

        UserService.getSessionId().then(function () {
            UserService.getUser().then(function (user) {
                $scope.$broadcast("refreshUser", user);
            }).catch(function(err) {
                console.error(err);
            });
        });

        $scope.showTable = true;
        $scope.setState($state.current.name);
    }
]);

app.controller('AccountCartApprovalLogController', [
    '$scope',
    '$uibModalInstance',
    'request',
    'logs',
    'status',
    function ($scope, $uibModalInstance, request, logs, status) {
        $scope.cart = request;
        $scope.logs = logs;
        $scope.status = status;
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);

app.controller('AccountNonStockController', [
    '$scope',
    '$state',
    'NgTableParams',
    'PartService',
    'CartService',
    'UserService',
    '$filter',
    '$rootScope',
    function ($scope, $state, NgTableParams, PartService, CartService, UserService, $filter, $rootScope) {
        if (
            ('account.quote' === $state.current.name && !UserService.canViewQuoteRequest($scope.user)) ||
            ('account.non_stock' === $state.current.name && !UserService.canViewOrderNonStock($scope.user))
        ) {
            $state.go('unauthorized');
            return false;
        }

        $scope.setState($state.current.name);
        $scope.orderType = $state.current.data.orderType ? $state.current.data.orderType : '';
        $scope.items = [];
        $scope.uoms = PartService.refinedUoms;
        $scope.lineFiles = [];
        $scope.loading = null;

        UserService.getSessionId().then(function (session) {
            $scope.cartSession = session;
            $scope.cartSession += 'Q' === $scope.orderType ? '-quote' : '-nonstock';

            CartService.getCart($scope.cartSession).then(function(data){
                $scope.cart = data;
            }).finally(function() {
                if (!$scope.cart) {
                    $scope.cart = CartService.initCart();
                } else if (!$scope.cart.cart_lines) {
                    $scope.cart.cart_lines = [];
                }
                refreshTable();
            });
        });
        $scope.subTotal = 0;

        if (!$scope.user ||
            ($scope.orderType === 'O' && (!$scope.user.can_submit_non_stock || !$scope.user.can_submit_orders)) ||
            ($scope.orderType === 'Q' && !$scope.user.can_submit_quotes)
        ) {
            $state.go('404');
        }

        $scope.datePickerFormat = 'MM/dd/yyyy';
        $scope.datepicker = { opened: false};
        $scope.open = function() {
            $scope.datepicker.opened = true;
        };
        $scope.dateOptions = {
            minDate: new Date()
        };

        var calculateSubTotal = function() {
            var total = 0;
            angular.forEach($scope.cart.cart_lines, function (item) {
                if (item.last_paid && item.quantity) {
                    total += Number(Math.abs(item.last_paid * item.quantity));
                }
            });
            $scope.subTotal = total;
        };

        var refreshTable = function () {
            calculateSubTotal();
            $scope.tableParams = new NgTableParams({}, {
                counts: [],
                dataset: $scope.cart.cart_lines
            });
        }

        CartService.initSelectItems($scope);

        $scope.disableSubmit = function() {
            return $scope.loading || ($scope.orderType === 'Q' && !$scope.user.can_submit_quotes);
        };

        $scope.uploadFile = function(file){

            //if input element contains no files, then prevent reading image data
            if(!document.getElementById("uploadBtn").files.length){
                return;
            }
            var reader = new FileReader();
            reader.addEventListener('loadstart', function() {
                $scope.loading = 'upload';
                $rootScope.$broadcast('open-loading-modal');
            });

            reader.addEventListener('load',function(){
                var obj = {};
                obj.fileName = document.getElementById("uploadBtn").files[0].name;
                obj.fileSize = document.getElementById("uploadBtn").files[0].size;
                obj.fileContent = reader.result;
                $scope.lineFiles.push(obj);
            }, false);

            if (file) {
                reader.readAsDataURL(file.files[0]);
            }

            reader.addEventListener('loadend', function() {
                $scope.loading = null;
            });
            reader.onloadend = function() {
                $rootScope.$broadcast('close-loading-modal');
            };

        };

        $scope.removeLineFile = function(index) {
            if (index > -1) {
                $scope.lineFiles.splice(index, 1);
            }
        };

        $scope.addItemSubmit = function (item) {
            $scope.loading = 'add';
            var erp = item && item.partNumber ? item.partNumber : item.manufacturer;

            var obj = {
                erp: erp.replace(/,/g, ' ').replace(/  +/g, ' '),   // commas not allowed in ERP
                manufacturer: item.manufacturer,
                description: item.description,
                files: $scope.lineFiles.length ? $scope.lineFiles : null,
                last_paid: item.lastPaid,
                price: '0.00',
                product_number: '',
                quantity: item.qty,
                required_date: item.requiredDate ? moment(item.requiredDate).format("YYYY-MM-DD") : null,
                site_url: item.siteUrl,
                uom: item.uom
            };

            if (!$scope.cart.cart_lines) {
                $scope.cart.cart_lines = [];
            }
            $scope.cart.cart_lines.push(obj);
            $scope.lineFiles = [];

            //reset form
            $scope.item = {};
            $scope.addItem.$setPristine();
            $scope.addItem.$setUntouched();

            CartService.postCart($scope.cartSession, $scope.cart.cart_lines, null)
                .then(function (data) {
                    $scope.cart = data;
                    refreshTable();
                }).catch(function () {
                $rootScope.$broadcast('notify', { message: "An error occurred. Item could not be added to the cart."});
            }).finally(function () {
                $scope.loading = null;
            });
        };

        $scope.removeItems = function() {
            var selected = $filter('filter')($scope.cart.cart_lines, {selected: true});
            if (selected.length) {
                $scope.loading = 'delete';
                CartService.removeItems($scope.cartSession, $scope.cart, selected).then(function(cart) {
                    $scope.cart = cart;
                    refreshTable();
                }).catch(function() {
                    $rootScope.$broadcast('notify', { message: "An error occurred. Item could not be deleted from the cart." });
                }).finally(function() {
                    $scope.selectItem();
                    $scope.loading = null;
                });
            }
        };

        $scope.submit = function() {
            $state.go('cart', {'cartSessionId': $scope.cartSession});
        };
    }
]);

app.controller('AccountUserSetupController', [
    '$scope',
    '$state',
    'CompanyService',
    'UserService',
    'NgTableParams',
    '$rootScope',
    '$window',
    function ($scope, $state, CompanyService, UserService, NgTableParams, $rootScope, $window) {
        if (!UserService.canViewUserSetup($scope.user)) {
            $state.go('unauthorized');
            return false;
        }
        $scope.data = {};
        $scope.displaySubmitStatus = false;

        $scope.requestTypes = [{
            value: 'add',
            label: 'Add a new user'
        }, {
            value: 'change',
            label: 'Update an existing user'
        }];

        $scope.availableCustomers = [];
        $scope.toggleUnlimited = function () {
            $scope.userSetup.spend_limit = !$scope.data.unlimited_spend ? 0 : null;
        };
        $scope.toggleUnlimitedMax = function () {
            $scope.userSetup.max_limit = !$scope.data.unlimited_max_spend ? 0 : null;
        };
        var initSetup = function() {
            $scope.setup = {
                request_date: moment(new Date()).format('YYYY-MM-DD'),
                request_type: $scope.requestTypes[0].value,
                status: 'pending',
                authorized_by: '',
                accounts: []
            };
        };
        initSetup();
        var initLineItem = function() {
            $scope.userSetup = {
                is_supervisor: false,
                supervisors: []
            };
            $scope.selectedSupervisors = [];
            $scope.workflow_limit_basis = null;
            $scope.checked = false;
            $scope.customerInput = '';
            $scope.hasWorkflow = false;
            $scope.data.unlimited_spend = true;
            $scope.data.unlimited_max_spend = true;
            $scope.toggleUnlimited();
            $scope.toggleUnlimitedMax();
        };
        initLineItem();

        $scope.setCustomerData = function() {
            $scope.userSetup.customer_number = $scope.customerInput.customerNumber;
            $scope.userSetup.customer_name = $scope.customerInput.customerName;
            CompanyService.getCompanySettings($scope.customerInput.customerNumber).then(function (settings) {
                $scope.workflow_currency = settings.workflow_currency;
                $scope.hasWorkflow = (settings.workflow_approval != null && settings.workflow_approval);
                $scope.workflow_limit_basis = settings.workflow_limit_basis ? settings.workflow_limit_basis : false;
            }).catch(function() {
                // ignore error
            })
        };

        $scope.availableCustomers.push({
            customerNumber: $scope.user.customer_number,
            customerName: $scope.user.customer_data.name,
            customerNameAlt: $scope.user.customer_data.alt_name
        });

        var foundDefaultCustomer = false;
        angular.forEach($scope.user.allowedCompanies, function(customer) {
            if (customer.customerNumber == $scope.user.customer_number) {foundDefaultCustomer = true}
            if (foundDefaultCustomer == false) {
                $scope.availableCustomers.push({
                    customerNumber: customer.customerNumber,
                    customerName: customer.customerName,
                    customerNameAlt: customer.customerNameAlt
                });
            }
            foundDefaultCustomer = false
        });
        $scope.customerInput = $scope.availableCustomers[0];
        $scope.setCustomerData($scope.availableCustomers[0]);

        $scope.searchSupervisors = function (query) {
            if (undefined === $scope.supervisors) {
                $scope.supervisors = [];
            }

            $scope.matchSupervisors = [];
            $scope.noSupervisors = false;
            $scope.loadingSearchSupervisors = false;
            if(query.length > 1) {
                $scope.loadingSearchSupervisors = true;
                var params = {
                    type: 'company',
                    id: $scope.userSetup.customer_number,
                    filter: query
                };
                UserService.getSupervisors(params).then( function (data) {
                    $scope.matchSupervisors = data;
                    if ($scope.supervisors.length) {
                        angular.forEach($scope.matchSupervisors, function (user) {
                            var exists = $scope.supervisors.filter(function (supervisor) {
                                return supervisor == user.id;
                            });

                            if (exists.length) {
                                user.selected = true;
                            }
                        });
                    }
                    $scope.loadingSearchSupervisors = false;
                }).catch(function (err) {
                    console.error(err);
                    $scope.loadingSearchSupervisors = false;
                    $scope.noSupervisors = true;
                });
            }
        };

        $scope.formatSupervisor = function (supervisor) {
            var str = !supervisor.name ? supervisor.username : supervisor.name + ' (' + supervisor.username + ')';
            str += '<br>' + 'Approval limit: ';

            if (-1 === supervisor.spend_limit) {
                str += 'Unlimited';
            } else if ('units' === $scope.workflow_currency) {
                str += supervisor.spend_limit + ' units';
            } else {
                str += '$' + supervisor.spend_limit;
            }
            return str;
        };

        $scope.x = {};
        $scope.setSupervisor = function (supervisor) {
            $scope.x.supervisorQuery = "";
            $scope.loadingSearchSupervisors = true;
            $scope.matchSupervisors = [];
            if (supervisor && supervisor.id > 0) {
                $scope.selectedSupervisors.push(supervisor);
                $scope.userSetup.supervisors.push({
                    id: supervisor.id,
                    username: supervisor.username
                });
                $scope.selectedApprover = false;
            }
            $scope.loadingSearchSupervisors = false;
            this.refreshSupervisors();
        };

        $scope.removeSupervisor = function(supervisor){
            $scope.x.supervisorQuery = "";
            $scope.loadingSearchSupervisors = false;
            $scope.matchSupervisors = [];
            var index = $scope.selectedSupervisors.indexOf(supervisor);
            if (index >= 0) {
                $scope.selectedSupervisors.splice(index,1);
            }
            this.refreshSupervisors();
        };

        $scope.refreshSupervisors = function () {
            $scope.userSetup.supervisors = [];
            angular.forEach($scope.selectedSupervisors, function (supervisor) {
                $scope.userSetup.supervisors.push(supervisor.id);
            });
        };

        $scope.addUserSubmit = function() {
            if (($scope.data.unlimited_spend && $scope.hasWorkflow) || !$scope.hasWorkflow) {
                $scope.userSetup.spend_limit = -1;
            }
            if (($scope.data.unlimited_max_spend && $scope.hasWorkflow) || !$scope.hasWorkflow) {
                $scope.userSetup.max_limit = -1;
            }
            if ($scope.userSetup.limit_reset || undefined != $scope.userSetup.limit_reset) {
                $scope.userSetup.limit_reset = moment($scope.userSetup.limit_reset).format('YYYY-MM-DD');
            }
            $scope.setup.accounts.push($scope.userSetup);
            $scope.addUser.$setPristine();
            refreshTable();
            initLineItem();
        };

        var refreshTable = function() {
            $scope.checkboxes.selectAll = false;
            $scope.tableParams = new NgTableParams({}, {
                counts: [],
                dataset: $scope.setup.accounts
            });
            };
            $scope.checkboxes = {};
            $scope.checkboxes.selectedItems = false;
            $scope.selectAllItems = function() {
                angular.forEach($scope.setup.accounts, function(line) {
                    line.checked = $scope.checkboxes.selectAll;
            });
            $scope.checkboxes.selectedItems = $scope.checkboxes.selectAll;
        };

        $scope.selectItem = function() {
            var isChecked = false;
            var selectAllCheckBox = 0;
            angular.forEach($scope.setup.accounts, function(line) {
                if (line.checked) {
                    isChecked = true;
                } else {
                    selectAllCheckBox++;
                }
            });
            $scope.checkboxes.selectedItems = isChecked;
            $scope.checkboxes.selectAll = !(selectAllCheckBox > 0);
        };

        $scope.removeItems = function() {
            var i = $scope.setup.accounts.length;
            while (i--) {
                if ($scope.setup.accounts[i].checked) {
                    $scope.setup.accounts.splice(i, 1);
                }
            }
            refreshTable();
        };

        $scope.submit = function(submitOneLine) {
            $rootScope.$broadcast('open-loading-modal');
            $scope.loading = 'submit';
            if (submitOneLine){
                $scope.addUserSubmit();
            }
            UserService.postUserRequestSetup($scope.setup).then(function(status) {
                if (status === 201) {
                    $scope.displaySubmitStatus = true;
                    $window.scrollTo(0, 0);
                }
            }).catch(function () {
                $rootScope.$broadcast('notify', {message: "An error occurred. The request could not be made at this time."});
            }).finally(function () {
                $scope.loading = null;
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.return = function() {
            initSetup();
            initLineItem();
            $scope.displaySubmitStatus = false
        }
    }
]);

app.controller('UniversalCribController', [
    '$scope',
    '$rootScope',
    '$state',
    'UserService',
    'NgTableParams',
    'AlgoliaService',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    '$uibModal',
    'PartService',
    function ($scope, $rootScope, $state, UserService, NgTableParams, AlgoliaService, OPEN_BROWSE_CUSTOMER_NUMBER, $uibModal, PartService) {
        if (!UserService.canViewUniversalCrib($scope.user)) {
            $state.go('unauthorized');
            return false;
        }

        var dataSource = $rootScope.currentCustomer.universal_crib_data_source;
        var catalogs = $rootScope.currentCustomer.universal_crib_catalogs;
        var accountStr = $rootScope.currentCustomer.universal_crib_accounts;
        var accounts = accountStr ? accountStr.split(',') : false;

        var params = {
            hitsPerPage: 1000,
            distinct: true
        }

        if (dataSource === 'custom') {
            var catalogFilters = [];
            angular.forEach(catalogs, function(catalog) {
                catalogFilters.push('catalogs:' + catalog);
            });
            params.facetFilters = [catalogFilters, 'active:true'];
        } else {
            params.facetFilters = [];
            params.facetFilters.push(['itemStatus:1'], ['itemWarehouseStatus:1']);

            if (accounts){
                var tenants = [];
                angular.forEach(accounts, function(account) {
                    tenants.push('tenantID:' + account);
                });
                params.facetFilters.push(tenants);
            } else {
                params.facetFilters.push('tenantID:' + $scope.user.customer_number);
            }
        }

        $scope.setState($state.current.name);

        $scope.productsData = {
            term: '',
            active: false,
            submitted: false,
            maxResults: 1000,
            results: []
        };

        $scope.searchProducts = function() {
            $scope.productsData.active = true;
            $scope.productsData.submitted = true;

            AlgoliaService.cribSearch($scope.productsData.term, dataSource, params).then( function (data) {
                var hits = data.hits;
                angular.forEach(hits, function(x){
                    x.cpnArr = [x.cpn];
                });

                hits = hits.reduce(function(a, b) {
                    var i = a.findIndex( function(x){return x.erp === b.erp});
                    if (i === -1) {
                        a.push(b)
                    } else if (!a[i].cpnArr.includes(b.cpn)) {
                        a[i].cpnArr.push(b.cpn)
                    }
                    return a;
                }, [])

                angular.forEach(hits, function(x){
                    x.cpnString = x.cpnArr.sort().join(', ');
                });

                $scope.productsData.results = hits;

                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 20
                }, {
                    dataset: hits,
                    counts: []
                });
            }).finally(function() {
                $scope.productsData.active = false;
            });
        }

        $scope.search = {
            active: false,
            submitted: true,
            result: false
        };

        $scope.searchProductDetails = function(item) {
            $scope.search.active = true;
            $scope.cpnString = item.cpnString;
            $scope.cpnArr = item.cpnArr;

            $scope.modalInstance = $uibModal.open({
                scope: $scope,
                templateUrl: 'universalCribDetailModal.html',
                size: 'lg'
            });
            $scope.modalInstance.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });

            PartService.searchCustomerProducts(String(OPEN_BROWSE_CUSTOMER_NUMBER), item.erp, $rootScope.currentCustomer.universal_crib_accounts).then( function (part) {
                $scope.search.active = false
                if (part) {
                    var key = Object.keys(part)[0];
                    $scope.search.result = part[key][0];
                    $scope.custTableParams = new NgTableParams({
                        page: 1,
                        count: 20
                    }, {
                        dataset: $scope.search.result.warehouses,
                        counts: []
                    });
                }
            }).finally(function() {
                $scope.search.active = false;
            });
        }
        $scope.closeModal = function () {
            $scope.modalInstance.dismiss();
        };
    }
]);

