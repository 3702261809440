(function () {
    'use strict';
}());

app.service('ShippingAddressService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    function ($http, $q, UserService, API_URL) {
        var baseUrl = API_URL;

        this.postAddress = function (data) {
            var deferral = $q.defer();
            var url = baseUrl + "/shipping-address";
            var response = {};

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (data) {
                UserService.getUser().then(function() {
                    response.data = data;
                    response.status = data.status;
                    deferral.resolve(response);
                });
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };

        this.putAddress = function (data, id) {
            data.id = undefined;
            var deferral = $q.defer();
            var url = baseUrl + "/shipping-address/" + id;
            var response = {};

            $http({
                method: "PUT",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (response) {
                UserService.getUser().then(function() {
                    deferral.resolve(response);
                });
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };

        this.deleteAddress = function (id) {
            var deferral = $q.defer();
            var url = baseUrl + "/shipping-address/" + id;
            var response = {};

            $http({
                method: "DELETE",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (response) {
                UserService.getUser().then(function() {
                    deferral.resolve(response);
                });
            }, function (err) {
                response.data = err.data || 'Request failed';
                response.status = err.status;
                deferral.resolve(response);
            });

            return deferral.promise;
        };
    }
]);