(function () {
    'use strict';
}());

app.directive('priceEstimateAlert', [
    function () {
        return {
            restrict: 'E',
            template: '<div class="alert alert-warning">' +
                '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ' +
                'Dollar amounts are based on the <i>Estimated or Last Price Paid</i> provided by the user.' +
                '</div>',
            replace: true
        }
    }
]);
