(function () {
    'use strict';
}());


app.controller('RegistrationController', [
    '$state',
    'isAuthenticated',
    function ($state, isAuthenticated) {
        if (isAuthenticated) {
            $state.go('account.profile');
        }
    }
]);

app.controller('RegistrationLookupController', [
    '$rootScope',
    '$scope',
    '$state',
    'AlgoliaService',
    'CompanyService',
    function ($rootScope, $scope, $state, AlgoliaService, CompanyService) {
        $scope.init = function () {
            $scope.custnoClearResults();
            $scope.customerNumber = null;
            if ($scope.form) {
                $scope.form.$setPristine();
                $scope.form.$setUntouched();
            }
        };

        $scope.custnoPattern = /^\d+$/;
        $scope.custnoClearResults = function () {
            $scope.custnoResults = false;
            $scope.customer = null;
        };

        $scope.custnoSearch = function (form) {
            if (form.$invalid) { return; }
            $rootScope.$broadcast('open-loading-modal');
            $scope.customer = null;

            var blockedAccounts = [87023, 208828, 211855, 212157, 231499];
            CompanyService.getCompanySegment($scope.customerNumber).then(function (data) {
                // COD accounts get blocked, too
                if (data && data.cod) {
                    blockedAccounts.push(+$scope.customerNumber);
                }
            }).then(function () {
                return AlgoliaService.getCustomer($scope.customerNumber);
            }).then(function (data) {
                if (data && blockedAccounts.indexOf(data.customerNumber) === -1) {
                    $scope.customer = data;
                }
            }).catch(function(err) {
                console.log('Customer Lookup', err);
            }).finally(function() {
                $scope.custnoResults = true;
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.custnoAccept = function () {
            $state.go('account_create.register', { customerNumber: $scope.customer.customerNumber });
        };

        $scope.setForm = function (form) {
            $scope.form = form;
        };

        $scope.init();
    }
]);

app.controller('RegistrationRegisterController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    'AccountService',
    'AlgoliaService',
    'UserService',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    function ($rootScope, $scope, $state, $stateParams, $q, AccountService, AlgoliaService, UserService, OPEN_BROWSE_CUSTOMER_NUMBER) {

        $scope.initData = function () {
            $scope.data = {
                customer: null,
                regType: $stateParams.regType,
                username: null,
                password: null,
                name: null,
                phone: null,
                phone_extension: null,
            };
        };
        $scope.initData();

        $scope.formError = null;

        var openBrowseTypes = ['vallen', 'supplier'];
        AlgoliaService.getCustomer($stateParams.customerNumber).then(function (data) {
            // 87023 is only allowed for regTypes 'vallen' and 'supplier'
            if (data && (
                (data.customerNumber !== 87023 && openBrowseTypes.indexOf($scope.data.regType) === -1) ||
                (data.customerNumber === OPEN_BROWSE_CUSTOMER_NUMBER && openBrowseTypes.indexOf($scope.data.regType) >= 0)
            )){
                $scope.data.customer = data;
            }
        }).catch(function(err) {
            console.log('Customer Lookup', err);
        }).finally(function() {
            if (!$scope.data.customer) {
                $state.go('account_create.lookup');
            }
        });

        $scope.passwordPattern = /((?=.*\d)(?=.*[a-z]).{6,})/;
        AccountService.initPasswordValidator($scope);
        $scope.passwordKeyUp();

        $scope.checkUserStatus = function () {
            $scope.data.username = $scope.data.username.toLowerCase();
            var domain = $scope.data.username.split('@').pop().trim();
            var deferred = $q.defer();
            if ($scope.data.regType === 'vallen' || domain === 'vallen.com') {
                return UserService.getAssociateStatus($scope.data.username).then(function (response){
                    if (!response.authorized) {
                        deferred.resolve('We are unable to find a Vallen associate that matches ' + $scope.data.username + '.');
                        return deferred.promise;
                    }
                }).catch(function (){
                    deferred.resolve('We are unable to validate your email address at this time.');
                    return deferred.promise;
                });
            }
            var error = null;
            return UserService.getUserStatus($scope.data.username).then(function (data){
                if (data.id) {
                    error = 'There is already an account for ' + $scope.data.username;
                    if (!data.active) {
                        error += ', but that account is currently inactive. Contact support to have the account activated.';
                    } else {
                        error += '. You may log in using this e-mail address at any time.';
                    }
                }
                return error;
            }).catch(function (){
                // ok, keep going
                return null;
            });
        };

        $scope.register = function () {
            $rootScope.$broadcast('open-loading-modal');
            $scope.formError = null;
            $scope.checkUserStatus().then(function (userError) {
                if (userError) {
                    throw Error(userError);
                }
                return UserService.getUserSettings($scope.data.customer.customerNumber, $scope.data.username);
            }).then(function (settings) {
                var regData = {
                    customer_number: $scope.data.customer.customerNumber,
                    username: $scope.data.username,
                    password: $scope.data.password,
                    name: $scope.data.name,
                    phone: $scope.data.phone,
                    phone_extension: $scope.data.phone_extension,
                    user_type: $stateParams.userType
                };

                angular.forEach(UserService.getUserSettingFields(), function (prop) {
                    if (settings.hasOwnProperty(prop)) {
                        regData[prop] = settings[prop];
                    }
                });
                return UserService.postUser(regData);
            }).then(function (response) {
                if (response.status === 201) {
                    $scope.initData();
                    if (response.data.active) {
                        // user may log in and use site
                        $rootScope.$broadcast('notify', { message: "Your account has been successfully created." });
                        $state.go('account_login');
                    } else {
                        // user requires approval
                        $state.go('account_create.pending');
                    }
                } else {
                    throw Error(response.data && response.data.message ? response.data.message : 'An unknown error occurred');
                }
            }).catch(function (error) {
                $rootScope.$broadcast('notify', { message: "An error occurred. See screen for details." });
                $scope.formError = error;
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            });
        };
    }
]);

app.controller('RegistrationCustomerController', [
    '$rootScope',
    '$scope',
    '$state',
    'StatesService',
    'UserService',
    function ($rootScope, $scope, $state, StatesService, UserService) {

        $scope.initData = function () {
            $scope.data = {
                email: null,
                name: null,
                customer_type: null,
                city: null,
                state: null,
                vallen_associate: null,
                company_name: null,
                company_dba: null,
                company_phone: null,
                duns: null,
                business_type: null,
                vertical: null,
                mro: null,
                employees: null,
                business_nature: null,
                services: []
            };
        };
        $scope.initData();

        $scope.dunsPattern = /\d{9}/;

        $scope.toggleService = function (opt) {
            var index = $scope.data.services.indexOf(opt);

            if (index > -1) {
                $scope.data.services.splice(index, 1);
            } else {
                $scope.data.services.push(opt);
            }
        };

        $scope.states = StatesService.getStates();

        $scope.submit = function () {
            $rootScope.$broadcast('open-loading-modal');

            $scope.formError = null;
            UserService.getUserStatus($scope.data.email).then(function (data) {
                if (data.id) {
                    var error;
                    error = 'There is already an account for ' + $scope.data.email;
                    if (!data.active) {
                        error += ', but that account is currently inactive. Contact support to have the account activated.';
                    } else {
                        error += '. You may log in using this e-mail address at any time.';
                    }
                    throw Error(error);
                }
                return UserService.postCustomerApplication($scope.data);
            }).then(function (response) {
                if (response.status === 201) {
                    $scope.initData();
                    $rootScope.$broadcast('notify', { message: "Your request has been successfully submitted." });
                    $state.go('account_create.customer_confirmation');
                } else {
                    throw Error(response.data && response.data.message ? response.data.message : 'An unknown error occurred');
                }
            }).catch(function (error) {
                $rootScope.$broadcast('notify', { message: "An error occurred. See screen for details." });
                $scope.formError = error;
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.options = {
            business_type: [
                'Corporation',
                'Government',
                'Joint Venture',
                'LLC',
                'LLP',
                'Partnership',
                'Proprietorship',
                'Other'
            ],
            employees: [
                '< 500',
                '500 - 1,000',
                '1,000 - 2,000',
                '2,000 - 5,000',
                '5,000 - 10,000',
                '> 10,000'
            ],
            mro: [
                '< $25,000',
                '$25,000 - $100,000',
                '$100,000 - $500,000',
                '$500,000 - $1,000,000',
                '> $1,000,000'
            ],
            services: [
                'Integrated Solutions',
                'MROP',
                'Safety Services',
                'Web Features',
                'eProcurement',
                'VMI/Vending Solutions'
            ],
            vertical: [
                'Aerospace',
                'Airlines',
                'Automotive',
                'Boat Building',
                'Chemical Manufacturing',
                'Construction or Mining Equipment',
                'HVAC System Manufacturing',
                'General/Industrial Manufacturing',
                'PetroChemical',
                'Pharmaceutical',
                'Rail Transportation',
                'Recreational Vehicles',
                'Other'
            ]
        };
    }
]);
