(function () {
    'use strict';
}());

app.service('CDFService', [
    '$cookies',
    '$http',
    '$q',
    'API_URL',
    'UserService',
    function ($cookies, $http, $q, API_URL, UserService) {
        var self = this;

        self.getEditableFields = function (user) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/";

            if(user.id) {
                url += "editable/" + user.id + "/" + user.customer_number + "?active=1";
            } else {
                url += user.customer_number + "?active=1&editable=1";
            }

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        self.getCdfRequests = function (approver_id) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/requests/" + approver_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        self.getRequestTotals = function (user_id) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/requests/" + user_id + "/totals";

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"},
                ignoreLoadingBar: true
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        self.resolveRequest = function (id, action) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/requests/" + id + "/" + action;

            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                var data = err.data;
                if (!data.message) {
                    data.message = 'We were unable to process your request.';
                }
                deferral.resolve(data);
            });

            return deferral.promise;
        };

        self.getPendingRequests = function (user_id) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/requests/user/" + user_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"},
                ignoreLoadingBar: true
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        self.getRequest = function (id) {
            var deferral = $q.defer(),
                url = API_URL + "/customer-defined-fields/request/" + id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };
    }
]);
