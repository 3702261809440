(function () {
    'use strict';
}());

app.service('CatalogService', [
    '$http',
    '$q',
    'API_URL',
    function ($http, $q, API_URL) {
        this.getItemBreadcrumbs = function (product, sku) {
            var breadcrumbs = [
                {
                    url: { state: 'categories', params: null },
                    text: 'Categories'
                },
                {
                    url: {state: 'catalog.department', params: { department: product.taxonomy.department.name }},
                    text: product.taxonomy.department.name
                },
                {
                    url: {
                        state: 'catalog.category',
                        params: {
                            department: product.taxonomy.department.name,
                            category: product.taxonomy.category.name
                        }
                    },
                    text: product.taxonomy.category.name
                },
                {
                    url: {
                        state: 'catalog.style',
                        params: {
                            department: product.taxonomy.department.name,
                            category: product.taxonomy.category.name,
                            style: product.taxonomy.style.name
                        }
                    },
                    text: product.taxonomy.style.name
                },
                {
                    url: {
                        state: 'catalog.type',
                        params: {
                            department: product.taxonomy.department.name,
                            category: product.taxonomy.category.name,
                            style: product.taxonomy.style.name,
                            type: product.taxonomy.type.name
                        }
                    },
                    text: product.taxonomy.type.name
                },
                {
                    url: {
                        state: undefined !== sku ? 'catalog_product' : null,
                        params: {
                            slug: product.slug
                        }
                    },
                    text: product.vendor.name + ' ' + product.heading,
                }
            ];

            if (undefined !== sku) {
                breadcrumbs.push({
                    url: {state: null, params: {}},
                    text: sku
                });
            }

            return breadcrumbs;
        };

        this.nullCatalogMsg = function() {
            return 'Catalog search is not available for your account. Order using part numbers in Order Pad.';
        };

        this.searchCustomCatalog = function(catalog, token){
            var defer = $q.defer();
            //display full catalog list on empty query string
            var url = API_URL + "/catalogs";
            if(catalog.length) {
                url += "?filter=" + encodeURIComponent(catalog);
            }
            $http({
                method: "GET",
                url: url,
                headers: { 'Authorization': 'Bearer {' + token + '}'}
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.getSearchFilter = function(slug){
            var defer = $q.defer();
            var url = API_URL + "/search-filter/" + slug;
            $http({
                method: "GET",
                url: url
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };
    }
]);
