(function () {
    'use strict';
}());

app.controller('CategoriesController', [
    '$scope',
    '$timeout',
    '$window',
    '$filter',
    'CatalogService',
    'FilterService',
    'AlgoliaService',
    'UserService',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    function ($scope, $timeout, $window, $filter, CatalogService, FilterService, AlgoliaService, UserService, OPEN_BROWSE_CUSTOMER_NUMBER) {
        if ($scope.isMobile || $scope.isSmall) {
            $scope.mobileFiltersShow = false;
        }

        $scope.user = UserService.getPersistedUser();

        FilterService.deleteFilters();
        $scope.catalogOptions = [];
        if ($scope.user && $scope.user.customer_number != OPEN_BROWSE_CUSTOMER_NUMBER) {

            $scope.$on('getCatalogOptions', function () {
                UserService.getSessionId().then( function () {
                    UserService.getUserCatalogs();
                    $scope.catalogOptions = ($window.sessionStorage['userCatalogs']) ?
                        JSON.parse($window.sessionStorage['userCatalogs']) : [];
                });

            });

            $timeout( function () {
                $scope.$broadcast('getCatalogOptions');
            }, 500);
        }

        $scope.loading = true;
        $scope.error = false;
        $scope.query = '';

        $scope.$on('refreshCategories', function (e, params) {
            var facetFilters = [['type:department', 'type:category']];
            if (params) {
                facetFilters.push(params);
            }
            AlgoliaService.searchTaxonomy({
                hitsPerPage: 1000,
                facetFilters: facetFilters
            }).then(function(data) {
                var hits = data.hits;
                var departmentHash = {};
                var categories = [];
                angular.forEach(hits, function(hit) {
                    if (hit.slug) {
                        if (hit.type === 'department') {
                            departmentHash[hit.objectID] = hit;
                        } else if (hit.type === 'category') {
                            categories.push(hit);
                        }
                    }
                });

                var departments = {};
                angular.forEach(departmentHash, function(department) {
                    if (departments[department.slug] === undefined) {
                        departments[department.slug] = department;
                        departments[department.slug].categories = {};
                    }
                });

                angular.forEach(categories, function(category) {
                    category.department = departmentHash[category.parent];
                    category.department_slug = departmentHash[category.parent].slug;
                    if (departments[category.department_slug].categories[category.slug] === undefined) {
                        departments[category.department_slug].categories[category.slug] = category;
                    } else {
                        departments[category.department_slug].categories[category.slug].product_count += category.product_count;
                        departments[category.department_slug].categories[category.slug].item_count += category.item_count;
                    }
                });

                $scope.departments = [];
                angular.forEach(departments, function (department) {
                    var categories = [];
                    angular.forEach(department.categories, function (category) {
                        categories.push(category);
                    });
                    department.categories = $filter('orderBy')(categories, 'name');
                    $scope.departments.push(department);
                });

                $scope.loading = false;
                $scope.departments = $filter('orderBy')($scope.departments, 'name');
            }).catch(function(){
                $scope.error = true;
            });
        });

        $scope.$broadcast('refreshCategories');

        $scope.filterByCatalog = function (c) {
            $scope.catalog = null;
            var catalog = [];
            if (c !== null) {
                $scope.catalog = c.name;
                catalog = ['catalogs:' + c.name];
            }
            $scope.$broadcast('refreshCategories', catalog)
        };

        $scope.reset = function() {
            angular.forEach($scope.departments, function(department) {
                department.matches = false;
            });
        };

        $scope.filterData = function(data) {
            if (!data) {
                return false;
            }

            var query = $scope.query.toLowerCase();
            var match = !$scope.query ||
                data.name.toLowerCase().includes(query) ||
                data.department.name.toLowerCase().includes(query);

            if (match) {
                data.department.matches = true;
            }

            return match;
        };

        $scope.isNullCatalog = UserService.isNullCatalog();
        $scope.errorMessage = function() {
            if (!$scope.loading) {
                if ($scope.isNullCatalog) {
                    return CatalogService.nullCatalogMsg();
                } else if ($scope.error) {
                    return 'Uh Oh! An unknown error occurred while retrieving data. Please refresh the page to try again.';
                } else if (!$scope.departments.length) {
                    return 'Uh Oh! There are no products found in this catalog. Please contact support.';
                }
            }
            return null;
        };
    }
]);
