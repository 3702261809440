(function () {
    'use strict';
}());

app.directive('catalogPromo', [
    function () {
        return {
            restrict: 'E',
            template: '<div ng-repeat="p in promo" class="margin-t-05 margin-b-05">' +
                '<a ng-if="p.promoImage && p.promoUrl" href="{{p.promoUrl}}" target="_blank"><img class="img-responsive center-block" src="{{p.promoImage}}"></a>' +
                '<img ng-if="p.promoImage && !p.promoUrl" class="img-responsive center-block" src="{{p.promoImage}}">' +
                '</div>',
            scope: {
                promo: '='
            }
        }
    }
]);
