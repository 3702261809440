(function () {
    'use strict';
}());

app.service('BillingInfoService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    function ($http, $q, UserService, API_URL) {
        var baseUrl = API_URL;

        this.getTokens = function (data) {
            var deferral = $q.defer();
            var url = baseUrl + "/tokens/user/" + data.user_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.resolve(err);
            });

            return deferral.promise;
        };

        this.putToken = function (token, data) {
            var deferral = $q.defer();
            var url = baseUrl + "/tokens/" + token;

            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: data
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        this.postToken = function (data) {
            var deferral = $q.defer();
            var url = baseUrl + "/tokens";
            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(data)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        this.deleteToken = function (token) {
            var deferral = $q.defer();
            var url = baseUrl + "/tokens/" + token;

            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        this.deletePaymentMethod = function (id) {
            var deferral = $q.defer(),
                url = baseUrl + "/payment-method/" + id;
            
            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization": "Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.postPaymentMethod = function (data) {
            var deferral = $q.defer(),
                url = baseUrl + "/payment-method";

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer {"+UserService.getToken()+"}"},
                data: JSON.stringify(data)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getVerifyingPost = function (user) {
            var deferral = $q.defer();
            var url = baseUrl + "/swp/site-verify";
            var params = {};

            if (user.hasOwnProperty("username")) {
                params.email = user.username;
            }
            if (user.hasOwnProperty("id")) {
                params.user_id = user.id;
            }

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(params)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.resolve(err);
            });

            return deferral.promise;
        };
    }
]);
