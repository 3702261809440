(function () {
    'use strict';
}());

app.service('GifmService', [
    '$http',
    '$q',
    'API_URL',
    'UserService',
    function ($http, $q, API_URL, UserService) {
        this.createRequest = function (request) {
            var deferral = $q.defer(),
                url = API_URL + "/gifm",
                data = request;

            data.user_id = 0;

            $http.defaults.useXDomain = true;
            $http({
                method: "POST",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(data)
            }).then(function(response) {
                deferral.resolve(response.data);
            }, function (data) {
                deferral.reject(data);
            });

            return deferral.promise;
        };

        this.getRequests = function () {
            var deferral = $q.defer(),
                url = API_URL + "/gifm/";

            $http.defaults.useXDomain = true;
            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function(response) {
                deferral.resolve(response.data);
            }, function (data) {
                deferral.reject(data);
            });

            return deferral.promise;
        };

        this.removeRequest = function (id) {
            var deferral = $q.defer(),
                url = API_URL + "/gifm/" + id;

            $http.defaults.useXDomain = true;
            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function(response) {
                deferral.resolve(response.data);
            }, function (data) {
                deferral.reject(data);
            });

            return deferral.promise;
        };
    }
]);