(function () {
    'use strict';
}());

app.controller('ProductController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$stateParams',
    '$uibModal',
    '$filter',
    'CartService',
    'CatalogService',
    'PricingService',
    'UIService',
    'UserService',
    'VendorService',
    'AlgoliaService',
    'ShoppingListService',
    'AnalyticsService',
    'BLACKLIST_FACETS',
    'PHONE',
    function ($rootScope, $scope, $state, $window, $stateParams, $uibModal, $filter, CartService, CatalogService, PricingService, UIService, UserService, VendorService, AlgoliaService, ShoppingListService, AnalyticsService, BLACKLIST_FACETS, PHONE) {
        var product = null;
        $scope.loading = true;
        $scope.pricingLoading = true;

        $scope.user = UserService.getPersistedUser();
        var dupTaxResults = {};

        $scope.$on("getDirectShip", function (e, object) {
            AlgoliaService.searchTaxonomy({
                hitsPerPage: 1,
                facetFilters: ['type:vendor', 'vendor_id:' + object.vendor]
            }, dupTaxResults).then(function(data) {
                if (data && data.hits && data.hits.length) {
                    var vendor = data.hits[0];
                    if (vendor.direct_ship) {
                        object.item.direct_ship = vendor.direct_ship;
                        object.item.apsv = vendor.apsv;
                    }
                }
            });
        });

        AlgoliaService.getProductBySlug($stateParams.slug).then(function(res) {
            product = res;
            if (product) {
                $scope.product = product;
                $rootScope.productMeta = $scope.product.product_id;
                init();
                AlgoliaService.setPricing($scope.product, 'units' === $rootScope.workflow.currency).then(function() {
                    $scope.pricingLoading = false;
                    initPricing();
                }, function() {
                    $scope.pricingLoading = false;
                });

                angular.forEach($scope.product.items, function (item) {
                    item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer)
                    $scope.$broadcast("getDirectShip", {vendor: item.vendor.vendor_id, item: item});
                });

            } else {
                $state.go('404');
            }
            $scope.loading = false;
        }, function() {
            $state.go('404');
            $scope.loading = false;
        });

        function init() {
            $scope.descriptionTab = false;
            if (product && product.heading) {
                var brand = '';
                if (product.brand) {
                    brand = product.brand + ' ';
                }
                $rootScope.setMetaTitle(brand + product.heading);
                if (product.features) {
                    $rootScope.setMetaDescription(String(product.features).replace(/<[^>]+>/gm, ''));
                }
                if (product.features || product.country_of_origin || product.standards) {
                    $scope.descriptionTab = true;
                }
            }

            //Filter out duplicate ERPs.
            var filteredItems = {};
            angular.forEach(product.items, function (item) {
                if (!filteredItems[item.erp]) {
                    filteredItems[item.erp] = item;
                }
            });
            product.items = [];
            angular.forEach(filteredItems, function (item) {
                product.items.push(item);
            });

            function updateSelectedItems() {
                var selected = 0;
                angular.forEach($scope.product.items, function (i) {
                    if (i.quantity >= 1) {
                        selected++;
                    }
                });

                angular.forEach($scope.product.items, function (i) {
                    i.selectedItems = selected;
                });
            }

            function deselectItems() {
                angular.forEach($scope.product.items, function (i) {
                    i.quantity = 0;
                    i.selectedItems = 0;
                });
            }

            $scope.addGridQty = function (item) {
                item.entity.quantity++;
                updateSelectedItems();
            };

            $scope.reduceGridQty = function (item) {
                if (item.entity.quantity >= 1) {
                    item.entity.quantity--;
                }
                updateSelectedItems();
            };

            $scope.partNumber = function (item) {
                return $filter('partNumber')(item, $rootScope.currentCustomer);
            };

            $scope.product = product;
            $scope.tabs = {
                1: $scope.product.items.length > 1,
                2: $scope.product.items.length === 1
            };

            $scope.breadcrumbs = CatalogService.getItemBreadcrumbs($scope.product);

            $scope.collapseDescription = true;
            $scope.toggleDescription = function () {
                $scope.collapseDescription = !$scope.collapseDescription;
            };

            $scope.descriptionCharLimit = ($scope.isLarge) ? 800 : 600;
            $scope.showMore = false;
            if ($scope.product.hasOwnProperty('features') && $scope.product.features != undefined) {
                $scope.showMore = $scope.product.features.length > $scope.descriptionCharLimit;
            }

            var columnDefs = [
                {
                    enableCellEdit: false,
                    name: 'vpn',
                    field: 'vpn',
                    displayName: 'MPN',
                    cellClass: 'pinned',
                    headerCellClass: 'text-center header',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center">' +
                    '{{ row.entity.vpn }}' +
                    '</div>'
                },
                {
                    enableCellEdit: false,
                    name: 'erp',
                    field: 'erp',
                    displayName: $rootScope.company_name_abbr + ' No.',
                    cellClass: 'pinned',
                    headerCellClass: 'text-center header',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center">' +
                        '{{ grid.appScope.partNumber(row.entity) }}' +
                    '</div>'
                }
            ];

            var i = 0;
            angular.forEach($scope.product.specifications, function (value) {
                if (!BLACKLIST_FACETS.includes(value)) {
                    columnDefs.push({
                        enableCellEdit: false,
                        name: value,
                        field: 'specifications[' + i + '].value',
                        displayName: value,
                        headerCellClass: 'pinned text-center header',
                        cellClass: 'text-center'
                    });
                    i++;
                }
            });

            if ('units' !== $rootScope.workflow.currency) {
                columnDefs.push({
                    enableCellEdit: false,
                    enableFiltering: false,
                    name: 'selected_uom.display',
                    width: 120,
                    displayName: 'Price',
                    cellClass: 'pinned text-center visible',
                    headerCellClass: 'header text-center',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center">\n' +
                        '<uom-price product="row.entity"></uom-price></div>'
                });
            }

            Array.prototype.push.apply(columnDefs, [
                {
                    name: 'reduceQty',
                    displayName: "",
                    cellClass: 'pinned text-center pdp-qty-lines',
                    headerCellClass: 'header text-center qty-hide-line',
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableSorting: false,
                    width: 25,
                    cellTemplate: '<div class="qty-minus" style="margin-top: 3px;">' +
                        '<button class="btn btn-sm btn-primary" data-ng-if="row.entity.erp !== null" ' +
                        'data-ng-disabled="row.entity.selected_uom.price <= 0 || row.entity.quantity <= 0" ' +
                        'data-ng-click="row.entity.selected_uom.price <= 0 || grid.appScope.reduceGridQty(row)">' +
                        '<i class="fa fa-minus"></i></button></div>'
                },
                {
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    name: 'quantity',
                    width: 50,
                    displayName: "Qty",
                    enableSorting: false,
                    enableFiltering: false,
                    cellClass: 'pinned text-center pdp-qty-lines',
                    headerCellClass: 'header text-center qty-hide-line',
                    type: 'number'
                },
                {
                    name: 'addQty',
                    displayName: "",
                    cellClass: 'pinned text-center',
                    headerCellClass: 'header text-center',
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableSorting: false,
                    width: 25,
                    cellTemplate: '<div class="qty-plus" style="margin-top: 3px;">' +
                        '<button class="btn btn-sm btn-primary" data-ng-if="row.entity.erp !== null" ' +
                        'data-ng-disabled="row.entity.selected_uom.price <= 0" ' +
                        'data-ng-click="row.entity.selected_uom.price <= 0 || grid.appScope.addGridQty(row)">' +
                        '<i class="fa fa-plus"></i></button></div>'
                },
                {
                    enableCellEdit: false,
                    name: 'avail',
                    width: 50,
                    displayName: "Avail",
                    enableSorting: false,
                    enableFiltering: false,
                    cellClass: 'pinned text-center',
                    headerCellClass: 'header text-center',
                    cellTemplate: '<availability-icon item="row.entity"></availability-icon>'
                },
                {
                    enableCellEdit: false,
                    name: 'order',
                    width: 150,
                    displayName: "Add Items to Cart",
                    enableSorting: false,
                    enableFiltering: false,
                    cellClass: 'pinned text-center',
                    headerCellClass: 'header text-center',
                    cellTemplate: '<div style="margin-top: 2px;"><button class="btn btn-success btn-sm" ' +
                    'data-ng-if="row.entity.erp !== null"' +
                    'data-ng-disabled="row.entity.quantity < 1 || !row.entity.price"' +
                    'data-ng-click="grid.appScope.addAndContinue()">' +
                    '<i class="fa fa-cart-plus"></i> {{row.entity.selectedItems >= 2 ? "Add All to Cart" : "Add to Cart"}}' +
                    '</button></div>'
                },
                {
                    enableCellEdit: false,
                    name: 'list',
                    width: 150,
                    displayName: "Add Items to List",
                    enableSorting: false,
                    enableFiltering: false,
                    cellClass: 'pinned text-center',
                    headerCellClass: 'header text-center',
                    cellTemplate: '<div style="margin-top: 2px;"><button class="btn  btn-complementary btn-sm" ' +
                    'data-ng-if="row.entity.erp !== null"' +
                    'data-ng-disabled="row.entity.quantity < 1 || !row.entity.price"' +
                    'data-ng-click="grid.appScope.addToShoppingList()">' +
                    '<i class="fa fa-list"></i> {{row.entity.selectedItems >= 2 ? "Add All to List" : "Add to List"}}' +
                    '</button></div>'
                }
            ]);

            $scope.grid = {
                data: $scope.product.items,
                enableFiltering: true,
                rowHeight: 35,
                enableVerticalScrollbar: 0,
                enableHorizontalScrollbar: 0,
                columnDefs: columnDefs,
                onRegisterApi: function (gridApi) {
                    gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
                        if (!rowEntity.quantity || rowEntity.quantity < 0) {
                            rowEntity.quantity = 0;
                        }
                    });
                }
            };

            $scope.getTableDimensions = function () {
                var rowHeight = 35;
                var headerHeight = 75;
                return {
                    height: ($scope.product.items.length * rowHeight) + headerHeight + 'px'
                }
            };

            $scope.print = function () {
                $window.print();
            };

            $scope.slickScrollListBreakpoints = [
                {
                    breakpoint: $scope.xs,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        dots: false
                    }
                }
            ];
            var getLines = function () {
                var lines = [];
                angular.forEach($scope.product.items, function (item) {
                    if (item.selected_uom && item.quantity > 0 && !isNaN(item.quantity)) {
                        var i = {
                            erp: item.erp,
                            iref: item.iref,
                            description: CartService.formatItemDescription(item, $scope.product),
                            taxonomy: product.taxonomy,
                            specifications: item.specifications,
                            image: product.image,
                            quantity: item.quantity,
                            price: item.selected_uom.price,
                            uom: item.selected_uom.uom.toLowerCase(),
                            vendor: $scope.product.vendor.slug,
                            product_number: product.product_number,
                            product_id: product.product_id,
                            unspsc: product.unspsc
                        };
                        if (item.hasOwnProperty("direct_ship")) i.direct_ship = item.direct_ship;
                        if (item.hasOwnProperty("apsv")) i.apsv = item.apsv;
                        if (item.hasOwnProperty("units")) i.units = parseFloat(item.units);
                        lines.push(i);
                    }
                });
                return lines;
            };

            var successMessage = "Your cart has been updated.";
            var errorMessage = "An error occurred while updating your cart. Please try again.";

            $scope.addAndContinue = function () {
                $rootScope.$broadcast('open-loading-modal');
                var lines = getLines();

                UserService.getSessionId().then(function (session) {
                    CartService.postCart(session, lines).then(function (data) {
                        angular.forEach(lines, function (line) {
                            AnalyticsService.addToCart(line);
                        });
                        $rootScope.$broadcast('close-loading-modal');
                        $rootScope.$broadcast('notify', {message: (false !== data) ? successMessage : errorMessage});
                        deselectItems();
                    });
                });
            };

            $scope.addAndCheckOut = function () {
                $rootScope.$broadcast('open-loading-modal');
                var lines = getLines();

                UserService.getSessionId().then(function (session) {
                    CartService.postCart(session, lines).then(function (data) {
                        var product_id = window.location.href.substr(window.location.href.lastIndexOf('-') + 1).toUpperCase();
                        angular.forEach(lines, function (line) {
                            line.product_id = product_id;
                            AnalyticsService.addToCart(line);
                        });
                        $rootScope.$broadcast('close-loading-modal');
                        $rootScope.$broadcast('notify', {message: (false !== data) ? successMessage : errorMessage});

                        if (false !== data) {
                            $state.go('cart');
                        }
                    });
                });
            };

            $scope.addToShoppingList = function () {
                //check if user is logged in
                var user = UserService.getPersistedUser();
                if (null == user) {
                    var errorMsg = "You must be logged in to use this feature.";
                    $rootScope.$broadcast('notify', {message: errorMsg});
                    return;
                }

                $rootScope.$broadcast('open-loading-modal');

                // check if Shopping lists already exist.
                ShoppingListService.getLists({ owner: true }).then(function (data) {
                    $rootScope.$broadcast('close-loading-modal');
                    if (data) {
                        ShoppingListService.shoppingListModal(data, getLines());
                        deselectItems();

                    } else {
                        var errorMsg = "An error occurred while checking for Shopping List. Please try again.";
                        $rootScope.$broadcast('notify', {message: errorMsg});
                    }
                });
            };

            $scope.matchview = 0;
            $rootScope.$on('refresh_options', function (event, options) {
                var matches;
                $scope.params = {};
                $scope.selectOptions = options;
                angular.forEach($scope.selectOptions, function (options) {
                    if (options.options.length < 2) {
                        options.selected = options.options[0];
                    }
                });
                if ($window.sessionStorage["quick_filtered"] !== "") {
                    matches = JSON.parse($window.sessionStorage["quick_filtered"]);
                    $scope.single_view = false;
                    if (matches.length === 1) {
                        if (matches[0].erp !== null) {
                            $scope.params.sku = matches[0].erp;
                        } else {
                            $scope.single_view = matches[0];
                            $scope.single_view.vendor = $scope.product.vendor;
                        }

                        $scope.matchview = 1;
                    }
                } else if ($scope.product.items.length === 1) {
                    if ($scope.product.items[0].erp !== null) {
                        $scope.params.sku = $scope.product.items[0].erp;
                    } else {
                        $scope.single_view = $scope.product.items[0];
                    }

                    $scope.matchview = 1;
                } else {
                    $scope.matchview = 0;
                }

                if ($scope.matchview) {
                    angular.forEach($scope.product.items, function (item) {
                        if ((item.erp === $scope.params.sku || ($scope.single_view && $scope.single_view.erp === item.erp)) && item.selected_uom && item.selected_uom.price) {
                            item.quantity = 1;
                            return false;
                        }
                    });
                }
            });

            AnalyticsService.productDetail(product);
        }

        function initPricing() {
            function formatCurrency(n, currency) {
                return currency + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
            }

            angular.forEach(product.items, function (v) {
                v.quantity = 0;
                if (v.hasOwnProperty('pricing')) {
                    angular.forEach(v.pricing, function (p) {
                        if (!p.display && undefined !== p.price) {
                            p.display = formatCurrency(p.price, "$") + " " + p.uom;
                        }
                    });
                    v.selected_uom = v.pricing[0];
                }
            });

            $rootScope.$broadcast("productPricingLoaded");
        }
    }
]);
