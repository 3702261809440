(function () {
    'use strict';
}());

app.directive("printButton", function() {
    return {
        restrict: 'E',
        template : '<button class="btn btn-sm btn-default"><i class="fa fa-print"></i>&nbsp Print</button>',
        link: function(scope, element) {
            element.on('click', function(event) {
                window.print();
            });
        }
    };
});
