(function () {
    'use strict';
}());

app.controller('WordPressController', [
    '$scope',
    '$rootScope',
    '$window',
    '$stateParams',
    'WORDPRESS_URL',
    function ($scope, $rootScope, $window, $stateParams, WORDPRESS_URL) {
        $scope.loading = true;

        var iframe = angular.element('#wp-iframe');
        iframe.css('height', '1200px');
        $scope.path = WORDPRESS_URL + '/' + $stateParams.path;

        $window.addEventListener('message', function(event) {
            var type = event.data.type,
                data = event.data.data;
            if ($scope.loading) {
                $scope.loading = false;
            }
            if (type === 'sizeChange') {
                $rootScope.setMetaTitle(data.title);
                iframe.css('width', '100%');
                iframe.css('height', data.height + 'px');
            }

            if(!$scope.$$phase) {
                $scope.$evalAsync();
            }
        });
    }
]);
