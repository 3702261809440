(function () {
    'use strict';
}());

app.directive('prop65Alert', [
    function () {
        return {
            restrict: 'E',
            template: '<div class="panel panel-danger margin-t-1"><div class="panel-body">' +
                '<i class="fa fa-exclamation-triangle fa-lg text-danger" aria-hidden="true"></i> ' +
                '<b>WARNING:</b> this product can expose you to chemicals known to the State of California to cause  ' +
                'cancer, birth defects, and/or other reproductive harm. For more information go to  ' +
                '<a href="https://www.p65warnings.ca.gov" target="_blank" class="text-info">www.P65Warnings.ca.gov</a>.' +
                '</div></p>',
            replace: true
        }
    }
]);
