(function () {
    'use strict';
}());

app.directive('udfTextInput', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'directives/udfTextInput.html',
            replace: true,
            scope: {
                prop: '=',
                data: '='
            },
            require: 'ngModel',
            link : function(scope, element, attrs, ctrl){
                scope.validateTextField = function(prop, value) {
                    if (prop.maxValue && ['dec', 'deci', 'int', 'inte'].indexOf(prop.dataType.toLowerCase()) >= 0) {
                        var num = value ? parseFloat(value.replace(/,/g, '')) : 0;
                        if (num < prop.minValue || num > prop.maxValue) {
                            ctrl.$setValidity('udfText', false);
                        } else {
                            ctrl.$setValidity('udfText', true);
                        }
                    }
                }
            }
        }
    }
]);
