(function () {
    'use strict';
}());

app.directive('itemImage', [
    function () {
        return {
            restrict: 'E',
            template: '<img ' +
                'data-ng-src="{{ item.image ? item.image : fallbackImage }}" ' +
                'fallback="{{ fallbackImage }}" ' +
                'class="pull-left product-img" ' +
                'alt="{{ item.description }}"' +
                '/>',
            replace: true,
            scope: {
                item : '='
            },
            controller: ['$scope', '$rootScope', 'PRODUCT_RESOURCES', function($scope, $rootScope) {
                $scope.fallbackImage = $rootScope.s3Resources + '/catalog/products/fallback_square_107x100.gif';
            }]
        }
    }
]);
