(function () {
    'use strict';
}());

app.service('FilterService', [
    '$window',
    '$location',
    function ($window, $location) {
        var __construct = function () {
            var filters = $window.sessionStorage.getItem("filters");

            if (null === filters) {
                $window.sessionStorage.setItem("filters", JSON.stringify({}));
                filters = JSON.parse($window.sessionStorage.getItem("filters"));
            } else {
                filters = JSON.parse($window.sessionStorage.getItem("filters"));
            }
            return filters;
        };

        this.initFilters = function(stateParams) {
            var params = $location.search();
            if (stateParams) {
                params = stateParams;
            }
            this.deleteFilters();

            var filters = {};

            if (params.q) {
                filters.q = params.q;
            }

            if (params.department) {
                filters.department = params.department;
            }

            if (params.category) {
                filters.category = params.category;
            }

            if (params.type) {
                filters.type = params.type;
            }

            if (params.style) {
                filters.style = params.style;
            }

            if (params.styles) {
                filters.styles = params.styles;
            }

            if (params.vendors) {
                filters.vendors = params.vendors;
            }

            if (params.vendor) {
                filters.vendor = params.vendor.split('|');
            }

            if (params.filters) {
                var filters_array = params.filters.split('|');
                filters.filters = {};
                angular.forEach(filters_array, function(val) {
                    var decoded = decodeURIComponent(val);
                    var split = decoded.split('_');
                    if (split && split.length >= 2) {
                        if (!filters.filters[split[0]]) {
                            filters.filters[split[0]] = [];
                        }
                        filters.filters[split[0]].push(split[1]);
                    }
                });
            }
            this.setFilters(filters);
        };

        this.setFilter = function (key, value) {
            var filters = __construct();
            filters[key] = value;
            $window.sessionStorage.setItem("filters", JSON.stringify(filters));
            return JSON.parse($window.sessionStorage.getItem("filters"));
        };

        this.updateFilterQuery = function(dbParams) {
            var filters = __construct();

            var params = {};
            if (filters.q) {
                params.q = filters.q;
            } else {
                params.q = null;
            }

            if (filters.department && !dbParams) {
                params.department = filters.department;
            } else {
                params.department = null;
            }

            if (filters.category && !dbParams) {
                params.category = filters.category;
            } else {
                params.category = null;
            }

            if (filters.type) {
                params.type = filters.type;
            } else {
                params.type = null;
            }

            if (filters.style) {
                params.style = filters.style;
            } else {
                params.style = null;
            }

            if (filters.vendor) {
                params.vendor = filters.vendor.join('|');
            } else {
                params.vendor = null;
            }

            if (filters.filters ) {
                var filters_arr = [];
                angular.forEach(filters.filters, function (values, title) {
                    angular.forEach(values, function (v, k) {
                        filters_arr.push(encodeURIComponent(title + "_" + v));
                    });
                });
                params.filters = filters_arr.join("|");

                if (params.filters == '') {
                    delete params.filters;
                }
            } else {
                params.filters = null;
            }

            var nparams = $location.search();
            angular.merge(nparams, params);
            $location.search(nparams);
        };

        this.getFilter = function (key) {
            var filters = __construct();
            return filters[key];
        };

        this.getFilters = function () {
            return __construct();
        };

        this.setFilters = function (filters) {
            $window.sessionStorage.setItem("filters", JSON.stringify(filters));
            return __construct();
        };

        this.deleteFilter = function (key) {
            var filters = __construct();
            filters[key] = undefined;
            $window.sessionStorage.setItem("filters", JSON.stringify(filters));
            return JSON.parse($window.sessionStorage.getItem("filters"));
        };

        this.deleteSingleBrand = function (value) {
            var filters = __construct();
            if (filters.vendor) {
                filters.vendor.splice(filters.vendor.indexOf(value), 1);
                if (filters.vendor.length <= 0) {
                    delete filters.vendor;
                }
            }
            $window.sessionStorage.setItem("filters", JSON.stringify(filters));
            return JSON.parse($window.sessionStorage.getItem("filters"));
        };

        this.deleteSingleFilter = function (key, value) {
            var filters = __construct();
            if (filters.filters[key]) {
                filters.filters[key].splice(filters.filters[key].indexOf(value), 1);
                if (filters.filters[key].length <= 0) {
                    delete filters.filters[key];
                }
                if (Object.keys(filters.filters).length <= 0) {
                    delete filters.filters;
                }
            }
            $window.sessionStorage.setItem("filters", JSON.stringify(filters));
            return JSON.parse($window.sessionStorage.getItem("filters"));
        };

        this.deleteFilters = function () {
            $window.sessionStorage.setItem("filters", JSON.stringify({}));
            return true;
        }
    }
]);
