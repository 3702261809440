(function () {
    'use strict';
}());

app.directive('uomPrice', ['PricingService',
    function (PricingService) {
        return {
            restrict: 'E',
            templateUrl: 'directives/uomPriceList.html',
            replace: true,
            scope: {
                product: '='
            },
            controller: ['$scope',function($scope){
                $scope.itemList = [];
                $scope.getUOMPrices = function(i, pricing){
                    var uomCheckList = [];
                    uomCheckList[i.uom] = true;
                    if(!$scope.itemList[i.erp]){
                        angular.forEach(pricing, function(p){
                            i.loadingPrice = true;
                            if(!uomCheckList[p.uom] ){ //if specific uom value is not present then make a call to get the price
                                PricingService.getPricing([{erp: i.erp, uom: p.uom}], true).then(function (res) {
                                    angular.forEach(res[0], function (r) {
                                        uomCheckList[p.uom] = true;
                                        p.price = r.price;
                                        p.display = r.display;
                                    });
                                    if(Object.keys(uomCheckList).length === pricing.length){//check if we have got all uom price for the item
                                        i.loadingPrice = false;
                                        $scope.itemList[i.erp] = true;
                                    }
                                });
                            }
                        });
                    }
                };
            }]


        }
    }
]);
