(function () {
    'use strict';
}());

app.service('VendorService', [
    '$http',
    '$q',
    'API_URL',
    'UserService',
    function ($http, $q, API_URL, UserService) {
        this.getVendor = function (id) {
            var deferral = $q.defer();
            var url = API_URL + "/vendor/sx/" + id;
            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data)
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };
    }
]);
