app.service('ResourcesService', function () {
    var self = this;

    self.getW9Downloads = function () {
        var baseUrl = '/assets/data/w9-downloads/';

        return [
            {
                label: 'Vallen Distribution Inc. W-9',
                url: baseUrl + 'Form_W-9_Vallen_Distribution.pdf'
            },
            {
                label: 'Encon Safety Products Inc.',
                url: baseUrl + 'Form_W-9_Encon.pdf'
            }
        ];
    };

    self.getResaleExemptionCertLinks = function () {
        var certs = [
            {
                title: 'Multijurisdiction',
                links: [
                    'Resale_Multijurisdiction_2023.pdf',
                    'Resale_Multijurisdiction_2024.pdf',
                    'Streamlined Sales Tax (IN, NV, WV, WY)_2023.pdf',
                    'Streamlined_Sales_Tax_2024.pdf',
                ]
            },
            {
                title: 'Alabama (AL)',
                links: [
                    'Resale_AL_Corporate_2024.pdf',
                    'Resale_Multijurisdiction_2023.pdf',
                    'Resale_Multijurisdiction_2024.pdf',
                ]
            },
            {
                title: 'Arizona (AZ)',
                links: [
                    'Resale_AZ_2023.pdf',
                    'Resale_AZ_2024.pdf'
                ]
            },
            {
                title: 'Arkansas (AR)',
                links: [
                    'Streamlined_AR_2024.pdf'
                ]

            },
            {
                title: 'California (CA)',
                links: [
                    'Resale_CA_2023.pdf',
                    'Resale_CA_2024.pdf'
                ]
            },
            {
                title: 'Colorado (CO)',
                links: [
                    'Resale_CO_2024.pdf'
                ]
            },
            {
                title: 'Connecticut (CT)',
                links: [
                    'Resale_CT_2024.pdf'
                ]
            },
            {
                title: 'District of Columbia (DC)',
                links: [
                    'Resale_DC_2023.pdf',
                    'Resale_DC_2024.pdf'
                ]
            },
            {
                title: 'Florida (FL)',
                links: [
                    'Resale_FL_Clearwater-49th-ST-N_2023.pdf',
                    'Resale_FL_Clearwater-49th-ST-N_2024.pdf',
                    'Resale_FL_Clearwater-Hwy-19-N_2023.pdf',
                    'Resale_FL_Clearwater-Hwy-19-N_2024.pdf',
                    'Resale_FL_Corporate-0_2023.pdf',
                    'Resale_FL_Corporate-0_2024.pdf',
                    'Resale_FL_Corporate-5_2023.pdf',
                    'Resale_FL_Corporate-5_2024.pdf',
                    'Resale_FL_Jacksonville_2023.pdf',
                    'Resale_FL_Jacksonville_2024.pdf',
                    'Resale_FL_Kissimmee_2023.pdf',
                    'Resale_FL_Kissimmee_2024.pdf',
                    'Resale_FL_Lakeland_2024.pdf',
                    'Resale_FL_Merritt-Island_2023.pdf',
                    'Resale_FL_Merritt-Island_2024.pdf',
                    'Resale_FL_Panama-City_2023.pdf',
                    'Resale_FL_Panama-City_2024.pdf',
                    'Resale_FL_Peachtree-Corporate_2024.pdf',
                    'Resale_FL_Riverview_2023.pdf',
                    'Resale_FL_Riverview_2024.pdf',
                    'Resale_FL_Tampa_2023.pdf',
                    'Resale_FL_Tampa_2024.pdf',
                    'Resale_FL_Vero-Beach_2023.pdf',
                    'Resale_FL_Vero-Beach_2024.pdf',
                ]
            },
            {
                title: 'Georgia (GA)',
                links: [
                    'Streamlined_GA_2024.pdf'
                ]
            },
            {
                title: 'Hawaii (HI)',
                links: [
                    'Resale_HI_2024.pdf'
                ]
            },
            {
                title: 'Idaho (ID)',
                links: [
                    'Resale_ID_2024.pdf'
                ]
            },
            {
                title: 'Illinois (IL)',
                links: [
                    'Resale_IL_2024.pdf'
                ]
            },
            {
                title: 'Indiana (IN)',
                links: [
                    'Resale_IN_2024.pdf',
                    'Streamlined Sales Tax (IN, NV, WV, WY)_2023.pdf'
                ]
            },
            {
                title: 'Iowa (IA)',
                links: [
                    'Streamlined_IA_2024.pdf'
                ]
            },
            {
                title: 'Kansas (KS)',
                links: [
                    'Streamlined_KS_2024.pdf'
                ]
            },
            {
                title: 'Kentucky (KY)',
                links: [
                    'Streamlined_KY_2024.pdf'
                ]
            },
            {
                title: 'Louisiana (LA)',
                links: [
                    'Resale_LA_Convent_20240630.pdf',
                    'Resale_LA_Corporate-Peachtree_20260731.pdf',
                    'Resale_LA_Deridder_20240630.pdf',
                    'Resale_LA_Garyville_20240531.pdf',
                    'Resale_LA_Geismar-Hwy-73_20261130.pdf',
                    'Resale_LA_Geismar-Hwy-75_20251130.pdf',
                    'Resale_LA_Geismar_20260430.pdf',
                    'Resale_LA_Hahnville_20240630.pdf',
                    'Resale_LA_La-Place_20260430.pdf',
                    'Resale_LA_Metairie_20240630.pdf',
                    'Resale_LA_St-John-Parish_20260427.pdf',
                    'Resale_LA_Sulphur_20240630.pdf',
                    'Resale_LA_Sulphur_2027.pdf',
                    'Resale_LA_Thibodaux_20260331.pdf',
                    'Resale_LA_Westlake_20261130.pdf',
                ]
            },
            {
                title: 'Maine (ME)',
                links: [
                    'Resale_ME_2024.pdf'
                ]
            },
            {
                title: 'Maryland (MD)',
                links: [
                    'Resale_Multijurisdiction_2023.pdf',
                    'Resale_Multijurisdiction_2024.pdf',
                    'Resale_MD_2024.pdf',
                ]
            },
            {
                title: 'Massachusetts (MA)',
                links: [
                    'Resale_MA_2023.pdf',
                    'Resale_MA_2024.pdf',
                ]
            },
            {
                title: 'Michigan (MI)',
                links: [
                    'Streamlined_MI_2024.pdf'
                ]
            },
            {
                title: 'Minnesota (MN)',
                links: [
                    'Streamlined_MN_2024.pdf'
                ]
            },
            {
                title: 'Mississippi (MS)',
                links: [
                    'Resale_MS_2024.pdf',
                    'Resale_MS_sales-tax-permit.pdf'
                ]
            },
            {
                title: 'Missouri (MO)',
                links: [
                    'Resale_MO_2024.pdf'
                ]
            },
            {
                title: 'Nebraska (NE)',
                links: [
                    'Streamlined_NE_2024.pdf'
                ]
            },
            {
                title: 'Nevada (NV)',
                links: [
                    'Streamlined_NV_2024.pdf',
                    'Streamlined Sales Tax (IN, NV, WV, WY)_2023.pdf'
                ]
            },
            {
                title: 'New Jersey (NJ)',
                links: [
                    'Streamlined_NJ_2024.pdf'
                ]
            },
            {
                title: 'New Mexico (NM)',
                links: [
                    'Resale_Multijurisdiction_2023.pdf',
                    'Resale_Multijurisdiction_2024.pdf',
                    'NM_Request-for-form.pdf'
                ]
            },
            {
                title: 'New York (NY)',
                links: [
                    'Resale_NY_2023.pdf',
                    'Resale_NY_2024.pdf'
                ]
            },
            {
                title: 'North Carolina (NC)',
                links: [
                    'Streamlined_NC_2024.pdf'
                ]
            },
            {
                title: 'North Dakota (ND)',
                links: [
                    'Streamlined_ND_2024.pdf'
                ]
            },
            {
                title: 'Ohio (OH)',
                links: [
                    'Streamlined_OH_2024.pdf'
                ]
            },
            {
                title: 'Oklahoma (OK)',
                links: [
                    'Streamlined_OK_2024.pdf'
                ]
            },
            {
                title: 'Pennsylvania (PA)',
                links: [
                    'Resale_PA_2024.pdf'
                ]
            },
            {
                title: 'Puerto Rico (PR)',
                links: [
                    'Puerto Rico_ARECIBO_2024.pdf',
                    'PUERTO-RICO-AS2916-FORM.pdf',
                    'Puerto Rico_LAS_PIEDRAS_2024.pdf',
                    'PR-ELIGIBLE-RESELLER_2023.pdf'
                ]
            },
            {
                title: 'Rhode Island (RI)',
                links: [
                    'Resale_RI_2024.pdf'
                ]
            },
            {
                title: 'South Carolina (SC)',
                links: [
                    'Resale_SC_2024.pdf'
                ]
            },
            {
                title: 'South Dakota (SD)',
                links: [
                    'Streamlined_SD_2024.pdf'
                ]
            },
            {
                title: 'Tennessee (TN)',
                links: [
                    'Streamlined_TN_2024.pdf',
                ]
            },
            {
                title: 'Texas (TX)',
                links: [
                    'Resale_TX_2024.pdf'
                ]
            },
            {
                title: 'Utah (UT)',
                links: [
                    'Resale_UT_2024.pdf'
                ]
            },
            {
                title: 'Vermont (VT)',
                links: [
                    'Resale_VT_2024.pdf'
                ]
            },
            {
                title: 'Virginia (VA)',
                links: [
                    'Resale_VA_2023.pdf',
                    'Resale_VA_2024.pdf'
                ]
            },
            {
                title: 'Washington (WA)',
                links: [
                    'Streamlined_WA_2024.pdf',
                    'Streamlined_WA_20260105.pdf',
                ]
            },
            {
                title: 'West Virginia (WV)',
                links: [
                    'Streamlined_WV_2024.pdf',
                    'Streamlined Sales Tax (IN, NV, WV, WY)_2023.pdf'
                ]
            },
            {
                title: 'Wisconsin (WI)',
                links: [
                    'Streamlined_WI_2024.pdf'
                ]
            },
            {
                title: 'Wyoming (WY)',
                links: [
                    'Streamlined_WY_2024.pdf',
                    'Streamlined Sales Tax (IN, NV, WV, WY)_2023.pdf'
                ]
            }
        ];

        var formattedCerts = [];

        certs.sort(function (a, b) {
            return b.title < a.title || 'Multijurisdiction' === b.title ? 1 : -1;
        });
        angular.forEach(certs, function (cert) {
            var links = [];
            cert.links.sort();
            angular.forEach(cert.links, function (link) {
                links.push({
                    label: link,
                    url: '/assets/data/resale-exemption-certificates/' + link
                });
            });
            formattedCerts.push({
                title: cert.title,
                links: links
            });
        });
        return formattedCerts;
    }
});
