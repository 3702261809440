(function () {
    'use strict';
}());

app.directive('plusMinus', [
    function () {
        return {
            restrict: 'E',
            template: '<div class="pull-right">' +
                '<span ng-if="!open"><i class="fa fa-plus"></i></span>' +
                '<span ng-if="open"><i class="fa fa-minus"></i></span>' +
                '</div>',
            replace: true,
            scope: {
                open: '='
            }
        }
    }
]);
