(function () {
    'use strict';
}());

app.controller('AdminController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'UserService',
    function ($rootScope, $scope, $state, $window, OPEN_BROWSE_CUSTOMER_NUMBER, UserService) {
        $rootScope.disablePaddedContent = true;
        var user = $rootScope.currentUser;
        if (!user) {
            $state.go('account_login');
        }

        $scope.user = user;
        $scope.go = function (link) {
            $state.go(link);
        };

        $scope.setState = function (state) {
            $scope.state = state;
        };

        $scope.notOpenBrowse = $scope.user && OPEN_BROWSE_CUSTOMER_NUMBER !== $scope.user.customer_number;
        $scope.isAdmin = $scope.user.roles.indexOf("ROLE_ADMIN") !== -1 || $scope.user.roles.indexOf("ROLE_SUPERADMIN") !== -1;
        $scope.isSuperAdmin = $scope.user.roles.indexOf("ROLE_SUPERADMIN") !== -1;

        /**
         * @doc Action Bar
         * In different views for each controller, create a html string in the controller,
         * then call $rootScope.$broadcast('setAction', htmlString); to it in.
         *
         */
        $scope.action = false;
        $rootScope.$on('setAction', function (e,args) {
           if (args !== undefined) {
               $scope.action = args;
           }
        });

        $rootScope.getAdminToken = function () {
            return UserService.getToken();
        };
    }
]);

app.controller('AdminIndexController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    function ($rootScope, $scope, $state, $window) {

    }
]);

//ADMIN USERS
app.controller('AdminUsersController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$timeout',
    '$uibModal',
    'NgTableParams',
    'UserService',
    'DOMAIN',
    function ($rootScope, $scope, $state, $window, $timeout, $uibModal, NgTableParams, UserService, DOMAIN) {
        var loginUser = JSON.parse($window.localStorageOverride.user);
        if (!UserService.canViewManageUsers(loginUser)) {
            $state.go('unauthorized');
            return false;
        }

        $scope.setState($state.current.name);
        $scope.loading = true;
        $scope.activeCheck = true;
        $scope.metaData = {
            filter: '',
            offset: 1,
            page: 1,
            limit: 10,
            order_by: 'username',
            order_direction: 'ASC',
            active: 'active',
            current_items: 0,
            total_items: 0
        };

        $scope.$on('updateMetaData', function (e, a) {
            $scope.metaData = a;
        });

        $scope.$on('getUsers', function (e,a) {
            $scope.users = [];

            if (DOMAIN === loginUser.domain && 'vallen' !== DOMAIN) {
                a.domain = DOMAIN;
            } else {
                a.customer_numbers = loginUser.customer_number;
            }
            a.offset = a.page * a.limit;

            UserService.getUsers(a).then(function (data) {
                $scope.loading = false;
                if (data) {
                    $scope.metaData.total_items = parseInt(data.meta.total_items);
                    $scope.metaData.current_items = parseInt(data.meta.current_items);
                    $scope.users = data.users;
                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: a.limit
                    }, {
                        dataset: $scope.users,
                        counts: []
                    });
                }
            });
        });

        $scope.$broadcast('getUsers', $scope.metaData);

        $scope.getUsers = function (metadata) {
            $scope.loading = true;
            $scope.$broadcast('getUsers', metadata);
        };

        $scope.toggleActiveUsers = function () {
            $scope.loading = true;
            $scope.metaData.active = $scope.metaData.active !== 'active' ? 'active' : 'both';
            $scope.metaData.page = 1;
            $scope.$broadcast('getUsers', $scope.metaData);
        };

        $scope.deactivateUserModal = function (id) {
            $rootScope.$broadcast('open-loading-modal');
            UserService.getUserById(id).then( function (user) {
                $rootScope.$broadcast('close-loading-modal');
                if (!user) {
                    $rootScope.$broadcast('notify', {message: "Error retrieving user"});
                } else {
                    $scope.deactivateModalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'adminUserConfirmDeactivateModal.html',
                        controller: 'AdminUserDeactivateInstanceCtrl',
                        windowClass: '',
                        resolve: {
                            user: function () {
                                return user;
                            }
                        }
                    });
                    $scope.deactivateModalInstance.result.then(function(){
                        // modal closed
                    }, function() {
                        // modal dismissed
                    });
                    $scope.deactivateModalInstance.closed.then(function() {
                        $scope.$broadcast('getUsers', $scope.metaData);
                    });
                }
            }).catch(function(err) {
                console.error(err);
            });
        };
        $scope.reactivateUserModal = function (id) {
            $rootScope.$broadcast('open-loading-modal');
            UserService.getUserById(id).then( function (user) {
                $rootScope.$broadcast('close-loading-modal');
                if (!user) {
                    $rootScope.$broadcast('notify', {message: "Error retrieving user"});
                } else {
                    $scope.reactivateUserModalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'adminUserConfirmReactivateModal.html',
                        controller: 'AdminUserReactivateInstanceCtrl',
                        windowClass: '',
                        size: 'lg',
                        resolve: {
                            user: function () {
                                return user;
                            }
                        }
                    });

                    $scope.reactivateUserModalInstance.result.then(function(){
                        // modal closed
                    }, function() {
                        // modal dismissed
                    });
                    $scope.reactivateUserModalInstance.closed.then( function () {
                        $scope.$broadcast('getUsers', $scope.metaData);
                    });
                }
            }).catch(function(err) {
                console.error(err);
            });
        };

        $scope.addUser = function () {
            $scope.addModalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'adminUserDetailsModal.html',
                controller: 'AdminUserModalInstanceCtrl',
                windowClass: '',
                size: 'lg',
                resolve: {
                    action: function () {
                        return 'add';
                    },
                    user: function () {
                        return {};
                    },
                    loggedUser: function () {
                        return loginUser;
                    }
                }
            });

            $scope.addModalInstance.result.then( function () {
                //No result for cancelling
            }, function () {
                $scope.$broadcast('getUsers', $scope.metaData);
            });
        };

        $scope.editUser = function (id) {
            UserService.getUserById(id).then( function (user) {
                if (!user) {
                    $rootScope.$broadcast('notify', {message: "Error retrieving user"});
                } else {
                    $scope.editModalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'adminUserDetailsModal.html',
                        controller: 'AdminUserModalInstanceCtrl',
                        windowClass: '',
                        size: 'lg',
                        resolve: {
                            action: function () {
                                return 'edit';
                            },
                            user: function () {
                                return user;
                            },
                            loggedUser: function () {
                                return loginUser;
                            }
                        }
                    });

                    $scope.editModalInstance.result.then(function (e) {
                        // No results, only cancel
                    }, function () {
                        $scope.$broadcast('getUsers', $scope.metaData);
                    });
                }
            }).catch(function(err) {
                console.error(err);
            });
        };
    }
]);

app.controller('AdminUserModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'action',
    'user',
    'loggedUser',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'PASSWORD_REGEX',
    'CompanyService',
    'UserService',
    'UtilityService',
    'CatalogService',
    'CDFService',
    'DepartmentService',
    'DOMAIN',
    function ($rootScope, $scope, $uibModalInstance, $timeout, action, user, loggedUser, OPEN_BROWSE_CUSTOMER_NUMBER, PASSWORD_REGEX, CompanyService, UserService, UtilityService, CatalogService, CDFService, DepartmentService, DOMAIN) {
        var generatePassword = function () {
            var length = 16,
                charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
                retVal = '';

            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }

            return retVal;
        };

        $scope.action = action;
        $scope.loggedUser = loggedUser;

        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

        $scope.domains = [
            'vallen',
            'boringsmith'
        ];

        $scope.awCalendar = {
            opened: {},
            dateFormat: 'MM/dd/yyyy',
            dateOptions: {},
            open: function($event, which) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.awCalendar.opened[which] = true;
            }
        };

        $scope.supervisorSearch = {};
        var initSupervisorSearch = function (unitType) {
            $scope.supervisorSearch[unitType] = {
                'query': '',
                'matches': [],
                'selected': [],
                'notfound': false,
                'loading': false
            };
        };

        $scope.hasApprovalWorkflow = function() {
            return !!($scope.companySettings && $scope.companySettings.workflow_approval);
        };

        $scope.getActiveSupervisors = function (user) {
            if ($scope.hasApprovalWorkflow()) {
                return UserService.getSupervisors({
                    type: 'user',
                    id: user.id,
                    customer_number: user.customer_number
                }).then( function (data) {
                    angular.forEach(data, function(supervisor) {
                        if (supervisor.selected) {
                            $scope.supervisorSearch[supervisor.unit_type].selected.push(supervisor);
                        }
                    });
                }).catch(function (err) {
                    console.error(err);
                });
            } else {
                return new Promise(function (resolve) {
                    resolve(true);
                });
            }
        };

        $scope.allowRequireCC = function () {
            return $scope.user.can_submit_orders;
        };

        $scope.checkSubmitOrders = function () {
            if (!$scope.user.can_submit_orders) {
                $scope.user.require_cc = false;
                $scope.user.disable_cc = false;
                $scope.user.can_submit_non_stock = false;
            }
        };

        $scope.checkRequireCC = function () {
            if ($scope.user.require_cc) {
                $scope.user.disable_cc = false;
            }
        };

        $scope.checkDisableCC = function () {
            if ($scope.user.disable_cc) {
                $scope.user.require_cc = false;
            }
        };

        $scope.toggleSupervisor = function (unitType) {
            if (!$scope.userApprovalWorkflow[unitType].is_supervisor) {
                $scope.userApprovalWorkflow[unitType].is_master = false;
            }
        };

        $scope.toggleMaster = function (unitType) {
            if ($scope.userApprovalWorkflow[unitType].is_master) {
                $scope.userApprovalWorkflow[unitType].is_supervisor = true;
            }
        };

        $scope.setUserDefaults = function (user) {
            user.catalog = [];
            user.require_cc = false;
            user.disable_cc = false;
            user.can_edit_bill_to = false;
            user.can_edit_ship_to = false;
            user.can_submit_orders = true;
            user.can_submit_quotes = false;
            user.email_tracking = true;
            user.can_share_cart = false;
            user.domain = DOMAIN;

            if ($scope.companySettings) {
                angular.forEach(UserService.getUserSettingFields(), function (prop) {
                    if ($scope.companySettings.hasOwnProperty(prop)) {
                        user[prop] = $scope.companySettings[prop];
                    }
                });
            }
        };

        $scope.isValidEmail = function (user) {
            return UtilityService.isValidEmail(user.username);
        };

        $scope.getEditableCdf = function (user) {
            CDFService.getEditableFields(user).then(function (data) {
                user.cdf = data;
            }).catch(function() {
                user.cdf = [];
            });
        };

        $scope.getUserApprovalWorkflow = function (user) {
            if (undefined === $scope.userApprovalWorkflow) {
                $scope.userApprovalWorkflow = {};
            }
            if (user) {
                user.approval_workflow = [];
                return UserService.getUserApprovalWorkflow(user.id, user.customer_number).then(function (workflow) {
                    user.approval_workflow = workflow;
                    angular.forEach(workflow, function (aw) {
                        if (aw.limit_reset) {
                            // reformat date/timezone to display correctly in datepicker
                            var d = new Date(aw.limit_reset);
                            d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
                            aw.limit_reset = d;
                        }
                        if (-1 === aw.spend_limit) {
                            aw.unlimited_spend = true;
                            aw.spend_limit = null;
                        }
                        if (-1 === aw.max_limit) {
                            aw.unlimited_max_spend = true;
                            aw.max_limit = null;
                        }
                        $scope.userApprovalWorkflow[aw.unit_type] = aw;
                    });
                });
            } else {
                return new Promise(function (resolve) {
                    resolve(true);
                });
            }
        };

        $scope.getCompanySettings = function (customer_number) {
            $scope.companySettings = {};
            $scope.userApprovalWorkflow = {};
            return CompanyService.getCompanySettings(customer_number).then(function (settings) {
                if (settings) {
                    $scope.companySettings = settings;
                    if (settings.workflow_approval) {

                        var types = settings.workflow_unit_type && settings.workflow_unit_type.length ? settings.workflow_unit_type : [''];
                        angular.forEach(types, function(u) {
                            // set default AW settings
                            if (undefined === $scope.userApprovalWorkflow[u]) {
                                var limit_reset_years = null;
                                var limit_reset = null;
                                if (settings.workflow_limit_basis === 'cumulative') {
                                    // limit reset date defaults to one year from now
                                    limit_reset_years = 1;
                                    limit_reset = new Date(new Date().setFullYear(new Date().getFullYear() + limit_reset_years));
                                }

                                $scope.userApprovalWorkflow[u] = {
                                    unit_type: u,
                                    limit_reset: limit_reset,
                                    limit_reset_years: limit_reset_years,
                                    spend_limit: null,
                                    unlimited_spend: true,
                                    max_limit: settings.order_limit === -1 ? null : settings.order_limit,
                                    unlimited_max_spend: settings.order_limit === -1,
                                    is_supervisor: false,
                                    is_master: false,
                                    supervisors: []
                                }
                            }
                            initSupervisorSearch(u);
                        });
                    }
                }
            });
        }

        $scope.getDepartmentCount = function (user) {
            $scope.departmentCount = 0;
            DepartmentService.getDepartments({
                customer_number: user.customer_number,
                limit: 1
            }).then(function (data) {
                $scope.departmentCount = data.meta.total_items;
            }).catch(function(err) {
                console.error(err);
            });
        };
        $scope.getDepartmentCount(loggedUser);

        $scope.refreshValues = function (user) {
            $scope.getCompanySettings(user.customer_number).then(function() {
                $scope.setUserDefaults(user);
            });
            $scope.getEditableCdf(user);
            $scope.getDepartmentCount(user);
        };

        $scope.editCustomerNumber = function (cn) {
            $scope.customerNumberQuery = cn;
            var companies = $scope.loggedUser.allowedCompanies;
            $scope.selectedCompany = companies[0];
            angular.forEach(companies, function (v,k) {
                if (v.company_number === cn) {
                    $scope.selectedCompany = companies[k];
                }
            });
            $scope.companies = companies;
            $scope.alterCN = true;
        };

        $scope.saveSelectedCompany = function (custno) {
            CompanyService.getCompanySummary(custno).then(function (data) {
                $scope.setCompany(data);
            });
        };

        $scope.saveMatchedCompany = function () {
            $scope.setCompany($scope.matchCompany);
        };

        $scope.setCompany = function (company) {
            if (company) {
                $scope.user.customer_number = company.customer_number;
                $scope.user.customer_type = company.type;
                $scope.user.company_info = company;
            }
            $scope.cancelEditCustomerNumber();
            $scope.$broadcast('userInfo', $scope.user);
            $scope.refreshValues($scope.user);
        };

        $scope.cancelEditCustomerNumber = function () {
            $scope.alterCN = false;
            $scope.matchCompany = false;
        };

        $scope.superAccess = loggedUser.roles.indexOf('ROLE_SUPERADMIN') !== -1;
        $scope.$on('userInfo', function (e,a) {
            $scope.isAdmin = a.roles.indexOf('ROLE_ADMIN') !== -1 || a.manage_users;
            $scope.isOpenBrowse = a.customer_number == OPEN_BROWSE_CUSTOMER_NUMBER;
            $scope.user = a;
            $scope.getCompanySettings(a.customer_number)
                .then(function() {
                    return $scope.getActiveSupervisors(a);
                })
                .then(function() {
                    return $scope.getUserApprovalWorkflow(a);
                });
        });

        if (action === 'edit') {
            $scope.title = "Edit User";
            $scope.$broadcast('userInfo', user);
            if (user.customer_number != OPEN_BROWSE_CUSTOMER_NUMBER) {
                CompanyService.getCompanySummary(user.customer_number).then(function (data) {
                    user.company_info = (data) ? data : false;
                    // make sure user's customer_number is included in list of allowedCompanies
                    if(user.company_info && (!user.allowedCompanies || !user.allowedCompanies[user.company_info.customer_number])) {
                        $scope.setAllowedCompany(user.company_info);
                    }
                });
            }
        } else if (action === 'add') {
            $scope.isOpenBrowse = (parseInt(loggedUser.customer_number) === OPEN_BROWSE_CUSTOMER_NUMBER);
            $scope.title = "Add User";
            $scope.user = {roles: ['ROLE_USER']};
            $scope.user.forceReset = true;
            $scope.user.customer_number = loggedUser.customer_number;
            $scope.saveSelectedCompany($scope.user.customer_number);
            $scope.refreshValues($scope.user);
        }

        $scope.toggleUnlimited = function (unitType) {
            $scope.userApprovalWorkflow[unitType].spend_limit = !$scope.userApprovalWorkflow[unitType].unlimited_spend ? 0 : null;
        };
        $scope.toggleUnlimitedMax = function (unitType) {
            $scope.userApprovalWorkflow[unitType].max_limit = !$scope.userApprovalWorkflow[unitType].unlimited_max_spend ? 0 : null;
        };

        $scope.toggleRole = function (role) {
            var pos = $scope.user.roles.indexOf(role);

            if (pos === -1) {
                $scope.user.roles.push(role);
            } else {
                $scope.user.roles.splice(pos, 1);
            }

            $scope.$broadcast('userInfo', $scope.user);
        };

        $scope.addUser = function (user) {
            if (!validUserFormData(user)) {
                return false;
            }

            if (user.forceReset) {
                user.password = '@1Aa' + generatePassword();
            }

            user.token = $rootScope.getAdminToken();
            $rootScope.$broadcast('open-loading-modal');
            UserService.postUser(user).then(function (data) {
                if (data.hasOwnProperty('status') && data.status != 201) {
                    $rootScope.$broadcast('close-loading-modal');
                    if (data.data.length) {
                        angular.forEach(data.data, function (d) {
                            $rootScope.$broadcast('notify', {message: d.message});
                        });
                    } else {
                        $rootScope.$broadcast('notify', {message: data.data.message});
                    }
                    return false;
                } else {
                    data = data.data;
                    if (user.forceReset) {
                        UserService.recoverPassword(data.username).then( function () {
                            $uibModalInstance.dismiss('success');
                            $rootScope.$broadcast('close-loading-modal');
                            $rootScope.$broadcast('notify', {message: 'User created successfully.'});
                        }, function () {
                            $rootScope.$broadcast('notify', {message: 'Server error, failed to send password reset email.'});
                            $rootScope.$broadcast('close-loading-modal');
                        });
                    } else {
                        $uibModalInstance.dismiss('success');
                        $rootScope.$broadcast('close-loading-modal');
                        $rootScope.$broadcast('notify', {message: 'User created successfully.'});
                    }
                }
            }, function (err) {
                if (err.message) {
                    $rootScope.$broadcast({message: err.message});
                } else {
                    $rootScope.$broadcast({message: 'Server error, failed to save user.'});
                }
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.updateUser = function (user) {
            if (!validUserFormData(user)) {
                return false;
            }

            $uibModalInstance.dismiss('success');
            $rootScope.$broadcast('open-loading-modal');
            UserService.updateUserAdmin(user).then( function (data) {
                var message = (data) ? "User updated" : "Error saving user information";
                $rootScope.$broadcast('notify', {message: message});
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.resetUserPassword = function (user) {
            $uibModalInstance.dismiss('success');
            $rootScope.$broadcast('open-loading-modal');
            UserService.recoverPassword(user.username).then( function(data) {
                $rootScope.$broadcast('close-loading-modal');
                var message = (!data) ?
                    "We were unable to find an account associated with the e-mail address for this user. Please check the email and again." :
                    "Password reset instructions sent to Users email.";
                $rootScope.$broadcast('notify', { message: message });
            }, function() {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        var validUserFormData = function(user) {
            $scope.userForm.submitted = true;
            if (!$scope.userForm.$valid) {
                $rootScope.$broadcast('notify', {message: 'Required fields are missing'});
                return false;
            }
            var errs = [];

            if ($scope.companySettings && $scope.companySettings.workflow_approval) {
                user.approval_workflow = Object.values($scope.userApprovalWorkflow);
                if ('cumulative' === $scope.companySettings.workflow_limit_basis){
                    for (var i = 0, len = user.approval_workflow.length; i < len; i++) {
                        var aw = user.approval_workflow[i];
                        if(!aw.unlimited_spend && !aw.limit_reset_years) {
                            errs.push(aw.unit_type + " Limit Term");
                        }
                        var invalidLimitReset = false;
                        if (aw.limit_reset) {
                            var today = new Date();
                            today.setHours(0,0,0,0);
                            aw.limit_reset.setHours(0,0,0,0);
                            if (today >= aw.limit_reset) {
                                invalidLimitReset = true;
                            }
                        } else {
                            invalidLimitReset = true;
                        }

                        if (invalidLimitReset && !aw.unlimited_spend && (aw.spend_limit === 0 || aw.spend_limit > 0)) {
                            errs.push(aw.unit_type + " Limit Reset Date (must be future date)");
                        }

                        if (!aw.unlimited_spend &&
                            !errs.length &&
                            (!aw.supervisors || !aw.supervisors.length) &&
                            'department' !== $rootScope.workflow.group
                        ) {
                            if (!confirm('You have not specified a supervisor to handle the ' + aw.unit_type + ' approval workflow. Are you sure you want to continue?')) {
                                return false;
                            }
                        }
                    }
                }
            }

            if (errs.length) {
                $rootScope.$broadcast('notify', {message: 'Required field(s) are missing: '+ errs.join(', ')});
                return false;
            }
            user.department = $scope.formatDepartment();
            return true;
        };

        $scope.loadingCompanies = false;
        $scope.loadingSearchCompanies = false;
        $scope.matchCompany = false;

        $scope.searchCompanies = function (company) {
            $scope.loadingSearchCompanies = true;
            var continueSearch = false;

            if (company) {
                if (!$scope.superAccess) {
                    if ($scope.isAdmin) {
                        angular.forEach(loggedUser.allowedCompanies, function (allowedCompany) {
                            if (company === allowedCompany.company_number ) {
                                continueSearch = true;
                            }
                        });
                    } else if ($scope.isSuperAdmin) {
                        continueSearch = true;
                    }
                } else {
                    continueSearch = true;
                }
            }

            if (continueSearch) {
                CompanyService.getCompanySummary(company).then(function (data) {
                    $scope.loadingSearchCompanies = false;
                    $scope.matchCompany = data;
                });
            } else {
                $scope.loadingSearchCompanies = false;
            }
        };

        $scope.setAllowedCompany = function (company) {
            $scope.user.companyQuery = "";
            $scope.matchCompany = false;
            $scope.loadingCompanies = true;

            var data = {
                user_id: $scope.user.id,
                company_number: company.customer_number,
                company_name: company.name,
                company_type: company.type
            };

            CompanyService.setAllowedCompany(data, $rootScope.getAdminToken()).then( function (data) {
                if (!data) {
                    $scope.loadingCompanies = false;
                    $rootScope.$broadcast('notify', {message: "Unable to set allowed company"});
                } else {
                    UserService.getUserById($scope.user.id).then( function (user) {
                        $scope.loadingCompanies = false;
                        $scope.user.allowedCompanies = (user.allowedCompanies) ? user.allowedCompanies : [data];
                        $scope.$broadcast('userInfo', $scope.user);
                    }).catch(function(err) {
                        console.error(err);
                    });
                }
            });
        };

        $scope.removeAllowedCompany = function (id) {
            $scope.loadingCompanies = true;
            CompanyService.removeAllowedCompany(id, $rootScope.getAdminToken()).then( function () {
                UserService.getUserById($scope.user.id).then( function (user) {
                    $scope.loadingCompanies = false;
                    $scope.$broadcast('userInfo', user);
                }).catch(function(err) {
                    console.error(err);
                });
            });
        };

        $scope.setDefaultCustomer = function (custNo) {
            $scope.user.default_customer_number = $scope.user.default_customer_number === custNo ? '' : custNo;
        };

        $scope.loadingSearchCatalogs = false;
        $scope.matchCatalogs = [];

        $scope.searchCatalogs = function (query) {
            if(query) {
                $scope.loadingSearchCatalogs = true;
                CatalogService.searchCustomCatalog(query, $rootScope.getAdminToken()).then(function (results) {
                    $scope.loadingSearchCatalogs = false;
                    $scope.matchCatalogs = results.data;
                }, function () {
                    $scope.loadingSearchCatalogs = false;
                });
            } else {
                $scope.loadingSearchCatalogs = false;
                $scope.matchCatalogs = [];
            }
        };

        $scope.setCatalog = function (catalogName){
            $scope.user.catalogQuery = "";
            $scope.loadingSearchCatalogs = true;
            $scope.matchCatalogs = [];

            if($scope.user.catalog === undefined) {
                $scope.user.catalog = [];
            }

            var index = $scope.user.catalog.indexOf(catalogName);
            if( index === -1 ){
                $scope.user.catalog.push(catalogName);
            }
            $scope.loadingSearchCatalogs = false;
        };

        $scope.removeCatalog = function(catalogName){
            var index = $scope.user.catalog.indexOf(catalogName);
            if( index >= 0 ){
                $scope.user.catalog.splice(index,1);
            }
        };

        $scope.searchSupervisors = function (unitType) {
            $scope.supervisorSearch[unitType].matches = [];
            $scope.supervisorSearch[unitType].notfound = false;
            $scope.supervisorSearch[unitType].loading = false
            if($scope.supervisorSearch[unitType].query.length > 1) {
                $scope.supervisorSearch[unitType].loading = true;
                var params = {
                    type: 'company',
                    id: $scope.user.customer_number,
                    filter: $scope.supervisorSearch[unitType].query,
                    unit_type: unitType
                };
                UserService.getSupervisors(params).then( function (data) {
                    $scope.supervisorSearch[unitType].matches = data;
                    var supervisors = $scope.userApprovalWorkflow[unitType].supervisors;
                    if (supervisors) {
                        angular.forEach($scope.supervisorSearch[unitType].matches, function (user) {
                            var exists = supervisors.filter(function (supervisor) {
                                return +supervisor === user.id;
                            });

                            if (exists.length) {
                                user.selected = true;
                            }
                        });
                    }
                }).catch(function (err) {
                    console.error(err);
                    $scope.supervisorSearch[unitType].notfound = true;
                }).finally(function () {
                    $scope.supervisorSearch[unitType].loading = false;
                });
            }
        };

        $scope.setSupervisor = function (supervisor, unitType) {
            $scope.supervisorSearch[unitType].query = '';
            $scope.supervisorSearch[unitType].loading = true;
            $scope.supervisorSearch[unitType].matches = [];

            if (supervisor && supervisor.id > 0) {
                $scope.supervisorSearch[unitType].selected.push(supervisor);
                $scope.userApprovalWorkflow[unitType].supervisors.push(supervisor.id);
                $scope.selectedApprover = false;
            }
            $scope.supervisorSearch[unitType].loading = false;
            this.refreshSupervisors(unitType);
        };

        $scope.removeSupervisor = function(supervisor, unitType){
            $scope.supervisorSearch[unitType].query = '';
            $scope.supervisorSearch[unitType].loading = false;
            $scope.supervisorSearch[unitType].matches = [];
            var index = $scope.supervisorSearch[unitType].selected.indexOf(supervisor);
            if (index >= 0) {
                $scope.supervisorSearch[unitType].selected.splice(index,1);
            }
            this.refreshSupervisors(unitType);
        };

        $scope.refreshSupervisors = function (unitType) {
            $scope.userApprovalWorkflow[unitType].supervisors = [];
            angular.forEach($scope.supervisorSearch[unitType].selected, function (supervisor) {
                $scope.userApprovalWorkflow[unitType].supervisors.push(supervisor.id);
            });
        };

        $scope.formatSupervisor = function (supervisor) {
            var str = !supervisor.name ? supervisor.username : supervisor.name + ' (' + supervisor.username + ')';
            str += '<br>' + 'Approval limit: ';

            if (-1 === supervisor.spend_limit) {
                str += 'Unlimited';
            } else if ($scope.companySettings && 'units' === $scope.companySettings.workflow_currency) {
                str += supervisor.spend_limit + ' units';
            } else {
                str += '$' + supervisor.spend_limit;
            }
            return str;
        };

        $scope.loadingSearchDepartments = false;
        $scope.matchDepartments = [];
        if($scope.user.departments === undefined) {
            $scope.user.departments = [];
        }

        $scope.searchDepartments = function (query) {
            if(query) {
                $scope.loadingSearchDepartments = true;
                DepartmentService.getDepartments({
                    filter: query,
                    customer_number: $scope.user.customer_number
                }).then(function (data) {
                    // Don't include results that have already been assigned
                    $scope.matchDepartments = data.departments.filter(function(d1){
                        return !$scope.user.departments.some(function(d2){
                            return d1.id === d2.department.id;
                        });
                    });
                }).catch(function(err) {
                    console.error(err);
                }).finally(function() {
                    $scope.loadingSearchDepartments = false;
                });
            } else {
                $scope.loadingSearchDepartments = false;
                $scope.matchDepartments = [];
            }
        };

        $scope.setAllowedDepartment = function (department){
            $scope.user.departmentQuery = "";
            $scope.loadingSearchDepartments = true;
            $scope.matchDepartments = [];

            var userDept = {
                is_default: 0 === $scope.user.departments.length,
                department: department
            };
            $scope.user.departments.push(userDept);
            $scope.loadingSearchDepartments = false;
        };

        $scope.removeAllowedDepartment = function(userDept){
            $scope.user.departmentQuery = "";
            $scope.loadingSearchDepartments = true;
            $scope.matchDepartments = [];
            var index = $scope.user.departments.indexOf(userDept);
            if (index >= 0) {
                $scope.user.departments.splice(index,1);
            }
            $scope.loadingSearchDepartments = false;
        };

        $scope.setDefaultDepartment = function(userDept){
            $scope.loadingSearchDepartments = true;
            angular.forEach($scope.user.departments, function (d) {
                d.is_default = userDept.department.id === d.department.id;
            });
            $scope.loadingSearchDepartments = false;
        };

        $scope.formatDepartment = function() {
            // set old department field to value from default
            var defaultDepartment = $scope.user.departments.filter(function (dept) {
                return dept.is_default;
            });
            if (defaultDepartment.length) {
                return defaultDepartment[0].department.department_key.trim();
            }
            return '';
        }
    }
]);

app.controller('AdminUserDeactivateInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'user',
    'UserService',
    function ($rootScope, $scope, $window, $uibModalInstance, user, UserService) {
        $scope.user = user;
        var self =  JSON.parse($window.localStorageOverride.user);
        $scope.canDelete = self.id !== user.id;

        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

        $scope.removeUser = function (user) {
            $uibModalInstance.dismiss('success');
            $rootScope.$broadcast('open-loading-modal');
            UserService.deleteUser(user.id).then( function (data) {
                $rootScope.$broadcast('close-loading-modal');
                var message = (data) ? "User de-activated" : "Error de-activating user";
                $rootScope.$broadcast('notify', {message: message});
            });
        };
    }
]);

app.controller('AdminUserReactivateInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'user',
    'UserService',
    function ($rootScope, $scope, $window, $uibModalInstance, user, UserService) {
        $scope.user = user;
        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

        $scope.reactivateUser = function (user) {
            $uibModalInstance.dismiss('success');
            $rootScope.$broadcast('open-loading-modal');
            user.active = true;
            UserService.updateUserAdmin(user).then( function (data) {
                $rootScope.$broadcast('close-loading-modal');
                var message = (data) ? "User re-activated" : "Error re-activating user";
                $rootScope.$broadcast('notify', {message: message});
            });
        };
    }
]);
//END ADMIN USERS

//ADMIN WORKFLOW
app.controller('AdminOrderRequestsController', [
    '$filter',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'AnalyticsService',
    'CartService',
    'CompanyService',
    'OrderService',
    'UserService',
    'DepartmentService',
    'NgTableParams',
    'user',
    function ($filter, $rootScope, $scope, $state, $stateParams, $timeout, $uibModal, AnalyticsService, CartService, CompanyService, OrderService, UserService, DepartmentService, NgTableParams, user) {

        $scope.setState($state.current.name);
        $scope.user = user;
        if (!$scope.user) {
            UserService.getUser().then(function (data) {
                $scope.user = data;
            }).catch(function(err) {
                console.error(err);
            });
        }

        /**
         * Check limits for Company Users. The limit must not exceed the
         * amount total of the cart. If that succeeds, proceed as normal. If not,
         * then the limit and the approver will need to be checked.
         *
         * If the user has a limit but no approver, an exception will be thrown
         * and will halt the process, stating to contact support.
         *
         * If the user has a limit and an approver, then proceed to submit the cart
         * to the approver service instead of creating an order. The cart will
         * no longer be in the initiators hands, but can view the status from
         * the account settings.
         */

        function checkUserLimit (request) {
            var cartTotal = 'units' === $scope.orderSettings.workflow_currency ? Number(request.total_units) : Number(request.sub_total);
            var lastPaidTotal = CartService.calculateLastPaidTotal(request);

            return OrderService.checkOrderLimits(
                $scope.user.id,
                request.customer_number,
                Math.max(cartTotal, lastPaidTotal),
                CartService.calculateItemSubTotals(request),
                request.department,
                true
            );
        }

        $scope.$on('updateRequests', function (e, a) {
            $scope.tableParams = new NgTableParams({},{
                dataset: a
            });
        });

        $scope.$on('getRequests', function () {
            $scope.hasEstimates = false;
            CartService.getCartRequests(user.id).then(function (data) {
                if (!data || (typeof data == 'array' && !data.length)) {
                    $state.go('account.profile');
                } else {
                    var requests = [];
                    angular.forEach(data, function (request) {
                        var subtotal = 0;
                        request.customer_type = $scope.user.customer_type;
                        request.totalUnits = 0;
                        request.initiator_and_customer = request.initiator + ' ' + request.customer_number;
                        request.oenum = request.order_numbers && request.order_numbers.length ? request.order_numbers.join(', ') : request.quote_id;
                        request.ponum = request.misc_info && request.misc_info['Customer PO'] ? request.misc_info['Customer PO'] : '';

                        angular.forEach(request.cart_lines, function (line) {
                            if (undefined === line.units) {
                                angular.forEach(line.specifications, function (spec) {
                                    if (spec.title === "Units") {
                                        line.units = Number(spec.value);
                                    }
                                });
                            }
                            line.totalUnits = undefined === line.units ? 0 : Math.abs(Number(line.units) * line.quantity);
                            request.totalUnits += line.totalUnits;
                            if (line.last_paid && !line.line_subtotal) {
                                subtotal += Math.abs(Number(line.last_paid) * line.quantity);
                                request.estimated = true;
                                $scope.hasEstimates = true;
                            } else {
                                subtotal += Math.abs(Number(line.price) * line.quantity);
                            }
                        });
                        request.sub_total = subtotal;
                        requests.push(request);
                    });
                    $scope.requests = requests;
                    $scope.$broadcast('updateRequests', requests);
                }
            }).catch(angular.noop);
        });
        $scope.$broadcast('getRequests');

        $scope.inspect = function (id) {
            CartService.getCartRequest(id).then(function (data) {
                data.totalUnits = 0;
                angular.forEach(data.cart_lines, function (line) {
                   if (!line.units) {
                       angular.forEach(line.specifications, function (spec) {
                           if (spec.title === "Units") {
                               line.units = spec.value;
                           }
                       });
                   }

                    line.totalUnits = Math.abs(line.units * line.quantity);
                    data.totalUnits += Number(line.totalUnits);
                });
                if (!data.in_approval || !data.approvers.includes(user.id)) {
                    $state.go('admin.order_requests', {s: undefined});
                    $rootScope.$broadcast('notify', {message: "This request has already advanced or approved."});
                } else {
                    $scope.cartCustomer = null;
                    CompanyService.getCompanyMaster(data.customer_number).then(function(data) {
                        $scope.cartCustomer = data;
                    });

                    $scope.orderSettings = null;
                    CompanyService.getCompanySettings(data.customer_number).then(function (settings) {
                        $scope.orderSettings = settings;
                    });

                    UserService.getUserById(data.initiator_id, data.customer_number).then( function (i) {
                        $scope.addModalInstance = $uibModal.open({
                            animation: true,
                            ariaLabelledBy: 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            templateUrl: 'adminInspectApprovalModal.html',
                            controller: 'AdminOrderInspectInstanceCtrl',
                            windowClass: '',
                            size: 'lg',
                            scope: $scope,
                            resolve: {
                                cart: function () {
                                    return data;
                                },
                                initiator: function () {
                                    return i;
                                }
                            }
                        });

                        $scope.addModalInstance.result.then( function (request) {
                            //No result for cancelling
                            switch(request.action) {
                                case 'approve':
                                    $scope.approve(request.id);
                                    break;
                                case 'reject':
                                    $scope.reject(request.id);
                                    break;
                                default:
                                    break;
                            }
                        }, function () {});
                    }).catch(function(err) {
                        console.error(err);
                    });
                }
            });
        };

        $scope.approve = function (id) {
            $rootScope.$broadcast('open-loading-modal', {order: true});
            var cart;
            var cartRequest;
            var orders;

            CartService.getCartRequest(id)
            .then(function (result) {
                cartRequest = result;
                if (!cartRequest.in_approval || !cartRequest.approvers.includes(user.id)) {
                    $state.go('admin.order_requests', {s: undefined});
                    throw Error('This request has already been processed.');
                } else {
                    return checkUserLimit(cartRequest);
                }
            }).then(function (checkUser) {
                if (checkUser.error) {
                    throw Error(checkUser.message);
                } else if (checkUser.needs_approval) {
                    // requires approval
                    return CartService.advanceCartForApproval(cartRequest.id, user.id, cartRequest)
                        .then(function (advanced) {
                            if (advanced) {
                                $scope.$broadcast('getRequests');
                                $scope.advancedModal =  $uibModal.open({
                                    animation: true,
                                    ariaLabelledBy: 'modal-title',
                                    ariaDescribedBy: 'modal-body',
                                    templateUrl: 'adminApprovalAdvancedModal.html',
                                    controller: 'AdvancedApprovalInstanceCtrl',
                                    windowClass: '',
                                    size: 'lg'
                                });
                                $scope.advancedModal.result.then(function(){
                                    // modal closed
                                }, function() {
                                    // modal dismissed
                                });
                            } else {
                                throw Error('Error advancing order for approval.');
                            }
                        });
                } else if (cartRequest.quote_id) {
                    // convert quote to order
                    return OrderService.postConvertToOrder(1, cartRequest.quote_id, 0, cartRequest.misc_info['Customer PO'])
                        .then(function (converted) {
                            if (converted && converted.successType === "y") {
                                return OrderService.getOrder(converted.newOrderNumber + '-' + converted.newOrderSuffix, cartRequest.customer_number, true);
                            } else {
                                var message = converted.errorMessage ? "Error: " + converted.errorMessage : "Error: Unable to convert quote.";
                                throw Error(message);
                            }
                        }).then(function (order) {
                            if (order) {
                                orders = [OrderService.reformatOrder(order)];
                                return orders;
                            } else {
                                throw Error('Unable to locate order details.');
                            }
                        });
                } else {
                    // submit 'regular' order
                    return CartService.getCart(cartRequest.session_id)
                        .then(function (result) {
                            cart = result;
                            return UserService.getUserById(cartRequest.initiator_id)
                        }).then(function (initiator) {
                            return OrderService.formatApprovalData(cartRequest, initiator);
                        }).then(function (formattedData) {
                            return OrderService.submitOrderByToken(formattedData, cart.id, cartRequest.id);
                        }).then(function (result) {
                            if (result.error || result.length < 1 || result.code >= 400) {
                                throw Error(result.message);
                            } else {
                                orders = result;
                                return orders;
                            }
                        });
                }
            }).then(function () {
                if (orders) {
                    cartRequest.order_numbers = [];
                    angular.forEach(orders, function (o) {
                        cartRequest.order_numbers.push(o.order_number);
                    });

                    return CartService.resolveCartRequest(cartRequest, 'approve')
                        .then(function () {
                            $scope.$broadcast('getRequests');
                            $scope.requestModal = $uibModal.open({
                                animation: true,
                                ariaLabelledBy: 'modal-title',
                                ariaDescribedBy: 'modal-body',
                                templateUrl: 'views/_partials/orderConfirmationModal.html',
                                controller: 'OrderSuccessApprovalInstanceCtrl',
                                windowClass: '',
                                size: 'lg',
                                resolve: {
                                    orders: function () {
                                        return orders;
                                    }
                                }
                            });
                            $scope.requestModal.result.then(function () {
                                // modal closed
                            }, function () {
                                // modal dismissed
                            });
                    });
                } else {
                    return true;
                }
            }).catch(function (error) {
                if (!error) { error = 'Error processing your request.'; }
                CartService.handleCartError(error, $scope);
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        $scope.reject = function (request) {
            $rootScope.$broadcast('open-loading-modal');
            CartService.getCartRequest(request)
            .then(function (cartRequest) {
                if (!cartRequest.in_approval || !cartRequest.approvers.includes(user.id)) {
                    $state.go('admin.order_requests', {s: undefined});
                    throw Error('This request has already been processed.');
                } else if (!cartRequest) {
                    throw Error('Unable to find request. Please contact support.');
                } else {
                    return CartService.resolveCartRequest(cartRequest, 'reject');
                }
            }).then(function (response) {
                $scope.$broadcast('getRequests');
                $scope.rejectModalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'adminConfirmRejectRequestModal.html',
                    controller: 'RejectConfirmRequestInstanceCtrl',
                    windowClass: '',
                    size: 'lg',
                    resolve: {
                        response: function () {
                            return response;
                        }
                    }
                });

                $scope.rejectModalInstance.result.then(function(s){
                    // modal closed
                }, function(s) {
                    // modal dismissed
                });
            }).catch(function (error) {
                CartService.handleCartError(error, $scope);
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            });
        };

        function init(params) {
            if (params.s !== undefined) {
                $scope.inspect(params.s);
            }
        }

        init($stateParams);
    }
]);

//ADMIN CDF APPROVALS
app.controller('AdminCdfRequestsController', [
    '$filter',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    'AnalyticsService',
    'CartService',
    'OrderService',
    'UserService',
    'NgTableParams',
    'user',
    'CDFService',
    function ($filter, $rootScope, $scope, $state, $stateParams, $timeout, $uibModal, AnalyticsService, CartService, OrderService, UserService, NgTableParams, user, CDFService) {
        /**
         * Approve or reject CDF data submitted by users
         */
        $scope.setState($state.current.name);
        $scope.user = user;

        $scope.$on('updateRequests', function (e, a) {
            $scope.tableParams = new NgTableParams({},{
                dataset: a
            });
        });

        $scope.$on('getRequests', function () {
            CDFService.getCdfRequests(user.id).then(function (data) {
                if (!data || (typeof data == 'array' && !data.length)) {
                    $state.go('account.profile');
                } else {
                    var requests = [];
                    angular.forEach(data, function (request) {
                        requests.push(request)
                    });
                    $scope.requests = requests;
                    $scope.$broadcast('updateRequests', requests);
                }
            }).catch(angular.noop);
        });
        $scope.$broadcast('getRequests');

        $scope.approve = function (request) {
            CDFService.getRequest(request).then( function (result) {
                if ('Pending' !== result.status || !result.approvers.includes(String(user.id))) {
                    $rootScope.$broadcast('notify', {message: 'This request has already been ' + result.status});
                } else {
                    CDFService.resolveRequest(request, 'approve').then( function (result) {
                        var msg = result.message === 'Success' ? 'This request has been Approved' : result.message;
                        $rootScope.$broadcast('notify', {message: msg});
                        $scope.$broadcast('getRequests');
                    });
                }
                $state.go('admin.profile_requests', {s: undefined, a: undefined});
            }).catch(angular.noop);
        };

        $scope.reject = function (request) {
            CDFService.getRequest(request).then( function (result) {
                if ('Pending' !== result.status || !result.approvers.includes(String(user.id))) {
                    $rootScope.$broadcast('notify', {message: 'This request has already been ' + result.status});
                } else {
                    CDFService.resolveRequest(request, 'reject').then( function (result) {
                        var msg = result.message === 'Success' ? 'This request has been Rejected' : result.message;
                        $rootScope.$broadcast('notify', {message: msg});
                        $scope.$broadcast('getRequests');
                    });
                }
                $state.go('admin.profile_requests', {s: undefined, r: undefined});
            }).catch(angular.noop);
        };

        function init(params) {
            if (params.s !== undefined) {
                if (params.a !== undefined) {
                    $scope.approve(params.s);
                } else if (params.r !== undefined) {
                    $scope.reject(params.s);
                }
            }
        }

        init($stateParams);
    }
]);


app.controller('AdminOrderInspectInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'cart',
    'initiator',
    'CartService',
    'lodash',
    function ($rootScope, $scope, $window, $uibModalInstance, cart, initiator, CartService, lodash) {
        if (!cart.misc_info || 0 === cart.misc_info.length) { cart.misc_info = {}; }
        $scope.cart = cart;
        $scope.initiator = initiator;
        $scope.originalCart = angular.copy(cart);
        $scope.validQuantities = true;
        $scope.errorMessages = null;

        var oldQty = $scope.originalCart.cart_lines.map(function (x) { return x.quantity; });

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.approve = function (request) {
            request.action = 'approve';
            submit(request);
        };

        $scope.reject = function (request) {
            if (confirm('Are you sure you want to reject this order?')) {
                request.action = 'reject';
                submit(request);
            } else {
                return false;
            }
        };

        $scope.invalidPO = function () {
            return !$scope.cart.misc_info['Customer PO'] && $scope.cartCustomer && $scope.cartCustomer.poreqfl;
        };

        $scope.checkNums = function () {
            var numRegex = /^\d+$/;
            var newQty = $scope.cart.cart_lines.map(function (x) { return x.quantity; });
            var total = 0;
            var totalUnits = 0;
            var errors = [];
            $scope.cart.sub_total = 0;

            for (var i = 0; i < newQty.length; i++) {
                if (undefined === newQty[i]) {
                    errors.push('All quantities must be greater than or equal to 0 but not more than the original quantity.');
                } else if (!numRegex.test(newQty[i])) {
                    errors.push('All quantities must be numeric.');
                } else if (newQty[i] > oldQty[i]) {
                    errors.push('New quantity may not exceed original quantity.');
                } else if (newQty[i] < 0) {
                    errors.push('New quantity must be greater than or equal to 0.');
                }

                var line = $scope.cart.cart_lines[i];
                line.line_sub_total = Number(line.price) * parseInt(newQty[i]);
                $scope.cart.sub_total += line.line_sub_total;
                if (line.units) {
                    totalUnits += Math.abs(Number(line.units) * parseInt(newQty[i]));
                }

                total += parseInt(newQty[i]);
            }
            if (totalUnits) {
                $scope.cart.total_units = totalUnits;
            }
            CartService.setCartTotals($scope.cart);

            if (total < 1) {
                errors.push('At least one item must have quantity > 0 in order to approve.');
            }
            $scope.validQuantities = errors.length === 0;
            $scope.errorMessages = errors.length > 0 ? errors : null;
        };

        var cartChanged = function () {
            if (!$scope.cart.misc_info['Customer PO']) { delete $scope.cart.misc_info['Customer PO']; }
            if (!$scope.cart.misc_info['Shipping Instructions']) { delete $scope.cart.misc_info['Shipping Instructions']; }
            if (!$scope.cart.misc_info['Comments']) { delete $scope.cart.misc_info['Comments']; }
            var newQty = $scope.cart.cart_lines.map(function (x) { return x.quantity; });

            return (
                !lodash.isEqual($scope.originalCart.misc_info, $scope.cart.misc_info) ||
                !lodash.isEqual(oldQty, newQty) ||
                $scope.originalCart.comment !== $scope.cart.comment
            );
        };

        var submit = function (cart) {
            if (cartChanged()) {
                cart.sub_total = 0;
                cart.cart_lines = cart.cart_lines.filter(function(item) { return item.quantity > 0; });
                CartService.updateCartRequest(cart).then(function (data) {
                    data.action = cart.action;
                    $uibModalInstance.close(data);
                }, function (err) {
                    var message = err && err.data && err.data.message ? err.data.message : 'Error updating cart.';
                    $rootScope.$broadcast('notify', { message: message });
                });
            } else {
                $uibModalInstance.close(cart);
            }
        };
    }
]);

app.controller('RejectConfirmRequestInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    function ($rootScope, $scope, $window, $uibModalInstance) {
        $scope.close = function () {
            $uibModalInstance.dismiss('close');
        };
    }
]);

app.controller('AdvancedApprovalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    function ($rootScope, $scope, $window, $uibModalInstance) {
        $scope.close = function () {
            $uibModalInstance.dismiss('close');
        };
    }
]);

app.controller('OrderSuccessApprovalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'CompanyService',
    'orders',
    function ($rootScope, $scope, $window, $uibModalInstance, CompanyService, orders) {
        $scope.orders = orders;

        $scope.orderSettings = null;
        if (orders[0] && orders[0].customer_number) {
            CompanyService.getCompanySettings(orders[0].customer_number).then(function (settings) {
                $scope.orderSettings = settings;
            });
        }

        $scope.close = function () {
            $uibModalInstance.dismiss('close');
        };
    }
]);

//ADMIN DEPARTMENTS
app.controller('AdminDepartmentsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$uibModal',
    'NgTableParams',
    'UserService',
    'DepartmentService',
    function ($rootScope, $scope, $state, $window, $uibModal, NgTableParams, UserService, DepartmentService) {
        var loginUser = JSON.parse($window.localStorageOverride.user);
        if (!UserService.canViewManageDepartments(loginUser)) {
            $state.go('unauthorized');
            return false;
        }

        $scope.user = loginUser;
        $scope.setState($state.current.name);
        $scope.loading = true;
        $scope.activeCheck = true;
        $scope.metaData = {
            filter: '',
            offset: 1,
            page: 1,
            limit: 25,
            order_by: 'department_key',
            order_direction: 'ASC',
            active: 'both',
            current_items: 0,
            total_items: 0
        };

        $scope.$on('updateMetaData', function (e, a) {
            $scope.metaData = a;
        });

        $scope.$on('getDepartments', function (e,a) {
            $scope.loading = true;
            a.customer_number = $scope.user.customer_number;
            a.offset = a.page * a.limit;
            DepartmentService.getDepartments(a).then(function (data) {
                $scope.loading = false;
                if (data) {
                    $scope.metaData.total_items = parseInt(data.meta.total_items);
                    $scope.metaData.current_items = parseInt(data.meta.current_items);
                    $scope.departments = data.departments;
                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: a.limit
                    }, {
                        dataset: $scope.departments,
                        counts: []
                    });
                }
            }).catch(function(err) {
                $rootScope.$broadcast('notify', { message: 'Error retrieving departments.' });
                console.error(err);
            });
        });

        $scope.$broadcast('getDepartments', $scope.metaData);

        $scope.getDepartments = function (metadata) {
            $scope.loading = true;
            $scope.$broadcast('getDepartments', metadata);
        };

        $scope.filterActiveDepartments = function (check) {
            $scope.loading = true;
            $scope.metaData.active = (check === true) ? "active" : "both";
            $scope.metaData.page = 1;
            $scope.$broadcast('getDepartments', $scope.metaData);
        };

        $scope.addDepartment = function () {
            $scope.addModalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'adminDepartmentModal.html',
                controller: 'AdminDepartmentModalInstanceCtrl',
                windowClass: '',
                size: 'lg',
                resolve: {
                    action: function () {
                        return 'add';
                    },
                    user: function () {
                        return loginUser;
                    },
                    department: function () {
                        return { active: true, supervisors: [], spend_limit: 0 };
                    }
                }
            }).result.then( function () {
                //No result for cancelling
            }, function () {
                $scope.$broadcast('getDepartments', $scope.metaData);
            });
        };

        $scope.editDepartment = function (id) {
            DepartmentService.getDepartmentById(id, true).then( function (department) {
                $scope.editModalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'adminDepartmentModal.html',
                    controller: 'AdminDepartmentModalInstanceCtrl',
                    windowClass: '',
                    size: 'lg',
                    resolve: {
                        action: function () {
                            return 'edit';
                        },
                        user: function () {
                            return loginUser;
                        },
                        department: function () {
                            return department;
                        }
                    }
                }).result.then(function (e) {
                    // No results, only cancel
                }, function () {
                    $scope.$broadcast('getDepartments', $scope.metaData);
                });
            }).catch(function(err) {
                $rootScope.$broadcast('notify', { message: 'Error retrieving department.' });
                console.error(err);
            });
        };
    }
]);


app.controller('AdminDepartmentModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'action',
    'lodash',
    'user',
    'department',
    'DepartmentService',
    'UserService',
    function ($rootScope, $scope, $uibModalInstance, action, lodash, user, department, DepartmentService, UserService) {

        $scope.action = action;
        $scope.approvers = [];
        $scope.department = department;
        $scope.user = user;
        $scope.supervisor = null;
        $scope.supervisors = $scope.approvers;

        $scope.cancel = function () {
            $uibModalInstance.close('cancel');
        };

        $scope.changeSupervisor = function(supervisor) {
            $scope.supervisor = supervisor && supervisor.id > 0 ? supervisor : null;
        };

        $scope.tableLoaded = function() {
            setTimeout(function() {
                $('#userList').removeClass('hide').DataTable({
                    "aoColumns": [
                        null,
                        null
                    ]
                })
            }, 0);
        };

        $scope.setSupervisor = function() {
            if ($scope.supervisor) {
                var a = lodash.find($scope.department.supervisors, {id: $scope.supervisor.id});
                if (!a) {
                    $scope.department.supervisors.push($scope.supervisor);
                    $scope.department.supervisors = lodash.sortBy($scope.department.supervisors, ['name']);
                }

                $scope.$broadcast('supervisorList');
            }
        };

        $scope.removeSupervisor = function(supervisor) {
            var index = $scope.department.supervisors.indexOf(supervisor);
            if (index !== -1) {
                $scope.department.supervisors.splice(index, 1);
                $scope.$broadcast('supervisorList');
            }
        };

        $scope.$on('supervisorList', function () {
            $scope.supervisor = null;
            $scope.supervisors = [];

            var approvers = $scope.approvers;
            angular.forEach(approvers, function (approver) {
                if (approver.id > 0) {
                    var a = lodash.find($scope.department.supervisors, {id:approver.id});
                    if (!a) {
                        $scope.supervisors.push(approver);
                    }
                }
            });
        });

        $scope.$on('approverList', function () {
            $scope.loadingApprovers = true;
            $scope.noApprovers = false;
            $scope.spendLimit = {};

            var params = {
                type: 'company',
                id: $scope.user.customer_number
            };
            UserService.getSupervisors(params).then( function (data) {
                angular.forEach(data, function (u) {
                    var value = u.name + ' - ' + u.username + ' (Approval Limit: ';
                    value += -1 == u.spend_limit ? 'Unlimited' : u.spend_limit;
                    value += ')';
                    $scope.approvers.push({
                        id: u.id,
                        name: u.name,
                        username: u.username,
                        value: value,
                        spend_limit: u.spend_limit
                    });
                    $scope.spendLimit[u.id] = u.spend_limit;
                });
                $scope.$broadcast('supervisorList');
            }).catch(function (err) {
                console.error(err);
            });
        });
        $scope.$broadcast('approverList');

        if (action === 'edit') {
            $scope.title = "Edit Department";
        } else if (action === 'add') {
            $scope.title = "Add Department";
        }

        $scope.addDepartment = function (department) {
            if (!validDepartmentFormData()) {
                return false;
            }
            department.customer_number = $scope.user.customer_number;

            $rootScope.$broadcast('open-loading-modal');
            DepartmentService.postDepartment(department).then(function () {
                $uibModalInstance.dismiss('success');
                modalMessage('Department created successfully.');
            }).catch(function(err) {
                var message = err.message ? err.message : 'Error saving department information.';
                modalMessage(message);
                console.error(err);
            });
        };

        $scope.updateDepartment = function (department) {
            if (!validDepartmentFormData()) {
                return false;
            }
            $rootScope.$broadcast('open-loading-modal');
            DepartmentService.updateDepartment(department).then( function () {
                $uibModalInstance.dismiss('success');
                modalMessage('Department updated successfully.');
            }).catch(function(err) {
                console.error(err);
                var message = err.message ? err.message : 'Error saving department information.';
                modalMessage(message);
            });
        };

        $scope.removeDepartment = function (department) {
            if (confirm('Are you sure you want to delete this department?')) {
                $rootScope.$broadcast('open-loading-modal');
                DepartmentService.deleteDepartment(department.id).then( function () {
                    $uibModalInstance.dismiss('success');
                    modalMessage('Department deleted.');
                }).catch(function(err) {
                    console.error(err);
                    var message = err.message ? err.message : 'Error deleting department information.';
                    modalMessage(message);
                });
            }
        };

        var validDepartmentFormData = function () {
            $scope.departmentForm.submitted = true;
            if (!$scope.departmentForm.$valid) {
                $rootScope.$broadcast('notify', { message: 'Required fields are missing.' });
                return false;
            }
            return true;
        };

        var modalMessage = function (message) {
            $rootScope.$broadcast('close-loading-modal');
            $rootScope.$broadcast('notify', { message: message });
        };
    }
]);
