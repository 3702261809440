(function () {
    'use strict';
}());

app.controller('ResourcesSupplierRoutingGuideController', [
    '$scope',
    function ($scope) {
        $scope.urlRouteGuide = 'https://ecomm-api.vallen.com/content/wp-content/uploads/2023/03/Supplier_Routing_Guide_02282023.xlsx';
        $scope.urlCarrierLetter = 'https://ecomm-api.vallen.com/content/wp-content/uploads/2022/09/Vallen_Carrier_Letter_08012022.pdf';
    }
]);

app.controller('ResourcesW9DownloadsController', [
    '$scope',
    'ResourcesService',
    function ($scope, ResourcesService) {
        $scope.links = ResourcesService.getW9Downloads();
    }
]);

app.controller('ResourcesResaleExemptionCertController', [
    '$scope',
    'ResourcesService',
    function($scope,ResourcesService){
        $scope.data = ResourcesService.getResaleExemptionCertLinks();
        $scope.location = '';
        $scope.filterLocations = function(row) {
            return !$scope.location || $scope.location.title == row.title;
        };
    }
]);
