(function () {
    'use strict';
}());

app.service('IpLookupService', [
    '$http',
    '$q',
    'IPSTACK_ACCESS_KEY',
    function ($http, $q, IPSTACK_ACCESS_KEY) {

        this.getIpData = function () {
            var deferral = $q.defer(),
                url = 'https://api.ipstack.com/check?access_key='+IPSTACK_ACCESS_KEY;

            $http({
                method: "GET",
                url: url
            }).then( function(response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };
    }
]);