(function () {
    'use strict';
}());

app.controller('ScanToWebController', [
    '$scope',
    '$rootScope',
    '$state',
    '$uibModal',
    '$window',
    'NgTableParams',
    'ScanToWebService',
    'UserService',
    function ($scope, $rootScope, $state, $uibModal, $window, NgTableParams, ScanToWebService, UserService) {
        $scope.setState($state.current.name);
        $scope.loading = true;

        function goToScan(scan) {
            $state.go('account.scan_to_web_details', {id: scan.id, status: scan.status});
        }

        if ($window.sessionStorage.getItem('goToS2W')) {
            var scan = JSON.parse($window.sessionStorage['goToS2W']);
            $window.sessionStorage.removeItem('goToS2W');
            goToScan(scan);
        }

        $scope.$on('refreshScans', function (e, status) {
            var sortDir = +status === 1 ? 'asc' : 'desc';
            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: { created_on: sortDir }
            }, {
                filterDelay: 500,
                getData: function (params) {
                    var sorting = params.sorting();
                    var order_by = Object.keys(sorting)[0];
                    var urlParams = {
                        status: status,
                        limit: params.count(),
                        page: params.page(),
                        filter: params.filter().locationFilter ? params.filter().locationFilter : '',
                        order_by: order_by,
                        order_direction: sorting[order_by],
                    };
                    $scope.loading = true;
                    return ScanToWebService.getScans(urlParams).then(function (response) {
                        $scope.filtered = urlParams.filter;
                        $scope.loading = false;
                        $scope.scans = response && response.scans ? response.scans : [];
                        params.total(response.meta.total_items);
                        return $scope.scans;
                    });
                }
            });
        });

        $scope.$broadcast('refreshScans', 1);
        $scope.showTable = function () {
            return ($scope.scans && $scope.scans.length) || $scope.filtered;
        };
        $scope.selectedScan = null;
        $scope.getScans = function (status) {
            $scope.$broadcast('refreshScans', status);
        };

        $scope.goToScan = function (scan) {
            var user = UserService.getPersistedUser();
            $scope.currentCustomerNumber = user.customer_number;
            $scope.s2wCustomerNumber = scan.tenant_id;
            if ($scope.currentCustomerNumber !== $scope.s2wCustomerNumber) {
                if (!user.can_multiview) {
                    $rootScope.$broadcast('notify', {message: 'Unable to view Scan: your customer number does not match the customer scanned. Please contact support.'});
                    return false;
                }
                ScanToWebService.s2wAlertModal(user, $scope, scan, false);
            } else {
                goToScan(scan);
            }
        }
    }
]);

app.controller('ScanToWebDetailsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    '$uibModal',
    '$window',
    'NgTableParams',
    'AnalyticsService',
    'CartService',
    'OrderService',
    'ScanToWebService',
    'UDFService',
    'UserService',
    'user',
    'scan',
    'PricingService',
    function ($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $window, NgTableParams, AnalyticsService, CartService, OrderService, ScanToWebService, UDFService, UserService, user, scan, PricingService) {
        $scope.selectedItems = {};
        $scope.itemsSelected = false;
        $scope.s2wShipTo = undefined;
        $scope.customerMaster = null;
        $scope.billingAddresses = [];
        $scope.poRequired = false;
        $scope.udfForm = false;
        $scope.custAuthenticated = false;
        $scope.currentCustomerNumber = user.customer_number;
        $scope.s2wCustomerNumber = scan.tenant_id;

        var init = function () {
            $timeout(function() {
                // Make sure user is signed in to the correct account - timeout required to make sure modal is loaded in DOM
                if ($scope.s2wCustomerNumber === $scope.currentCustomerNumber) {
                    $scope.custAuthenticated = true;
                    $scope.$broadcast('setScan', scan);
                } else {
                    if (!user.can_multiview) {
                        $rootScope.$broadcast('notify', {message: 'Unable to view Scan: your customer number does not match the customer scanned. Please contact support.'});
                        $state.go('account.scan_to_web');
                        return false;
                    }
                    ScanToWebService.s2wAlertModal(user, $scope, scan, true);
                }
            }, 1000);
        };
        init();

        $scope.$on('getShippingAndBilling', function (e, a) {
            if (a && a.hasOwnProperty('user')) user = a.user;
            if (a && a.hasOwnProperty('scan')) scan = a.scan;
            UserService.getCustomerMaster(user.id).then( function (customerMaster) {
                var cm = customerMaster ? customerMaster.sx_customer : false;
                if (!cm) {
                    $rootScope.$broadcast('notify', {message: 'Unable to get billing data. Please contact Go Digital for support.'});
                } else {
                    scan.billingInfo = {
                        billingCompanyName: cm.customerName,
                        billingAddress1: cm.customerAddress1,
                        billingAddress2: cm.customerAddress2,
                        billingCity: cm.customerCity,
                        billingState: cm.customerState,
                        billingZip: cm.customerZipCode,
                    }

                    if (!scan.po_number && cm.defaultCustomerPO) {
                        scan.po_number = cm.defaultCustomerPO;
                    }
                    $scope.poRequired = cm.poRequired;
                }
            });

            UserService.getUserShipTos().then(function (data) {
                var shipTos = data.filter(function (x) { return x.shipTo === scan['ship_to']; });
                if (!shipTos.length) {
                    $rootScope.$broadcast('notify', {message: 'Unable to get shipping data. Please contact Go Digital for support.'});
                } else {
                    var shipTo = shipTos[0];
                    var shippingAddress = shipTo.addr1.split(';');
                    scan.shippingInfo = {
                        shippingName: shipTo.shipTo + ' - ' + shipTo.name,
                        shippingAddress1: shippingAddress[0],
                        shippingAddress2: shippingAddress[1] ? shippingAddress[1] : '',
                        shippingAddress3: shipTo.addr3,
                        shippingCity: shipTo.city,
                        shippingState: shipTo.state,
                        shippingZip: shipTo.zip
                    }
                    if (!scan.po_number && shipTo.customerPo) {
                        scan.po_number = shipTo.customerPo;
                    }
                    if (!$scope.poRequired) {
                        $scope.poRequired = shipTo.poRequired;
                    }
                }
            });
        });

        $scope.$on('generateUdfForm', function (e, args) {
            UDFService.initUdfData($scope, args.user.customer_number, args.scan, args.scan.items, 'scan');
        });

        $scope.editLineProperties = function (line) {
            window.scrollTo(0, 0);
            $scope.modalInstance =  $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: "modalEditLineProperties.html",
                controller: "EditS2WLinePropertiesController",
                windowClass: '',
                size: 'lg',
                resolve: {
                    scan: function () {
                        return scan;
                    },
                    line: function () {
                        return line;
                    },
                    lineProperties: function () {
                        return $scope.udfProperties.line;
                    }
                }
            });
            $scope.modalInstance.result.then(function(s){
                // modal closed
            }, function(s) {
                // modal dismissed
            });
        };

        $scope.$on('setItem', function (e, item) {
            var heading = (item.cpn) ? item.cpn + " - " : "";
            heading += (item.description) ? item.description : item.erp;
            item.heading = heading;
            $scope.items.push(item);
        });

        $scope.$on('getItems', function (e, args) {
            var lines = args.items;
            PricingService.getPricing(lines, false, true).then(function (data) {
                angular.forEach(lines, function (line) {
                    line.quantity_available = null;
                    if (data && data.length) {
                        for (var i = 0; i < data[0].length; i++) {
                            if (line.erp == data[0][i].erp) {
                                line.quantity_available = data[0][i].quantity_available;
                                for (var x = 0; x < data[0][i].pricing.length; x++) {
                                    if (data[0][i].pricing[x].uom.toLowerCase() === line.uom.toLowerCase()) {
                                        line.price = data[0][i].pricing[x].price;
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                    }


                    if (line.specifications && line.specifications.length) {
                        var specs = [];
                        line.specifications.forEach(function (spec) {
                            if (typeof spec == 'string') {
                                var ex = spec.split("|");
                                specs.push({
                                    title: ex[0],
                                    value: ex[1]
                                });
                            } else if (typeof spec == 'object') {
                                specs.push(spec);
                            }
                        })
                        line.specifications = specs;
                    }
                    $scope.calculate();
                    $scope.$broadcast('setItem', line);
                });

                var nextPage = $scope.tableParams ? $scope.tableParams.page() : 1;
                if ($scope.tableParams && nextPage > 1 && $scope.tableParams.data.length < 1) {
                    nextPage--;
                }
                $scope.tableParams = new NgTableParams({
                    page: nextPage
                }, {
                    counts: [],
                    dataset: $scope.items
                });
            });
        });

        $scope.calculate = function() {
            var subtotal = 0;
            angular.forEach($scope.scan.items, function(line) {
                subtotal += line.quantity * line.price;
            });
            $scope.sub_total = subtotal;
        };

        $scope.$on('setScan', function (e, scan) {
            $scope.loading = true;
            $scope.items = [];
            var args = {scan: scan, user: user}
            $scope.$broadcast('getShippingAndBilling', args);
            if (scan.hasOwnProperty('header_properties')) {
                $scope.$broadcast('generateUdfForm', args);
            }
            if (scan.items.length > 0) {
                $scope.scan = scan;
                $scope.loading = false;
                $scope.$broadcast('getItems', $scope.scan);
            } else {
                ScanToWebService.archiveScan(scan).then( function () {
                    $state.go('account.scan_to_web');
                });
            }
        })

        $scope.$on('isSelected', function () {
            $scope.itemsSelected = (Object.keys($scope.selectedItems).length);
        });

        $scope.$broadcast('isSelected');

        $scope.addQty = function (item) {
            item.quantity++;
            $scope.calculate();
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 2) {
                item.quantity--;
            } else {
                return false;
            }
            $scope.calculate();
        };

        $scope.changeItemQty = function (scan, item) {
            if (item.quantity <= 0) {
                item.quantity = 1;
                $rootScope.$broadcast('notify', {message: 'Quantity required. If no quantity, please remove item.'})
            }
            $scope.calculate();
        };

        $scope.removeItem = function (i, index) {
            var copyScan = angular.copy($scope.scan);
            var copyScanItems = angular.copy(copyScan.items);
            copyScan.items = [];
            angular.forEach(copyScanItems, function (item) {
                if (i.erp !== item.erp) {
                    copyScan.items.push(item);
                }
            });

            ScanToWebService.updateScan(copyScan).then( function (data) {
                if (data) {
                    $rootScope.$broadcast('notify', {message: "Item removed from scan"});
                    $scope.tableParams.data.splice(index, 1);
                    $scope.$broadcast('setScan', data);
                } else {
                    $rootScope.$broadcast('notify', {message: "Unable to remove item from scan"});
                }
            });
            $scope.calculate();
        };

        $scope.reactivateScan = function (scan) {
            scan.status = 1;
            ScanToWebService.updateScan(scan).then( function (data) {
                if (data) {
                    $scope.$broadcast('getShippingAndBilling');
                    $scope.$broadcast('setScan', data);
                    $rootScope.$broadcast('notify', {message: "Scan reactivated"});
                } else {
                    $rootScope.$broadcast('notify', {message: "Unable to reactivate from scan"});
                }
            });
        }

        $scope.archiveScan = function (scan) {
            ScanToWebService.archiveScan(scan).then( function () {
                $state.go('account.scan_to_web');
                $rootScope.$broadcast('notify', {message: "Scan archived"});
            });
        }

        $scope.disableCheckout = function () {
            return $scope.s2wDetailsForm.$invalid || $rootScope.invalidLineProps || $scope.loading || $scope.loadingUdfLines || $scope.loadingUdfHeader;
        };

        var checkHeaderUdf = function () {
            if (!$scope.udfProperties.header) {
                return new Promise(function (resolve) {
                    resolve({});
                });
            } else {
                return UDFService.verifyUdfValues($scope.udfProperties.header, $scope.data.header_properties);
            }
        }

        var submitScan = function (scan) {
            return UserService.getSessionId().then(function (session) {
                return CartService.postCart(session, scan.items, "VMI:N").then(function (cart) {
                    var user = UserService.getPersistedUser();
                    var data = {
                        order_type: 'o',
                        order_source_type: cart.order_source_type,
                        order_session: OrderService.createOrderSession(),
                        customer_number: UserService.getCustomerNumber(),
                        company_number: UserService.getCompanyNumber(),
                        warehouse_key: UserService.getCustomerWarehouse(),
                        user_id: (user) ? user.id : undefined,
                        session_id: cart.session_id,
                        email: scan.receiver_email ? scan.receiver_email : user.username,
                        name: user.name,
                        phone: user.phone,
                        phone_extension: user.phone_extension,
                        bill_to_address_1: scan.billingInfo.billingAddress1,
                        bill_to_address_2: scan.billingInfo.billingAddress2,
                        bill_to_city: scan.billingInfo.billingCity,
                        bill_to_state: scan.billingInfo.billingState ? scan.billingInfo.billingState : null,
                        bill_to_zip: scan.billingInfo.billingZip,
                        ship_to_company: scan.shippingInfo.shippingCompanyName,
                        ship_to_address_1: scan.shippingInfo.shippingAddress1,
                        ship_to_address_2: scan.shippingInfo.shippingAddress2,
                        ship_to_city: scan.shippingInfo.shippingCity,
                        ship_to_state: scan.shippingInfo.shippingState,
                        ship_to_zip: scan.shippingInfo.shippingZip,
                        customer_po_number: scan.po_number,
                        order_lines: [],
                        header_properties: scan.header_properties,
                        save_for_express: false,
                        promo_codes: [],
                        ship_to_id: scan.ship_to
                    };

                    angular.forEach(cart.cart_lines, function (v) {
                        var i = {
                            actual_sell_price: v.price,
                            erp: v.erp,
                            iref: v.iref,
                            order_quantity: v.quantity,
                            unit_of_measure: v.uom,
                            cpn: v.cpn,
                            description: v.description,
                            comment: 'Manufacturer: ' + v.manufacturer + '\nAdditional Information: ' + v.comment
                        };

                        if (v.hasOwnProperty('apsv') && v.apsv !== null) i.apsv = v.apsv;
                        if (v.hasOwnProperty('direct_ship') && v.direct_ship !== null) i.direct_ship = v.direct_ship;
                        if (v.hasOwnProperty('line_properties') && v.line_properties !== null) i.line_properties = v.line_properties;
                        data.order_lines.push(i);
                    });

                    return OrderService.submitOrderByToken(data, cart.id).then(function (orders) {
                        if (orders.error || orders.length < 1 || orders.code >= 400) {
                            throw Error('Error processing order');
                        } else {
                            if (!scan.order_number) { scan.order_number = []; }
                            angular.forEach(orders, function (order) {
                                AnalyticsService.purchase(cart, order);
                                scan.order_number.push(order.order_number);
                            });
                            $scope.orders = orders
                            $rootScope.$broadcast('close-loading-modal');

                            ScanToWebService.updateScan(scan).then(function () {
                                ScanToWebService.archiveScan(scan);
                            });

                            $scope.s2wOrderConfirmationModalInstance = $uibModal.open({
                                animation: true,
                                ariaLabelledBy: 'modal-title',
                                ariaDescribedBy: 'modal-body',
                                templateUrl: 'views/_partials/orderConfirmationModal.html',
                                controller: 'Scan2WebOrderConfirmationModalInstanceCtrl',
                                windowClass: '',
                                scope: $scope,
                                size: 'lg'
                            });

                            $scope.s2wOrderConfirmationModalInstance.result.then(function () {
                                $state.go('account.scan_to_web');
                            }, function() {
                                $state.go('account.scan_to_web');
                            });
                        }
                    }).catch(function(err) {
                        CartService.handleCartError(err, $scope);
                    }).finally(function(){
                        CartService.deleteCart(session).then(function () {
                            CartService.getCart(session);
                        });
                    });
                });
            })
        }

        $scope.releaseOrder = function (scan) {
            var cart = CartService.getCartCache();
            if (cart && cart.cart_lines.length) {
                $rootScope.$broadcast('notify', {message: 'There are current items in your cart. Please complete your cart order before releasing Scan.'});
                return false;
            }

            $rootScope.$broadcast('open-loading-modal', {order: true});
            var step = 'udf';
            checkHeaderUdf().then(function () {
                scan.header_properties = $scope.data.header_properties;
                step = 'order';
                $window.sessionStorage['s2wOverride'] = 1;
                return submitScan(scan);
            }).catch(function (err) {
                $rootScope.$broadcast('close-loading-modal');
                if ('udf' === step) {
                    angular.forEach(err, function (id) {
                        $scope.s2wDetailsForm[id].$setValidity('pattern', false);
                    });
                    $rootScope.$broadcast('notify', {message: 'One or more of the values is invalid. Please try again.'});
                } else {
                    $rootScope.$broadcast('notify', {message: 'Failed to process order, please check that your order information is correct. Contact support if the problem persists.'});
                }
            });
        }
    }
]);

app.controller('Scan2WebAlertModalInstanceCtrl', [
    '$scope',
    '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        $scope.cancelAdd = function () {
            $uibModalInstance.close(false);
        };

        $scope.addToCart = function () {
            $uibModalInstance.close(true);
        };

        $scope.proceedS2w = function () {
            $uibModalInstance.close(true);
        };
    }
]);

app.controller('Scan2WebOrderConfirmationModalInstanceCtrl', [
    '$scope',
    '$uibModalInstance',
    function ($scope, $uibModalInstance) {
        $scope.close = function () {
            $uibModalInstance.close(true);
        }
    }
]);

app.controller('EditS2WLinePropertiesController', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    '$q',
    'scan',
    'line',
    'lineProperties',
    'ScanToWebService',
    'UDFService',
    function ($rootScope, $scope, $window, $uibModalInstance, $q, scan, line, lineProperties, ScanToWebService, UDFService) {
        UDFService.initUdfLineEdit($scope, scan, scan.items, line, 'scan', lineProperties, $uibModalInstance);
    }
]);
