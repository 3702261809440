(function () {
    'use strict';
}());

app.controller('VallenToolsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'UserService',
    function ($rootScope, $scope, $state, $window, OPEN_BROWSE_CUSTOMER_NUMBER, UserService) {
        $rootScope.disablePaddedContent = true;
        var user = $rootScope.currentUser;
        if (!user || !user.is_vallen) {
            $state.go('account_login');
        }

        $scope.user = user;
        $scope.go = function (link) {
            $state.go(link);
        };

        $scope.setState = function (state) {
            $scope.state = state;
        };


        /**
         * @doc Action Bar
         * In different views for each controller, create a html string in the controller,
         * then call $rootScope.$broadcast('setAction', htmlString); to it in.
         *
         */
        $scope.action = false;
        $rootScope.$on('setAction', function (e,args) {
           if (args !== undefined) {
               $scope.action = args;
           }
        });

        $rootScope.getAdminToken = function () {
            return UserService.getToken();
        };
    }
]);

app.controller('VallenToolsIndexController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    function ($rootScope, $scope, $state, $window) {

    }
]);

app.controller('VallenToolsVendorsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'NgTableParams',
    'AlgoliaService',
    'VendorService',
    function ($rootScope, $scope, $state, $uibModal, NgTableParams, AlgoliaService, VendorService) {
        $scope.setState($state.current.name);

        $scope.search = {
            term: '',
            active: false,
            submitted: false,
            maxResults: 1000,
            results: []
        };

        $scope.searchVendors = function () {
            $scope.search.results = [];
            if (!$scope.search.term) { return; }

            $scope.search.submitted = true;
            $scope.search.active = true;
            var perPage = 20;

            AlgoliaService.searchVendors($scope.search.term.trim(), { hitsPerPage: $scope.maxResults }).then(function (vendors) {
                if (vendors.hits && vendors.hits.length) {
                    $scope.search.results = vendors.hits;
                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: perPage
                    }, {
                        dataset: $scope.search.results,
                        counts: []
                    });
                }
            }).finally(function() {
                $scope.search.active = false;
            });
        };

        $scope.vendorDetails = function (vendor) {
            $rootScope.$broadcast('open-loading-modal');
            $scope.vendor = null;

            VendorService.getVendor(vendor.vendorNumber).then(function (data) {
                var addrLines = data.addr.split(';');
                addrLines.push(data.addr3);
                data.addrLines = addrLines.filter(function (x) { return x != ''; });
                $scope.vendor = data;
            }).finally(function() {
                $rootScope.$broadcast('close-loading-modal');
                $scope.vendorDetailModal = $uibModal.open({
                    templateUrl: 'vendorDetailModal.html',
                    windowClass: 'modal-fit',
                    scope: $scope
                });
                $scope.vendorDetailModal.result.then(function(){
                    // modal closed
                }, function() {
                    // modal dismissed
                });
            });
        };

        $scope.closeModal = function () {
            $scope.vendorDetailModal.dismiss();
        };
    }
]);

app.controller('VallenToolsCustomersController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'NgTableParams',
    'AlgoliaService',
    'CompanyService',
    '$q',
    function ($rootScope, $scope, $state, $uibModal, NgTableParams, AlgoliaService, CompanyService, $q) {
        $scope.setState($state.current.name);

        $scope.search = {
            term: '',
            active: false,
            submitted: false,
            maxResults: 1000,
            results: []
        };

        $scope.searchCustomers = function () {
            $scope.search.results = [];
            if (!$scope.search.term) { return; }

            $scope.search.submitted = true;
            $scope.search.active = true;

            var perPage = 20;

            AlgoliaService.searchCustomers($scope.search.term.trim(), { hitsPerPage: $scope.search.maxResults })
            .then(function (customers) {
                if (customers.hits && customers.hits.length) {
                    $scope.search.results = customers.hits;
                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: perPage
                    }, {
                        dataset: $scope.search.results,
                        counts: []
                    });
                }
            })
            .finally(function() {
                $scope.search.active = false;
            });
        };

        $scope.customerDetails = function (customer) {
            $rootScope.$broadcast('open-loading-modal');
            $scope.allCustomerData = [];
            $scope.customer = { data: null };

            var billTo = CompanyService.getCompanyMaster(customer.customerNumber).then(function(data) {
                data.display_type = "";
                $scope.allCustomerData.push($scope.modifyData(data));
            });

            var shipTos = CompanyService.getCompanyShipTos(customer.customerNumber).then(function(data) {
                angular.forEach(data.ship_tos, function(shipTo) {
                    shipTo.display_type = shipTo.shipto + ' - ';
                    $scope.allCustomerData.push($scope.modifyData(shipTo))
                });
            });

            $q.all([billTo, shipTos]).then(function() {
                for (var i = 0; i < $scope.allCustomerData.length; i++) {
                    if (!$scope.allCustomerData[i].display_type) {
                        var obj = $scope.allCustomerData[i];
                        $scope.allCustomerData.splice(i, 1);
                        $scope.allCustomerData.unshift(obj);
                        break;
                    }
                }

                if ($scope.allCustomerData.length >= 1) {
                    $scope.customer.data = $scope.allCustomerData[0];
                }

                $rootScope.$broadcast('close-loading-modal');
                $scope.customerDetailModal = $uibModal.open({
                    templateUrl: 'customerDetailModal.html',
                    windowClass: 'modal-fit',
                    scope: $scope
                });
                $scope.customerDetailModal.result.then(function(){
                    // modal closed
                }, function() {
                    // modal dismissed
                });
            });
        };

        $scope.modifyData = function(data) {
            var addrLines = data.addr.split(';');
            data.addrLines = addrLines.filter(function (x) { return x != ''; });
            data.freightOut = (data.outbndfrtfl === "0") ? "No" : "Yes";
            return data;
        };

        $scope.closeModal = function () {
            $scope.customerDetailModal.dismiss();
        };
    }
]);

app.controller('VallenToolsProductsController', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'NgTableParams',
    'PartService',
    '$stateParams',
    function ($rootScope, $scope, $state, $uibModal, NgTableParams, PartService, $stateParams) {
        $scope.setState($state.current.name);

        $scope.search = {
            customer: '',
            part: '',
            whsePart: '',
            active: false,
            submitted: false,
            result: false
        };

        $scope.searchProducts = function (searchType) {
            var customer = '';
            var part = '';
            $scope.searchType = searchType;
            if (!searchType) {
                customer = '87023';
                part = $scope.search.whsePart;
            } else {
                customer = $scope.search.customer;
                part = $scope.search.part;
            }

            search(customer, part);
        };

        function init(params) {
            if (params.erp) {
                var customer;
                if (params.custnum) {
                    customer = params.custnum;
                    $scope.search.customer = params.custnum;
                    $scope.search.part = params.erp;
                } else {
                    customer = '87023';
                    $scope.search.whsePart = params.erp;
                }

                search(customer, params.erp);
            }
        }

        function search(customer, part) {

            $scope.search.submitted = true;
            $scope.search.active = true;
            $scope.search.result = false;

            PartService.searchCustomerProducts(customer, part).then( function (part) {
                if (part) {
                    var key = Object.keys(part)[0];
                    $scope.search.result = part[key][0];

                    $scope.custTableParams = new NgTableParams({
                        page: 1,
                        count: 20
                    }, {
                        dataset: $scope.search.result.warehouses,
                        counts: []
                    });
                }
            }).finally(function() {
                $scope.search.active = false;
            });
        }

        init($stateParams);
    }
]);

app.controller('VallenToolsCustomerApplicationsController', [
    '$rootScope',
    '$scope',
    '$state',
    'UserService',
    function ($rootScope, $scope, $state, UserService) {
        $scope.data = {};
        $scope.loading = true;
        $scope.showTable = false;
        $scope.limits = [10,25,50,100];
        $scope.setState($state.current.name);

        $scope.customerTypeOptions = [
            {value: '', label:'All Customer Types'},
            {value: 'New', label:'New Customers'},
            {value: 'Existing', label:'Existing Customers'}
        ];

        $scope.statusOptions = [
            {value:'', label:'All Statuses'},
            {value:'Pending', label:'Pending'},
            {value:'Won', label:'Won'},
            {value:'No Decision', label:'No Decision'},
            {value:'Tier 1 Developing', label:'Tier 1 Developing'},
            {value:'Lost', label:'Lost'}
        ];

        function initFilters(){
            $scope.metaData = {
                limit: $scope.limits[1],
                offset: 1,
                order_direction: 'DESC',
                total_items: 0,
                page: 1,
                type : $scope.customerTypeOptions[1].value,
                status : $scope.statusOptions[1].value,
                bdm_prospect: false,
                filter : ''
            };
        }

        initFilters();

        $scope.getCustomerApplicationsData = function (metaData){
            $rootScope.$broadcast('open-loading-modal');
            if(metaData){
                $scope.metaData.offset = metaData.page;
            }
            UserService.getCompanyApplications($scope.metaData).then( function(data){
                if (data){
                    $scope.metaData.total_items = data.meta.total_items;
                    $scope.data = data.customer_applications;
                }
                $scope.showTable = $scope.data.length > 0;
            }).catch(function(err) {
                $rootScope.$broadcast('notify', {message: "Unable to retrieve applications"});
            }).finally(function() {
                $rootScope.$broadcast('close-loading-modal');
                $scope.loading = false;
            });
        };
        $scope.getCustomerApplicationsData();

        $scope.applyFilter = function(applicationFilter){
            applicationFilter.page = 1;
            $scope.getCustomerApplicationsData(applicationFilter);
        };

        $scope.clearFilter = function(){
            $scope.metaData.page = 1;
            $scope.metaData.type = '';
            $scope.metaData.status = '';
            $scope.metaData.filter = '';
            $scope.metaData.bdm_prospect = false;
            $scope.getCustomerApplicationsData();
        };

        $scope.segmentOptions = [
            {value: 'Direct', label:'Direct'},
            {value: '', label:'TBD'}
        ];
    }
]);

app.controller('VallenToolsCustomerApplicationController', [
    '$rootScope',
    '$scope',
    '$state',
    'UserService',
    '$stateParams',
    function ($rootScope, $scope, $state, UserService, $stateParams) {
        $scope.loading = true;
        $scope.application = {};
        var originalApp = null;
        $rootScope.$broadcast('open-loading-modal');

        UserService.getCompanyApplication($stateParams.id).then(function (data) {
            if(data){
                $scope.application = data;
                originalApp = angular.copy(data);
                $scope.isEditable = !originalApp.status || (originalApp.status !== 'Approved' && originalApp.status !=='Rejected');
            }
        }).catch(function(err) {
            $rootScope.$broadcast('notify', {message: "Unable to locate application"});
        }).finally(function (){
            $rootScope.$broadcast('close-loading-modal');
            $scope.loading = false;
        });

        $scope.setState($state.current.name);

        $scope.status = [
            'Pending',
            'Won',
            'No Decision',
            'Tier 1 Developing',
            'Lost'
        ];

        $scope.segment = [
            'TBD',
            'Direct'
        ];

        $scope.data = {
            note: ''
        };

        $scope.saveNote = function() {
            if($scope.data.note){
                $rootScope.$broadcast('open-loading-modal');
                var note = {
                    description : $scope.data.note,
                    updated_by : $rootScope.currentUser.username,
                    updated_date : Date.now()
                };
                if (!$scope.application.notes) {
                    $scope.application.notes = [];
                }
                $scope.application.notes.push(note);
                originalApp.notes = $scope.application.notes;
                UserService.putCompanyApplication(originalApp).then(function () {
                    $rootScope.$broadcast('notify', { message: "Note added successfully"});
                    $scope.data.note = '';
                }).catch(function(err) {
                    $rootScope.$broadcast('notify', {message: "Error saving note"});
                }).finally(function() {
                    $rootScope.$broadcast('close-loading-modal');
                });
            }
        };

        $scope.save = function() {
            if ($scope.application.status &&
                ($scope.application.status === 'Approved' || $scope.application.status === 'Rejected' ) &&
                !confirm('Are you sure you want to mark this application as "' + $scope.application.status + '"? This action cannot be undone.')
            ) {
                return false;
            }

            $rootScope.$broadcast('open-loading-modal');
            $scope.application.updated_by = $rootScope.currentUser.username;
            UserService.putCompanyApplication($scope.application).then(function () {
                $rootScope.$broadcast('notify', {message: "Customer Application updated successfully"});
                $state.go('vallen_tools.customer_applications');
            }).catch(function(err) {
                $rootScope.$broadcast('notify', {message: "Error saving customer application"});
            }).finally(function() {
                $rootScope.$broadcast('close-loading-modal');
            });
        };
    }
]);
