(function () {
    'use strict';
}());

app.controller('BodyController', [
    '$cookies',
    '$interval',
    '$rootScope',
    '$scope',
    '$window',
    '$state',
    '$uibModal',
    'UserService',
    'CartService',
    'CompanyService',
    'PHONE',
    'StatesService',
    'DOMAIN',
    function ($cookies, $interval, $rootScope, $scope, $window, $state, $uibModal, UserService, CartService, CompanyService, PHONE, StatesService, DOMAIN) {
        $rootScope.phone = PHONE;
        $scope.states = StatesService.getStates();
        $scope.domain = DOMAIN;
        $scope.date = new Date();
        $scope.xs = 760;
        $scope.sm = 992;
        $scope.md = 1200;
        $rootScope.poLabel = 'PO / Reference #';
        $rootScope.poLabelAlt = 'PO / Ref #';
        $rootScope.support_email = 'support@vallen.com';

        /*
            Domain-specific variables. Default to Vallen and override if necessary
         */

        if(DOMAIN === 'boringsmith'){
            $rootScope.company_name = 'Boring-Smith';
            $rootScope.company_name_abbr = 'BSM';
        } else {
            $rootScope.company_name = 'Vallen';
            $rootScope.company_name_abbr = 'Vallen';
        }


        function calculateSize() {
            $rootScope.isMobile = $window.innerWidth <= $scope.xs;
            $rootScope.isSmall = $window.innerWidth > $scope.xs && $window.innerWidth < $scope.sm;
            $rootScope.isMedium = $window.innerWidth > $scope.sm && $window.innerWidth < $scope.md;
            $rootScope.isLarge = $window.innerWidth > $scope.md;
        }

        $rootScope.disablePaddedContent = false;
        function onScroll() {
            var refresh = false;
            $rootScope.YPosition = this.pageYOffset;
            $rootScope.navbarFixed = $rootScope.YPosition >= 130 || $window.innerWidth <= $scope.xs;
            if (!$rootScope.disablePaddedContent && $rootScope.paddedContent !== $scope.navbarFixed) {
                $rootScope.paddedContent = $scope.navbarFixed;
                refresh = true;
            }
            if (!$rootScope.navbarFixed && $rootScope.setNavSearchFocused) {
                if ($rootScope.getNavSearchFocused()) {
                    $rootScope.setNavSearchFocused(false);
                    refresh = true;
                }
            }
            if(refresh || $rootScope.disablePaddedContent) {
                $rootScope.$evalAsync();
            }
        }

        calculateSize();

        angular.element($window).bind('resize', function(){
            calculateSize();
            onScroll();
        });

        var ua = navigator.userAgent;
        $rootScope.uaDevice = {
            browser: /Edge\/\d+/.test(ua) ? 'ed' : /MSIE 9/.test(ua) ? 'ie9' : /MSIE 10/.test(ua) ? 'ie10' : /MSIE 11/.test(ua) ? 'ie11' : /MSIE\s\d/.test(ua) ? 'ie?' : /rv\:11/.test(ua) ? 'ie11' : /Firefox\W\d/.test(ua) ? 'ff' : /Chrome\W\d/.test(ua) ? 'gc' : /Chromium\W\d/.test(ua) ? 'oc' : /\bSafari\W\d/.test(ua) ? 'sa' : /\bOpera\W\d/.test(ua) ? 'op' : /\bOPR\W\d/i.test(ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '',
            os: /Windows NT 10/.test(ua) ? "win10" : /Windows NT 6\.0/.test(ua) ? "winvista" : /Windows NT 6\.1/.test(ua) ? "win7" : /Windows NT 6\.\d/.test(ua) ? "win8" : /Windows NT 5\.1/.test(ua) ? "winxp" : /Windows NT [1-5]\./.test(ua) ? "winnt" : /Mac/.test(ua) ? "mac" : /Linux/.test(ua) ? "linux" : /X11/.test(ua) ? "nix" : "",
            mobile: /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 1 : 0,
            tablet: /Tablet|iPad/i.test(ua)
        };

        $rootScope.navbarFixed = false;
        $rootScope.paddedContent = false;
        $rootScope.YPosition = 0;
        $window.onscroll = onScroll;

        $scope.$on("reset-scrolling", function () {
            $rootScope.navbarFixed = this.pageYOffset >= 130 || $window.innerWidth <= $scope.xs;
            $rootScope.paddedContent = $scope.navbarFixed;
            $rootScope.YPosition = this.pageYOffset;
        });

        var notificationIndex = 0;
        $scope.notifications = {};
        $rootScope.$on('notify', function (event, args) {
            if (args !== undefined) {
                notificationIndex++;
                $scope.notifications[notificationIndex] = args.message;
            }
        });

        $rootScope.ordersCache = {};
        $rootScope.workflow = {};
        $rootScope.currentCustomer = {};
        $scope.$on('updateUser', function() {
            $rootScope.currentUser = UserService.getPersistedUser();
            $rootScope.workflow = {
                approval: false,
                currency: '',
                limit_basis: '',
                group: '',
                dept_limit: '',
                method: ''
            };

            try {
                if ($rootScope.currentUser) {
                    $rootScope.$broadcast('getAllowedCompanies', $rootScope.currentUser);
                    CompanyService.getCompanySettings($rootScope.currentUser.customer_number).then(function (settings) {
                        $rootScope.currentCustomer = settings;
                        if (settings.phone) { $rootScope.phone = settings.phone; }
                        if (undefined !== settings.workflow_approval && settings.workflow_approval) {
                            $rootScope.workflow = {
                                approval: settings.workflow_approval,
                                currency: settings.workflow_currency,
                                limit_basis: settings.workflow_limit_basis,
                                group: settings.workflow_group,
                                dept_limit: settings.workflow_dept_limit,
                                method: settings.workflow_method
                            };
                        }
                    });
                } else {
                    $rootScope.currentUser = false;
                    // $scope.isAuthenticated = false;
                }
            } catch(err) {
                $rootScope.currentUser = false;
                // $scope.isAuthenticated = false;
            }
            return false;
        });

        $scope.isAuthenticated = UserService.getIsAuthenticated();
        if (!$scope.isAuthenticated && UserService.getPersistedUser()) {
            $window.localStorageOverride.clear();
        }

        $rootScope.$on('login', function () {
            $scope.isAuthenticated = true;
            $scope.$broadcast('updateUser');
        });

        $rootScope.$on('logout', function () {
            $rootScope.phone = PHONE;
            $scope.isAuthenticated = false;
            $scope.$broadcast('updateUser');
        });

        $scope.openLoginModal = function (destination, destinationParams) {
            $scope.loginModal = $uibModal.open({
                templateUrl: 'login.html',
                controller: 'LoginModalController',
                scope: $scope,
                windowClass: 'login',
                resolve: {
                    destination: function() {
                        return destination;
                    },
                    destinationParams: function() {
                        return destinationParams;
                    }
                }
            });
            $scope.loginModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        window.openWelcomeModal = function() {
            $scope.welcomeModal = $uibModal.open({
                templateUrl: 'welcome.html',
                controller: 'LoginModalController',
                scope: $scope,
                windowClass: 'login',
                resolve: {
                    destination: function() {
                        return null;
                    }
                }
            });
            $scope.welcomeModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        $scope.openLoadingModal = function (options) {
            $scope.loadingOptions = options;
            $scope.loadingModal = $uibModal.open({
                templateUrl: 'loading.html',
                controller: 'LoadingModalController',
                windowClass: 'loading',
                scope: $scope,
                backdrop: 'static'
            });
            $scope.loadingModal.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        $rootScope.$on('open-loading-modal', function (event, options) {
            $scope.openLoadingModal(options);
        });

        // Sequence is critical here - must getUser() before updateUser
        $scope.isPunchOut = UserService.getIsPunchOut();
        UserService.getUser().then(function () {
            $scope.$broadcast('updateUser');
        }).catch( function(err) {
            $scope.$broadcast('updateUser');
        });
        CartService.getCart();

        $scope.login = {
            username: null,
            password: null
        };

        $scope.loginAlert = null;

        $scope.logout = function () {
            UserService.logout();
            $state.go('account_login');
        };

        $rootScope.fields = {
            email : ""
        };

        $scope.goBack = function(pages) {
            if (!pages) { pages = -1; }
            window.history.go(pages);
        };

        $scope.openContactModal = function (type, defaultValues) {
            if (!$scope.isAuthenticated) {
                $scope.openLoginModal('contact-modal', {type: type});
                return;
            }

            $scope.inquiryCode = type;
            if (defaultValues) {
                $scope.contact = defaultValues;
            }

            $scope.contactModal = $uibModal.open({
                templateUrl: 'contactModal.html',
                controller: 'ContactModalController',
                scope: $scope,
                windowClass: 'contact-form-modal'
            });
            $scope.contactModal.result.then(function(){
                // modal closed
                $scope.contact = {};
            }, function() {
                // modal dismissed
                $scope.contact = {};
            });
        };

        // Interval functions
        $interval(function () {
            if ($window.localStorageOverride['user'] !== undefined) {
                var user = UserService.getPersistedUser();
                if (!user) { return false; }
                if (user.roles.indexOf("ROLE_ADMIN") !== -1 || user.roles.indexOf("ROLE_SUPERADMIN") !== -1 || user.is_supervisor) {
                    CartService.getHasCartRequests(user.id).then(function (requests) {
                        if (requests > 0) {
                            if (
                                $window.sessionStorage['cartsToApprove'] === undefined ||
                                $window.sessionStorage['cartsToApprove'] < requests
                            ) {
                                $rootScope.$broadcast('notify', {message: "Attention: You have a cart request waiting for approval."});
                                $window.sessionStorage['cartsToApprove'] = requests;
                            } else {
                                if ($window.sessionStorage['cartsToApprove'] !== requests) {
                                    $window.sessionStorage['cartsToApprove'] = requests;
                                }
                            }
                        } else {
                            $window.sessionStorage.removeItem('cartsToApprove');
                        }
                    }).catch(angular.noop);
                }
            }
        }, 10000);
    }]
);

app.controller('HomeController', [
    '$scope',
    '$state',
    '$rootScope',
    '$location',
    '$window',
    '$anchorScroll',
    'HomepageService',
    'UserService',
    'WORDPRESS_URL',
    'DOMAIN',
    function ($scope, $state, $rootScope, $location, $window, $anchorScroll, HomepageService, UserService, WORDPRESS_URL, DOMAIN) {
        $rootScope.hideCustomLanding = true;
        $scope.cards = HomepageService.getCards();
        $scope.partnerLogos = HomepageService.getPartnerLogos();
        $rootScope.isLoggedIn = false;
        $scope.domain = DOMAIN;

        $scope.catalogLinks = {
            eaton: HomepageService.eatonCatalogs(),
            trane: HomepageService.traneCatalogs()
        };

        $scope.cardLink = function (item) {
            if(!$rootScope.isLoggedIn && item.loginRequired){
                $scope.openLoginModal(item.route);
            } else if (item.route) {
                $state.go(item.route);
            } else if (item.wordpress) {
                $state.go('wordpress', { path: item.wordpress });
            } else if (item.hash) {
                if ($location.hash() !== item.hash) {
                    $location.hash(item.hash);
                } else {
                    $anchorScroll();
                }
            }
        };

        var useDefaultHome = function() {
            $rootScope.homeTemplate = 'views/base/home/default.html';
            $rootScope.landingIframeSrc = null;
        };

        // look for custom homepage
        UserService.getUser().then(function(user) {
            if (user) {
                $rootScope.isLoggedIn = true;
                $rootScope.user = user;

                if (user.custom_landing_page && user.custom_landing_page != false) {
                    // locally-hosted custom homepage (ends in '.html')
                    if (user.custom_landing_page.substr(-5) === '.html') {
                        $rootScope.homeTemplate = 'views/base/home/' + user.custom_landing_page;
                        $rootScope.landingIframeSrc = null;
                    } else {
                        // wordpress-hosted custom homepage
                        $rootScope.homeTemplate = null;
                        $rootScope.landingIframeSrc = WORDPRESS_URL + user.custom_landing_page;

                        var landingIframe = angular.element('#landingIframe');
                        landingIframe.css('height', '1200px');

                        $window.addEventListener('message', function (event) {
                            var type = event.data.type,
                                data = event.data.data;

                            if (type === 'sizeChange' && data.title === user.custom_landing_page.replace(/\//g, '')) {
                                if ($scope.loading) {
                                    $scope.loading = false;
                                }
                                landingIframe.css('width', '100%');
                                landingIframe.css('height', data.height + 'px');
                            }
                        });
                    }
                } else {
                    useDefaultHome();
                }
            }
        }).catch( function(err) {
            console.error(err);
        }).finally(function() {
            if (!$rootScope.isLoggedIn || (!$rootScope.homeTemplate && !$rootScope.landingIframeSrc)) {
                useDefaultHome();
            }
        });

        window.addEventListener('message', function(event) {
            if (event.data.type === 'sizeChange' && $scope.loading) {
                $scope.loading = false;
            }

            if(!$scope.$$phase) {
                $scope.$evalAsync();
            }
        });
    }
]);


app.controller('LoginCardController', [
    '$scope',
    '$rootScope',
    'HomepageService',
    'UserService',
    '$state',
    function ($scope, $rootScope, HomepageService, UserService, $state) {
        $scope.login = {
            username: null,
            password: null
        };

        $scope.homeLogin = function() {
            $rootScope.$broadcast('open-loading-modal');
            UserService.login($scope.login.username, $scope.login.password).then(function (authenticated) {
                if (authenticated) {
                    $rootScope.$broadcast('close-loading-modal');
                    $state.go('account.profile');
                } else {
                    UserService.getUserStatus($scope.login.username).then(function(user) {
                        $rootScope.$broadcast('notify', {message: user.errorMessage});
                    }).finally(function() {
                        $rootScope.$broadcast('close-loading-modal');
                    })
                }
            })
        };

        $rootScope.homeLogout = function() {
            UserService.logout();
            $rootScope.isLoggedIn = false
        }
    }
]);

app.controller('ContactCardController', [
    '$scope',
    '$rootScope',
    'StatesService',
    'SupportService',
    function ($scope, $rootScope, StatesService, SupportService) {
        $scope.data = {};
        $scope.states = StatesService.getStates();
        $scope.states.push({name: 'Other/International', abbr: '--'});

        $scope.inquiryTypes = SupportService.getInquiryTypes();

        $scope.confirmation = false;
        $scope.launchContact = function() {
            $scope.openContactModal();
            var elem = document.getElementById('homeContact').elements;
            angular.forEach(elem, function (e) {
                e.blur();
            });
        };
    }
]);

app.controller('HomeCarouselController', [
    '$scope',
    'HomepageService',
    function ($scope, HomepageService) {
        $scope.myInterval = 7500;
        $scope.slides = HomepageService.getSlides();
    }
]);
