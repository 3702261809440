(function () {
    'use strict';
}());

app.service('SalesRepService', [
    '$cookies',
    '$http',
    '$q',
    'API_URL',
    function ($cookies, $http, $q, API_URL) {
        this.getToken = function () {
            return !!$cookies.get('token') ? $cookies.get('token') : false;
        };

        this.getAllowedCustomers = function (repId) {
            var url = API_URL + '/sales_rep/' + repId;
            var deferral = $q.defer();

            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + this.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false)
            });

            return deferral.promise;
        };
    }
]);