(function () {
    'use strict';
}());

app.directive('catalogProductLink', [
    function () {
        return {
            restrict: 'E',
            template: '<span>' +
                '<a data-ng-if="item.erp && item.price" ui-sref="catalog_product_erp({erp: item.erp})" class="underline">' +
                '{{desc}}</a>' +
                '<span data-ng-if="!item.erp || !item.price">{{desc}}</span>' +
                '</span>',
            replace: true,
            scope: {
                item: '=',
                trim: '='
            },
            controller: ['$scope', '$filter', function($scope, $filter) {
                $scope.desc = angular.copy($scope.item.description);
                if ($scope.trim) {
                    $scope.desc = $filter('trimText')($scope.desc, $scope.trim);
                }
            }]
        }
    }
]);
