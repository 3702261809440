(function() {
    'use strict';
}());

app.service('OrderService', [
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    '$window',
    '$filter',
    'UserService',
    'API_URL',
    function($rootScope, $http, $location, $q, $state, $window, $filter, UserService, API_URL) {
        var self = this;

        self.getUnspscCodes = function (items) {
            var itemsQuery = '';
            var user = UserService.getPersistedUser();
            angular.forEach(items, function(item) {
                itemsQuery += item.erp + ',';
            });

            var deferral = $q.defer(),
                url = API_URL + "/punchout/unspsc?items=" + itemsQuery;

            if (user && user.hasOwnProperty("unspsc_version")) {
                url += "&version=" + user.unspsc_version;
            }

            if (user && user.hasOwnProperty("unspsc_default")) {
                url += "&default_unspsc=" + user.unspsc_default;
            }

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject();
            });

            return deferral.promise;
        };

        self.getOrder = function (order, customerNumber, ignoreCache) {
            var deferral = $q.defer(),
                url = API_URL + "/order/" + order;

            // disable caching for now 4-24-2020
            // if (order && $rootScope.ordersCache[order] && !ignoreCache) {
            //     deferral.resolve($rootScope.ordersCache[order]);
            //     return deferral.promise;
            // }

            if (undefined != customerNumber) {
                url += '?customer_number=' + customerNumber;
            }

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function (response) {
                var data = response.data;
                data.header.trackingUrl = self.getTrackingUrl(data);
                if (data.lines) {
                    angular.forEach(data.lines, function(item) {
                        item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer);
                        item.moreInfo = false;
                        if (item.webData && (
                                item.webData.siteUrl ||
                                item.webData.requiredDate ||
                                item.webData.lastPaidPrice ||
                                item.webData.longDescription ||
                                (item.webData.files && item.webData.files.length > 0)
                        )) {
                            item.moreInfo = true;
                        }
                    });
                }
                // disable caching for now 4-24-2020
                // $rootScope.ordersCache[order] = data;
                deferral.resolve(data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getOrderStatus = function (order) {
            var deferral = $q.defer(),
                url = API_URL + "/order/" + order + "/order-status";

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getPastOrders = function (id, filter, order_by, order_direction, limit, offset) {
            var deferral = $q.defer();

            if (id === undefined) {
                try {
                    id = UserService.getPersistedUser().id;
                } catch(e) {
                    deferral.resolve(false);
                    return deferral.promise;
                }
            }

            var url = API_URL + "/orders?user_id=" + id;

            if (undefined != filter) url += "&filter=" + filter;

            if (undefined != order_by) {
                url += "&order_by=" + order_by;
            } else {
                url += "&order_by=order_number";
            }

            if (undefined != order_direction) {
                url += "&order_direction=" + order_direction;
            } else {
                url += "&order_direction=DESC";
            }

            if (undefined != limit) {
                url += "&limit=" + limit;
            } else {
                url += "&limit=200";
            }

            if (undefined != offset) url += "&offset" + offset;
            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function(response){
                var array = [];
                angular.forEach(response.data.orders, function (order) {
                    array.push(order);
                });
                deferral.resolve(array);
            }, function () {
                deferral.resolve(false)
            });

            return deferral.promise;
        };

        self.submitOrderByToken = function (data, cart, requestId) {
            var deferral = $q.defer(),
                url = API_URL + "/order?cart=" + cart;
            if (undefined != requestId) {
                url += "&request_id=" + requestId;
            }

             $http({
                 method: "POST",
                 url: url,
                 headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                 data: data,
                 customErrorName: 'submitOrderByToken'
             }).then(function (response) {
                 self.formatEstimatedPrices(response.data);
                 deferral.resolve(response.data);
             }, function (err) {
                 deferral.reject(err);
             });

             return deferral.promise;
        };

        self.getExpressCheckout = function () {
            var deferral = $q.defer(),
                url = API_URL + "/order/expressCheckout/check";

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.resolve(err);
            });

            return deferral.promise;
        };

        self.expressCheckout = function (cart, params) {
            var deferral = $q.defer(),
                url = API_URL + "/order/expressCheckout/process?cart=" + cart;
            if (!params) {
                params = {};
            }

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(params)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.submitOrderByOpenBrowse = function (data, cart) {
            var deferral = $q.defer(),
                url = API_URL + "/order/openbrowse?cart=" + cart;

             $http({
                 method: "POST",
                 url: url,
                 data: JSON.stringify(data),
                 customErrorName: 'submitOrderByOpenBrowse'
             }).then(function (response) {
                 deferral.resolve(response.data);
             }, function (err) {
                 deferral.reject(err);
             });

             return deferral.promise;
        };

        self.createOrderSession = function () {
            var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var sessionId = '';
            for (var i = 32; i > 0; --i) sessionId += chars[Math.floor(Math.random() * chars.length)];
            return sessionId;
        };

        self.getOrderHistory = function (metaData) {
            var deferral = $q.defer(),
                url = API_URL + "/orders/history";

            var params = {};

            if (undefined != metaData.customerNumber) {
                params.customerNumber = metaData.customerNumber;
            }

            if (undefined != metaData.fromDate) {
                var fromDate = metaData.fromDate ? moment(metaData.fromDate).format("YYYY-MM-DD") : '';
                params.from = fromDate;
            }

            if (undefined != metaData.toDate) {
                var toDate = metaData.toDate ? moment(metaData.toDate).format("YYYY-MM-DD") : '';
                params.to = toDate;
            }

            if (metaData.transType) {
                params.transType = metaData.transType;
            }

            if (metaData.stageCd) {
                params.stageCd = metaData.stageCd;
            }

            if (metaData.filter) {
                params.filter = metaData.filter;
            }

            if (undefined != metaData.limit) {
                params.limit = metaData.limit;
            }

            if (undefined != metaData.page) {
                params.page = metaData.page;
            }

            if (undefined != metaData.shipTo) {
                params.shipTo = metaData.shipTo;
            }

            if (undefined != metaData.omitCancelled) {
                params.omitCancelled = metaData.omitCancelled;
            }

            if (undefined != metaData.userId && metaData.viewType && metaData.viewType === 'mine') {
                params.userId = metaData.userId;
            }

            $http({
                method: "POST",
                url : url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(params)
            }).then(function (response) {
                if (response.data && response.data.orders) {
                    angular.forEach(response.data.orders, function(order) {
                        order.totinvamt = parseFloat(order.totinvamt);
                        order.totlineord = parseFloat(order.totlineord);
                        if (!order.totinvamt) {
                            order.totinvamt = order.totlineord;
                        }
                    });
                }
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.getTrackingUrl = function (order) {
            var carrierCode = order.header.carrierCode;
            var trackingNum = order.header.proNumber;
            if (trackingNum && carrierCode) {
                var carrier = carrierCode
                if ('1Z' === trackingNum.substring(0, 2) || 'UPS' === carrier.substring(0, 3)) {
                    carrier = 'UPS';
                } else if ('FXF' === carrierCode.substring(0, 3)  || 'FedEx' === carrier.substring(0, 5)) {
                    carrier = 'FedEx';
                }

                switch (carrier) {
                    case 'UPS':
                        return 'https://www.ups.com/track?tracknum=' + trackingNum;
                    case 'FedEx':
                        return 'http://www.fedex.com/Tracking?tracknumbers=' + trackingNum;
                    case 'AAA-Coop PPD':
                        return 'http://www.aaacooper.com/Transit/ProTrackResults.aspx?ProNum=' + trackingNum;
                    case 'Dayton-PPD':
                        return 'https://tools.daytonfreight.com/tracking/detail/' + trackingNum;
                    case 'Estes-PPD':
                        return 'http://www.estes-express.com/cgi-dta/edn419.mbr/output?search_type=F&random_number&hash_value&search_criteria=' + trackingNum;
                    case 'PENINSULA TRUCKING':
                        // this takes you to a page where you can manually lookup the tracking info
                        return 'http://www.peninsulatruck.com/Tools/Track.aspx?id=' + trackingNum;
                    case 'S.EasternPPD':
                        // this takes you to a page where you can manually lookup the tracking info
                        return 'https://sefl.com/Tracing/?proNumber=' + trackingNum;
                    case 'SAIA Prepaid':
                        return 'https://www.saia.com/track/details;pro=' + trackingNum;
                    case 'Ylw-Rdwy-PPD':
                        return 'https://my.yrc.com/tools/track/shipments?referenceNumberType=PRO&referenceNumber=' + trackingNum;
                }
            }
            return null;
        };

        self.getWebOrders = function (id, customer_number) {
            var deferral = $q.defer();

            if (id === undefined) {
                try {
                    id = UserService.getPersistedUser().id;
                    customer_number = UserService.getPersistedUser().customer_number;
                } catch(e) {
                    deferral.resolve(false);
                    return deferral.promise;
                }
            }

            var url = API_URL + "/orders/web-orders?user_id=" + id;
            if (undefined != customer_number) url += "&customer_number=" + customer_number;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then( function(response){
                deferral.resolve(response ? response : []);
            }, function () {
                deferral.resolve(false)
            });

            return deferral.promise;
        };

        self.postConvertToOrder = function(accountingEntity, orderNumber, orderSuffix, poRequired) {
            var deferral = $q.defer();

            var data = {};
            data.accounting_entity = accountingEntity;
            data.order_number = orderNumber;
            data.order_suffix = orderSuffix;
            data.po_number = poRequired;

            $http({
                method: "POST",
                url: API_URL + "/order/convert_to_order",
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });
            return deferral.promise;
        };

        self.postQuoteQuantities = function(sxeQuote) {
            var deferral = $q.defer();
            var data = {};
            data.quote = sxeQuote;

            $http({
                method: "POST",
                url: API_URL + "/order/quote-quantities",
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err.data);
            });
            return deferral.promise;
        };

        self.checkOrderLimits = function(userId, customerNumber, cartTotal, itemTotals, department, approverMode) {
            var deferral = $q.defer();
            if (!cartTotal) { cartTotal = 0; }
            var subTotals = angular.copy(itemTotals);
            subTotals.cartTotal = cartTotal;

            var data = {
                sub_totals: subTotals
            };

            var url = API_URL + '/user-approval-workflow/check-limit/' + userId + '/' + customerNumber;
            if (department && department.id) {
                data.department = department.id;
            }
            if (approverMode) {
                data.approver = approverMode;
            }
            $http({
                method: "POST",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"},
                data: JSON.stringify(data)
            }).then( function(response){
                deferral.resolve(response ? response.data : []);
            }, function (err) {
                deferral.reject(err);
            });
            return deferral.promise;
        };

        self.reformatOrder = function(order) {
            var newOrder = {
                order_type: order.header.orderType.toLowerCase(),
                order_number: order.header.orderNumber,
                bill_to_address_1: order.header.customerAddress1,
                bill_to_address_2: order.header.customerAddress2,
                bill_to_city: order.header.billToCity,
                bill_to_state: order.header.billToState,
                bill_to_zip: order.header.billToZipCode,
                display_sales_amount: order.header.displayItemSalesAmount,
                display_total_order_value: order.header.displayTotalOrderValue,
                estimated_pricing: order.header.estimatedPricing,
                ship_to_company: order.header.shipToName,
                ship_to_address_1: order.header.shipToAddress1,
                ship_to_address_2: order.header.shipToAddress2,
                ship_to_city: order.header.shipToCity,
                ship_to_state: order.header.shipToState,
                ship_to_zip: order.header.shipToZipCode,
                sales_amount: order.header.itemSalesAmount,
                sales_tax_amount: order.header.salesTaxAmount,
                total_invoice_amount: order.header.invoiceAmount,
                total_units_amount: order.header.webData ? order.header.webData.units : 0
            };
            var order_lines = [];
            angular.forEach(order.lines, function(line) {
                order_lines.push({
                    line_number: line.lineNumer,
                    erp: line.itemNumber,
                    description: line.itemDescription1,
                    actual_sell_price: line.actualSellPrice,
                    display_price: line.displayPrice,
                    estimated_pricing: line.estimatedPricing,
                    unit_of_measure: line.unitOfMeasure,
                    units: line.webData ? line.webData.units : 0,
                    order_quantity: line.quantityOrdered,
                });
            });
            newOrder.order_lines = order_lines;

            return newOrder;
        };

        self.formatEstimatedPrices = function (orders) {
            if (!orders || !orders.length) { return; }
            var total = 0;
            angular.forEach(orders, function (o) {
                o.estimated_pricing = false;
                o.display_sales_amount = o.sales_amount;
                o.display_total_invoice_amount = o.total_invoice_amount;
                o.display_total_order_value = o.total_order_value;
                angular.forEach(o.order_lines, function (i) {
                    i.display_price = i.actual_sell_price;
                    i.estimated_pricing = false;
                    if (!i.actual_sell_price && i.last_paid) {
                        i.display_price = i.last_paid;
                        i.estimated_pricing = true;
                        o.estimated_pricing = true;
                    }
                    total += (i.display_price * i.order_quantity);
                });
                if (o.estimated_pricing) {
                    o.display_sales_amount = o.display_total_invoice_amount = o.display_total_order_value = total;
                }
            });
        };

        self.formatApprovalData = function (data, initiator) {
            var total = data.sub_total + data.tax + data.shipping;
            if (data.discount) {
                total = total - data.discount;
            }

            total = parseFloat(total).toFixed(2);

            var object = {
                token: data.token,
                card_number: null,
                cvv: null,
                order_type: data.order_type,
                amount: $filter('currency')(total, "").replace(",", ""),
                order_session: this.createOrderSession(),
                customer_number: data.customer_number ? data.customer_number : UserService.getCustomerNumber(),
                company_number: UserService.getCompanyNumber(),
                warehouse_key: data.warehouse_key ? data.warehouse_key : UserService.getCustomerWarehouse(),
                user_id: initiator.id,
                session_id: data.session_id,
                department: data.department,
                email: initiator.username,
                name: initiator.name,
                phone: initiator.phone,
                phone_extension: initiator.phone_extension ? initiator.phone_extension : null,
                bill_to_address_1: data.billing_info.billingAddress1,
                bill_to_address_2: data.billing_info.billingAddress2,
                bill_to_city: data.billing_info.billingCity,
                bill_to_state: data.billing_info.billingState,
                bill_to_zip: data.billing_info.billingZip,
                ship_to_company: data.shipping_info.shipToCompany ? data.shipping_info.shipToCompany : null,
                ship_to_id: data.shipping_info.shippingShipTo ? data.shipping_info.shippingShipTo : null,
                ship_to_address_1: data.shipping_info.shippingAddress1,
                ship_to_address_2: data.shipping_info.shippingAddress2,
                ship_to_city: data.shipping_info.shippingCity,
                ship_to_state: data.shipping_info.shippingState,
                ship_to_zip: data.shipping_info.shippingZip,
                header_properties: data.header_properties,
                customer_po_number: data.misc_info["Customer PO"] ? data.misc_info["Customer PO"] : null,
                geocode: data.shipping_info.geocode ? data.shipping_info.geocode.GeoCode : null,
                instructions: data.misc_info["Shipping Instructions"] ? data.misc_info["Shipping Instructions"] : null,
                comments: data.misc_info["Comments"] ? data.misc_info["Comments"] : null,
                order_lines: [],
                save_for_express: false,
                promo_codes: data.promo_codes
            };

            angular.forEach(data.cart_lines, function (v) {
                var i = {
                    actual_sell_price: v.price,
                    erp: v.erp,
                    iref: v.iref,
                    order_quantity: v.quantity,
                    unit_of_measure: v.uom,
                    unit_type: v.unit_type,
                    cpn: v.cpn,
                    description: v.description,
                    required_date: v.required_date,
                    site_url: v.site_url,
                    last_paid: v.last_paid,
                    files: v.files
                };
                var comments = [];
                if (v.manufacturer) comments.push('Manufacturer: ' + v.manufacturer);
                if (v.comment) comments.push('Additional Information: ' + v.comment);
                if (comments.length) i.comment = comments.join('\n');

                if (v.hasOwnProperty('apsv') && v.apsv !== null) i.apsv = v.apsv;
                if (v.hasOwnProperty('direct_ship') && v.direct_ship !== null) i.direct_ship = v.direct_ship;
                if (v.hasOwnProperty('line_properties') && v.line_properties !== null) i.line_properties = v.line_properties;

                object.order_lines.push(i);
            });
            return object;
        };
    }
]);
