(function () {
    'use strict'
}());

app.service('ScanToWebService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    '$uibModal',
    '$rootScope',
    '$window',
    'CompanyService',
    '$state',
    function ($http, $q, UserService, API_URL, $uibModal, $rootScope, $window, CompanyService, $state) {
        var base_url = API_URL + "/scan-to-web";

        this.getScans = function (params) {
            var defer = $q.defer();
            if(params === undefined) {
                params = {};
            }

            var queryString = Object.keys(params).map(function (key) {
                return key + '=' + encodeURIComponent(params[key])
            }).join('&');

            $http({
                url: base_url + '?' + queryString,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };
        
        this.getScanById = function (id, status) {
            var defer = $q.defer();
            var url = base_url + "?id=" + id;
            if (status !== undefined) {
                url += '&status=' + status;
            }
            $http({
                url: url,
                headers: { "Authorization" : "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.updateScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/" + scan.id;
            
            scan.items.forEach( function (item) {
                if (item.hasOwnProperty('specifications')) {
                    item.specifications.forEach(function (specification) {
                        delete specification["$$hashKey"];
                    });
                }
            })
            
            $http({
                url: url,
                method: 'PUT',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" },
                data: JSON.stringify(scan)
            }).then( function (response) {
                defer.resolve(response.data);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.removeScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/" + scan.id;
            $http({
                url: url,
                method: 'DELETE',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function () {
                defer.resolve(true);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.archiveScan = function (scan) {
            var defer = $q.defer();
            var url = base_url + "/archive/" + scan.id;
            $http({
                url: url,
                method: 'PUT',
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function () {
                defer.resolve(true);
            }, function () {
                defer.resolve(false);
            });

            return defer.promise;
        };

        this.s2wAlertModal = function (user, $scope, scan, s2wRedirect) {
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 's2wCustomerChangeModal.html',
                controller: 'Scan2WebAlertModalInstanceCtrl',
                windowClass: '',
                scope: $scope,
                size: 'lg'
            });
            modalInstance.result.then(function(resolve){
                if (resolve) {
                    $rootScope.$broadcast('open-loading-modal');
                    user.customer_number = $scope.s2wCustomerNumber;
                    CompanyService.getCompanySettings($scope.s2wCustomerNumber).then( function (data) {
                        user.catalog = (data) ? data.catalog : null;
                        UserService.putUser(user, user.id).then( function () {
                            UserService.getUser(user.id).then( function () {
                                $rootScope.$broadcast('close-loading-modal');
                                $window.sessionStorage.setItem('goToS2W', JSON.stringify(scan));
                                $window.location.reload();
                            }).catch(function(err) {
                                console.error(err);
                            });
                        });
                    });
                } else if (s2wRedirect) {
                    $state.go('account.scan_to_web');
                }
            }, function() {
                // modal dismissed
            });
        };


        // @todo come back later
        // this.patchReadState = function (scan) {
            // var url = base_url + "/read/" + scan.id;
            // $http({
                // url: url,
                // method: 'PATCH',
                // headers: {"Authorization": "Bearer {" + UserService.getToken() + "}" }
            // }).then( function () {
                // return true
            // });
        // };
    }
]);
