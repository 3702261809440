(function() {
    'use strict';
}());

app.service ('CSVService', [
    '$http',
    '$q',
    function ($http, $q) {
        var self = this;

        this.CSVToArray = function(strData, strDelimiter){
            strDelimiter = (strDelimiter || ",");

            var objPattern = new RegExp((
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ), "gi");

            var arrData = [[]];
            var arrMatches;
            while (arrMatches = objPattern.exec(strData)) {
                var strMatchedDelimiter = arrMatches[1];
                if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                    arrData.push([]);
                }

                var strMatchedValue;
                if (arrMatches[2]){
                    strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"");
                } else {
                    strMatchedValue = arrMatches[3];
                }

                arrData[arrData.length - 1].push(strMatchedValue);
            }

            return(arrData);
        };

        this.loadCSV = function (path) {
            var deferral = $q.defer();

            $http({
                method: 'GET',
                url: path,
                cache: true
            }).then(function (response) {
                try {
                    var parsed = self.CSVToArray(response.data, ',');
                    deferral.resolve(parsed);
                } catch (err) {
                    deferral.reject(err);
                }
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        }
    }
]);