(function () {
    'use strict'
}());

app.service('UIService', [
    'DOMAIN',
    'UserService',
    '$q',
    '$rootScope',
    function (DOMAIN, UserService, $q, $rootScope) {
        this.smoothScrollTo = function(eID) {
            var startY = currentYPosition();
            var stopY = elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            
            if (distance < 100) {
                scrollTo(0, stopY); return;
            }
            
            var speed = Math.round(distance / 100);
            if (speed >= 60) speed = 60;
            if (speed < 20)  speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (var i=startY; i<stopY; i+=step) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for ( var i=startY; i>stopY; i-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }

            function currentYPosition() {
                // Firefox, Chrome, Opera, Safari
                if (self.pageYOffset) return self.pageYOffset;
                // Internet Explorer 6 - standards mode
                if (document.documentElement && document.documentElement.scrollTop)
                    return document.documentElement.scrollTop;
                // Internet Explorer 6, 7 and 8
                if (document.body.scrollTop) return document.body.scrollTop;
                return 0;
            }

            function elmYPosition(eID) {
                var elm = document.getElementById(eID);
                var y = elm.offsetTop;
                var node = elm;
                while (node.offsetParent && node.offsetParent != document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                } return y;
            }

        };

        this.isImage = function (src) {
            var deferred = $q.defer();

            var image = new Image();
            image.onerror = function () {
                deferred.resolve(false);
            };
            image.onload = function () {
                deferred.resolve(true);
            };
            image.src = src;

            return deferred.promise;
        };

        this.setLogo = function (scope) {
            if (DOMAIN === 'boringsmith') {
                scope.logo = '/assets/images/logo.boring-smith.header.png';
                scope.navLogo = '/assets/images/logo.boring-smith.navbar.png';
                scope.logoAlt = 'Shop Boring-Smith';
            } else if (UserService.getIsVallenXpress()) {
                scope.logo = '/assets/images/logo.vallen-xpress.jpg';
                scope.navLogo = '/assets/images/logo.vallen-xpress.jpg';
                scope.logoAlt = 'Shop Vallen Supply';
            } else {
                scope.logo = '/assets/images/logo.vallen.header.png';
                scope.navLogo = $rootScope.s3Resources + '/header/logo.vallen.nav.png';
                scope.logoAlt = 'Shop Vallen Supply';
            }
        };
    }
]);
