(function () {
    'use strict';
}());

app.service('ShopperApprovedService', [
    '$rootScope',
    '$http',
    '$q',
    'API_URL',
    function ($rootScope, $http, $q, API_URL) {
        this.getReviews = function (max_reviews) {
            var url = API_URL + '/shopper-approved-reviews?max_reviews=' + max_reviews;
            var deferral = $q.defer();
            $http.defaults.useXDomain = true;
            $http({
                method: "GET",
                url: url
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false)
            });

            return deferral.promise;
        };
    }
]);