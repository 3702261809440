(function () {
    'use strict';
}());

app.controller('SupportController', [
    '$scope',
    '$location',
    '$uibModal',
    '$anchorScroll',
    function ($scope, $location, $uibModal, $anchorScroll) {
        $scope.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        };

        $scope.openFeedbackModal = function () {
            $scope.openContactModal('feedback');
        };
    }
]);
