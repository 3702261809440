(function () {
    'use strict';
}());

app.service('OrderPadService', [
    'API_URL',
    '$http',
    '$q',
    '$window',
    'UserService',
    function (API_URL, $http, $q, $window, UserService) {
        var self = this;
        var __construct = function () {
            var orderLines = $window.sessionStorage.getItem("order_lines");

            if (null === orderLines) {
                $window.sessionStorage.setItem("order_lines", JSON.stringify([]));
                orderLines = JSON.parse($window.sessionStorage.getItem("order_lines"));
            } else {
                orderLines = JSON.parse($window.sessionStorage.getItem("order_lines"));
            }

            return orderLines;
        };

        self.getSessionId = function () {
            var session_id = ($window.localStorageOverride["session_id"]) ?
                JSON.parse($window.localStorageOverride["session_id"]): false;

            if (UserService.getPersistedUser()) {
                session_id = UserService.getPersistedUser().session_id;
            }
            return session_id;
        };

        self.freshLine = function () {
            return {
                erp: "",
                cpn: "",
                iref: "",
                image: null,
                description: "",
                quantity: 1,
                set: 0
            }
        };

        self.refreshOrderLines = function (orderLines) {
            var arr = [];
            var index = 0;
            var countLines = self.countOrderLines(orderLines);
            if (countLines) {
                angular.forEach(orderLines, function (line) {
                    line.set = 2;
                    line.index = index;
                    arr.push(line);
                    index++;
                });
            }

            if (arr.length < 5) {
                var count = 5 - arr.length;
                for (var i = 0; i < count; i++) {
                    var line = self.freshLine();
                    line.index = index;
                    arr.push(line);
                    index++;
                }
            } else {
                line = self.freshLine();
                line.index = index;
                index++;
                arr.push(line);
            }

            return arr;
        };


        self.gatherOrderLines = function (lines) {
            var deferral = $q.defer();
            var lineArr = [];

            angular.forEach(lines, function (line) {
                if (line && line.partNumber) {
                    var obj = {
                        type: line.type ? line.type : null,
                        cpn: line.cpn,
                        erp: line.erp,
                        quantity: line.quantity,
                        partNumber: line.partNumber
                    };
                    lineArr.push(obj);
                }
            });

            var user = UserService.getPersistedUser() || false;

            var data = {};
            data.session_id = self.getSessionId();
            data.user_id = user.id || null;
            data.parts = JSON.stringify(lineArr);

            $http({
                method: "POST",
                url: API_URL + "/order-pad/validate",
                data: JSON.stringify(data)
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        var setOrderLine = function (key, value) {
            if (value) {
                var orderLines = __construct();
                if (orderLines && !orderLines.push) {
                    orderLines = [];
                }

                orderLines.push(value);
                $window.sessionStorage.setItem("order_lines", JSON.stringify(orderLines));
            }
            return JSON.parse($window.sessionStorage.getItem("order_lines"));
        };

        self.setOrderLine = function (key, value) {
            return setOrderLine(key, value);
        };

        self.getOrderLines = function () {
            var defer = $q.defer();
            var session_id = self.getSessionId();

            if (!session_id) {
                defer.resolve(false);
                return defer.promise;
            }

            var url = API_URL + "/order-pad/" + session_id;

            $http({
                url: url,
                method: "GET"
            }).then( function (response) {
                defer.resolve(response.data.items);
            }, function () {
                defer.resolve(false)
            });

            return defer.promise;
        };

        self.countOrderLines = function (lines) {
            return (lines) ? lines.length : 0;
        };

        self.deleteOrderLine = function (key, lines) {
            var deferral = $q.defer();
            lines.sort(function(a, b) {
                return a.order - b.order;
            });
            lines.splice(key, 1);
            deferral.resolve(lines);

            return deferral.promise;
        };

        self.deleteOrderLines = function () {
            $window.sessionStorage.setItem("order_lines", JSON.stringify({}));
            return true;
        };

        self.deleteOrderPad = function () {
            var defer = $q.defer();
            var session_id = self.getSessionId();

            if (!session_id) {
                defer.resolve(false);
                return defer.promise;
            }

            var url = API_URL + "/order-pad/" + session_id;

            $http({
                url: url,
                method: "DELETE"
            }).then( function () {
                defer.resolve(true);
                self.deleteOrderLines();
            }, function () {
                defer.resolve(false)
            });

            return defer.promise;
        };

        self.parseOrderLines = function (string) {
            var deferral = $q.defer();
            Papa.parse(string, {
                complete: function (results) {
                    var lines = [];
                    angular.forEach(results.data, function (data) {
                        data.line = {
                            term: data[0].trim(),
                            partNumber: data[0].trim(),
                            quantity: (data[1] !== undefined) ? parseFloat(data[1]) : 1
                        };
                        lines.push(data.line);
                    });
                    deferral.resolve(lines);
                }
            });

            return deferral.promise;
        };
    }
]);
