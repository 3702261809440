(function () {
    'use strict';
}());

app.controller('LocationsController', [
    '$rootScope',
    '$scope',
    '$http',
    function($rootScope, $scope, $http) {
        $scope.loading = true;
        $scope.query = '';
        $scope.data = [];

        $http.get('/assets/data/locations.json').then(function(locations) {
            $scope.data = locations.data;
            $scope.data.sort(function(a, b) {
                return (a.state > b.state) ? 1 : (a.state === b.state) ? ((a.city > b.city) ? 1 : -1) : -1;
            });

            angular.forEach($scope.data, function(row) {
                var mapParams = row.addr1 + ' ' + row.city + ', ' + row.state + ' ' + row.zip;
                row.mapUrl = 'https://www.google.com/maps/place/' + encodeURIComponent(mapParams);

                var data = angular.copy(row);
                delete data.mapUrl;
                delete data.country;
                row.lookUp = Object.values(data).join(' ').toLowerCase();
            });
        }).catch(function(err) {
            console.error(err);
            $rootScope.$broadcast('notify', {message: "Failed to load data."});
        }).finally(function() {
            $scope.loading = false;
        });

        $scope.showLocation = function(row) {
            var query = $scope.query.toLowerCase().trim();
            return !query || (row.lookUp && row.lookUp.includes(query));
        };
    }
]);
