(function () {
    'use strict';
}());

app.directive('background', [
    function () {
        return function (scope, element, attrs) {
            attrs.$observe('background', function (value) {
                element.css({
                    'background-image': 'url(' + value + ')'
                });
            });
        }
    }
]);