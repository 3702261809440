(function () {
    'use strict';
}());

app.service('PricingService', [
    '$http',
    '$q',
    '$filter',
    'UserService',
    'CompanyService',
    'API_URL',
    'lodash',
    function ($http, $q, $filter, UserService,CompanyService, API_URL, lodash) {
        this.getPricing = function (items, specifyUom, showAll) {
            var formattedItems = [];
            var response;
            var deferral = $q.defer();
            var customerNumber = UserService.getCustomerNumber();
            var whse = UserService.getCustomerWarehouse();
            var defaultWhse = null;
            var i;
            angular.forEach(items, function (item) {
                if (item.erp) {
                    i = encodeURIComponent(item.erp);
                    if (item.uom && specifyUom) {
                        i += '|' + item.uom;
                    }
                    formattedItems.push(i);
                }
            });
            formattedItems = lodash.uniq(formattedItems);
            response = CompanyService.getCompanySettings(customerNumber).then(function(settings){
                if(settings && settings.item_whse.length !== 0){
                    defaultWhse = whse;
                    whse = settings.item_whse;
                }
            }, function(){
                // no settings for this account
            });

            response.finally(function() {
                var url = API_URL + "/pricing?customer_number=" + customerNumber + "&warehouse_key=" +
                    whse + "&erp_item_numbers=" + formattedItems.join(',');

                if (null !== UserService.getDefaultShipTo()) {
                    url += "&ship_to=" + UserService.getDefaultShipTo();
                }

                var userId = UserService.getUserId();
                if (userId) {
                    url += "&user_id=" + userId;
                }
                if (showAll) {
                    url += "&all=true";
                }
                if (defaultWhse) {
                    url += "&default_warehouse=" + defaultWhse;
                }
                if (!formattedItems.length) {
                    deferral.resolve(false);
                    return deferral.promise;
                }

                $http({
                    method: "GET",
                    url: url
                }).then(function (response) {
                    var data = response.data;
                    //Filter out duplicate prices.
                    if (data.length) {
                        var parts = data[0];
                        angular.forEach(parts, function(part) {
                            var filtered = [];

                            if (!part.pricing || !part.pricing.length || part.pricing[0].length === 0) {
                                part.pricing = [];
                            }

                            if (part.price && part.uom) {
                                var defaultPrice = {
                                    default: true,
                                    defined_display: part.uom,
                                    display: part.display,
                                    price: part.price,
                                    uom: part.uom
                                };

                                if (part.pricing.length > 0) {
                                    var priceMatch = $filter('filter')(part.pricing, {'price': part.price, 'uom': part.uom}, true);
                                    if (priceMatch[0]) {
                                        defaultPrice = priceMatch[0];
                                    }
                                }
                                part.pricing.unshift(defaultPrice);
                            }

                            angular.forEach(part.pricing, function(pricing) {
                                if(filtered.indexOf(pricing) === -1){
                                    filtered.push(pricing);
                                }
                            });
                            part.pricing = filtered;
                        });
                    }
                    deferral.resolve(data);
                }).catch(function () {
                    // no pricing found - do not return any data
                    deferral.resolve(false);
                });
            }, function () {
                deferral.resolve(false);
            });
            return deferral.promise;
        };

        this.getShippingAndTax = function (data) {
            var url = API_URL + "/order/quote";
            var deferral = $q.defer();
            $http({
                method: "POST",
                url: url,
                data: data
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };
    }
]);
