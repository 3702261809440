(function() {
    'use strict';
}());


app.service('AnalyticsService', [
    '$location',
    '$window',
    'UserService',
    '$rootScope',
    function($location, $window, UserService, $rootScope) {

        var self = this;

        this.taxCost = 0;
        this.shippingCost = 0;
        this.discountAmount = 0;
        this.woopraObject = {};

        this.identifyUser = function (UserObject) {
            var gaUser = {
                customerNumber: UserService.getCustomerNumber(),
                isPunchout: $rootScope.punchout,
                name: null,
                userId: null,
                userName: null,
            };
            if (UserObject) {
                gaUser.name = UserObject.name;
                gaUser.userName = UserObject.email;
                if(window.woopra) {
                    woopra.identify({
                        name: gaUser.name,
                        phone: UserObject.phone,
                        email: gaUser.userName,
                        company: UserObject.company
                    });
                }
            } else {
                var user = UserService.getPersistedUser();
                if (user) {
                    gaUser.customerNumber = user.customer_number;
                    gaUser.name = user.name;
                    gaUser.userId = user.id;
                    gaUser.userName = user.username;

                    if(window.woopra) {
                        woopra.identify("email", gaUser.userName).push()
                    }
                }
            }

            ga('set', 'dimension2', gaUser.userId);
            ga('set', 'dimension3', gaUser.userName);
            ga('set', 'dimension5', gaUser.customerNumber);
            ga('set', 'dimension6', gaUser.isPunchout);
            ga('set', 'dimension7', gaUser.name);
        };

        this.addToCart = function (v, qty) {
            var product = {
                'id': v.product_id ? v.product_id : '',
                'name': v.description,
                'brand': v.vendor,
                'variant': v.erp,
                'price': v.price,
                'quantity': qty ? qty : v.quantity
            };

            if (v.taxonomy)
                product.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;

            ga('ec:addProduct', product);
            ga('ec:setAction', 'add');
            ga('send', 'event', 'Cart', 'click', 'add to cart');

            if (window.woopra) {
                var woopraObject = {
                    cart_price: v.price * (qty ? qty : v.quantity),
                    card_description: v.description,
                    cart_erp: v.erp,
                    cart_quantity: qty ? qty : v.quantity,
                    cart_uom: v.uom
                };

                window.woopra.track('add to cart', woopraObject);
            }
        };


        this.removeFromCart = function (v, qty) {

            var product = {
                'id': v.product_id ? v.product_id : '',
                'name': v.description,
                'brand': v.vendor,
                'variant': v.erp,
                'price': v.price,
                'quantity': qty ? qty : v.quantity

            };
            if (v.taxonomy)
                product.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;

            ga('ec:addProduct', product);
            ga('ec:setAction', 'remove');
            ga('send', 'event', 'Cart', 'click', 'remove from cart');

            if (window.woopra) {

                var woopraObject = {
                    cart_price: v.price * (qty ? qty : v.quantity),
                    card_description: v.description,
                    cart_erp: v.erp,
                    cart_quantity: qty ? qty : v.quantity,
                    cart_uom: v.uom
                };

                window.woopra.track('remove from cart', woopraObject);

            }

        };

        this.cartFunnel = function (cart, step) {
            if (!cart || !cart.cart_lines) {
                return;
            }

            angular.forEach(cart.cart_lines, function (v) {
                var product = {
                    'id': v.product_id ? v.product_id : '',
                    'name': v.description,
                    'brand': v.vendor,
                    'variant': v.erp,
                    'price': v.price,
                    'quantity': v.quantity

                };
                if (v.taxonomy) {
                    product.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;
                }

                ga('ec:addProduct', product);
            });

            ga('ec:setAction','checkout', {
                'step': step
            });

            if (window.woopra) {
                window.woopra.track('cart'+step);
            }
        };

        this.purchase = function (cart, order) {

            var woopraProducts = "";
            angular.forEach(order.order_lines, function (v, i) {
                angular.forEach(cart.cart_lines, function (c) {
                    if (c.erp===v.erp) {
                        v.product_id = c.product_id;
                        v.image = c.image;
                        v.vendor = c.vendor;
                    }
                });

                // woopra

                if (window.woopra) {
                    var qty = v.order_quantity;
                    var productName = v.description;
                    var itemNumber = v.erp;
                    var base = (i+1) + ': qty: ' + qty + " " + productName + ' erp: ' + itemNumber;
                    var delimiter = ', | ';
                    if (i!==0) {
                        var product = delimiter + base;
                    } else {
                        var product = base;
                    }
                    woopraProducts += product;

                }

                // ga
                var GAproduct = {
                    'id': v.product_id ? v.product_id : '',
                    'name': v.description,
                    'brand': v.vendor,
                    'variant': v.erp,
                    'price': v.actual_sell_price,
                    'quantity': v.order_quantity

                };
                if (v.taxonomy)
                    product.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;

                ga('ec:addProduct', GAproduct);

            });

            // woopra purchase
            if (window.woopra) {
                var woopraObject = {
                    order_number: order.order_number,
                    cart_price: order.total_order_value,
                    products: woopraProducts,
                    direct_ship:order.order_type==='d'?'true':'false'
                };

                window.woopra.track('cart6', woopraObject);
            }

            // google purchase
            ga('ec:setAction', 'purchase', {
                'id': order.order_number,
                'affiliation': order.order_system,
                'revenue': order.total_order_value,
                'tax': self.taxCost,
                'shipping': self.shippingCost,
                'direct_ship': order.order_type==='d'?'true':'false',
                'discount': self.discountAmount
            });
        };

        this.productClick = function (v, index) {

            var click = {
                'id': v.product_id,
                'name': v.vendor.name + " " + v.heading,
                'brand': v.vendor.slug,
                'position': index
            };

            if (v.taxonomy)
                click.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;

            ga('ec:addProduct', click);
            ga('ec:setAction', 'click', {list: window.location.hash});
            ga('send', 'event', 'UX', 'click');
        };

        this.productDetail = function (v) {
            var product = {
                'id': v.product_id ? v.product_id : '',
                'name': v.vendor.name + " " + v.heading,
                'brand': v.vendor.slug
            };

            if (v.taxonomy) {
                product.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;
            }

            ga('ec:addProduct', product);
            ga('ec:setAction', 'detail');
        };

        this.productImpressions = function (products, listName) {
            var productIndex = 0;
            angular.forEach(products, function (v) {
                var impression = {
                    'id': v.product_id,
                    'name': v.vendor.name + " " + v.heading,
                    'brand': v.vendor.slug,
                    'list': listName,
                    'position': productIndex
                };

                if (v.taxonomy)
                    impression.category = v.taxonomy.department.name + '/' + v.taxonomy.category.name;

                productIndex ++;

                ga('ec:addImpression', impression);
            });

            ga('send', 'pageview');
        };

        this.getUrlParameters = function () {
            if (window.location.href.includes("?")) {
                var array = window.location.href.split("?");
                var parameters = array[1].split("&");
                if (parameters.length) {
                    o = {};
                    angular.forEach(parameters, function (p) {
                        var parameter = p.split("=");
                        o[parameter[0]] = parameter[1];
                    });
                }
                return o;
            }

        };

        this.search = function (params, results) {
            if (window.woopra) {
                if (params.term) {
                    // search query
                    var woopraObject = {
                        search_term: params.term,
                        results: results
                    };

                    window.woopra.track('search', woopraObject);
                } else if (!params.term && (params.category||params.department||params.style||params.type||params.vendor)) {
                    // catalog search
                    var woopraObject = {
                        results: results
                    };

                    var parameters = ['category','department','style','type','vendor'];
                    angular.forEach(parameters, function (p) {
                        if (params[p]) {woopraObject[p] = params[p]}
                    });
                    window.woopra.track('catalog', woopraObject);
                }

            }
        };
        this.sendPageView = function () {
            self.identifyUser();

            ga('send', 'pageview', {
                page: $location.url()
            });

            if (window.woopra) {
                woopra.track();
            }
        };
    }
]);
