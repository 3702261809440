(function () {
    'use strict';
}());

app.directive('noDirty', [
    function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                ngModelCtrl.$setDirty = angular.noop;
            }
        }
    }
]);