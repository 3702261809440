(function () {
    'use strict';
}());

app.service('PunchoutService', [
    '$http',
    '$q',
    'UserService',
    'API_URL',
    function ($http, $q, UserService, API_URL) {
        var self = this;

        self.logPunchout = function (cart) {
            var defer = $q.defer();

            var orderNumbers = cart.cart_lines
                .map(function (a) {
                    return a.order_number;
                })
                .filter(function (v, i, arr) {
                    return v && arr.indexOf(v) === i;
                });

            if (orderNumbers.length) {
                var promises = [];
                var data = {}
                angular.forEach(orderNumbers, function (order_number) {
                    data = {
                        "customer_number": cart.customer_number,
                        "order_number": order_number
                    };
                    promises.push($http({
                            method: "POST",
                            url: API_URL + "/punchout-log",
                            headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                            data: data
                        })
                    );
                });
                $q.all(promises).then(defer.resolve).catch(function (err) {
                    defer.reject(err);
                });
            } else {
                defer.resolve();
            }
            return defer.promise;
        };

        self.getPunchoutLog = function (customer_number) {
            var defer = $q.defer();
            $http({
                method: "GET",
                url: API_URL + "/punchout-log?limit=250&customer_number=" + customer_number,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var orderNumbers = response.data.data
                    .map(function (a) {
                        return a.order_number;
                    })
                    .filter(function (v, i, arr) {
                        return arr.indexOf(v) === i;
                    });
                defer.resolve(orderNumbers);
            }).catch(function () {
                // no records found
                defer.resolve();
            });
            return defer.promise;
        };
    }
]);
