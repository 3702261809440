app.controller('WelcomeController', [
    '$rootScope',
    '$scope',
    '$stateParams',
    'UserService',
    'AccountService',
    '$state',
    '$window',
    function ($rootScope, $scope, $stateParams, UserService, AccountService, $state, $window) {
        $scope.data = {};

        AccountService.initPasswordValidator($scope);
        $scope.passwordKeyUp();

        $scope.reset = function() {
            $rootScope.$broadcast('open-loading-modal');
            $scope.user = JSON.parse($window.localStorageOverride.user);
            $scope.user.password = $scope.data.password;
            $scope.user.update_password = $scope.data.update_password;
            UserService.resetPasswordAuthenticated($scope.user, $scope.user.id).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data.status === 200) {
                    $rootScope.$broadcast('notify', { message: "Password successfully updated." });
                    $state.go('account_password_reset_welcome_confirmed');
                } else {
                    angular.forEach(data.data, function (v) {
                        if (undefined !== v) {
                            $rootScope.$broadcast('notify', { message: v });
                        }
                    });
                }
            });
        };

        try {
            $scope.user = JSON.parse($window.localStorageOverride.user);
        } catch (e) {}

        function setRandomPassword() {
            $scope.data.password = Math.random().toString(36);
            $scope.user = JSON.parse($window.localStorageOverride.user);
            $scope.user.password = $stateParams.p;
            $scope.user.update_password = $scope.data.password;
            $window.localStorageOverride['welcomeResetP'] = $scope.user.update_password;
            UserService.resetPasswordAuthenticated($scope.user, $scope.user.id).then(function (data) {
                if (data.status !== 200) {
                    console.error('Failed to set password.');
                }
            });
        }

        if ($stateParams.u && $stateParams.p) {
            if ($scope.user && $scope.user.username === $stateParams.u && $window.localStorageOverride['welcomeResetP']) {
                $scope.data.password = $window.localStorageOverride['welcomeResetP'];
            } else {
                UserService.login($stateParams.u, $stateParams.p, true).then(function (res) {
                    if (res) {
                        $scope.data.password = $stateParams.p;
                        //setRandomPassword();
                    } else {
                        $rootScope.$broadcast('notify', {message: "Link has expired, please reset your password."});
                        $state.go('account_password_recovery');
                    }
                }, function () {
                    $rootScope.$broadcast('notify', {message: "Link has expired, please reset your password."});
                    $state.go('account_password_recovery');
                });
            }
        } else {
            $state.go('home');
        }
    }
]);
