(function() {
    'use strict';
}());

app.service ('FooterService', [
    'DOMAIN',
    function (DOMAIN) {

        this.getMenu = function () {
            var menu = {
                about_us: [],
                resources: [],
                customer_service: [
                    {
                        title: 'Support / Feedback',
                        route: 'support'
                    },
                    {
                        title: 'Shipping Information',
                        route: 'shipping_information'
                    },
                    {
                        title: 'Return Policy',
                        route: 'return_policy'
                    },
                ],
            };


            if(DOMAIN === 'vallen'){
                menu.about_us = [
                    {
                        title: 'Careers',
                        route: 'careers'
                    },
                    {
                        title: 'About Vallen',
                        route: 'wordpress({path: \'aboutus\'})'
                    },
                    {
                        title: 'Locations',
                        route: 'locations'
                    },
                    {
                        title: 'Privacy Policy',
                        route: 'privacy_policy'
                    },
                    {
                        title: 'Terms & Conditions',
                        route: 'termsandconditions'
                    },
                    {
                        title: 'Compliance',
                        route: 'wordpress({path: \'aboutus#Compliance\'})'
                    },
                    {
                        title: 'VICKIE - Women\'s ERG',
                        route: 'wordpress({path: \'vickie\'})'
                    },
                    {
                        title: 'BERG – Black ERG',
                        route: 'wordpress({path: \'berg\'})'
                    },
                    {
                        title: 'SALUTE – Veteran ERG',
                        route: 'wordpress({path: \'salute\'})'
                    }
                ];

                menu.resources = [
                    {
                        title: 'W9 Downloads',
                        route: 'resources.w9_downloads'
                    },
                    {
                        title: 'Resale Exemption Certificates',
                        route: 'resources.resale_exemption_certificates'
                    },
                    {
                        title: 'Supplier Routing Guide',
                        route: 'resources.supplier_routing_guide'
                    },
                    {
                        title: 'ISO-9001 Certificate',
                        route: 'resources.iso_certificate'
                    },
                    {
                        title: 'Credit Application',
                        route: 'account_create.customer'
                    },
                    {
                        title: 'Vallen.com Help Videos',
                        route: 'wordpress({path:\'helpvideos\'})',
                        loginRequired: true
                    }
                ];
            } else if (DOMAIN === 'boringsmith') {
                menu.about_us = [
                    {
                        title: 'About Boring-Smith',
                        route: 'about'
                    },
                    {
                        title: 'Privacy Policy',
                        route: 'privacy_policy'
                    },
                    {
                        title: 'Terms & Conditions',
                        route: 'termsandconditions'
                    }
                ];

                menu.resources = [
                    {
                        title: 'W9 Downloads',
                        route: 'resources.w9_downloads'
                    },
                    {
                        title: 'Resale Exemption Certificates',
                        route: 'resources.resale_exemption_certificates'
                    },
                    {
                        title: 'Supplier Routing Guide',
                        route: 'resources.supplier_routing_guide'
                    }
                ];

            }
            menu.resources.push({
                title: 'Coronavirus Update',
                route: 'wordpress({path: \'coronavirus\'})'
            });

            //if (DOMAIN === 'vallen'){
            //    menu.resources.push({
            //        title: 'Year End Blowout Sale',
            //        route: 'resources.2021_blow_out_sale'
            //    });
            //}
            return menu;

        };

        this.getCompanyDescription = function () {
            var description = '';
            switch (DOMAIN) {
                case 'boringsmith':
                    description = 'We are a leading distributor of MROP products to the Manufactured Housing, Recreational Vehicle, Commercial Modular, Cargo Trailer, Specialty Vehicle and Recreational Marine industries.';
                    break;
                case 'vallen':
                default:
                    description = 'Vallen is the Market Leader for Industrial Distribution and Supply Chain Solutions.';
            }
            return description;
        }

        this.getSocialMedia = function () {
            var social = [];

            if(DOMAIN === 'vallen'){
                social = [
                    {
                        href: 'https://twitter.com/VallenUSA',
                        fa_icon: 'fa-twitter'
                    },
                    {
                        href: 'https://www.linkedin.com/company/vallenusa',
                        fa_icon: 'fa-linkedin'
                    },
                    {
                        href: 'https://www.facebook.com/vallenusa',
                        fa_icon: 'fa-facebook'
                    },
                ];
            }

            return social;
        }
    }
]);
