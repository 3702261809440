(function () {
    'use strict';
}());

app.service('InvoiceService', [
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    'API_URL',
    'Blob',
    'FileSaver',
    'UserService',
    'UtilityService',
    function ($rootScope, $http, $location, $q, $state, API_URL, Blob, FileSaver, UserService, UtilityService) {

        var self = this;
        var _getInvoicesBase = function (
            customerNumber,
            fromDate,
            toDate,
            rangeType,
            limit,
            offset,
            filter,
            url,
            collectionName
        ) {
            var deferral = $q.defer();


            offset = offset || 1;
            limit = limit || 50;

            url = API_URL + url;

            // todo: this code duplicates in many places, need to create a helper
            var params = [];

            if (undefined != customerNumber) {
                params.push('customerNumber=' + customerNumber);
            }

            if (undefined != fromDate) {
                params.push('from=' + fromDate);
            }

            if (undefined != toDate) {
                params.push('to=' + toDate);
            }

            if (undefined != limit) {
                params.push('limit=' + limit);
            }

            if (undefined != offset) {
                params.push('offset=' + offset);
            }

            if (undefined != filter) {
                params.push('filter=' + filter);
            }

            if (rangeType == 'dueDate') {
                params.push('type=aging');
            }

            var queryParams = params.join('&');

            url += '?' + queryParams;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var data = response.data;
                var result = [];
                if (!data[collectionName].isArray) {
                    for (var i in data[collectionName]) {
                        var fullInvoiceInfo = data[collectionName][i];
                        var invoiceInfo = fullInvoiceInfo.invoice;
                        if (!invoiceInfo) {
                            continue;
                        }

                        if (collectionName == 'paid_invoices') {
                            invoiceInfo.lastTransactionDate
                                = fullInvoiceInfo.lastTransactionDate || fullInvoiceInfo.transactionDate;
                        } else {
                            invoiceInfo.lastTransactionDate = fullInvoiceInfo.lastTransactionDate;
                        }

                        invoiceInfo.ageDate = fullInvoiceInfo.ageDate;
                        invoiceInfo.invoiceDate = fullInvoiceInfo.invoiceDate;
                        result.push(invoiceInfo);
                    }
                }

                deferral.resolve(result);
            }, function () {
                deferral.reject();
            });

            return deferral.promise;
        };

        self.getPaidInvoices = function (customerNumber, fromDate, toDate, rangeType, limit, offset, filter) {
            return _getInvoicesBase(
                customerNumber,
                fromDate,
                toDate,
                rangeType,
                limit,
                offset,
                filter,
                '/invoices/paid',
                'paid_invoices'
            );
        };

        self.getOpenInvoices = function (customerNumber, fromDate, toDate, rangeType, limit, offset, filter) {
            return _getInvoicesBase(
                customerNumber,
                fromDate,
                toDate,
                rangeType,
                limit,
                offset,
                filter,
                '/invoices/open',
                'open_invoices'
            );
        };

        self.sendEmailInvoice = function (
            orderNumber,
            customerNumber,
            userEmail
        ) {

            var deferral = $q.defer();

            var url = API_URL + "/invoices/send_invoice_email";

            var params = [];

            if (undefined != orderNumber) {
                params.push('orderNumber=' + orderNumber);
            }

            if (undefined != customerNumber) {
                params.push('customerNumber=' + customerNumber);
            }

            if (undefined != userEmail) {
                params.push('userEmail=' + userEmail);
            }

            var queryParams = params.join('&');

            url += '?' + queryParams;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response);
            }, function () {
                deferral.reject('Unable to find order.');
            });

            return deferral.promise;
        };

        self.getSummaryInvoices = function (custno, limit, page, options) {
            var deferral = $q.defer();
            limit = limit || 25;
            page = page || 1;

            var params = [];
            params.push('page=' + page);
            params.push('limit=' + limit);
            if (options) {
                if (options.filter) {
                    params.push('filter=' + encodeURIComponent(options.filter));
                }
                if (options.status) {
                    params.push('status=' + options.status);
                }
                if (options.orderBy) {
                    params.push('orderBy=' + options.orderBy);
                }
                if (options.fromDate) {
                    params.push('startDate=' + moment(options.fromDate).format("YYYY-MM-DD"));
                }
                if (options.toDate) {
                    params.push('endDate=' + moment(options.toDate).format("YYYY-MM-DD"));
                }
            }
            var url = API_URL + '/summary-invoices/list/' + custno + '?' + params.join('&');

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var data = response.data;
                deferral.resolve(data);
            }, function () {
                deferral.reject();
            });

            return deferral.promise;
        };

        self.getSummaryInvoiceDetails = function (billno, groupno, cost_center, limit, page) {
            var deferral = $q.defer();

            limit = limit || 25;
            page = page || 1;

            var params = [];
            params.push('page=' + page);
            params.push('limit=' + limit);
            if (cost_center) {
                params.push('cost_center=' + cost_center);
            }
            var url = API_URL + '/summary-invoices/detail/' + billno + '/' + groupno + '?' + params.join('&');

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var data = response.data;
                deferral.resolve(data);
            }, function () {
                deferral.reject();
            });

            return deferral.promise;
        };

        self.downloadSummaryInvoiceDetails = function (invoice_lines) {
            if (!invoice_lines || invoice_lines.length === 0) {
                return;
            }
            $rootScope.$broadcast('open-loading-modal');
            var csvString = [
                'Customer Number,' +
                'Title,' +
                'Requisition Id,' +
                'Billing Number,' +
                'Billing Date,' +
                'Deliver To,' +
                'Cost Center,' +
                'Line Number,' +
                'Part Number,' +
                'Description,' +
                'Quantity,' +
                'UOM,' +
                'Unit Price,' +
                'UNSPSC Code,' +
                'Manufacturer Name,' +
                'Manufacturer Part Number,' +
                'Customer Part Number\n'
            ];

            angular.forEach(invoice_lines, function (x) {
                var fields = [
                    UtilityService.cleanCsv(x.customer_number),
                    UtilityService.cleanCsv(x.invoice_id + '-' + x.summary_billing_number),
                    UtilityService.cleanCsv(x.invoice_id),
                    UtilityService.cleanCsv(x.summary_billing_number),
                    UtilityService.cleanCsv(x.summary_billing_date),
                    UtilityService.cleanCsv(x.deliver_to),
                    UtilityService.cleanCsv(x.cost_center),
                    UtilityService.cleanCsv(x.line_number),
                    UtilityService.cleanCsv(x.item_part_number),
                    UtilityService.cleanCsv(x.item_description),
                    UtilityService.cleanCsv(x.quantity),
                    UtilityService.cleanCsv(x.unit_of_measure),
                    UtilityService.cleanCsv(x.unit_price),
                    UtilityService.cleanCsv(x.unspsc_code),
                    UtilityService.cleanCsv(x.manufacturer_name),
                    UtilityService.cleanCsv(x.manufacturer_part_number),
                    UtilityService.cleanCsv(x.cust_part_nbr)
                ];
                csvString.push('"' + fields.join('","') + '"\n');
            })
            var file = new Blob(csvString, {type: 'text/csv;charset=utf-8'});
            var fileName = [
                invoice_lines[0].invoice_id,
                invoice_lines[0].summary_billing_number
            ];
            FileSaver.saveAs(file, fileName.join('_') + '.csv');
            $rootScope.$broadcast('close-loading-modal');
        };

        self.downloadSummaryInvoices = function (custNo, options) {
            $rootScope.$broadcast('open-loading-modal');

            self.getSummaryInvoices(custNo, 10000, 1, options).then(function(response) {
                var data = [];
                var invoices = response.invoices ? response.invoices : [];
                angular.forEach(invoices, function (invoice) {
                    var fields = [
                        UtilityService.cleanCsv(invoice.invoice_id + '-' + invoice.summary_billing_number),
                        UtilityService.cleanCsv(invoice.invoice_id),
                        UtilityService.cleanCsv(invoice.summary_billing_number),
                        UtilityService.cleanCsv(invoice.summary_billing_date),
                        UtilityService.cleanCsv(invoice.deliver_to),
                        UtilityService.cleanCsv(invoice.cost_center),
                        UtilityService.cleanCsv(invoice.sub_total),
                        UtilityService.cleanCsv(invoice.status)
                    ];
                    data.push('"' + fields.join('","') + '"\n');
                });
                if (data.length) {
                    data.unshift("Title,Requisition Id,Billing Number,Billing Date,Deliver To,Cost Center,Sub Total,Status\n");
                    var file = new Blob(data, {type: 'text/csv;charset=utf-8'});
                    FileSaver.saveAs(file, 'summary-invoices-' + custNo + '.csv');
                }
            }).catch(function () {
                $rootScope.$broadcast('notify', {message: 'Error downloading file'});
            }).finally(function () {
                $rootScope.$broadcast('close-loading-modal');
            })
        };

        self.postSubmitSummary = function (billno, groupno, cost_center) {
            var deferral = $q.defer();

            url = API_URL + '/summary-invoices/submit-invoice';

            var data = {
                billno: billno,
                groupno: groupno,
                cost_center: cost_center ? cost_center : null
            };

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var data = response.data;
                deferral.resolve(data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.initSummaryInvoiceFilter = function (scope) {
            // filter action buttons
            scope.applyFilters = function () {
                scope.getSummaryInvoices();
            };

            scope.clearFilters = function () {
                scope.initFilters();
                scope.getSummaryInvoices();
                scope.isFiltered = false;
            };

            scope.initFilters = function () {
                scope.invoiceFilter = {
                    selectedDate: scope.dateOptions && scope.dateOptions.length ? scope.dateOptions[0] : '',
                    fromDate: '',
                    toDate: '',
                    filter: '',
                    status: '',
                    orderBy: ''
                };
            };

            scope.hasFilters = function () {
                return (scope.invoiceFilter.fromDate ||
                    scope.invoiceFilter.toDate ||
                    scope.invoiceFilter.filter ||
                    scope.invoiceFilter.status
                );
            };

            scope.statusOptions = [
                {value: '', label: 'Any Status'},
                {value: 'Pending', label: 'Pending'},
                {value: 'Submitted', label: 'Submitted'}
            ];

            // initialize filters
            scope.initFilters();

            // date picker
            scope.datePickerFormat = 'yyyy-MM-dd';
            scope.datePicker = {
                from: {opened: false},
                to: {opened: false}
            };
            scope.openDatePicker = function (id) {
                scope.datePicker[id].opened = true;
            };

            // date options
            scope.dateOptions = [
                {value: '', label: 'All Billing Dates'},
                {value: '30', label: '30 days'},
                {value: '60', label: '60 days'},
                {value: '90', label: '90 days'},
                {value: 'custom', label: 'Billing Date Range'}
            ];
            scope.invoiceFilter.selectedDate = scope.dateOptions[0];

            scope.selectDateType = function () {
                if (scope.invoiceFilter.selectedDate.value === 'custom' || !scope.invoiceFilter.selectedDate.value) {
                    scope.invoiceFilter.fromDate = '';
                    scope.invoiceFilter.toDate = '';
                } else {
                    scope.invoiceFilter.fromDate = moment().subtract(scope.invoiceFilter.selectedDate.value, 'days').toDate();
                    scope.invoiceFilter.toDate = moment().toDate();
                }
            };

            scope.changeDates = function () {
                if (scope.invoiceFilter.fromDate || scope.invoiceFilter.toDate) {
                    scope.invoiceFilter.selectedDate = scope.dateOptions[scope.dateOptions.length - 1];
                } else if (!scope.invoiceFilter.fromDate && !scope.invoiceFilter.toDate) {
                    scope.invoiceFilter.selectedDate = scope.dateOptions[0];
                }
            };
        };
    }
]);
