(function () {
    'use strict';
}());

app.service('CompanyService', [
    '$http',
    '$q',
    'API_URL',
    'UserService',
    function ($http, $q, API_URL, UserService) {
        var baseUrl = API_URL;
        var self = this;

        self.getCompanySummary = function (customer_number) {
            var deferral = $q.defer();
            var url = baseUrl + '/company/1/' + customer_number + '/summary';

            $http({
                method: "GET",
                url: url
            }).then(function (response) {
                var data = response.data;
                if (data) {
                    data.customer_number = customer_number;
                }
                deferral.resolve(data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.setAllowedCompany = function (data, token) {
            var deferral = $q.defer(),
                url = baseUrl + "/user-company";

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data),
                headers: { "Authorization" : "Bearer {" + token + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.removeAllowedCompany = function (id, token) {
            var deferral = $q.defer(),
                url = baseUrl + "/user-company/" + id;

            $http({
                method: "DELETE",
                url: url,
                headers: { "Authorization" : "Bearer {" + token + "}" }
            }).then( function () {
                deferral.resolve(true);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getCompanySettings = function (customer_number) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer/settings/' + customer_number;
            $http({
                method: "GET",
                url: url,
                cache: true
            }).then(function (response) {
                var data = response.data;
                // make sure workflow-related data is null if workflow_approval is not enabled
                if (data && !data.workflow_approval) {
                    data.workflow_currency = null;
                    data.workflow_dept_limit = null;
                    data.workflow_group = null;
                    data.workflow_limit_basis = null;
                    data.workflow_method = null;
                }
                data.use_cdf = '203467' === customer_number;
                deferral.resolve(data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getCompanyMaster = function (custno) {
            var deferral = $q.defer();
            var url = baseUrl + '/customer/master/' + custno;
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getCompanyShipTos = function (customerNumber) {
            var deferral = $q.defer();
            var url = baseUrl + '/company/ship_tos/' + customerNumber;
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getCompanySegment = function (customerNumber) {
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: baseUrl + '/customer-segment/' + customerNumber,
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };
    }
]);
