(function() {
    'use strict';
}());

app.service('CartService', [
    '$cookies',
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    '$filter',
    '$timeout',
    '$window',
    'UserService',
    'VendorService',
    'API_URL',
    'AnalyticsService',
    'AlgoliaService',
    function($cookies, $rootScope, $http, $location, $q, $state, $filter, $timeout, $window, UserService, VendorService, API_URL, AnalyticsService, AlgoliaService) {
        var self = this;

        $rootScope.$on('login', function () {
            var merge = self.getCartCache();
            if (merge && merge.cart_lines && merge.cart_lines.length) {
                self.mergeCart(merge.id);
            } else {
                self.getCart();
            }
        });

        this.punchoutCart = {};

        $rootScope.$on("updatePunchOutCart", function (event, cart) {
            self.punchoutCart = cart;
            $rootScope.$broadcast("cart_update");
        });

        this.getCartCache = function (session) {
            var cartKey = self.getCartStorageKey(session);
            var cart;
            if ($window.localStorageOverride[cartKey] && !$cookies.get("isPunchout")) {
                try {
                    cart = JSON.parse($window.localStorageOverride[cartKey]);
                } catch (e) {
                    console.error('Failed to parse cart cache:', e);
                    $window.localStorageOverride[cartKey] = null;
                    return null;
                }
            } else if ($cookies.get("isPunchOut")) {
                try {
                    cart = self.punchoutCart;
                } catch (e) {
                    console.error('Failed to parse cart cache:', e);
                    $cookies.remove("cart");
                    return null;
                }
            } else {
                return null;
            }
            self.setDisplayItemNo(cart);
            return cart;
        };

        this.updateCartCache = function (cart) {
            if ($cookies.get("isPunchOut")) {
                $rootScope.$broadcast("updatePunchOutCart", cart);
            } else {
                var key = cart ? self.getCartStorageKey(cart.session_id) : 'cart';
                $window.localStorageOverride[key] = JSON.stringify(cart);
                self.sendCartUpdate();
            }
        };

        this.getCartType = function (session) {
            return session && session.indexOf('-') > 0 ? session.split('-').pop() : '';
        };

        this.getCartStorageKey = function (session) {
            var key = 'cart';
            var type = self.getCartType(session);
            if (type) {
                key += '-' + type;
            }
            return key;
        };
        
        this.sendCartUpdate = function() {
            $rootScope.$broadcast('cart_update');
        };

        this.getItemCount = function () {
            var cart = self.getCartCache();
            if (cart && cart.cart_lines) {
                return cart.cart_lines.length;
            }

            return 0;
        };

        this.getCart = function (session) {
            var deferral = $q.defer();

            if (!session) {
                UserService.getSessionId().then(function (session) {
                    if (!session) {
                        $rootScope.$broadcast('cart_update');
                        deferral.resolve($window.localStorageOverride["cart"]);
                    } else {
                        var url = API_URL + '/cart/' + session;
                        $http({
                            method: "GET",
                            url: url
                        }).then(function (response) {
                            var data = response.data;
                            if ($cookies.get("isPunchOut")) {
                                $rootScope.$broadcast("updatePunchOutCart", data);
                            } else {
                                $window.localStorageOverride["cart"] = JSON.stringify(data);
                                $rootScope.$broadcast('cart_update');
                            }
                            deferral.resolve(data);
                        }, function () {
                            self.postCart(session).then(function (data) {
                                if (false !== data) {
                                    if (!$cookies.get("isPunchOut")) {
                                        $window.localStorageOverride["cart"] = JSON.stringify(data);
                                    } else {
                                        $rootScope.$broadcast("updatePunchOutCart", data);
                                    }
                                    deferral.resolve(data);
                                } else {
                                    deferral.resolve(false);
                                }
                            });
                        });
                    }
                });
            } else {
                var url = API_URL + '/cart/' + session;

                $http({
                    method: "GET",
                    url: url
                }).then(function (response) {
                    self.updateCartCache(response.data); //wot
                    deferral.resolve(response.data);
                }, function () {
                    self.postCart(session).then(function (data) {
                        if (false !== data) {
                            self.updateCartCache(data); //test
                            deferral.resolve(data);
                        } else {
                            deferral.resolve(false);
                        }
                    });
                });
            }

            return deferral.promise;
        };

        this.postCart = function (session, lines, orderSourceType) {
            var deferral = $q.defer();
            var url = API_URL + "/cart";
            var data = {
                "session_id": session
            };

            var sequence = 1;
            var cart = self.getCartCache(session);
            if (cart) {
                sequence = (null == cart.cart_lines) ? 1 : cart.cart_lines.length + 1;
            }
            var user = UserService.getPersistedUser();
            if (user) {
                data.user_id = user.id;
            }
            data.customer_type = 'sxe';
            
            data.cart_lines = [];
            if (lines) {
                angular.forEach(lines, function (line) {
                    if (!line.uom) line.uom = 'EA';
                    if (!line.description) line.description = 'N/A';
                    if (line.quantity > 0 && line.quantity) {
                        line.sequence = sequence;
                        delete line.id;
                        delete line.line_sub_total;
                        ++sequence;
                        data.cart_lines.push(line);
                    }
                });
            }

            if (orderSourceType) {
                data.order_source_type = orderSourceType;
            }

            if (!session) {
                $window.localStorageOverride["cart"] = JSON.stringify(data);
                $rootScope.$broadcast('cart_update');
                deferral.resolve(data);
            } else {
                $http({
                    method: "POST",
                    url: url,
                    data: data
                }).then(function (response) {
                    self.getCart(session).then(function (cart) {
                        if ($cookies.get("isPunchOut")) {
                            $rootScope.$broadcast("updatePunchOutCart", cart);
                        } else {
                            $rootScope.$broadcast('cart_update');
                        }
                        deferral.resolve(cart);
                    });
                }, function () {
                    deferral.resolve(false);
                });
            }

            return deferral.promise;
        };

        this.deleteCart = function (session) {
            var deferral = $q.defer();

            if (!session) {
                delete $window.localStorageOverride["cart"];
                deferral.resolve(true);
            } else {
                var url = API_URL + "/cart/" + session;

                $http({
                    method: "DELETE",
                    url: url
                }).then(function () {
                    deferral.resolve(true);
                }, function () {
                    deferral.resolve(false);
                });
            }

            //just in case there is one added
            $window.sessionStorage.removeItem("s2wCartShipTo");

            return deferral.promise;
        };

        this.deleteCartLine = function (session, id, shared_cart) {
            var deferral = $q.defer();

            if (!session) {
                var cart = JSON.parse($window.localStorageOverride["cart"]);
                for (var i=0; i<cart.cart_lines.length; i++) {
                    var line = cart.cart_lines[i];
                    if (line.erp === id) {
                        cart.cart_lines.splice(i, 1);
                        break;
                    }
                }
                $window.localStorageOverride["cart"] = JSON.stringify(cart);
                $rootScope.$broadcast('cart_update');
                deferral.resolve(true);
            } else {
                var url = API_URL + "/cart/" + session + "/line/" + id;

                $http({
                    method: "DELETE",
                    url: url
                }).then(function () {
                    if (shared_cart) {
                        deferral.resolve(true);
                    } else {
                        self.getCart(session).then(function () {
                            $rootScope.$broadcast('cart_update');
                            deferral.resolve(true);
                        });
                    }
                }, function () {
                    deferral.resolve(false);
                });
            }

            return deferral.promise;
        };

        this.removeItem = function(session, cart, id, shared_cart) {
            // find the item in the cart_lines we are deleting and send that removal to GA
            for(var i = 0; i < cart.cart_lines.length; i ++) {
                if(cart.cart_lines[i].erp === id) {
                    AnalyticsService.removeFromCart(cart.cart_lines[i]);
                }
            }
            var defer = $q.defer();
            var self = this;
            $rootScope.$broadcast('open-loading-modal');

            var lines = [];
            var dupes = {};
            angular.forEach(cart.cart_lines, function (v) {
                var dupeId = v.erp + '-' + v.cpn + '-' + v.uom;
                if (!dupes[dupeId] && v.quantity > 0 && !isNaN(v.quantity)) {
                    var line = {
                        comment: v.comment,
                        comment_type: v.comment_type,
                        cpn: v.cpn,
                        description: v.description,
                        erp: v.erp,
                        image: v.image,
                        price: v.price,
                        quantity: v.quantity,
                        uom: v.uom,
                        is_gifm: v.is_gifm
                    };
                    dupes[dupeId] = true;
                    lines.push(line);
                }
            });

            self.deleteCartLine(session, id, shared_cart).then(function (data) {
                if (!shared_cart) {
                    self.getCart(session).then(function () {
                        $rootScope.$broadcast('close-loading-modal');
                        if (!data) {
                            defer.reject();
                        } else {
                            $rootScope.$broadcast('notify', { message: "Your cart has been updated." });
                            $rootScope.$broadcast('cart_update');
                            defer.resolve();
                        }
                    });
                } else {
                    $rootScope.$broadcast('close-loading-modal');
                    $rootScope.$broadcast('notify', { message: "Shared cart has been updated." });
                    defer.resolve();
                }

            }).catch(function(err) {
                console.error(err);
            });

            return defer.promise;
        };

        this.removeItems = function (session, cart, items) {
            var defer = $q.defer();
            var itemIds = items.map(function(a) {return a.id;});
            var self = this;

            if (itemIds.length) {
                var data = { "item_ids": itemIds };
                $http({
                    method: "DELETE",
                    url: API_URL + "/cart/" + session + "/lines",
                    data: JSON.stringify(data)
                }).then(function () {
                    self.getCart(session).then(function (cartData) {
                        defer.resolve(cartData);
                    }, function() {
                        defer.reject();
                    });
                }, function () {
                    defer.reject();
                });
            } else {
                defer.resolve();
            }
            return defer.promise;
        };

        this.updateCart = function (cart) {
            var defer = $q.defer();

            var newcart = Object.assign({}, cart);

            delete newcart.id;
            delete newcart.session_id;
            delete newcart.sub_total;
            delete newcart.cart_lines;

            $http({
                method: "PUT",
                url: API_URL + "/cart/" + cart.session_id,
                headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                data: JSON.stringify(newcart)
            }).then(function () {
                $rootScope.$broadcast('cart_update');
                defer.resolve();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        };

        this.updateCartLine = function (session, item) {
            var defer = $q.defer();

            var newitem = Object.assign({}, item);
            var id = newitem.id;

            delete newitem.id;
            delete newitem.line_sub_total;

            var url = API_URL + "/cart/" + session + "/line/" + id;
            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                data: JSON.stringify(newitem)
            }).then(function () {
                $rootScope.$broadcast('cart_update');
                defer.resolve();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        };

        this.mergeCart = function (merge) {
            var deferral = $q.defer();

            UserService.getSessionId().then(function (sessionId) {
                var url = API_URL + "/cart/" + sessionId + "/merge/" + merge;
                $http({
                    method: "PUT",
                    url: url
                }).then(function () {
                    delete $window.localStorageOverride["cart"];
                    self.getCart();
                    deferral.resolve(true);
                }, function () {
                    deferral.resolve(false);
                });
            });

            return deferral.promise;
        };

        this.getCartData = function (session) {
            var deferral = $q.defer(),
                url = API_URL + '/cart/' + session;

            $http({
                method: "GET",
                url: url
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.getCartOwnerData = function (session) {
            var deferral = $q.defer(),
                url = API_URL + '/cart/' + session + '/owner';
            $http({
                method: "GET",
                url: url
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.shareCart = function (approvers, cart, saveCart) {
            var deferral = $q.defer(),
                data = { "approvers": [], "save": saveCart },
                url;

            if (cart === undefined) {
                cart = self.getCartCache();
            }

            url = API_URL + "/cart/" + cart.session_id + "/share";

            angular.forEach(approvers, function (a) {
               data.approvers.push(a.id);
            });

            data.response_link = $state.href('cart_shared', {cart: cart.id}, {absolute: true});
            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(data)
            }).then( function () {
                deferral.resolve(true);
            },  function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.rejectCart = function (id) {
            var deferral = $q.defer(),
                url = API_URL + "/authcart/" + id + "/reject";

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization":"Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                if (response.data) {
                    deferral.resolve(true);
                } else {
                    deferral.reject(false);
                }
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        this.sendCartForApproval = function (cart_id, user_id, object) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/" + user_id + "/" + cart_id;

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                data: JSON.stringify(object)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        this.sendQuoteForApproval = function (user_id, quote_id, object) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/quote/" + user_id + "/" + quote_id;
            $http({
                method: "POST",
                url: url,
                headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                data: JSON.stringify(object)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject('Unable to obtain order approval.');
            });

            return deferral.promise;
        };

        this.advanceCartForApproval = function (id, user_id, object) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/advance/" + user_id + "/" + id;

            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                data: JSON.stringify(object)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (error) {
                deferral.reject(error);
            });

            return deferral.promise;
        };

        this.getHasCartRequests = function (user_id) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/check/" + user_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"},
                ignoreLoadingBar: true
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        this.getCartRequests = function (user_id) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/requests/" + user_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        this.getCartRequest = function (request_id) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/" + request_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                var result = response.data;
                self.setCartTotals(result);
                deferral.resolve(result);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.setCartTotals = function (cart) {
            this.setDisplayPrices(cart);
            var grandTotal = cart.sub_total_display + cart.tax + cart.shipping;
            cart.totals = [
                {type: "Subtotal", value: cart.sub_total_display, order: 0},
                {type: "Tax", value: cart.tax, order: 1},
                {type: "Shipping", value: cart.shipping, order: 2}
            ];

            if (cart.discount) {
                grandTotal = grandTotal - cart.discount;
                cart.totals.push({type: "Discount", value: cart.discount, order: 3});
            }

            cart.totals.push({type: "Total", value: grandTotal, s: true, order: 4});
        };

        this.setDisplayPrices = function (cart) {
            cart.estimated_price = false;
            cart.sub_total_display = 0;

            angular.forEach(cart.cart_lines, function (line) {
                if (line.last_paid && !line.price) {
                    line.price_display = line.last_paid;
                    cart.estimated_price = true;
                } else {
                    line.price_display = line.price;
                }
                line.line_sub_total_display = Math.abs(line.price_display * line.quantity);
                cart.sub_total_display += line.line_sub_total_display;
            });
        }

        this.deleteCartRequest = function (request_id) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/" + request_id;

            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function () {
                deferral.resolve(true);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.resolveCartRequest = function (request, action) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/resolve/" + action + "/" + request.id;
            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"},
                data: JSON.stringify(request)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.formatCartOwnerDisplay = function (cart) {
            return cart.username + ' - ' + $filter('currency')(cart.sub_total);
        };

        this.getCartApprovalLog = function (cart_id) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-approval-log/" + cart_id;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject(false);
            });

            return deferral.promise;
        };

        this.updateCartRequest = function (request) {
            var deferral = $q.defer(),
                url = API_URL + "/cart-request/" + request.id;
            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization":"Bearer {"+UserService.getToken()+"}"},
                data: JSON.stringify(request)
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.getSharedCarts = function (user) {
            if (user && user.shared_carts && !Array.isArray(user.shared_carts)) {
                user.shared_carts = Object.values(user.shared_carts)
            }
            var deferral = $q.defer();
            var sharedCarts = [];
            var promises = [];
            var carts = user && user.shared_carts ? user.shared_carts : [];
            angular.forEach(carts, function (shared_cart) {
                promises.push(
                    self.getCartOwnerData(shared_cart).then(function (cart) {
                        if (cart && cart.cart_lines > 0 &&
                            (cart.customer_number === user.customer_number || !cart.customer_number)
                        ) {
                            cart.display = self.formatCartOwnerDisplay(cart);
                            sharedCarts.push(cart);
                        }
                    })
                );
            });
            if (promises.length) {
                Promise.all(promises).then(function() {
                    deferral.resolve($filter('orderByObject')(sharedCarts, 'display'));
                }, function() {
                    deferral.resolve(false);
                });
            } else {
                deferral.resolve(false);
            }
            return deferral.promise;
        };

        self.handleCartError = function(err, scope) {
            if (err.data) { err = err.data; }
            $rootScope.$broadcast('close-loading-modal');
            if (err.type == 'payment') {
                $rootScope.$broadcast('notify', {message: 'Failed to process payment, please ensure that your payment info is correct.'});
            } else {
                if (Array.isArray(err) && err.length > 0 && err[0].message) {
                    $rootScope.$broadcast('notify', {message: err[0].message});
                } else if (err.message || err.error) {
                    var msg = err.message ? err.message : err.error;
                    $rootScope.$broadcast('notify', {message: msg + ' Contact support if the problem persists.'});
                } else if (err) {
                    $rootScope.$broadcast('notify', {message: err + ' Contact support if the problem persists.'});
                } else {
                    $rootScope.$broadcast('notify', {message: 'Failed to process order, please check that your order information is correct. Contact support if the problem persists.'});
                }
                scope.submitOrderError = true;

                if (err.type === 'udf') {
                    self.deleteCart(scope.cart.session_id).then(function () {
                        self.getCart(scope.cart.session_id);
                    });
                    $window.sessionStorage.removeItem('cart_state');
                    scope.orderError = err;
                    scope.setState('cart.error');
                    scope.showingOrder = true;
                }
            }
        };

        this.convertOrderLinesToCartLines = function (order) {
            var deferral = $q.defer();

            var updatedLines = [];
            if (order.lines && order.lines.length) {
                var user = {};

                if (UserService.getPersistedUser()) {
                    user = UserService.getPersistedUser();
                }
                var itemFilter = [];

                angular.forEach(order.lines, function(line) {
                    // convert orderLine to cartLine
                    var obj = {
                        erp: line.itemNumber,
                        iref: null,
                        cpn: line.webData ? line.webData.cpn : null,
                        description: line.itemDescription1 + ' ' + line.itemDescription2,
                        price: line.actualSellPrice,
                        line_sub_total: line.totalLineAmount,
                        quantity: line.quantityOrdered,
                        uom: line.unitOfMeasure,
                        units: line.webData ? line.webData.units : null,
                        comment: null,
                        direct_ship: line.webData ? line.direct_ship : null,
                        vendor: line.webData ? line.vendor : null,
                        apsv: line.webData ? line.apsv : null,
                        product_id: null,
                        product_number: null,
                        image: null,
                        specifications: null,
                        taxonomy: null,
                        order_number: order.header.orderNumber
                    };
                    if (obj.erp) {
                        itemFilter.push('erp:"' + AlgoliaService.cleanFilter(obj.erp) + '"');
                    }
                    updatedLines.push(obj);
                });

                // fetch supplemental data from algolia
                var params = {
                    filters: itemFilter.join(' OR ')
                };

                AlgoliaService.searchItems(params).then(function (data) {
                    angular.forEach(updatedLines, function (item) {
                        var itemMatch = $filter('filter')(data.hits, {erp: item.erp});
                        if (itemMatch[0]) {
                            var specs = [];
                            angular.forEach(itemMatch[0]['item_specifications'], function (spec) {
                                var specData = spec.split('|');
                                specs.push({title: specData[0], value: specData[1]});
                            });

                            item.image = itemMatch[0]['image'];
                            item.product_id = itemMatch[0]['product_id'];
                            item.product_number = itemMatch[0]['product_number'];
                            item.specifications = specs;
                            item.taxonomy = itemMatch[0]['taxonomy'];
                        }
                    });
                }).finally(function () {
                    deferral.resolve(updatedLines);
                });

            } else {
                deferral.resolve(updatedLines);
            }
            return deferral.promise;
        };

        self.setDisplayItemNo = function (cart) {
            if (cart && cart.cart_lines) {
                angular.forEach(cart.cart_lines, function(item) {
                    item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer)
                });
            }
        };

        self.initializeUdf = function (cart) {
            if (cart) {
                cart.header_properties = null;
                if (cart.cart_lines) {
                    angular.forEach(cart.cart_lines, function(item) {
                        if (!item.hasOwnProperty('line_properties')) {
                            item.line_properties = null;
                        }
                    });
                }
            }
        };
        
        self.initSelectItems = function(scope) {
            scope.checkboxes = {
                'selectAll': 0,
                'selectedItems': 0
            };

            scope.selectAllItems = function() {
                angular.forEach(scope.cart.cart_lines, function(item){
                    item.selected = scope.checkboxes.selectAll;
                });
                scope.checkboxes.selectedItems = scope.checkboxes.selectAll ? scope.cart.cart_lines.length : 0;
            };

            scope.selectItem = function() {
                var allChecked = 1;
                var checkedCount = 0;
                angular.forEach(scope.cart.cart_lines, function(item){
                    if (item.selected) { checkedCount++; }
                    else { allChecked = 0;}
                });
                scope.checkboxes.selectAll = allChecked;
                scope.checkboxes.selectedItems = checkedCount;
            };    
        };

        self.formatApprovalCart = function(orderType, scope) {
            if (scope.cart === undefined) {
                scope.cart = JSON.parse($window.localStorageOverride.cart);
            }
            var lines = [], totalUnits = 0;
            angular.forEach(scope.cart.cart_lines, function (line) {
                if (line.units > 0) {
                    totalUnits += Math.abs(line.units * line.quantity);
                }
                var l = angular.copy(line);
                delete l.$$hashkey;
                lines.push(l);
            });

            var d = angular.copy(scope.data);
            var object = {
                "order_type": orderType,
                "cart_lines": lines,
                "sub_total": scope.subTotal,
                "shipping": scope.shipping,
                "tax": scope.tax,
                "discount": scope.discount,
                "total_units": totalUnits,
                "token": d.token && scope.use_cc ? d.token : null,
                "shipping_info": {
                    "shippingAddress1": d.shippingAddress1,
                    "shippingAddress2": d.shippingAddress2,
                    "shippingCity": d.shippingCity,
                    "shippingState": d.shippingState.abbr,
                    "shippingZip": d.shippingZip
                }, "billing_info": {
                    "billingAddress1": d.billingAddress1,
                    "billingAddress2": d.billingAddress2,
                    "billingCity": d.billingCity,
                    "billingState": d.billingState ? d.billingState.abbr : null,
                    "billingZip": d.billingZip,
                }, "misc_info": {
                    "Customer PO": d.po,
                    "Shipping Instructions": d.shippingInstructions,
                    "Comments": d.comments
                },
                "promo_codes": []
            };
            if (d.shippingShipTo !== undefined) {
                object.shipping_info.shippingShipTo = d.shippingShipTo;
            }

            if (d.selectedGeoCode !== undefined) {
                object.shipping_info.geocode = d.selectedGeoCode;
            }

            if (d.department !== undefined) {
                object.department = d.department;
            }

            if (d.header_properties !== undefined) {
                object.header_properties = d.header_properties;
            }

            var list = "";
            if (d.promo_codes.free_freight) {
                list += "Free Shipping";
                object.promo_codes.push("freefr8");
            }

            if (d.promo_codes.discount) {
                var discounts = d.promo_codes.discount;
                if (list !== "") list += ", ";
                if (discounts.length) {
                    angular.forEach(discounts, function (pc) {
                        list += pc.promo_description + ", ";
                        object.promo_codes.push(pc.promo_code);
                    });
                } else if (discounts.hasOwnProperty('promo_code')) {
                    list += discounts.promo_description;
                    object.promo_codes.push(discounts.promo_code);
                }
                object.misc_info["Promo codes applied"] = list;
            }
            return object;
        };

        self.calculateLastPaidTotal = function (cart) {
            var unitTypeTotals = {};
            var total = 0;
            if (cart && cart.cart_lines) {
                angular.forEach(cart.cart_lines, function (line) {
                    if (line.unit_type && line.units) {
                        if (undefined === unitTypeTotals[line.unit_type]) {
                            unitTypeTotals[line.unit_type] = 0;
                        }
                        unitTypeTotals[line.unit_type] += line.units;
                    }
                    if (line.last_paid && line.last_paid > 0) {
                        total += Math.abs(line.last_paid * line.quantity);
                    }
                });
            }
            return total;
        };

        self.calculateItemSubTotals = function (cart) {
            var lines = [];
            if (cart && cart.cart_lines) {
                lines = angular.copy(cart.cart_lines);
            } else if (cart && cart.lines) {
                // reformat order data
                angular.forEach(cart.lines, function (line) {
                    lines.push({
                        units: line.webData ? line.webData.units : null,
                        unit_type: line.webData ? line.webData.unit_type : null,
                        quantity: line.quantityOrdered,
                        line_sub_total: line.totalLineAmount,
                        last_paid: line.webData ? line.webData.lastPaidPrice : null
                    });
                });
            }
            var itemSubTotals = {
                itemTotal: 0,
                lastPaid: 0
            };

            var total = 0;
            angular.forEach(lines, function (line) {
                var itemKey = 'itemTotal';

                if (line.units) {
                    if (line.unit_type) {
                        itemKey = line.unit_type;
                    }
                    if (undefined === itemSubTotals[itemKey]) {
                        itemSubTotals[itemKey] = 0;
                    }
                    itemSubTotals[itemKey] += Math.abs(line.units * line.quantity);
                } else {
                    itemSubTotals[itemKey] += Math.abs(line.line_sub_total);
                }
                if (line.last_paid && line.last_paid > 0) {
                    total += Math.abs(line.last_paid * line.quantity);
                }
            });
            itemSubTotals.lastPaid = total;
            return itemSubTotals;
        };

        self.formatItemDescription = function (item, product) {
            var itemDesc = item.description;
            if (!itemDesc || !$cookies.get("isPunchOut")) {
                itemDesc = (product.vendor.name + ' ' + product.heading).trim();
            }
            return itemDesc;
        };

        self.initCart = function () {
            return { cart_lines: [] };
        }
    }
]);
