(function () {
    'use strict';
}());

app.directive('availabilityIcon', ['UtilityService',
    function (UtilityService) {
        return {
            restrict: 'E',
            template: '<span class="has-tooltip {{icon.class}}" uib-tooltip="{{icon.text}}">\n' +
                '<span ng-if="icon.image == \'fa-refresh\'"><i class="fa fa-spin {{icon.image}}"></i></span>\n' +
                '<span ng-if="icon.image == \'fa-check-circle\'"><i class="fa {{icon.image}}"></i></span>\n' +
                '<span ng-if="icon.image == \'fa-exclamation-circle\'"><i class="fa {{icon.image}}"></i></span>\n' +
                '</span>',
            replace: true,
            scope: {
                item: '=',
                product: '=?'
            },
            link: function(scope) {
                scope.icon = {
                    class: 'text-gray',
                    image: 'fa-refresh',
                    text: ''
                };
                var callUs = ' Please call us so we can arrange a solution - ' + UtilityService.getSitePhone().replace(/-/g, '.') + '.';
                scope.$watch('item', function(i, o) {
                    if (i.quantity !== o.quantity ||
                        i.quantity_available !== o.quantity_available ||
                        scope.icon.image === 'fa-refresh' ||
                        i.price !== o.price
                    ) {
                        var available = scope.product ? scope.product.selected_availability : i.quantity_available;
                        if (!available) {
                            available = 0;
                        }
                        scope.icon.image = 'fa-exclamation-circle';

                        if (!i.price) {
                            scope.icon.class = 'text-gray';
                            scope.icon.text = 'This product isn\'t available at this time.' + callUs;
                        } else if (available < i.quantity && available !== 0 && !i.direct_ship) {
                            scope.icon.class = 'text-warning';
                            scope.icon.text = 'You\'ve entered a larger quantity than is available to ship immediately.' + callUs;
                        } else if ((available >= i.quantity && available !== 0) || i.direct_ship) {
                            scope.icon.class = 'text-success';
                            scope.icon.image = 'fa-check-circle';
                            scope.icon.text = 'This product is in stock and ready to ship';
                        } else if (available === 0) {
                            scope.icon.class = i.quantity > 0 ? 'text-warning' : 'text-gray';
                            scope.icon.text = 'This item isn\'t available to ship immediately.' + callUs;
                        } else {
                            scope.icon.class = 'text-gray';
                            scope.icon.image = 'fa-refresh';
                            scope.icon.text = '';
                        }
                    }
                }, true);
            }
        }
    }
]);



