(function () {
    'use strict';
}());

app.service('FacetService', [
    'BLACKLIST_FACETS',
    '$filter',
    'FilterService',
    function (BLACKLIST_FACETS, $filter, FilterService) {
        var self = this;

        self.parseItemSpecs = function (itemSpecs) {
            var filters = FilterService.getFilters();
            var facets = {};
            angular.forEach(itemSpecs, function(valueCount, spec) {
                var split = spec.split('|');
                if (split[0] && split[1] && !BLACKLIST_FACETS.includes(split[0])) {
                    var specName = split[0].trim();
                    var specValue = split[1].trim();
                    if (!facets[specName]) {
                        facets[specName] = {
                            values: {}
                        };
                    }

                    if (!facets[specName].values[specValue]) {
                        facets[specName].values[specValue] = valueCount;
                    } else {
                        facets[specName].values[specValue] += valueCount;
                    }
                }
            });

            var specs = [];
            facets = $filter('orderByObjectKeys')(facets);
            angular.forEach(facets, function(data, spec) {
                var obj = {
                    title: spec,
                    values: []
                };
                var values = $filter('orderByObjectKeys')(data.values);
                angular.forEach(values, function(count, name) {
                    obj.values.push({name: name, count: count});
                });
                if (obj.values.length > 1 || (filters && filters.filters && filters.filters[spec])) {
                    specs.push(obj);
                }
            });
            facets = null;
            // don't return 'too many' specs
            return specs.slice(0, 50);
        };
    }
]);
