(function () {
    'use strict';
}());

app.service('DepartmentService', [
    '$rootScope',
    '$http',
    '$location',
    '$q',
    '$state',
    '$window',
    'jwtHelper',
    'API_URL',
    'DEFAULT_CATALOGS',
    'OPEN_BROWSE_COMPANY_NUMBER',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    'OPEN_BROWSE_WAREHOUSE',
    'PASSWORD_REGEX',
    'UserService',
    '$cookies',
    function ($rootScope, $http, $location, $q, $state, $window, jwtHelper, API_URL, DEFAULT_CATALOGS, OPEN_BROWSE_COMPANY_NUMBER, OPEN_BROWSE_CUSTOMER_NUMBER, OPEN_BROWSE_WAREHOUSE, PASSWORD_REGEX, UserService, $cookies) {
        var baseUrl = API_URL;
        var self = this;

        self.getDepartments = function (p) {
            var deferral = $q.defer(),
                url = baseUrl + '/department';
            var params = {
                order_direction: 'ASC',
                limit: '25',
                offset: '1'
            };
            if (p.customer_number !== undefined && p.customer_number !== "") params.customer_number = p.customer_number;
            if (p.filter !== undefined && p.filter !== "") params.filter = p.filter;
            if (p.order_by !== undefined && p.order_by !== "") params.order_by = p.order_by;
            if (p.order_direction !== undefined && p.order_direction !== "") params.order_direction = p.order_direction;
            if (p.limit !== undefined && p.limit !== "") params.limit = p.limit;
            if (p.offset !== undefined && p.offset !== "") params.offset = p.offset;
            if (p.active !== undefined && p.active !== "") params.active = p.active;
            if (p.user_id !== undefined && p.user_id !== "") params.user_id = p.user_id;

            $http({
                method: 'GET',
                url: url,
                params: params,
                headers: { "Authorization": "Bearer {"+ UserService.getToken() +"}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.getDepartmentById = function (id, users) {
            var url = baseUrl + '/department/' + id;
            if (users) {
                url += '/users';
            }
            var deferral = $q.defer();
            $http({
                method: "GET",
                url: url,
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.reject("Unable to locate the department you're looking for")
            });

            return deferral.promise;
        };

        self.postDepartment = function (department) {
            var deferral = $q.defer();
            var url = baseUrl + '/department';

            $http({
                method: "POST",
                url: url,
                data: JSON.stringify(department),
                headers: { "Authorization": "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.updateDepartment = function (department) {
            var deferral = $q.defer(),
                data = angular.copy(department);

            delete data.id;
            delete data.created_by;
            delete data.created_date;
            delete data.updated_by;
            delete data.updated_date;
            delete data.users;

            $http({
                method: "PUT",
                url: baseUrl + "/department/" + department.id,
                data: JSON.stringify(data),
                headers: { "Authorization" : "Bearer {" + UserService.getToken() + "}" }
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.deleteDepartment = function (department_id) {
            var deferral = $q.defer();
            $http({
                method: "DELETE",
                url: baseUrl + "/department/" + department_id,
                headers: { "Authorization": "Bearer {" + UserService.getToken() +"}" }
            }).then( function () {
                deferral.resolve(true);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };
    }
]);
