(function () {
    'use strict';
}());

var descriptions = {
    'Abrasives': 'You will find what you need at Vallen when you are looking for durable abrasives and accessories. Shop for sandpaper, diamond tools, wheels, cutters, and more.',
    'Chemicals & Adhesives': 'Lubricants, sealants, cleaners, coolants, and rust preventatives are just some of the many quality chemicals and adhesives you can shop for at Vallen.',
    'Cutting Tools & Accessories': 'Shop with Vallen when you are searching for versatile cutting tools and accessories. These tools are suited for hole making, sawing, engraving, and more.',
    'Hand Tools': 'Look no further than Vallen when you want hand tools and construction tools. Shop for drive tools, clampers, toolboxes, drills, marking tools, and others.',
    'Indexable Tooling': 'Indexable tools are suited for milling, threading, drilling, and turning applications. You can count on Vallen when you are shopping for indexable tools.',
    'Machine Tool Accessories': 'Allow Vallen to help you when you are in the market for machine tool accessories. Shop for quality adapters, holders, tool holders, sleeves, chucks, and more.',
    'Paint Marking and Tape': 'For your convenience, a range of long lasting paint, marking products, and tape are available right here at Vallen. Shop for masking tape, epoxy, and more.',
    'Power Tools': 'Reliable power tools and parts are a specialty at Vallen. Start shopping today for cordless power tools, electric tools, drills, and other options.',
    'Precision Measuring Tools': 'Start right here at Vallen when you are in the market for trustworthy precision measuring tools. Shop for multiple electronic, optical, and hand tools.',
    'Safety': 'Safety should remain a top priority across any site or work place. Shop for a large collection of safety signs, protective products, and more at Vallen.',
    'Workholding': 'Depend on Vallen for all your workholding supplies and hardware supplies. Shop for bushings, clamps, inserts, washers, screws, nuts, plates, and more.'
};

var content = {
    'Abrasives': 'You can depend on Vallen when you are shopping for abrasives and abrasive accessories. These trustworthy products assist with grinding, polishing, buffing, sharpening, or sanding numerous surfaces. Browse through our selection of these power tool parts and other accessories including belts, discs, files, wheels, sandpaper, cutters, diamond tools, power brushes, and many more.',
    'Chemicals & Adhesives': 'Trust Vallen if you are in the market for trustworthy chemicals, sealants, and adhesives to assist with securing, bonding, cleaning, or repairing finishes in standard or diverse settings. Shop for a wide selection of rust preventatives, coolants, lubricants, greases, cleaners, and more to meet your requirements.',
    'Cutting Tools & Accessories': 'High performing cutting tools and accessories are essential for various applications. With the tools offered at Vallen you can tackle any challenge including hole making, sawing, engraving, thread making, deburring, end milling, and more. Find an assortment of cutters, drills, extensions, wrenches, bits, saw blades, and other accessories.',
    'Hand Tools': 'Vallen has all the dependable hand tools and construction tools that you might need. For your convenience, you can shop for a wide collection of reliable clampers, drive tools, toolboxes, drills, marking tools, cutting tools, clamping tools, striking accessories, and others that are suited for any task. Shop for screwdrivers, pliers, levels, wrenches, ratchets, cutters, sockets, and more.',
    'Indexable Tooling': 'No matter what indexable tools you are searching for, Vallen is the place to start shopping. We have a wide selection of drilling, milling, parting, port contour, threading, and turning tools to match any task. Search through our range of over 2,000 indexable inserts to match any cutting tools, turning tools, or other tools.',
    'Machine Tool Accessories': 'When you need to enhance the functions or accuracy of any machine, Vallen is the place to start shopping. Find a great assortment of high performing machine tool accessories including adapters, replacement parts, sleeves, flanges, tool holders, extensions, chucks, keys, knobs, nuts, shims, arbors, and many others.',
    'Paint, Marking & Tape': 'At Vallen, we know how important heavy-duty paints, marking products, and tapes are for any task. That is why we carry an assortment of versatile and steadfast supplies and accessories suited for interior or exterior painting. Find epoxy, marking tape, duct tape, aerosol products, paint rollers, mixing boards, sealing supplies, primer, acrylic, and more.',
    'Power Tools': 'Vallen is a leading supplier of power tools and accessories. Here you will be able to find a vast selection of cordless power tools, electric tools, pipe tools, pneumatic tools, electric tools, and other accessories. Shop for hammer drills, saws, adapters, radios, sanders, hammers, drill drivers, bits, plugs, screwdrivers, and others.',
    'Precision Measuring Tools': 'With a large inventory of precision measuring tools, Vallen is the supplier for you. Discover our large selection of electronic, optical, and hand tools including hanging kits, gauges, microscopes, multimeters, protractors, refractometers, reels, rules, testers, meters, tool sets, gages, and various other accessories.',
    'Safety': 'At Vallen we understand that safety is important for any workplace or construction site. Start right here and sort through our range of dependable safety and protective products to match your needs. Shop for warning signs, flexible tape, protective tape, safety signs, hazardous supplies, and many other supplies.',
    'Workholding': 'With our range of prominent brands and versatile options to choose from, Vallen is where you should start shopping for workholding supplies and hardware. Find a collection of handles, bushings, clamps, inserts, washers, screws, nuts, plates, handles, workstops, plungers, jaws, knobs, chucks, buttons, and more.'
};

app.controller('CatalogController', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$location',
    '$timeout',
    '$window',
    'CatalogService',
    'FilterService',
    'PricingService',
    'FacetService',
    'UserService',
    '$filter',
    'AnalyticsService',
    'AlgoliaService',
    'BLACKLIST_FACETS',
    'dbFilter',
    function ($rootScope, $scope, $state, $stateParams, $location, $timeout, $window, CatalogService, FilterService, PricingService, FacetService, UserService, $filter, AnalyticsService, AlgoliaService, BLACKLIST_FACETS, dbFilter) {
        if (dbFilter) {
            $stateParams = {
                department: dbFilter.department,
                category: dbFilter.category,
                styles: dbFilter.styles,
                vendors: dbFilter.vendors
            };
        }

        $scope.orderOptions = [
            {key: "", value: "Relevance"},
            {key: "orderby_cost_asc", value: "Price (Low to High)"},
            {key: "orderby_cost_desc", value: "Price (High to Low)"}
        ];

        $scope.user = UserService.getPersistedUser();

        $scope.orderBy = $scope.orderOptions[0];
        $scope.facetPanels = {};
        $scope.breadcrumbs = null;
        $scope.options = {
            orderBy: ''
        };

        $scope.productMeta = {
            current_items: 0,
            total_items: 0
        };

        $scope.showFacet = function(title) {
            return !BLACKLIST_FACETS.includes(title);
        };
        var dupTaxResults = {};

        function init(params) {
            FilterService.initFilters(params);
            $scope.brandPage = params.brands;
            $scope.searchPage = !!params.q;
            $scope.catalogFacets = [];
            $scope.preFilteredPage = !!dbFilter;
            if ($scope.user && $scope.user.availableCatalogs && params.catalog) {
                angular.forEach($scope.user.availableCatalogs, function (catalogFacet) {
                    if (params.catalog == catalogFacet.name) {
                        catalogFacet.checked = true;
                        catalogFacet.status = true;
                    }
                });
            }
        }

        init($stateParams);

        if (!$scope.brandPage && !$scope.searchPage && !$stateParams.department && !$scope.preFilteredPage) {
            $state.go('categories');
        }

        function addBreadcrumbFromFilter(cacheKey, filterKey, addState) {
            var filter = $scope.filters[filterKey];
            if (cacheKey && filter) {
                var breadcrumb = {
                    name: filter
                };

                if (addState) {
                    var params = {};
                    params[filterKey] = filter;
                    params = $rootScope.mergeBlankParams(params);
                    breadcrumb.state = 'new_catalog(' + JSON.stringify(params) + ')';
                }

                $scope.breadcrumbs.push(breadcrumb);
            }
        }

        $scope.updateBreadcrumbs = function() {
            $scope.breadcrumbs = [];

            if ($scope.brandPage) {
                AlgoliaService.searchTaxonomy({
                    hitsPerPage: 1,
                    facetFilters: [['type:vendor'], ['name:' + $scope.filters['vendor']]]
                }, dupTaxResults).then(function(data) {
                    if (data && data.hits && data.hits.length) {
                        $scope.vendor = data.hits[0];
                    } else {
                        $state.go('404');
                    }
                });
            } else {
                $scope.vendor = null;
            }

            if ($scope.filters['department'] && !$scope.searchPage && !$scope.brandPage) {
                var description = descriptions[$scope.filters['department']];
                if (description) {
                    $scope.setMetaDescription(description);
                }
                $scope.content = content[$scope.filters['department']];
            } else {
                $scope.content = null;
            }

            addBreadcrumbFromFilter('departments', 'department', true);
            addBreadcrumbFromFilter('categories', 'category');
            addBreadcrumbFromFilter('styles', 'style');
            addBreadcrumbFromFilter('types', 'type');

            var title = '';

            if ($scope.filters.q) {
                title = 'Search Results for ' + $scope.filters.q;
            }

            if ($scope.brandPage) {
                title = $scope.filters['vendor']
            }

            angular.forEach($scope.breadcrumbs, function(breadcrumb, i) {
                if ($scope.filters.q || $scope.brandPage || i > 0) {
                    title += ' > ';
                }
                title += breadcrumb.name;
            });

            if (!title || title == '') {
                title = 'Catalog';
            }

            $scope.setMetaTitle(title);
        };

        var productOffset = 0;
        function getProductParams(loadingMore) {
            $scope.filters = FilterService.getFilters();
            var filters = FilterService.getFilters();

            var params = {
                term: filters.q,
                facetFilters: []
            };

            if (params.term && $scope.appendedSearchQuery) {
                params.term = params.term + ' ' + $scope.appendedSearchQuery;
            } else if($scope.appendedSearchQuery) {
                params.term = $scope.appendedSearchQuery;
            }

            if (filters.catalogChecked) {
                params.facetFilters.push(filters.catalogChecked);
            }

            if (filters.vendor) {
                angular.forEach(filters.vendor, function(vendor) {
                    params.facetFilters.push('taxonomy.vendor.name:' + vendor);
                });
            }
            if (filters.vendors && !filters.vendor)    {
                var vendors = [];
                angular.forEach(filters.vendors, function (s) {
                    vendors.push('taxonomy.vendor.name:' + s);
                });
                params.facetFilters.push(vendors);
            }

            if (filters.filters) {
                angular.forEach(filters.filters, function (values, title) {
                    angular.forEach(values, function (v, k) {
                        params.facetFilters.push('item_specifications:' + title + '|' + v);
                    });
                });
            }

            if (filters.department) {
                params.facetFilters.push('taxonomy.department.name:' + filters.department);
            }
            if (filters.category) {
                params.facetFilters.push('taxonomy.category.name:' + filters.category);
            }
            if (filters.style) {
                params.facetFilters.push('taxonomy.style.name:' + filters.style);
            }
            if (filters.styles && !filters.style)    {
                var styles = [];
                angular.forEach(filters.styles, function (s) {
                    styles.push('taxonomy.style.name:' + s);
                });
                params.facetFilters.push(styles);
            }
            if (filters.type) {
                params.facetFilters.push('taxonomy.type.name:' + filters.type);
            }

            if ($scope.options && $scope.options.orderBy) {
                params.orderBy = $scope.options.orderBy;
            }

            if (!loadingMore) {
                params.facets = '*';
            }

            return params;
        }

        $scope.loadMore = function () {
            return !$scope.loadingProducts && !$scope.loadingMoreProducts && $scope.productMeta && $scope.productMeta.current_items < $scope.productMeta.total_items;
        };

        $scope.formatCatalog = function(catalog) {
            const needle = /_/g;
            return catalog.replace(needle, ' ');
        }

        $scope.fetchProducts = function(loadingMore, cancelShowLoading) {
            $scope.loadingMoreProducts = loadingMore;
            if (!cancelShowLoading) {
                $scope.loadingProducts = !loadingMore;
            }

            if (!loadingMore) {
                productOffset = 0;
                if (!cancelShowLoading) {
                    $scope.products = null;
                }
            } else {
                productOffset++;
            }

            $scope.productRetrievalError = false;
            var params = getProductParams(loadingMore);
            params.page = productOffset;
            params.hitsPerPage = 10;
            var maxItems = 6;
            params.distinct = $scope.user.availableCatalogs && $scope.user.availableCatalogs.length > 1 ? maxItems * $scope.user.availableCatalogs.length : maxItems;
            params.getRankingInfo = true;

            // Per Algolia, hitsPerPage * distinct should be <= 1000
            while (params.distinct * params.hitsPerPage > 1000) {
                params.distinct--;
            }

            return AlgoliaService.searchProducts(params).then(function(data) {
                if (data.hits.length === 0) {
                    $scope.loadingProducts = false;
                    $scope.loadingMoreProducts = false;
                    $scope.productRetrievalError = true;
                    return false;
                }

                $scope.productMeta = {
                    current_items: data.hits.length,
                    limit: data.hitsPerPage,
                    offset: data.page,
                    total_items: data.nbHits >= 0 ? data.nbHits : data.hits.length,
                    applied_rules: data.appliedRules
                };

                $scope.queryUserData = data.userData;
                if (!loadingMore) {
                    $scope.departmentFacets = objectToArray($filter('orderByObjectKeys')(data.facets['taxonomy.department.name']));
                    $scope.categoryFacets = objectToArray($filter('orderByObjectKeys')(data.facets['taxonomy.category.name']));
                    $scope.styleFacets = objectToArray($filter('orderByObjectKeys')(data.facets['taxonomy.style.name']));
                    $scope.typeFacets = objectToArray($filter('orderByObjectKeys')(data.facets['taxonomy.type.name']));
                    $scope.vendorFacets = objectToArray($filter('orderByObjectKeys')(data.facets['taxonomy.vendor.name']));

                    if ($scope.user && $scope.user.availableCatalogs) {
                        angular.forEach($scope.user.availableCatalogs, function(catalogFacet) {
                            angular.forEach(data.facets.catalogs, function(x, y) {
                                if (catalogFacet.name == y) {
                                    catalogFacet.product_count = x;
                                }
                            });
                        });
                        $scope.productCount = [];
                        angular.forEach($scope.user.availableCatalogs, function(catalogFacet) {
                            if(catalogFacet.product_count > 0) {
                                $scope.productCount.push(catalogFacet);
                            }
                        });
                        if ($scope.productCount.length == 1) {
                            $scope.productCount[0].checked = true;
                            $scope.productCount[0].disabled = true;
                        }
                    }

                    $scope.initItemFacets();
                    $scope.contextFacets = FacetService.parseItemSpecs(data.facets['item_specifications']);
                    var formattedProducts = [];

                    angular.forEach(data.hits, function (product) {
                        if ($scope.user) {
                            angular.forEach($scope.user.catalog_meta_data, function(metaData){
                                if (product.catalogs == metaData.catalog) {
                                    product.imageUrl = metaData.imageUrl;
                                }
                            });
                        }

                        formattedProducts.push(product);
                    });
                    $scope.products = formattedProducts;
                    calculateCurrentItems();

                    AnalyticsService.search(params, $scope.productMeta.current_items);
                } else {
                    angular.forEach(data.hits, function (product) {
                        if ($scope.user) {
                            angular.forEach($scope.user.catalog_meta_data, function(metaData){
                                if (product.catalogs == metaData.catalog) {
                                    product.imageUrl = metaData.imageUrl;
                                }
                            });
                        }
                        $scope.products.push(product);
                    });
                    calculateCurrentItems();
                }

                var pricingItems = [];
                angular.forEach(data.hits, function (product) {
                    if (product.items && product.items.length) {
                        angular.forEach(product.items, function(item) {
                            item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer);
                            $scope.$broadcast("getDirectShip", {vendor: item.vendor.vendor_id, item: item});
                            pricingItems.push(item);
                        });
                    }
                });

                $scope.priceLoading = false;
                PricingService.getPricing(pricingItems).then(function (pricing) {
                    if (pricing) {
                        $scope.priceLoading = true;
                        angular.forEach(pricingItems, function (item) {
                            item.unavailable = false;
                            if (!Array.isArray(item.specifications)) {
                                item.specifications = objectToArray(item.specifications);
                            }
                            angular.forEach(pricing, function (p) {
                                angular.forEach(p, function (i) {
                                    if (item.erp == i.erp) {
                                        item.price = i.price;
                                        item.display = i.display;
                                        item.pricing = i.pricing;
                                        item.uom = i.uom;
                                        item.quantity_available = i.quantity_available;
                                        item.quantity = 0;
                                        item.selected_uom = i;

                                        if (!item.hasOwnProperty("units") || !item.hasOwnProperty("unit_type")) {
                                            angular.forEach(item.item_specifications, function (spec) {
                                                var split = spec.split('|');
                                                if (split[0] === "Units") {
                                                    item.units = split[1];
                                                    item.quantity = 0;
                                                }
                                                if (split[0] === "Unit Type") {
                                                    item.unit_type = split[1];
                                                }
                                            });
                                        }

                                        if ('units' === $rootScope.workflow.currency) {
                                            if (item.units === undefined) {
                                                item.unavailable = true;
                                            }
                                        } else {
                                            if (item.price === undefined) {
                                                item.unavailable = true;
                                            }
                                        }

                                        return false;
                                    }
                                });
                            });
                        });

                        $rootScope.$broadcast("getPriceRange");
                        $scope.priceLoading = false;
                    }
                });

                $scope.updateBreadcrumbs();

                $scope.loadingMoreProducts = false;
                $scope.loadingProducts = false;
                AnalyticsService.productImpressions(data.products, window.location.hash);
            }, function(err) {
                $scope.loadingProducts = false;
                $scope.loadingMoreProducts = false;
                $scope.productRetrievalError = true;
            });
        };

        $scope.showAllItems = function(product, show) {
            if (show) {
                if (product.allItemsLoaded) {
                    product.loadingAllItems = false;
                    product.showAllItems = true;
                } else {
                    product.loadingAllItems = true;
                    var params = getProductParams(false);
                    params.hitsPerPage = 1000;
                    AlgoliaService.getProductBySlug(product.slug, params).then(function (res) {
                        if (res && res.items) {
                            var newItems = [];
                            angular.forEach(res.items, function (item) {
                                var alreadyAdded = false;
                                angular.forEach(product.items, function (oldItem) {
                                    if (item.objectID === oldItem.objectID) {
                                        alreadyAdded = true;
                                        return false;
                                    }
                                });

                                if (!alreadyAdded) {
                                    var newSpecs = [];
                                    item.displayItemNo = $filter('partNumber')(item, $rootScope.currentCustomer);
                                    $scope.$broadcast("getDirectShip", {vendor: item.vendor.vendor_id, item: item});
                                    angular.forEach(item.specifications, function (spec) {
                                        newSpecs.push(spec.value);
                                    });
                                    item.specifications = newSpecs;
                                    newItems.push(item);
                                }
                            });
                            res.items = newItems;
                            AlgoliaService.setPricing(res, 'units' === $rootScope.workflow.currency).then(function () {
                                product.items = product.items.concat(res.items);
                                product.allItemsLoaded = true;
                                product.loadingAllItems = false;
                                product.showAllItems = true;
                                $rootScope.$broadcast("getPriceRange");
                            }, function () {
                                product.loadingAllItems = false;
                                $rootScope.$broadcast('notify', {message: 'Failed to load product.'});
                            });
                        } else {
                            product.loadingAllItems = false;
                            $rootScope.$broadcast('notify', {message: 'Failed to load product.'});
                        }
                    }, function () {
                        product.loadingAllItems = false;
                        $rootScope.$broadcast('notify', {message: 'Failed to load product.'});
                    });
                }
            } else {
                product.loadingAllItems = false;
                product.showAllItems = false;
            }
        };

        var calculateCurrentItems = function() {
            if ($scope.productMeta.applied_rules) {
                // counts are handled differently when custom search rules are applied
                var slugs = [];
                angular.forEach($scope.products, function(product) {
                    if (slugs.indexOf(product.slug) === -1) {
                        slugs.push(product.slug);
                    }
                });
                $scope.productMeta.current_items = slugs.length;
            } else {
                $scope.productMeta.current_items = $scope.products.length;
            }
        };

        $rootScope.$on("getPriceRange", function () {
            angular.forEach($scope.products, function (product) {
                product.highestPrice = 0;
                product.lowestPrice = undefined;
                product.lowestUom = "";
                product.highestUom = "";
                angular.forEach(product.items, function (item) {
                    if (item.price !== undefined) {
                        if (item.price <= product.lowestPrice || product.lowestPrice === undefined) {
                            product.lowestPrice = item.price;
                            product.lowestUom = item.uom;
                        }
                        if (item.price > product.highestPrice) {
                            product.highestPrice = item.price;
                            product.highestUom = item.uom;
                        }
                    }
                });
            });
        });

        $scope.$on("getDirectShip", function (e, object) {
            AlgoliaService.searchTaxonomy({
                hitsPerPage: 1,
                facetFilters: ['type:vendor', 'vendor_id:' + object.vendor]
            }, dupTaxResults).then(function(data) {
                if (data && data.hits && data.hits.length) {
                    var vendor = data.hits[0];
                    if (vendor.direct_ship) {
                        object.item.direct_ship = vendor.direct_ship;
                        object.item.apsv = vendor.apsv;
                    }
                }
            });
        });

        function objectToArray(obj) {
            var formatted = [];
            angular.forEach(obj, function (row, key) {
                formatted.push({
                    name: key,
                    product_count: row
                });
            });
            return formatted;
        }

        $scope.checkActiveOptions = function() {
            var opts = 0;
            if ($scope.options.selectedTypeFacet) { opts++; }
            if ($scope.options.selectedStyleFacet) { opts++; }
            if ($scope.options.selectedCategoryFacet && !$scope.preFilteredPage) { opts++; }
            return opts > 1;
        };

        $scope.initItemFacets = function() {
            var activeOpts = 0;
            if ($scope.options.selectedCategoryFacet) { activeOpts++; }
            if ($scope.options.selectedTypeFacet) { activeOpts++; }
            if ($scope.options.selectedStyleFacet) { activeOpts++; }
            if ($scope.filters && $scope.filters.vendor) { activeOpts++; }
            $scope.showItemFacets = activeOpts > 0 || $scope.filters.filters;
        };

        $scope.showItemSpecFilters = function() {
            $scope.showItemFacets = true;
        };

        $scope.clearOptions = function() {
            if ($scope.searchPage || $scope.brandPage) {
                $scope.options.selectedDepartmentFacet = null;
            }
            $scope.options.selectedCatalogFacet = null;
            if (!$scope.preFilteredPage) {
                $scope.options.selectedCategoryFacet = null;
            }
            $scope.options.selectedStyleFacet = null;
            $scope.options.selectedTypeFacet = null;
            $scope.fetchProductAndUpdateFacets();
        };

        $scope.updateFilters = function() {
            if ($stateParams && Object.keys($stateParams).length > 0) {
                FilterService.initFilters($stateParams);
                $stateParams = null;
            }
            var filters = FilterService.getFilters();
            filters.department = $scope.options.selectedDepartmentFacet ? $scope.options.selectedDepartmentFacet : undefined;
            filters.category = $scope.options.selectedCategoryFacet ? $scope.options.selectedCategoryFacet : undefined;
            filters.style = $scope.options.selectedStyleFacet ? $scope.options.selectedStyleFacet : undefined;
            filters.type = $scope.options.selectedTypeFacet ? $scope.options.selectedTypeFacet : undefined;

            filters.catalogChecked = [];

            if ($scope.user && $scope.user.availableCatalogs) {
                angular.forEach($scope.user.availableCatalogs, function (catalog) {
                    if (catalog.status != false) {
                        filters.catalogChecked.push('catalogs:' + catalog.name);
                    }
                });
            }

            FilterService.setFilters(filters);

            FilterService.updateFilterQuery(dbFilter);
            $scope.filters = filters;
        };

        $scope.fetchProductAndUpdateFacets = function(cancelFilterUpdate, cancelShowLoading) {
            if ($scope.updatingAll) {
                return false;
            }

            $scope.updatingAll = true;
            if (!cancelShowLoading) {
                $scope.loadingProducts = true;
            }

            if (!cancelFilterUpdate) {
                $scope.updateFilters();
            }

            return $scope.fetchProducts(false, cancelShowLoading).then(function() {
                $scope.updateCheckedFromFilters();
                $scope.updatingAll = false;
            }, function() {
                $scope.updatingAll = false;
            });
        };

        $scope.updateFromFilters = function(params) {
            var filters = FilterService.getFilters();
            $scope.options.selectedDepartmentFacet = params.department ? params.department : filters.department;
            $scope.options.selectedCategoryFacet = params.category ? params.category : filters.category;
            $scope.options.selectedStyleFacet = params.style ? params.style : filters.style;
            $scope.options.selectedTypeFacet = params.type ? params.type : filters.type;
        };

        $scope.removeSingleFilter = function (key, filter, isBrand) {
            if (isBrand) {
                FilterService.deleteSingleBrand(filter);
            } else {
                FilterService.deleteSingleFilter(key, filter);
            }
            $scope.fetchProductAndUpdateFacets();
        };

        $scope.clearFilters = function () {
            FilterService.deleteFilter('filters');
            if (!$scope.brandPage) {
                FilterService.deleteFilter('vendor');
            }
            $scope.fetchProductAndUpdateFacets();
        };

        $scope.checkActiveFilters = function(list) {
            return list.map(function(e) { return e.checked; }).indexOf(true) != -1;
        };

        $scope.clearFilterBlock = function(title, list) {
            angular.forEach(list, function(value) {
                value.checked = false;
                $scope.createSelectedList(title, value);
            });
            $scope.fetchProductAndUpdateFacets();
        };

        $scope.createSelectedList = function (facet_title, item) {
            var all_filters = FilterService.getFilters();
            if (facet_title === 'Brands') {
                var vendor = all_filters.vendor;
                if (!vendor) {
                    vendor = [];
                }

                if (item.checked) {
                    vendor.push(item.name);
                } else {
                    if (vendor.length === 1) {
                        vendor = [];
                    } else {
                        vendor.splice(vendor.indexOf(item.name), 1);
                    }
                }
                if (vendor.length > 0) {
                    FilterService.setFilter('vendor', vendor);
                } else {
                    FilterService.deleteFilter('vendor');
                }
            } else {
                var filters = all_filters.filters;
                if (undefined == filters) filters = {};
                if (item.checked) {
                    if (undefined == filters[facet_title]) filters[facet_title] = [];
                    filters[facet_title].push(item.name);
                } else if (filters[facet_title] != null) {
                    if (filters[facet_title].length == 1) {
                        delete filters[facet_title];
                    } else {
                        filters[facet_title].splice(filters[facet_title].indexOf(item.name), 1);
                    }
                }

                if (Object.keys(filters).length) {
                    FilterService.setFilter('filters', filters);
                } else {
                    FilterService.deleteFilter('filters');
                }
            }
        };

        $scope.updateCheckedFromFilters = function() {
            var filters = FilterService.getFilters();

            if ($scope.vendorFacets) {
                angular.forEach($scope.vendorFacets, function (v) {
                    v.checked = filters.vendor && filters.vendor.includes(v.name);
                });
            }

            if ($scope.contextFacets) {
                angular.forEach($scope.contextFacets, function (v) {
                    angular.forEach(v.values, function (value) {
                        value.checked = filters.filters && filters.filters[v.title] && filters.filters[v.title].includes(value.name);
                    });
                });
            }
        };

        $scope.appendedSearchQuery = '';
        $scope.suggestSearch = function(query) {
            $scope.appendedSearchQuery = query;
            $scope.fetchProductAndUpdateFacets(false, true);
        };

        $scope.loadingProducts = true;
        $scope.updateFromFilters($stateParams);
        $scope.fetchProductAndUpdateFacets();

        //Listen for back button and update.
        $scope.$on('$locationChangeSuccess', function() {
            if (!$scope.updatingAll) {
                var params = $location.search();
                if (params && Object.keys(params).length > 0) {
                    init(params);
                    $scope.updateFromFilters(params);
                    $scope.fetchProductAndUpdateFacets();
                }
            }
        });

        $scope.showFilters = false;
        $scope.setMobileFiltersShow = function () {
            $scope.showFilters = (!$scope.showFilters);
        };

        $scope.isNullCatalog = UserService.isNullCatalog();
        $scope.errorMessage = function() {
            if ($scope.isNullCatalog) {
                return CatalogService.nullCatalogMsg();
            } else {
                return 'No products found matching your search. Please search a different term and try again.';
            }
        };
    }
]);
