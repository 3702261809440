(function () {
    'use strict';
}());

app.controller('StrategicSourcingController', [
    '$rootScope',
    '$scope',
    '$state',
    'CSVService',
    'user',
    function($rootScope, $scope, $state, CSVService, user) {
        if (!user || !user.is_vallen) {
            $state.go('home');
            return;
        }

        $scope.loading = true;
        $scope.query = '';
        $scope.data = [];

        $scope.resetHeadings = function() {
            angular.forEach($scope.data, function(heading) {
                heading.matches = false;
            });
        };

        $scope.filterData = function(row) {
            if (!row || !row[0]) {
                return false;
            }
            var query = $scope.query.toLowerCase();
            var match = !$scope.query ||
                row[0].toLowerCase().includes(query) ||
                row[2].toLowerCase().includes(query) ||
                row[3].toLowerCase().includes(query);

            if (match) {
                row.heading.matches = true;
            }

            return match;
        };

        CSVService.loadCSV('/assets/data/strategic_sourcing.csv').then(function(data) {
            $scope.data = [];
            var sort = {};
            angular.forEach(data, function(row) {
                var heading = null;
                if (sort[row[2]]) {
                    heading = sort[row[2]];
                } else {
                    if (row[2]) {
                        heading = {
                            title: row[2],
                            rows: []
                        };
                        sort[row[2]] = heading;
                    }
                }

                if (heading) {
                    row.heading = heading;
                    heading.rows.push(row);
                }
            });

            angular.forEach(sort, function(heading) {
                $scope.data.push(heading);
            });
        }).catch(function(err) {
            console.error(err);
            $rootScope.$broadcast('notify', {message: "Failed to load data."});
        }).finally(function() {
            $scope.loading = false;
        });
    }
]);
