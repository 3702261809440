(function () {
    'use strict';
}());

app.controller('OrderPadController', [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    'CartService',
    'OrderPadService',
    'UserService',
    'PartService',
    function($rootScope, $scope, $state, $timeout, CartService, OrderPadService, UserService, PartService) {
        if (UserService.getIsVallenXpress()) {
            $state.go('404');
        }

        $scope.fallBackImg = $rootScope.s3Resources + '/catalog/products/fallback_square_107x100.gif';
        $scope.lineImage = function (line) {
            return line.image ? line.image : $scope.fallBackImg;
        }

        $scope.partNumberWidth = ($rootScope.isMobile || $rootScope.isSmall) ? {'width': '35%'} : {'width': '70%'};
        $rootScope.apiLoading = false;
        $rootScope.$on("refreshOrderLines", function () {
            var orderLines  = [];
            var errors = 0;
            OrderPadService.getOrderLines().then( function (lines) {
                angular.forEach(lines, function (line) {
                    if (!line.error)  {
                        orderLines.push(line);
                    } else {
                        errors++;
                    }
                });
                if (errors) {
                    $rootScope.$broadcast('notify', {message: "Unable to get " + errors + " Order Pad lines"});
                }
                $scope.orderLines = orderLines;
                $scope.newLines = OrderPadService.refreshOrderLines(orderLines);
                $scope.countLines = OrderPadService.countOrderLines(orderLines);
                $scope.showload = false;
                $scope.lineLoading = false;
                $rootScope.apiLoading = false;
                if (!$rootScope.summaryView) {
                    $rootScope.summaryView = ($scope.countLines > 0);
                } else if ($scope.countLines === 0) {
                    $rootScope.summaryView = false;
                }
            });
        });

        $rootScope.$on("gatherErrorLines", function (e, lines) {
            $scope.errorLines = lines;
        });

        $scope.$on('gatherOrderLines', function (e, arg) {
            var array = [];
            if ($scope.orderLines && $scope.orderLines.length) {
                angular.forEach($scope.orderLines, function (line) {
                    array.push(line);
                });
            }

            angular.forEach(arg, function (line) {
                array.push(line);
            });

            OrderPadService.gatherOrderLines(array).then( function (data) {
                $rootScope.apiLoading = false;
                if (data) {
                    var errorMessages = [];
                    angular.forEach(data.items, function (line) {
                        if (line.error) {
                            line.set = 3;
                            errorMessages.push(line);
                        } else {
                            line.set = 2;
                            var lineNumber = (OrderPadService.countOrderLines() || 0) + 1;
                            OrderPadService.setOrderLine(lineNumber, line);
                        }
                    });
                    if (errorMessages.length > 0) {
                        $rootScope.$broadcast('gatherErrorLines', errorMessages);
                    }
                    $rootScope.$broadcast("refreshOrderLines");
                } else {
                    $rootScope.$broadcast('notify', {message: "Something went wrong with gathering data. Please try again."})
                }
            });
        });

        $scope.clearAlert = function () {
            $scope.errorLines = [];
        };

        $rootScope.$broadcast("refreshOrderLines");
        $scope.checkEmpty = function (index) {
            var last = $scope.newLines.length - 1,
                isFull = true;
            angular.forEach($scope.newLines, function (n) {
                if (n.erp === "") {
                    isFull = false;
                    return true;
                }
            });
            if (index === last && isFull) {
                $scope.newLines.push(OrderPadService.freshLine());
            } else {
                if (isFull) {
                    $scope.newLines.push(OrderPadService.freshLine());
                }
            }
        };

        $scope.blurLine = function(line) {
            $timeout(function() {
                line.focus = false;
            }, 200);
        };

        $scope.lineLoading = false;
        $scope.autoFillItem = function (index, line) {
            if (line !== "") {
                $scope.search = [];
                $scope.lineLoading = index;
                $scope.searchload = true;
                PartService.searchProducts(line).then( function (results) {
                    $scope.searchload = false;
                    if (results) {
                        $scope.search = results;
                    } else {
                        $scope.search = { part_results: [], cpn_results: [] };
                    }
                });
            } else {
                $scope.searchload = false;
            }
        };

        $scope.selectAutoFillItems = function (index, line, type) {
            var nl = $scope.newLines[index];
            nl.selected = true;
            nl.partNumber = type === 'cpn' ? line.cpn : line.erp;
            nl.type = type;
            nl.erp = line.erp;
            nl.cpn = line.cpn;
            $scope.showload = false;
            $scope.lineLoading = false;
        };

        $scope.addOrderLine = function (line, index) {
            $rootScope.apiLoading = true;
            if (line.partNumber) {
                line.set = 1;
                line.order = index;
                $scope.$emit('gatherOrderLines', [line]);
            }
        };

        $scope.addAllOrderLines = function (all) {
            $rootScope.apiLoading = true;
            angular.forEach(all, function (line, index) {
                if (line.hasOwnProperty('partNumber')) {
                    line.set = 1;
                    line.index = index;
                }
            });
            $scope.$emit('gatherOrderLines', all);
        };

        var promise;
        $scope.removeOrderLine = function (index) {
            $rootScope.apiLoading = true;
            if (promise) $timeout.cancel(promise);
            OrderPadService.deleteOrderLine(index, $scope.newLines).then( function () {
                OrderPadService.deleteOrderLine(index, $scope.orderLines).then( function (lines) {
                    promise = $timeout(function () {
                        OrderPadService.gatherOrderLines(lines).then( function () {
                            $rootScope.$broadcast("refreshOrderLines");
                        });
                    }, 1000);
                });
            });
        };

        $scope.addQty = function (item) {
            if (item.set === 2) {
                return;
            }
            item.quantity++;
        };

        $scope.reduceQty = function (item) {
            if (item.set === 2) {
                return;
            }
            if (item.quantity > 1) {
                item.quantity--;
            }
        };

        $scope.addToCart = function () {
            $rootScope.$broadcast('open-loading-modal');
            var obj = {};
            angular.forEach($scope.orderLines, function (line) {
                var partNumber = line.erp;
                if (!obj.hasOwnProperty(partNumber)) {
                    obj[partNumber] = {
                        description: line.description ? line.description : ' ',
                        erp: line.erp,
                        iref: line.iref,
                        cpn: line.cpn,
                        price: line.price,
                        quantity: parseInt(line.quantity),
                        image: line.image,
                        uom: line.uom
                    };
                } else {
                    obj[partNumber].quantity = parseInt(obj[partNumber].quantity) + parseInt(line.quantity);
                }
            });

            var lines = Object.keys(obj).map( function (key) {
               return obj[key];
            });

            OrderPadService.deleteOrderPad().then(function () {
                UserService.getSessionId().then(function (session) {
                    CartService.postCart(session, lines).then(function (data) {
                        $rootScope.$broadcast('close-loading-modal');
                        if (data) {
                            OrderPadService.deleteOrderLines();
                            $state.go('cart')
                        } else {
                            $rootScope.$broadcast('notify', { message: "Unable to add to Cart." });
                        }
                    });
                });
            });
        };

        $scope.clearOrderPad = function () {
            return OrderPadService.deleteOrderPad().then( function () {
                $rootScope.$broadcast("refreshOrderLines");
            });
        };

        switch ($state.current.url) {
            case "/import":
                $scope.currentTabIndex = 1;
                break;
            case "/paste":
                $scope.currentTabIndex = 2;
                break;
            default:
                $scope.currentTabIndex = 0;
                break;
        }

        $scope.$watch($scope.currentTabIndex);
    }
]);

app.controller('OrderPadCSVController', [
    '$scope',
    '$state',
    '$rootScope',
    function($scope, $state, $rootScope) {
        $scope.loading = false;

        var stop = false;

        function handleData(data) {
            $scope.total = 0;
            $scope.$emit('gatherOrderLines', data);
        }

        function importError() {
            $rootScope.$broadcast("notify", { message: 'Invalid file format. Make sure file contains columns for item number and item quantity.'});
            $scope.loading = false;
            $rootScope.$broadcast('refreshOrderLines');
        }

        $scope.$watch('fileContent', function () {
            if (!$scope.fileContent) {
                return false;
            }
            $rootScope.apiLoading = true;
            $rootScope.summaryView = true;
            stop = false;
            try {
                var target = ($scope.fileContent.target) ? $scope.fileContent.target.result : $scope.fileContent;
                var workbook = XLSX.read(
                    target,
                    {type: 'binary'}
                );
                var sheet = workbook.Sheets[workbook.SheetNames[0]];
                var data = [];
                var row = 1;
                for (var i=0; i<Object.keys(sheet).length; i++) {
                    var key = Object.keys(sheet)[i];
                    var cell = sheet[key];
                    if ((key.startsWith('A')) && sheet['B' + row]) {
                        data.push({
                            term: cell.v,
                            partNumber: cell.v,
                            quantity: sheet['B' + row].v
                        });
                        row++;
                    } else if(key.startsWith('B')) {

                    } else {
                        // break;
                    }
                }
                if (!data.length) {
                    importError();
                } else {
                    handleData(data);
                }
            } catch(e) {
                importError();
            }

            $scope.fileContent = null;
        }, false);
    }
]);

app.controller('OrderPadCopyPasteController', [
    '$scope',
    '$rootScope',
    'OrderPadService',
    function($scope, $rootScope, OrderPadService) {
        $scope.loading = false;
        $scope.uploadCopyPaste = function () {
            if (!$scope.copyPaste) {
                return false;
            }
            $scope.loading = true;
            $rootScope.apiLoading = true;
            $rootScope.summaryView = true;
            OrderPadService.parseOrderLines($scope.copyPaste).then(function (data) {
                $scope.$emit('gatherOrderLines', data);
                $scope.copyPaste = "";
                $scope.loading = false;
            });
        };
    }
]);
