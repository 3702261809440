(function () {
    'use strict';
}());

app.directive('alertErrors', [
    function () {
        return {
            restrict: 'E',
            template: '<div class="alert alert-danger" data-ng-if="errors.length">' +
                '<p class="lead no-margin">' +
                '<i class="fa fa-exclamation-triangle"></i> Please correct the following errors:' +
                '</p>' +
                '<ul><li data-ng-repeat="e in errors">{{e}}</li></ul>' +
                '</div>',
            scope: {
                errors: '='
            }
        }
    }
]);
