(function() {
    'use strict';
}());

app.service('ShoppingListService', [
    '$http',
    '$uibModal',
    '$q',
    '$state',
    '$cookies',
    'UserService',
    'UtilityService',
    'PricingService',
    'API_URL',
    function($http, $uibModal, $q, $state, $cookies, UserService, UtilityService, PricingService, API_URL) {

        this.getLists = function(data){
            var deferral = $q.defer();
            var owner = null;
            var url = API_URL + "/shopping-lists";
            var user = UserService.getPersistedUser();
            if (undefined == user) {
                deferral.resolve(false);
                return deferral.promise;
            }

            url += "/" + user.id + "?format=_json";
            if (!data) {
                data = {};
            }
            if (!data.limit) {
                data.limit = 1000;
            }

            if (data.owner) owner = user.id;
            if (data.filter) url += "&filter=" + data.filter;
            if (data.limit) url += "&limit=" + data.limit;
            if (data.offset) url += "&offset=" + data.offset;
            if (!data.order_by) data.order_by = "updated_on";
            if (!data.order_direction) data.order_direction = "DESC";
            url += "&order_by=" + data.order_by + "&order_direction=" + data.order_direction;

            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                var array = [];
                angular.forEach(response.data.shopping_lists, function (list) {
                    if (!owner || owner === list.uid) {
                        array.push(list);
                    }
                });
                deferral.resolve(array);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.shoppingListModal = function(data, selectedItems) {
            var lists = data;

            var msg = (lists.length > 0);
            var modalInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'shoppingListModal.html',
                controller: 'shoppingListModalInstanceCtrl',
                windowClass: 'shopping-list-modal',
                size: 'lg',
                resolve: {
                    msg: function () {
                        return msg;
                    },
                    lists: function(){
                        return lists;
                    },
                    selectedItems: function(){
                        return selectedItems;
                    },
                    options: function () {
                        return {};
                    }
                }
            });
            modalInstance.result.then(function(){
                // modal closed
            }, function() {
                // modal dismissed
            });
        };

        this.getList = function (list, refresh) {
            var deferral = $q.defer();
            var url = API_URL + "/shopping-list/" + list;
            if (refresh) {
                url += "/refresh";
                if ($cookies.get("isPunchOut")) {
                    url += '?punchout=1';
                }
            }
            $http({
                method: "GET",
                url: url,
                headers: {"Authorization": "Bearer {"+UserService.getToken()+"}"}
            }).then(function (response) {
                var data = response.data;
                angular.forEach(data.items, function(item) {
                    PricingService.getPricing([item], true).then(function(data) {
                        if (data && data[0]) {
                            data = data[0][0];
                            if (item.erp === data.erp) {
                                item.price = data.price;
                                item.uom = data.uom;
                                item.quantity_available = data.quantity_available;
                            }
                        }
                    });

                    if (!item.hasOwnProperty("units")) {
                        angular.forEach(item.item_specifications, function (spec) {
                            var split = spec.split('|');
                            if (split[0] === "Units") {
                                item.units = Number(split[1]);
                            }
                        });
                    }
                });

                deferral.resolve(data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.postList = function (list, items) {
            var deferral = $q.defer();
            var url = API_URL + "/shopping-list";

            var data = {
                "name": list.name,
                "description": list.description,
                "public": list.public,
                "user_id": 0,
                "items": ""
            };

            var user = UserService.getPersistedUser();
            if (undefined !== user) {
                data.user_id = user.id;
            }

            if (undefined !== items) {
                data.items = items;
            }

            $http({
                method: "POST",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: data
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.updateList = function(list){
            var deferral = $q.defer();
            var url = API_URL + "/shopping-list/"+list.id;
            var data = {
                "name": list.name,
                "product_items": list.items,
                "description": list.description,
                "public": list.public,
                "users": list.users
            };

            $http({
                method: "PUT",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"},
                data: data
            }).then(function (response) {
                data.edit = 0;
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.deleteList = function(list){
            var deferral = $q.defer();
            var url = API_URL + "/shopping-list/"+list.id;

            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.deleteListItem = function(list, item){
            var deferral = $q.defer();
            var url = API_URL + "/shopping-list/"+list.id+"/item/"+item._id;
            $http({
                method: "DELETE",
                url: url,
                headers: {"Authorization": "Bearer {" + UserService.getToken() + "}"}
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        this.shareList = function (list, users) {
            var deferral = $q.defer();


            if (list === undefined) {
                deferral.resolve(false);
            } else {
                var url = API_URL + "/shopping-list/" + list.id + "/share";
                var data = {
                    'users': [],
                    'response_link': $state.href('account.shopping_lists', {}, {absolute: true})
                };

                angular.forEach(users, function (u) {
                    data.users.push(u);
                });

                $http({
                    method: "POST",
                    url: url,
                    data: JSON.stringify(data),
                    headers: {"Authorization":"Bearer {"+ UserService.getToken() +"}"},
                }).then( function () {
                    deferral.resolve(true);
                }, function () {
                    deferral.resolve(false);
                });
            }

            return deferral.promise;
        };

        this.shoppingListExport = function (user, lists) {
            var data = [];
            angular.forEach(lists, function (list) {
                var custName = '';
                if (user && user.customer_data) {
                    custName = user.customer_data.name;
                    if (user.customer_data.alt_name) {
                        custName += ' (' + user.customer_data.alt_name + ')';
                    }
                }
                angular.forEach(list.items, function (item) {
                    var fields = [
                        UtilityService.cleanCsv(list.customer_number),
                        UtilityService.cleanCsv(custName),
                        UtilityService.cleanCsv(list.name),
                        UtilityService.cleanCsv(list.description),
                        UtilityService.cleanCsv(list.owner_username),
                        UtilityService.cleanCsv(item.cpn),
                        UtilityService.cleanCsv(item.erp),
                        UtilityService.cleanCsv(item.description),
                        UtilityService.cleanCsv(item.quantity),
                        UtilityService.cleanCsv(item.uom),
                        item.tags ? UtilityService.cleanCsv(item.tags.join(';')) : ''
                    ];
                    data.push('"' + fields.join('","') + '"\n');
                });
            });
            if (data.length) {
                data.unshift("CustomerNumber,CustomerName,ListName,ListDesc,UserName,CustPartNo,SXePartNbr,ItemDesc,Quantity,UOM,Tags\n");
                return data;
            }
            return null;
        };
    }
]);
