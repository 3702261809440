(function() {
    'use strict';
}());

app.service('PromoCodeService', [
    '$http',
    '$q',
    'API_URL',
    function($http, $q, API_URL) {
        var self = this;

        self.getPromoCode = function (promoCode) {
            var deferral = $q.defer();
            var url = API_URL + "/promo-code/" + promoCode;
            $http({
                method: "GET",
                url: url
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false)
            });

            return deferral.promise;
        };

        self.getLiveCount = function () {
            var deferral = $q.defer();
            var url = API_URL + "/promo-codes/live-count";
            $http({
                method: "GET",
                url: url
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false)
            });
            return deferral.promise;
        };
    }
]);
