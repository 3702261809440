(function () {
    'use strict';
}());

app.directive('sdsLink', [
    function () {
        return {
            restrict: 'E',
            template: '<span>' +
                '<a data-ng-if="product.sds_url" href="{{ product.sds_url }}" target="_blank" class="sds-link">' +
                '<img src="{{ productResources }}/general-purpose/sds_icon.png" alt="SDS">' +
                'View Safety Data Sheet (SDS)</a>' +
                '</span>',
            replace: true,
            scope: {
                product : '='
            },
            controller: ['$scope', 'PRODUCT_RESOURCES', function($scope, PRODUCT_RESOURCES) {
                $scope.productResources = PRODUCT_RESOURCES;
            }]
        }
    }
]);
