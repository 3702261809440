(function () {
    'use strict';
}());

app.service('SupportService', [
    '$http',
    '$q',
    'API_URL',
    function ($http, $q, API_URL) {
        var self = this;

        self.sendMessage = function (message) {
            var url = API_URL + '/support';
            var deferral = $q.defer();

            $http.defaults.useXDomain = true;

            $http({
                method: "POST",
                url: url,
                data: message
            }).then( function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err)
            });

            return deferral.promise;
        };

        self.getInquiryTypes = function () {
            return [
                { code: 'quote', text: 'Request for Quote' },
                { code: 'salesPerson', text: 'Have a Sales Person Contact Me' },
                { code: 'invoiceCopy', text: 'Copy of Invoice' },
                { code: 'payablesReceivables', text: 'Payables or Receivables' },
                { code: 'w9', text: 'W-9 or Resale Certificate' },
                { code: 'feedback', text: 'Feedback' },
                { code: 'orderSupport', text: 'Order Support' },
                { code: 'websiteSupport', text: 'Website Support' },
                { code: 'generalInquiry', text: 'General Inquiry' }
            ];
        }
    }
]);
