(function () {
    'use strict';
}());


app.directive('fallback', [
    function () {
        return {
            link: function (scope, element, attrs) {
                if (!attrs.src) {
                    attrs.$set('src', attrs.fallback);
                }
                element.bind('error', function () {
                    if (attrs.src !== attrs.fallback) {
                        attrs.$set('src', attrs.fallback);
                    }
                });
            }
        }
    }
]);
