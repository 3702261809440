(function () {
    'use strict';
}());

var blackListFacet = ['Competitor Part#', 'Trane Part#'];

app.controller('BreadcrumbController', [
    '$scope',
    '$state',
    function ($scope, $state) {
        $scope.go = function (target) {
            (null !== target.params) ? $state.go(target.state, target.params) : $state.go(target.state);
        }
    }
]);

app.controller('ListItemsController', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModal',
    '$timeout',
    'lodash',
    'UserService',
    'CartService',
    'ShoppingListService',
    '$document',
    'FilterService',
    'AnalyticsService',
    function ($rootScope, $scope, $window, $uibModal, $timeout, lodash, UserService, CartService, ShoppingListService, $document, FilterService, AnalyticsService) {
        $scope.setProductQuantity = function (product) {
            product.quantity = 0;
            angular.forEach(product.items, function (item) {
                if (!isNaN(item.quantity)) {
                    product.quantity += item.quantity;
                }
            });
        };

        $scope.productClick = AnalyticsService.productClick;

        $scope.user = UserService.getPersistedUser();

        $scope.canClose = false;

        var clickListener = function() {
            if ($scope.canClose) {
                angular.forEach($scope.products, function(p){
                    p.quickView = false;
                });
                $scope.canClose = false;
                $scope.$evalAsync();
            }
        };

        $document.on('click', clickListener);

        $scope.$on("$destroy", function() {
            $document.off('click', clickListener);
        });

        $scope.toggleQuickView = function(p) {
            if (undefined === p.quickView) {
                p.quickView = false;
            }
            angular.forEach($scope.products, function(i){
                i.quickView = i.objectID === p.objectID ? !p.quickView : false;
            });
            $timeout(function() {
                $scope.canClose = p.quickView;
            }, 1);
        };

        var setProductQuantity = function (item, product) {
            product.quantity = 0;
            angular.forEach(product.items, function (item) {
                if (!isNaN(item.quantity)) {
                    product.quantity += item.quantity;
                }
            });

            var selected = 0;
            angular.forEach(product.items, function(i) {
                if (i.quantity >= 1) {
                    selected++;
                }
            });
            product.selectedItems = selected;
        };

        var resetQuantities = function (product) {
            product.selectedItems = 0;
            angular.forEach(product.items, function (item) {
                if (item.quantity > 0) {
                    item.quantity = 0;
                }
            });
        };

        $scope.addQty = function (item, product) {
            item.quantity++;
            setProductQuantity(item, product);
        };

        $scope.reduceQty = function (item, product) {
            if (item.quantity >= 1) {
                item.quantity--;
                setProductQuantity(item, product);
            }
        };

        var errorMessage = "An error occurred while updating your cart. Please try again.";

        $scope.addToCart = function (product) {
            $rootScope.$broadcast('open-loading-modal');
            var lines = getLines(product);

            var addedItems = 0;
            lodash.forEach(product.items, function(item) {
                if (item.quantity) {
                    addedItems += item.quantity;
                }
            });

            lodash.forEach(lines, function(line) {
                AnalyticsService.addToCart(line);
            });

            UserService.getSessionId().then(function (session) {
                CartService.postCart(session, lines).then(function (data) {
                    $rootScope.$broadcast('close-loading-modal');
                    if (data) {
                        product.added = parseInt(addedItems);
                        resetQuantities(product);
                    } else {
                        product.added = false;
                        $rootScope.$broadcast('notify', {message: errorMessage});
                    }
                })
            });
        };


        $scope.addToShoppingList = function (product) {

            //check if user is logged in
            var user = UserService.getPersistedUser();
            if (null == user) {
                var errorMsg = "You must be logged in to use this feature.";
                $rootScope.$broadcast('notify', {message: errorMsg});
                return;
            }

            $rootScope.$broadcast('open-loading-modal');

            // check if Shopping lists already exist.
            ShoppingListService.getLists({ owner: true }).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data) {
                    var lists = data;

                    var msg = (lists.length > 0);
                    var selectedItems = getLines(product);
                    var modalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'shoppingListModal.html',
                        controller: 'shoppingListModalInstanceCtrl',
                        windowClass: 'shopping-list-modal',
                        size: 'lg',
                        resolve: {
                            msg: function () {
                                return msg;
                            },
                            lists: function(){
                                return lists;
                            },
                            selectedItems: function(){
                                return selectedItems;
                            },
                            options: function () {
                                return {};
                            }
                        }
                    });
                    modalInstance.result.then(function(){
                        // modal closed
                    }, function() {
                        // modal dismissed
                    });

                } else {
                    var errorMsg = "An error occurred while checking for Shopping List. Please try again.";
                    $rootScope.$broadcast('notify', {message: errorMsg});
                }
            });
        };


        var getLines = function (product) {
            var lines = [];
            var error = false;
            angular.forEach(product.items, function (item) {
                if (item.quantity > 0 && !isNaN(item.quantity)) {
                    var specs = [];
                    angular.forEach(product.specifications, function(title, index) {
                        specs.push({
                            title: title,
                            value: item.specifications[index]
                        });
                    });

                    if (item.price && item.price > 0) {
                        var uomVals = item.selected_uom && item.selected_uom.uom;
                        var i = {
                            erp: item.erp,
                            iref: item.iref,
                            description: CartService.formatItemDescription(item, product),
                            taxonomy: product.taxonomy,
                            image: product.image,
                            quantity: item.quantity,
                            price: uomVals ? item.selected_uom.price : item.price,
                            uom: uomVals ? item.selected_uom.uom.toLowerCase() : item.uom.toLowerCase(),
                            specifications: specs,
                            vendor: product.vendor.slug,
                            product_number: product.product_number,
                            product_id: product.product_id,
                            unspsc: product.unspsc,
                            cpn: item.cpn,
                            vpn: item.vpn
                        };
                        if (item.hasOwnProperty("direct_ship")) i.direct_ship = item.direct_ship;
                        if (item.hasOwnProperty("apsv")) i.apsv = item.apsv;
                        if (item.hasOwnProperty("units")) i.units = parseFloat(item.units);
                        if (item.hasOwnProperty("unit_type")) i.unit_type = item.unit_type;
                        lines.push(i);
                    } else if (!error) {
                        error = true;
                        $rootScope.$broadcast('notify', {message: "Failed to add one or more items to cart."});
                    }
                }
            });
            return lines;
        };
    }
]);

app.controller('QuickAddController', [
    '$scope',
    '$rootScope',
    '$window',
    'VendorService',
    'BLACKLIST_FACETS',
    function ($scope, $rootScope, $window, VendorService, BLACKLIST_FACETS) {
        $window.sessionStorage["quick_filtered"] = "";
        $window.sessionStorage["quick_param"] = "";

        var selectedItem = $rootScope.selectedItem;
        $rootScope.selectedItem = null;

        var getOptions = function (items) {
            var selectorOptions = [],
                options = {};

            angular.forEach($scope.product.specifications, function (spec) {
                if (!BLACKLIST_FACETS.includes(spec)) {
                    options = { title: spec, options: [] };
                    selectorOptions.push(options);
                }
            });

            angular.forEach(items, function (item) {
                angular.forEach(item.specifications, function (spec) {
                    angular.forEach(selectorOptions, function (so) {
                        if (so.title === spec.title) {
                            if (so.options.length > 0) {
                                for (var s = 0; so.options.length > s; s++) {
                                    if (so.options[s] !== spec.value) {
                                        so.options.push(spec.value);
                                        break;
                                    }
                                }
                            } else {
                                so.options.push(spec.value);
                            }
                        }
                    });
                });
            });

            //For a large list of options that seem to have breakable characters.
            Array.prototype.contains = function(v) {
                for (var i = 0; i < this.length; i++) {
                    if (this[i] === v) return true;
                }
                return false;
            };

            Array.prototype.unique = function () {
                var arr = [];
                for (var i = 0; i < this.length; i++) {
                    if (!arr.contains(this[i])) {
                        arr.push(this[i]);
                    }
                }
                return arr;
            };

            angular.forEach(selectorOptions, function (option) {
                var duplicates = [];
                angular.forEach(option.options, function (v) {
                    duplicates.push(v);
                });

                option.options = duplicates.unique();
                option.options.sort();
            });

            $rootScope.$broadcast('refresh_options', selectorOptions);
        };

        var items = $scope.product.items;
        $scope.queryItems = function (params) {
            var saved_params = ($window.sessionStorage["quick_param"] !== "") ?
                JSON.parse($window.sessionStorage["quick_param"]) : {};
            var filtered = ($window.sessionStorage["quick_filtered"] !== "") ?
                JSON.parse($window.sessionStorage["quick_filtered"]) : $scope.product.items;
            var new_filtered = [];
            var query = [];

            query.push(params);
            angular.forEach(saved_params, function (p) {
                query.push(p);
            });
            angular.forEach(filtered, function (item) {
                for (var i in item.specifications) {
                    if (params.title === item.specifications[i].title) {
                        if (params.value === item.specifications[i].value) {
                            new_filtered.push(item);
                        }
                    }
                }
            });

            $window.sessionStorage["quick_param"] = JSON.stringify(query);
            $window.sessionStorage["quick_filtered"] = JSON.stringify(new_filtered);
            getOptions(new_filtered);
            if (new_filtered.length === 1) {
                $scope.matchview = 1;
                $scope.product.selected_availability = new_filtered[0].quantity_available;
            }
        };

        $scope.resetQuery = function () {
            $scope.matchview = 0;
            $window.sessionStorage["quick_param"] = "";
            $window.sessionStorage["quick_filtered"] = JSON.stringify(items);
            getOptions(items);

            angular.forEach($scope.product.items, function(item) {
                item.quantity = 0;
            });
        };

        $scope.resetQuery();
        if ($scope.product && $scope.product.items && $scope.product.items.length == 1) {
            $scope.matchview = 1;
            $scope.product.selected_availability = $scope.product.items[0].quantity_available;
            $scope.product.items[0].quantity = 1;
        }

        $scope.addQty = function (item) {
            item.quantity++;
        };

        $scope.reduceQty = function (item) {
            if (item.quantity >= 1) {
                item.quantity--;
            }
        };
    }
]);
