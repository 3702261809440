(function() {
    'use strict';
}());

app.service('UDFService', [
    '$http',
    '$q',
    '$rootScope',
    'API_URL',
    'CartService',
    function($http, $q, $rootScope, API_URL, CartService) {
        var self = this;

        self.getUdfProperties = function (customerNumber, type) {
            var deferral = $q.defer();

            $http({
                method: 'GET',
                url: API_URL + '/udf/'+customerNumber+'?property_type=' + type
            }).then(function (response) {
                angular.forEach(response.data, function (prop) {
                    prop.patternMatch = prop.regExp ? new RegExp(prop.regExp) : null;
                    if (prop.inputType === 'date' && prop.minValue && prop.maxValue) {
                        prop.dateOptions = {
                            minDate: new Date(prop.minValue),
                            maxDate: new Date(prop.maxValue)
                        };
                    }
                });
                deferral.resolve(response.data);
            }, function () {
                deferral.resolve(false);
            });

            return deferral.promise;
        };

        self.getUdfValues = function (custNo, groupName, filter, exactMatch) {
            var deferral = $q.defer();
            var url = API_URL + '/udf/values/'+custNo+'?group_name=' + groupName + '&filter=' + filter;
            if (exactMatch) { url += '&exact_match=1'; }
            $http({
                method: 'GET',
                url: url
            }).then(function (response) {
                deferral.resolve(response.data);
            }, function (err) {
                deferral.reject(err);
            });

            return deferral.promise;
        };

        self.verifyUdfValues = function (udfProps, udfValues, scope) {
            var deferral = $q.defer();
            var promises = [];
            $rootScope.udfSearch = {};

            // cache values that have already been verified. so as not to look them up again
            if (!$rootScope.verifiedUdfs) {
                $rootScope.verifiedUdfs = {};
            }
            angular.forEach(udfProps, function (prop) {
                if (prop.inputType === 'hidden' && scope) {
                    self.setHiddenValue(prop, scope, udfValues);
                }
                var id = prop.fieldName;
                var value = udfValues && udfValues[id] ? udfValues[id].value : '';
                if (prop.mustValidate && value) {
                    if (!$rootScope.verifiedUdfs[id]) {
                        $rootScope.verifiedUdfs[id] = [];
                    }
                    if ($rootScope.verifiedUdfs[id].indexOf(value) < 0) {
                        $rootScope.udfSearch[id] = {
                            searching: true,
                            status: ''
                        };

                        promises.push(self.getUdfValues(prop.customerNumber, prop.validationGroup, value, true).then(function (results) {
                            return { id: id, value: value, valid: results.length > 0 };
                        }));
                    } else {
                        // already verified
                    }
                }
            });
            if (promises.length) {
                var invalid = [];
                $q.all(promises).then(function(results){
                    angular.forEach(results, function (result) {
                        if (!$rootScope.udfSearch[result.id]) {
                            $rootScope.udfSearch[result.id] = {
                                searching: false,
                                status: ''
                            };
                        }
                        if (!result.valid) {
                            invalid.push(result.id);
                            $rootScope.udfSearch[result.id].status = 'Invalid value';
                        } else {
                            $rootScope.verifiedUdfs[result.id].push(result.value);
                        }
                        $rootScope.udfSearch[result.id].searching = false;
                    });
                    if (!invalid.length) {
                        deferral.resolve(true);
                    } else {
                        deferral.reject(invalid);
                    }
                }).catch(function (err) {
                    deferral.reject(err);
                });
            } else {
                deferral.resolve(true);
            }
            return deferral.promise;
        };

        self.initUdfData = function (scope, custno, cart, lines, source) {
            scope.udfProperties = {};
            $rootScope.invalidLineProps = false;
            scope.loadingUdfLines = false;
            scope.loadingUdfHeader = false;
            scope.visibleUdfHeader = 0;
            if (!scope.data) {
                scope.data = {};
            }

            if (('cart' === source && (!cart || !lines.length || !scope.user)) ||
                ('scan' === source && (!cart || !lines.length)) ||
                (scope.punchoutHook && 'quote' !== scope.cartType)
            ) { return; }
            scope.loadingUdfLines = scope.loadingUdfHeader = true;
            // Header properties
            self.getUdfProperties(custno, 'H').then(function (results) {
                if (results && results.length) {
                    scope.udfProperties.header = results;

                    // initialize header properties
                    if (cart && cart.header_properties) {
                        var validUdf = [];
                        // make sure only valid UDFs are in cart
                        Object.keys(cart.header_properties).map(function (e) {
                            validUdf = scope.udfProperties.header.filter(function (x) {
                                return x.fieldName === e;
                            });
                            if (!validUdf.length) {
                                delete cart.header_properties[e];
                            }
                        });
                    }
                    scope.data.header_properties = cart.header_properties ? cart.header_properties : {};
                    angular.forEach(scope.udfProperties.header, function (val) {
                        var key = val.fieldName;
                        if (val.inputType !== 'hidden') {   // as long as one udf is NOT hidden, display the page label
                            scope.visibleUdfHeader++;
                        }
                        if (!cart.header_properties || !cart.header_properties[key]) {
                            scope.data.header_properties[key] = {
                                'label': val.fieldLabel,
                                'value': val.value
                            };
                        }

                    });
                } else {
                    scope.data.header_properties = null;
                }
                // variables for udfForm.html
                scope.udfForm = {
                    properties: scope.udfProperties.header,
                    values: scope.data.header_properties
                };
                scope.loadingUdfHeader = false;
            });

            // Line properties
            self.getUdfProperties(custno, 'L').then(function (results) {
                if (results && results.length) {
                    scope.udfProperties.line = results;

                    // initialize line properties
                    angular.forEach(scope.udfProperties.line, function (val) {
                        var key = val.fieldName;
                        angular.forEach(lines, function(line) {
                            if (!line.line_properties) {
                                line.line_properties = {};
                            }

                            // special case for Raytheon
                            if (key === 'RAY_MANUFACTURER' && !!line.manufacturer) {
                                val.value = line.manufacturer;
                            }

                            if (!line.line_properties[key]) {
                                line.line_properties[key] = {
                                    'label': val.fieldLabel,
                                    'value': val.value
                                };
                            }

                            if (val.required && !line.line_properties[key].value) {
                                $rootScope.invalidLineProps = true;
                            }
                        });
                    });
                } else {
                    angular.forEach(lines, function(line) {
                        line.line_properties = null;
                    });
                }
                if ('cart' === source) {
                    CartService.updateCartCache(cart);
                }
                scope.loadingUdfLines = false;
            });

            $rootScope.udfLookup = function (prop, value) {
                var id = prop.fieldName;

                $rootScope.udfSearch = {};
                $rootScope.udfSearch[id] = {
                    active: true,
                    searching: true,
                    status: '',
                    results: []
                };

                if (value) {
                    $rootScope.udfSearch[id].status = 'Searching...';
                    self.getUdfValues(custno, prop.validationGroup, value).then(function (results) {
                        $rootScope.udfSearch[id].results = results;
                        $rootScope.udfSearch[id].searching = false;
                        $rootScope.udfSearch[id].status = results.length + ' results found';
                    }).catch(function() {
                        $rootScope.udfSearch[id].searching = false;
                        $rootScope.udfSearch[id].status = 'Error retrieving results';
                    });
                } else {
                    $rootScope.udfSearch[id].searching = false;
                }
            };

            $rootScope.setUdfValue = function (prop, val) {
                $rootScope.udfSearch = {};
                prop.value = val;
            };
        };

        self.initUdfLineEdit = function (scope, cart, lines, line, source, lineProperties, modal) {
            scope.loading = false;
            scope.closeModal = function () {
                $rootScope.udfSearch = {};
                modal.dismiss();
            };

            scope.line = line;
            scope.udfForm = {
                properties: lineProperties,
                values: angular.copy(scope.line.line_properties)
            };

            // must convert json date (string) into actual js date
            angular.forEach(scope.udfForm.properties, function(val) {
                var key = val.fieldName;
                var dateVal = scope.udfForm.values[key].value;
                if ('date' === val.dataType && dateVal && '[object Date]' !== Object.prototype.toString.call(dateVal)) {
                    scope.udfForm.values[key].value = new Date(dateVal);
                }
            });

            scope.disableSave = function() {
                var disable = scope.editLinePropertiesForm.$invalid;
                if (!disable && $rootScope.udfSearch) {
                    angular.forEach($rootScope.udfSearch, function (data) {
                        if (data.searching) {
                            disable = true;
                            return;
                        }
                    });
                }
                return disable;
            };

            scope.datepicker = {};
            scope.datepicker.open = function($event) {
                $event.preventDefault();
                $event.stopPropagation();
                scope.datepicker.opened = !scope.datepicker.opened;
            };

            scope.save = function () {
                self.verifyUdfValues(scope.udfForm.properties, scope.udfForm.values).then(function() {
                    scope.line.line_properties = angular.copy(scope.udfForm.values);
                    if ('scan' !== source) {
                        CartService.updateCartLine(cart.session_id, scope.line).then(function() {
                        }, function() {
                            $rootScope.$broadcast('notify', { message: "We were unable to update the order line properties for this item. Please try again." });
                        });

                        CartService.updateCartCache(cart);
                    }

                    scope.checkRequiredLineProps();
                    scope.closeModal();
                }).catch(function(invalid) {
                    $rootScope.$broadcast('notify', { message: 'One or more of the values is invalid. Please try again.' });
                    angular.forEach(invalid, function (id) {
                        if(angular.isDefined(scope.editLinePropertiesForm[id])){
                            scope.editLinePropertiesForm[id].$setValidity('pattern', false);
                        }
                    });
                });
            };

            scope.fillDown = function () {
                if (confirm('These settings will be applied to this and all subsequent line items. Is this what you want to do?')) {
                    self.verifyUdfValues(scope.udfForm.properties, scope.udfForm.values).then(function() {
                        var updateItem = false;
                        var promises = [];

                        // identify hidden fields for later processing
                        var hidden = [];
                        angular.forEach(scope.udfForm.properties, function(prop) {
                            if (prop.inputType === 'hidden') {
                                hidden.push(prop.fieldName);
                            }
                        });

                        angular.forEach(lines, function (cartLine) {
                            if (cartLine.id === scope.line.id) {
                                updateItem = true;
                            }
                            if (updateItem) {
                                var newValues = angular.copy(scope.udfForm.values);
                                // do not overwrite hidden field values
                                angular.forEach(hidden, function (hiddenField) {
                                    newValues[hiddenField].value = cartLine.line_properties[hiddenField].value;
                                });
                                cartLine.line_properties = newValues;

                                if ('scan' !== source) {
                                    promises.push(CartService.updateCartLine(cart.session_id, cartLine));
                                }
                            }
                        });

                        if ('scan' !== source) {
                            if (promises.length) {
                                $q.all(promises).then(function () {
                                }, function () {
                                    $rootScope.$broadcast('notify', {message: "We were unable to update the order line properties. Please try again."});
                                });
                            }

                            CartService.updateCartCache(cart);
                        }
                        scope.checkRequiredLineProps();
                        scope.closeModal();
                    }).catch(function(invalid) {
                        $rootScope.$broadcast('notify', { message: 'One or more of the values is invalid. Please try again.' });
                        angular.forEach(invalid, function (id) {
                            if(angular.isDefined(scope.editLinePropertiesForm[id])) {
                                scope.editLinePropertiesForm[id].$setValidity('pattern', false);
                            }
                        });
                    });
                }
            };

            scope.checkRequiredLineProps = function () {
                if ($rootScope.invalidLineProps) {
                    // get id for each required property
                    var reqProps = [];
                    angular.forEach(scope.udfForm.properties, function (p) {
                        if (p.required) {
                            reqProps.push(p.fieldName);
                        }
                    });

                    var allValid = true;
                    angular.forEach(lines, function (line) {
                        if (allValid) {
                            angular.forEach(reqProps, function(p) {
                                if (!line.line_properties[p].value) {
                                    allValid = false;
                                }
                            });
                        }
                    });
                    $rootScope.invalidLineProps = !allValid;
                }
            };
        };

        self.setHiddenValue = function (udf, scope, values) {
            if (!udf || udf.inputType !== 'hidden' || !scope.user) {
                return;
            }
            var id = udf.fieldName;
            var value = '';
            var cdf;
            switch (id) {
                case 'EMAIL_203467':
                    value = scope.user.username;
                    break;
                case 'NAME_203467':
                    value = scope.user.name;
                    break;
                case 'PHONE_203467':
                    value = scope.user.phone;
                    break;
                case 'DEPARTMENT_203467':
                    value = scope.data && scope.data.department ? scope.data.department.department_key : null;
                    break;
                case 'HEM_LGTH_203467':
                    if (scope.user.cdf) {
                        cdf = scope.user.cdf.find(function(o) {
                            return o.cdfId === 4;
                        });
                        value = cdf ? cdf.value : null;
                    }
                    break;
                case 'PATCH_NAME_203467':
                    if (scope.user.cdf) {
                        cdf = scope.user.cdf.find(function(o) {
                            return o.cdfId === 6;
                        });
                        value = cdf ? cdf.value : null;
                    }
                    break;
            }
            if (value) {
                if (!values[id]) {
                    values[id] = {
                        label: id,
                        value: ''
                    }
                }
                values[id].value = value;
            }
        };
    }
]);
